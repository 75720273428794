import { IIssueMessageDoc } from './IssueMessageDoc.model.interface';

export class IssueMessageDoc implements IIssueMessageDoc {
  readonly docId: string | null;
  readonly docName: string;
  readonly docType: string;
  readonly creationDate: Date;
  readonly metadata: string;

  constructor(data?: IIssueMessageDoc) {
    this.docId = data?.docId || '';
    this.docName = data?.docName || '';
    this.docType = data?.docType || '';
    this.creationDate = data?.creationDate || new Date();
    this.metadata = data?.metadata || '';
  }
}
