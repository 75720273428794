export * from './communications/communications-routing.module';
export * from './communiques/communiques-routing.module';
export * from './documentation/documentation-routing.module';
export * from './global-position/global-position-routing.module';
export * from './liquidations/liquidations-routing.module';
export * from './orders/orders-routing.module';
export * from './profile/profile-routing.module';
export * from './sandbox/sandbox-routing.module';

/**
 * This folder contains modules, which help organize and structure the application's.
 * Modules group related functionality, making it easier to manage and scale the application.
 * They define clear boundaries and encapsulate specific features or sections of the app, promoting modularity and maintainability.
 */
