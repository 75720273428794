import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from '@sl/app';
import { environment } from '@sl/environment';
import { SharedModule } from '@sl/shared';
import {
  AmsAnalyticsModule,
  // AmsErrorHandlerModule,
  AmsHttpClientModule,
  AmsLoggerModule,
  AmsSecurityModule,
  // IAmsErrorHandlerConfig,
  IAmsGoogleAnalyticsConfig,
  IAmsKeycloakFullConfig,
  IAmsLoggerConfig
} from 'ams-core-front';
import { ButtonModule } from 'primeng/button';
import { TranslocoRootModule } from '../transloco-root.module';
import { SpinnerInterceptor } from './interceptors/spinners.interceptor';
import { LayoutSidebarComponent } from './layout';

const primeNGModules: any[] = [ButtonModule];

/**
 * Datos de configuración del Google Analitics
 *
 * @author Arquitectura Empresarial - Santalucía
 * @type {IAmsGoogleAnalyticsConfig}
 */
const amsAnalyticsConfig: IAmsGoogleAnalyticsConfig = {
  environment
};

// /**
//  * Datos de configuración del keycloak recuperados del environment
//  *
//  * @author Arquitectura Empresarial - Santalucía
//  * @type {IAmsKeycloakFullConfig}
//  */
const amsKeycloakFullConfig: IAmsKeycloakFullConfig = {
  environment
};

/**
 * Datos de configuración del logger recuperados del environment
 *
 * @author Arquitectura Empresarial - Santalucía
 * @type {IAmsLoggerConfig}
 */
const amsLoggerConfig: IAmsLoggerConfig = {
  environment
};

/**
 * Datos de configuración del control de errores recuperados del environment
 *
 * @author Arquitectura Empresarial - Santalucía
 * @type {IAmsErrorHandlerConfig}
 */
// const amsErrorHandlerConfig: IAmsErrorHandlerConfig = {
//   environment
// };

/**
 * Listado de módulos a importar como parte del núcleo de la aplicación
 *  - AmsAnalyticsModule: el módulo para la gestión de Goggle Analitics
 *  - AmsErrorHandlerModule: el Módulo para la gestión de errores
 *  - AmsHttpClientModule: el módulo para la gestión de llamadas HTTP
 *  - AmsLoggerModule: el módulo de gestión de logs
 *  - AmsSecurityModule: el módulo para la gestión de seguridad de keycloak
 *  - ServiceWorkerModule: e módulo para la gestión del service worker y las PWA
 *
 * @author Arquitectura Empresarial - Santalucía
 * @type {any[]}
 */
const amsLibraryModules: any[] = [
  AmsAnalyticsModule.forRoot(amsAnalyticsConfig), // Google Analytics Module
  // AmsErrorHandlerModule.forRoot(amsErrorHandlerConfig), // Error Handler Module --> control global de errores
  AmsHttpClientModule, // HTTP Module --> control de ejecuciones en curso, control de errores e interceptor para cabecera 'X-Request-ID'
  AmsLoggerModule.forRoot(amsLoggerConfig), // Logger Module --> log de la aplicación
  AmsSecurityModule.forRoot(amsKeycloakFullConfig), // Keycloak Module --> gestión de la seguridad
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: environment.production,
    // Register the ServiceWorker as soon as the application is stable
    // or after 30 seconds (whichever comes first).
    registrationStrategy: 'registerWhenStable:30000'
  }) // ServiceWorker && PWA module
];

// const PIPES = [];

// const COMPONENTS = [];

// const DIRECTIVES = [];

const MODULES = [...primeNGModules, CommonModule, TranslocoRootModule];

/**
 * Módulo que gestiona los módulos principales a usar en la aplicación y que se incluyen en el app.module.ts
 * Incorpora toda la inyección de los módulos de la librería ams-core-front
 * ---------------------------------------------------------------------------------------------------------
 * ---------------------- SE DEBEN ELIMINAR TODOS LOS MÓDULOS QUE NO SEAN NECESARIOS -----------------------
 * ---------------------------------------------------------------------------------------------------------
 *
 * @author Arquitectura Empresarial - Santalucía
 * @class CoreModule
 * @typedef {CoreModule}
 */
@NgModule({
  declarations: [
    // ...PIPES,
    // ...DIRECTIVES,
    // ...COMPONENTS
  ],
  imports: [...amsLibraryModules, ...MODULES, AppRoutingModule, SharedModule, LayoutSidebarComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    }
  ],
  exports: [
    // ...PIPES,
    // ...DIRECTIVES,
    // ...MODULES,
    // ...COMPONENTS
    LayoutSidebarComponent
  ],
  entryComponents: [
    // ...COMPONENTS
    LayoutSidebarComponent
  ]
})
export class CoreModule {}
