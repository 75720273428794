/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderBillOrder } from '../../domain/orderBillOrder.model';
import { OrderBillOrderEntity } from '../entities/orderBillOrder.entity';
import { IOrderBillOrderMapper } from './orderBillOrder.mapper.interface';
import { OrderBillOrderStatusMapper } from './orderBillOrderStatus.mapper';

@Injectable({ providedIn: 'root' })
export class OrderBillOrderMapper implements IOrderBillOrderMapper {
  constructor(private readonly orderBillOrderStatusMapper: OrderBillOrderStatusMapper) {}

  mapFromApi(apiData: OrderBillOrderEntity): OrderBillOrder {
    return new OrderBillOrder({
      orderNumber: apiData.orderNumber,
      proNumber: apiData.proNumber,
      comments: apiData.comments,
      selfCheckingId: apiData.selfCheckingId,
      status: this.orderBillOrderStatusMapper.mapFromApi(apiData.status)
    });
  }

  mapToApi(order: OrderBillOrder): OrderBillOrderEntity {
    return new OrderBillOrderEntity({
      orderNumber: order.orderNumber,
      proNumber: order.proNumber,
      comments: order.comments,
      selfCheckingId: order.selfCheckingId,
      status: this.orderBillOrderStatusMapper.mapToApi(order.status)
    });
  }
}
