<div class="communiques__container">
  <div class="central-content__container">
    <article class="page-container">
      <h1 class="title">{{ 'communiques.title' | transloco }}</h1>
      <ng-container [ngTemplateOutlet]="Filters"></ng-container>
      <ng-container [ngTemplateOutlet]="Communiques"></ng-container>
    </article>
  </div>
</div>

<ng-template #Filters>
  <div class="page-container__filter">
    <sl-date-filter
      class="dropdown-filter"
      [showRangeFilter]="false"
      (selectedDates)="onDatePickerDates($event)"
      (removeSelectedDates)="onDatePickerRemove()"
    ></sl-date-filter>

    <ng-container #CheckboxFilters>
      <div class="page-container__filter__check">
        <div class="page-container__filter__check__buttons">
          <ng-container *ngFor="let option of checkboxActiveStatus.options; trackBy: trackByFn">
            <sl-checkbox
              [value]="option"
              [checked]="option === checkboxActiveStatus.value"
              (modelChange)="onCheckboxActiveStatusFilter($event)"
            >
              {{ 'filter.notificationStatus.' + option | transloco | titlecase }}
            </sl-checkbox>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <!-- ! Use this instead <sl-button-filter-communiques></sl-button-filter-communiques> -->
    <ng-container [ngTemplateOutlet]="ButtonFilterCommuniques"></ng-container>
  </div>
</ng-template>

<ng-template #Communiques>
  <ng-container *ngIf="filteredCommuniques.length; else NoCommuniques">
    <div class="communiques-content__container">
      <div class="communiques-content__container__left" [style.display]="isDetail ? 'none' : 'flex'">
        <ng-container #CommuniqueList *ngFor="let communique of filteredCommuniques; let i = index; trackBy: trackByFn">
          <div class="communiques-content__container__left__cell">
            <sl-communique-card
              [communique]="communique"
              [searchWord]="searchByWord.value"
              [isSelected]="selectedCommunique?.notificationId === communique.notificationId"
              (click)="onCommuniqueCardClick(i)"
            ></sl-communique-card>
          </div>
        </ng-container>
      </div>

      <ng-container #CommuniqueDetail *ngIf="showDetail">
        <div class="communiques-content__container__rigth" [style.display]="(windowWidth < 599 && !isDetail) ? 'none' : 'flex'">
          <div class="communiques-content__container__rigth__title">
            <span>
              <i
                class="pi icon-arrow-left"
                [style.cursor]="'pointer'"
                (click)="onButtonBackDetailClick()"
                (keypress)="onButtonBackDetailClick"
              >
              </i>
              {{ selectedCommunique?.title }}
            </span>
            <span>{{ selectedCommunique?.date }} {{ selectedCommunique?.hour }}</span>
          </div>

          <div class="communiques-content__container__rigth__content">
            <p>{{ selectedCommunique?.content }}</p>
            <span
              class="communiques-content__container__rigth__content__document"
              (click)="onDownloadDocument(selectedCommunique)"
              (keypress)="onDownloadDocument(selectedCommunique)"
            >
              <i *ngIf="selectedCommunique?.documentId" class="pi icon-adjunto"></i>
              {{ selectedCommunique?.documentName }}
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #NoCommuniques>
    <span class="noData">{{ 'communiques.noData' | transloco }} </span>
  </ng-template>
</ng-template>

<p-progressSpinner
  *ngIf="(isLoading$ | async) || (spinnerService.getLoadingObservable(downloadDocumentId)| async)"
  [style]="{width: '100px', height: '100px'}"
  styleClass="custom-spinner"
  strokeWidth="2"
  animationDuration="1s"
></p-progressSpinner>
<sl-error-modal
  *ngIf="showErrorModal"
  [errorMessage]="'errorModal.message' | transloco"
  (closeModal)="showErrorModal = false"
></sl-error-modal>

<!-- TODO: Extract to button-filter-communiques.component -->
<ng-template #ButtonFilterCommuniques>
  <div class="page-container__filter__options">
    <sl-button [type]="'link'" [icon]="showFilters ? 'menos' : 'mas'" (click)="showFilters = !showFilters;"> {{ 'Filtros' }} </sl-button>

    <!-- Panel -->
    <ng-container #Panel *ngIf="showFilters">
      <div class="page-container__filter__container">
        <span>{{ 'filter.states' | transloco }}</span>

        <ng-container #ReadStateFilter>
          <div class="page-container__filter__container__states">
            <div class="page-container__filter__container__states__item">
              <ng-container *ngFor="let option of checkboxReadState.options; trackBy: trackByFn">
                <sl-checkbox
                  [value]="option"
                  [checked]="option === checkboxReadState.value"
                  (modelChange)="onCheckboxReadStateChange($event)"
                >
                  {{ 'filter.notificationStatus.' + option | transloco | titlecase }}
                </sl-checkbox>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container #SearchByWord>
          <span>{{ 'filter.searchByWord' | transloco }}</span>
          <div
            class="page-container__filter__search"
            [ngClass]="{'page-container__filter__search__border': searchByWord.focusInput}"
            (focusin)="searchByWord.focusInput = true"
            (focusout)="searchByWord.focusInput = false;"
          >
            <i class="pi icon-buscar"></i>
            <input
              type="text"
              [placeholder]="searchByWord.placeholder | transloco"
              (input)="onSearchByWord()"
              [(ngModel)]="searchByWord.value"
            /></div
        ></ng-container>

        <ng-container #Footer>
          <div class="page-container__filter__container__buttons">
            <sl-button [type]="'secondary'" [size]="'s'" (click)="onDiscardFilters()">
              {{ 'button.discard' | transloco | titlecase }}
            </sl-button>
            <sl-button [type]="'primary'" [size]="'s'" (click)="onSubmitFilters()">
              {{ 'button.accept' | transloco | titlecase }}
            </sl-button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
