<div class="item-component__container">
  <ng-container *ngFor="let tracking of orderTrackings; let i = index; trackBy: trackByFn; first as isFirst; last as isLast">
    <div *ngIf="tracking.show" class="item-component__container__step">
      <!-- If Pending-->
      <div
        *ngIf="tracking.status === OrderSummaryStatusStatus.Pending && i === 0"
        class="item-component__container__step__circle"
        [style.background]="tracking.visited ? 'var(--color-type5)' : '#DCDCDC'"
        [style.color]="tracking.visited ? '#28344A' : '#525252'"
      >
        <span>{{ PENDING_CIRCLE_NUMBER }}</span>
        <div
          class="item-component__container__step__dashed"
          [class.final-state]="isLast"
          [class.intermediate-state]="!isLast && !isFirst"
          [style.border-color]="tracking.visited ? 'var(--color-primary6)' : '#C0BBBB'"
          [ngClass]="{ 'first-state': i === 0, 'intermediate-state': i !== 0 }"
        ></div>
      </div>

      <div
        *ngIf="tracking.status === OrderSummaryStatusStatus.Pending && i !== 0"
        class="item-component__container__step__modified"
        [style.background]="tracking.visited ? 'var(--color-type6)' : '#C0BBBB'"
      >
        <div
          class="item-component__container__step__dashed"
          [class.final-state]="isLast"
          [class.intermediate-state]="!isLast && !isFirst"
          [style.border-color]="tracking.visited ? 'var(--color-primary6)' : '#C0BBBB'"
          [ngClass]="{ 'first-state': i === 0, 'intermediate-state': i !== 0 }"
        ></div>
      </div>

      <!-- If not last && not modified -->
      <!-- <div
        *ngIf="i !== orderTrackings.length - 1 && tracking.status !== OrderSummaryStatusStatus.Modified"
        class="item-component__container__step__circle"
        [style.background]="tracking.visited ? 'var(--color-type5)' : '#DCDCDC'"
        [style.color]="tracking.visited ? '#28344A' : '#525252'"
      >
        <span>{{ tracking.num }}</span>
        <div
          class="item-component__container__step__dashed"
          [style.border-color]="tracking.visited ? 'var(--color-primary6)' : '#C0BBBB'"
          [ngClass]="{ 'first-state': i === 0, 'intermediate-state': i !== 0 }"
        ></div>
      </div> -->

      <!-- If not Pending or ReviewPending -->
      <div
        *ngIf="tracking.status !== OrderSummaryStatusStatus.Pending && tracking.status !== OrderSummaryStatusStatus.Revise"
        class="item-component__container__step__modified"
        [style.background]="tracking.visited ? 'var(--color-type6)' : '#C0BBBB'"
      >
        <div
          class="item-component__container__step__dashed"
          [class.final-state]="isLast"
          [class.intermediate-state]="!isLast && !isFirst"
          [style.border-color]="tracking.visited ? 'var(--color-primary6)' : '#C0BBBB'"
          [ngClass]="{ 'first-state': i === 0, 'intermediate-state': i !== 0 }"
        ></div>
      </div>

      <!-- If ReviewPending -->
      <div
        *ngIf="tracking.status === OrderSummaryStatusStatus.Revise"
        class="item-component__container__step__circle"
        [style.background]="tracking.visited ? 'var(--color-primary6)' : 'var(--color-grey9)'"
      >
        <!--<span *ngIf="i !== orderTrackings.length - 1">{{ tracking.num }}</span>-->
        <span [style.color]="'var(--color-white)'">
          <i class="pi icon-check"></i>
        </span>
        <div
          class="item-component__container__step__dashed"
          [class.final-state]="isLast"
          [class.intermediate-state]="!isLast && !isFirst"
          [style.border-color]="tracking.visited ? 'var(--color-primary6)' : '#C0BBBB'"
          [ngClass]="{ 'first-state': i === 0, 'intermediate-state': i !== 0 }"
        ></div>
      </div>

      <div class="item-component__container__step__info">
        <span [style.color]="tracking.visited ? 'var(--color-primary6)' : '#AAAAAA'">{{
          tracking.title ? ('orderStates.' + tracking.title | transloco) : ''
        }}</span>
        <!-- <span [style.color]="tracking.visited ? '#707070' : '#C6C2C2'">{{ 'orderStates.subtitle.' + tracking.subtitle | transloco }}</span> -->
        <span [style.color]="tracking.visited ? '#707070' : '#C6C2C2'">{{ tracking.date }}</span>
      </div>
    </div>
  </ng-container>
</div>
