import { IOrderConcept } from './orderConcept.model.interface';

export class OrderConcept implements IOrderConcept {
  readonly id: string;
  readonly description: string;
  readonly units: string;
  readonly tax: number;
  readonly netAmount: number;
  readonly totalAmount: number;
  readonly requestCode: string;
  readonly supplied: boolean;

  constructor(data: IOrderConcept) {
    this.id = data.id;
    this.description = data.description;
    this.units = data.units;
    this.tax = data.tax;
    this.netAmount = data.netAmount;
    this.totalAmount = data.totalAmount;
    this.requestCode = data.requestCode;
    this.supplied = data.supplied;
  }
}
