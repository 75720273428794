/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, take } from 'rxjs';
import { ILocalStorageStore, LocalStoragePlacesState } from '.';
import { Place } from '../../features/place';
import { StorageService } from '../../services/storage';

const STORAGE_KEY = {
  places: 'places'
};

const initialStatePlaces: LocalStoragePlacesState = {
  places: null,
  error: null,
  loading: false
};

@Injectable({ providedIn: 'root' })
export class LocalStorageStore implements ILocalStorageStore {
  private readonly _places$ = new BehaviorSubject<LocalStoragePlacesState>(initialStatePlaces);
  readonly places$ = this._places$.asObservable();

  constructor(private readonly storageService: StorageService) {
    this.initStore();
  }

  /** Selectors */

  selectPlaces(): Observable<LocalStoragePlacesState> {
    return this.places$;
  }

  getPlaces(): Place[] | null {
    return this._places$.getValue().places;
  }

  /* Reducer */

  updatePlaces(newState: Partial<LocalStoragePlacesState>): void {
    const currentState = this._places$.getValue();
    this._places$.next({ ...currentState, ...newState });
  }

  /** Actions */

  actionClear(): void {
    this.storageService.clear();
    this.updatePlaces({ places: null, loading: false, error: null });
  }

  actionGetPlaces(): void {
    this.updatePlaces({ loading: true, error: null });

    this.storageService
      .getLocalStorageItem<Place[]>(STORAGE_KEY.places)
      .pipe(
        filter((places): places is Place[] => Boolean(places)),
        take(1)
      )
      .subscribe({
        next: (places) => {
          this.updatePlaces({ loading: false, places: [...places] });
        },
        error: (error) => this.updatePlaces({ loading: false, error })
      });
  }

  actionSetPlaces(places: Place[]): void {
    this.updatePlaces({ loading: true, error: null });

    this.storageService
      .setLocalStorageItem(STORAGE_KEY.places, places)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.updatePlaces({ loading: false, places: [...places] });
        },
        error: (error) => this.updatePlaces({ loading: false, error })
      });
  }

  private initStore(): void {
    this.actionGetPlaces();
  }
}
