import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '@sl/shared';

@Component({
  selector: 'sl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  socialLinks: MenuOptions[] = [
    { name: 'instagram', url: 'https://www.instagram.com/irisglobal_es/', icon: 'icon-instagram' },
    { name: 'facebook', url: 'https://www.facebook.com/irisglobales', icon: 'icon-facebook' },
    { name: 'Twitter', url: 'https://twitter.com/irisglobal_es', icon: 'icon-Twitter' },
    { name: 'linkedin', url: 'https://www.linkedin.com/company/iris-global-soluciones/', icon: 'icon-linkedin' },
    { name: 'youtube', url: 'https://www.youtube.com/channel/UCgoAjN6Pvou4wA42jmDo1ng', icon: 'icon-youtube' }
  ];

  legalLinks: MenuOptions[] = [
    { name: 'footer.legal', url: 'https://www.irisglobal.es/documentos/aviso-legal-iris-global' },
    { name: 'footer.privacy', url: 'url' },
    { name: 'footer.cookies', url: 'url' }
  ];

  footerLinks: MenuOptions[] = [
    { name: 'web pública', url: 'url' },
    { name: 'preguntas frecuentes', url: 'url' },
    { name: 'información legal', url: 'url' },
    { name: 'política de cookies', url: 'url' },
    { name: 'política sobre protección de datos personales', url: 'url' }
  ];

  constructor(private router: Router) {}

  navigateTo(url?: string) {
    //to do navigate
    this.router.navigate([url]);
  }
}

export interface MenuOptions {
  name: string;
  url?: string;
  icon?: string;
  path?: string;
}
