/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderNoteNoteMessageDoc } from '../../domain/orderNoteNoteMessageDoc.model';
import { OrderNoteNoteMessageDocEntity } from '../entities/orderNoteNoteMessageDoc.entity';
import { IOrderNoteNoteMessageDocMapper } from './orderNoteNoteMessageDoc.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderNoteNoteMessageDocMapper implements IOrderNoteNoteMessageDocMapper {
  mapFromApi(apiData: OrderNoteNoteMessageDocEntity): OrderNoteNoteMessageDoc {
    return new OrderNoteNoteMessageDoc({
      docId: apiData.docId,
      docName: apiData.docName,
      docType: apiData.docType,
      creationDate: new Date(apiData.creationDate),
      metadata: apiData.metadata
    });
  }

  mapToApi(documentMetadata: OrderNoteNoteMessageDoc): OrderNoteNoteMessageDocEntity {
    return new OrderNoteNoteMessageDocEntity({
      docId: documentMetadata.docId,
      docName: documentMetadata.docName,
      docType: documentMetadata.docType,
      creationDate: documentMetadata.creationDate.toISOString(),
      metadata: documentMetadata.metadata
    });
  }
}
