<article class="listing">
  <header class="listing__header">
    <h2 class="listing__title">{{ 'liquidations.title' | transloco }}</h2>
  </header>
  <section class="listing__table">
    <ng-container [ngTemplateOutlet]="Table"></ng-container>
  </section>
  <section class="listing__total">
    <ng-container [ngTemplateOutlet]="TableSummary"></ng-container>
  </section>
</article>

<!-- TODO: extract to components -->
<ng-template #Table>
  <article class="table">
    <header class="table__header">
      <ng-container [ngTemplateOutlet]="Filters"></ng-container>
      <ng-container [ngTemplateOutlet]="FilterTags"></ng-container>
    </header>
    <section
      *ngIf="isDownloading 
          || ((spinnerService.getLoadingObservable('settlements') | async) === false 
            && (spinnerService.getLoadingObservable('orders') | async) === false)"
      class="table__content"
    >
      <sl-table
        [dataTable]="table"
        [showDownloadColumn]="true"
        [tableItemsName]="'liquidations.itemsName' | transloco"
        [totalPages]="totalTablePages"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        (pageChange)="onChangePage($event)"
        (downloadRowClick)="onDownloadRowClick($event)"
      ></sl-table>
    </section>
  </article>
</ng-template>

<ng-template #Filters>
  <div class="filters">
    <sl-date-filter
      class="dropdown-filter"
      [showRangeFilter]="false"
      (selectedDates)="onDatePickerDates($event)"
      (removeSelectedDates)="onDatePickerRemove()"
    ></sl-date-filter>

    <sl-dropdown
      class="dropdown-filter"
      [label]="dropdownStatusFilter.label"
      [placeholder]="dropdownStatusFilter.placeholder"
      [options]="dropdownStatusFilter.options"
      [(value)]="dropdownStatusFilter.value"
      (valueChange)="onDropdownStatusFilter($event)"
    ></sl-dropdown>

    <div class="button-filters">
      <ng-container [ngSwitch]="dropdownStatusFilter.value">
        <ng-container *ngSwitchCase="SETTLEMENT.STATUS.PENDING">
          <sl-button-filter-liquidations-pending
            [filters]="buttonFilterPending"
            (submitFilters)="onSubmitFiltersPending($event)"
            (discardFilters)="onDiscardFiltersPending($event)"
          ></sl-button-filter-liquidations-pending>
        </ng-container>
        <ng-container *ngSwitchCase="SETTLEMENT.STATUS.CLOSED">
          <sl-button-filter-liquidations-closed
            [filters]="buttonFilterClosed"
            (submitFilters)="onSubmitFiltersClosed($event)"
            (discardFilters)="onDiscardFiltersClosed($event)"
          ></sl-button-filter-liquidations-closed>
        </ng-container>
      </ng-container>
      <sl-button [type]="'link'" [icon]="'download'" [disabled]="isDownloading" (clicked)="onDownloadTableClick()">
        {{ 'Descargar' }}
      </sl-button>
    </div>
  </div>
</ng-template>

<ng-template #FilterTags>
  <div class="filter-tags">
    <ng-container *ngFor="let tag of filterTags.tags; trackBy: trackByFn">
      <sl-tag [showClose]="tag.showClose" (clickClose)="onFilterTagClose(tag)">{{ tag.prefix }} {{ tag.label }}</sl-tag>
    </ng-container>
  </div>
</ng-template>

<ng-template #TableSummary>
  <sl-table-summary
    *ngIf="tableSummary.data && tableSummary.data.length > 0 
      && (spinnerService.getLoadingObservable('settlements') | async) === false  
      && (spinnerService.getLoadingObservable('orders')| async) === false"
    class="table-summary"
    [title]="tableSummary.title || ''"
    [subtitle]="tableSummary.subtitle || ''"
    [prefix]="tableSummary.prefix || ''"
    [data]="tableSummary.data || []"
    [currency]="tableSummary.currency || ''"
    (itemClick)="onTableSummaryItemClick($event)"
  ></sl-table-summary
></ng-template>

<p-progressSpinner
  *ngIf="(spinnerService.getLoadingObservable('settlements') | async) 
    || (spinnerService.getLoadingObservable('orders') | async)"
  [style]="{width: '100px', height: '100px'}"
  styleClass="custom-spinner"
  strokeWidth="2"
  animationDuration="1s"
></p-progressSpinner>
<sl-error-modal
  *ngIf="showErrorModal"
  [errorMessage]="'errorModal.message' | transloco"
  (closeModal)="showErrorModal = false"
></sl-error-modal>
