import { Injectable } from '@angular/core';
import { GetNotificationsFilter, GetOrderStatisticsFilter, PlaceStore } from '@sl/services';
import { map, Observable } from 'rxjs';
import { Document, DocumentDetail, DocumentEntity, DocumentMapper } from '../../features/document';
import { DocumentDetailMapper } from '../../features/document/infrastructure/mappers/documentDetail.mapper';
import { GetDocumentsFilter } from '../../features/document/infrastructure/repositories/document.repository.interface';
import { IssueMapper } from '../../features/issue';
import {
  GetIssuesEntityResponse,
  GetIssuesFilter,
  GetIssuesResponse
} from '../../features/issue/infrastructure/repositories/issue.repository.interface';
import { OrderSummaryMapper } from '../../features/order/infrastructure/mappers/orderSummary.mapper';
import {
  GetOrderEntityResponse,
  GetOrderResponse,
  GetOrdersEntityResponse,
  GetOrdersFilter,
  GetOrdersResponse
} from '../../features/order/infrastructure/repositories/order.repository.interface';
import { OrderBill, OrderBillEntity } from '../../features/orderBill';
import { OrderBillMapper } from '../../features/orderBill/infrastructure/mappers/orderBill.mapper';
import { OrderNote, OrderNoteEntity } from '../../features/orderNote';
import { OrderNoteMapper } from '../../features/orderNote/infrastructure/mappers/orderNote.mapper';
import { ProviderData } from '../../features/provider/domain/providerData.model';
import { ProviderDataEntity } from '../../features/provider/infrastructure/entities/providerData.entity';
import { ProviderDataMapper } from '../../features/provider/infrastructure/mappers/providerData.mapper';
import { SettlementMapper } from '../../features/settlement';
import {
  GetSettlementsEntityResponse,
  GetSettlementsFilter,
  GetSettlementsResponse
} from '../../features/settlement/infrastructure/repositories/settlement.repository.interface';
import { ApiService } from '../api';
import { OrderMapper } from './../../features/order/infrastructure/mappers/order.mapper';
export const PREFIX = '/api/v1' as const;

@Injectable({ providedIn: 'root' })
export class ApiClientService {
  constructor(
    private readonly apiService: ApiService,
    private readonly placeStore: PlaceStore,
    private readonly orderMapper: OrderMapper,
    private readonly settlementMapper: SettlementMapper,
    private readonly orderSummaryMapper: OrderSummaryMapper,
    private readonly orderBillMapper: OrderBillMapper,
    private readonly documentMapper: DocumentMapper,
    private readonly documentDetailMapper: DocumentDetailMapper,
    private readonly orderNoteMapper: OrderNoteMapper,
    private readonly issueMapper: IssueMapper,
    private readonly providerDataMapper: ProviderDataMapper
  ) {}

  getOrdersStatistics<T>(statisticsParams: GetOrderStatisticsFilter): Observable<T> {
    const queryParams = {
      placeId: this.getPlaceId(),
      year: statisticsParams.year,
      activity: statisticsParams.activity
    };
    return this.apiService.get({ url: `${PREFIX}/providers/${this.getProviderId()}/places/orders/statistics`, queryParams });
  }

  getOrdersVolumetries<T>(): Observable<T> {
    const queryParams = {
      placeId: this.getPlaceId(),
      volumetries: 'v00006'
    };
    return this.apiService.get({ url: `${PREFIX}/providers/${this.getProviderId()}/places/orders/volumetries`, queryParams });
  }

  getNotifications<T>(params: GetNotificationsFilter): Observable<T> {
    const queryParams = {
      placeId: this.getPlaceId(),
      from: params.from,
      to: params.to,
      status: params.status
    };
    return this.apiService.get({ url: `${PREFIX}/providers/${this.getProviderId()}/places/notifications`, queryParams });
  }

  getOrders(params?: GetOrdersFilter): Observable<GetOrdersResponse> {
    const queryParams = {
      placeId: this.getPlaceId() || null,
      ...params
    };
    return this.apiService
      .get<GetOrdersEntityResponse>({ url: `${PREFIX}/providers/${this.getProviderId()}/places/orders`, queryParams })
      .pipe(
        map((res) => ({
          ...res,
          list: res.list.map((data) => this.orderSummaryMapper.mapFromApi(data))
        }))
      );
  }

  getOrder(orderId: string): Observable<GetOrderResponse> {
    return this.apiService
      .get<GetOrderEntityResponse>({ url: `${PREFIX}/orders/${orderId}` })
      .pipe(map((orderEntity) => this.orderMapper.mapFromApi(orderEntity)));
  }

  getDocuments(params?: GetDocumentsFilter): Observable<Document[]> {
    const queryParams = {
      placeId: this.getPlaceId(),
      ...params
    };
    return this.apiService
      .get<DocumentEntity[]>({ url: `${PREFIX}/providers/${this.getProviderId()}/places/documents`, queryParams })
      .pipe(map((documents) => documents.map((doc) => this.documentMapper.mapFromApi(doc))));
  }

  getDocument(documentId: string): Observable<DocumentDetail> {
    return this.apiService.get({ url: `${PREFIX}/providers/${this.getProviderId()}/places/documents/${documentId}` });
  }

  postDocument(orderId: string, documentDetail: DocumentDetail): Observable<Document> {
    const queryParams = {
      orderId
    };

    return this.apiService.post({
      url: `${PREFIX}/providers/${this.getProviderId()}/places/documents`,
      body: this.documentDetailMapper.mapToApi(documentDetail),
      queryParams
    });
  }

  deleteDocument(documentId: string): Observable<void> {
    return this.apiService.delete({ url: `${PREFIX}/documents/${documentId}` });
  }

  getSettlements(params?: GetSettlementsFilter): Observable<GetSettlementsResponse> {
    const queryParams = {
      placeId: this.getPlaceId(),
      ...params
    };
    return this.apiService
      .get<GetSettlementsEntityResponse>({
        url: `${PREFIX}/providers/${this.getProviderId()}/places/settlements`,
        queryParams
      })
      .pipe(
        map((res) => ({
          ...res,
          list: res.list.map((data) => this.settlementMapper.mapFromApi(data))
        }))
      );
  }

  getIssues(orderId?: string, params?: GetIssuesFilter): Observable<GetIssuesResponse> {
    const queryParams = {
      placeId: this.getPlaceId(),
      orderId,
      ...params
    };

    return this.apiService
      .get<GetIssuesEntityResponse>({ url: `${PREFIX}/providers/${this.getProviderId()}/places/issues`, queryParams })
      .pipe(map((issues) => issues.map((issue) => this.issueMapper.mapFromApi(issue))));
  }

  getProfileData(): Observable<ProviderData> {
    const queryParams = {
      placeId: this.getPlaceId() || null
    };
    return this.apiService
      .get<ProviderDataEntity>({ url: `${PREFIX}/providers/${this.getProviderId()}`, queryParams })
      .pipe(map((response) => this.providerDataMapper.mapFromApi(response)));
  }

  putProfileData<T>(body: any): Observable<void> {
    const queryParams = {};
    return this.apiService.put({ url: `${PREFIX}/providers/${this.getProviderId()}`, body, queryParams });
  }

  getOrderNotes(orderId: string): Observable<OrderNote[]> {
    const queryParams = {};
    return this.apiService
      .get<OrderNoteEntity[]>({ url: `${PREFIX}/orders/${orderId}/notes`, queryParams })
      .pipe(map((res) => res.map((data) => this.orderNoteMapper.mapFromApi(data))));
  }

  postOrderNotes<T = OrderNote[]>(orderId: string, orderNote: OrderNote): Observable<T> {
    const queryParams = {};
    return this.apiService.post({
      url: `${PREFIX}/orders/${orderId}/notes`,
      body: this.orderNoteMapper.mapToApi(orderNote),
      queryParams
    }) as Observable<T>;
  }

  putOrderNote<T = OrderNote[]>(orderId: string, orderNote: OrderNote, messageId: string): Observable<T> {
    const queryParams = {};
    return this.apiService.put({
      url: `${PREFIX}/orders/${orderId}/notes/${messageId}`,
      body: this.orderNoteMapper.mapToApi(orderNote),
      queryParams
    }) as Observable<T>;
  }

  postOrderBills<T>(orderId: string, orderBill: OrderBill): Observable<T> {
    return this.apiService.post<OrderBillEntity>({
      url: `${PREFIX}/orders/${orderId}/bills`,
      body: this.orderBillMapper.mapToApi(orderBill)
    }) as Observable<T>;
  }

  private getProviderId(): string | undefined {
    return this.placeStore.getProviderId();
    //return "00374000";
  }

  private getPlaceId(): string | string[] | undefined {
    return this.placeStore.getPlaceId();
    //return "00123001";
  }
}
