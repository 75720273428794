/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { environment } from '@sl/environment';
import { DocumentDetail, Order } from '@sl/features';
import { SharedModule } from '@sl/shared';
import { getFileContent, trackBy } from '@sl/utils';
import { DOCUMENT } from 'src/app/core/features/document/domain/enums/document.enum';
import { OrderNoteNoteMessage } from 'src/app/core/features/orderNote/domain/orderNoteNoteMessage.model';

@Component({
  selector: 'sl-modal-add-documents',
  templateUrl: './modal-add-documents.component.html',
  styleUrls: ['./modal-add-documents.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAddDocumentsComponent implements OnChanges {
  @ViewChild('generalFileInput') generalFileInput!: ElementRef<HTMLInputElement>;

  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() order: Order | undefined;
  @Input() messages: OrderNoteNoteMessage[] = [];

  @Output() readonly closeModal = new EventEmitter();
  @Output() readonly sendDocuments = new EventEmitter<SendDocumentsData>();

  generalDocuments: Document[] = [];
  generalDocument: Document | null = null;

  // Inputs
  dropdownGeneralDocumentTypes = {
    label: 'dropdown-document-type.label',
    placeholder: 'dropdown-document-type.placeholder',
    showArrow: true,
    options: ['JUSTIFICANTE', 'CORRESPONDENCIA', 'PRESUPUESTO', 'INFORME_TECNICO', 'OTROS_DOCUMENTOS', 'FOTOS'],
    value: null as string | null
  };

  // Constrains
  maxFileSizeBytes = environment.files.maxFileSizeBytes; // 10MB
  maxFileSizeMB = this.maxFileSizeBytes / 1024 / 1024;
  allowedTypeFiles = [
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  showFileError = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['order']?.currentValue !== changes['order']?.previousValue) {
      this.initGeneralDocuments();
      this.cdr.detectChanges();
    }
  }

  // Dropdown
  onDropdownGeneralDocumentTypeChange(value: string): void {
    this.generalDocuments = this.generalDocuments.map((doc) => ({ ...doc, type: value }));
  }

  // General Documents
  onButtonAddGeneralDocumentClick(): void {
    this.generalFileInput.nativeElement.click();
  }

  async onGeneralFileInput(event: any): Promise<void> {
    event.preventDefault();
    this.handleFiles(event);
  }

  async onFileDropped(fileList: FileList): Promise<void> {
    this.handleFiles(fileList);
  }

  onButtonDeleteGeneralDocumentClick(document: Document): void {
    this.generalDocuments = this.generalDocuments.filter(({ temporalId }) => document.temporalId !== temporalId);
  }

  // Action Buttons
  onButtonSendClick(): void {
    this.handleSendDocuments();
  }

  onButtonDeleteClick(): void {
    this.initGeneralDocuments();
  }

  disableButtonSend(): boolean {
    return (
      !this.dropdownGeneralDocumentTypes.value ||
      !this.generalDocuments.length ||
      this.generalDocuments.some((doc) => !doc.file || !this.isAllowedFile(doc.file) || !doc.type)
    );
  }

  // Shared
  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  onCloseModal(): void {
    this.closeModal.emit();
  }

  // Initialization
  private initGeneralDocuments(): void {
    this.generalDocuments = [];
    this.generalDocument = null;
  }

  // Utils
  private isAllowedFile(file: File): boolean {
    return file?.size <= this.maxFileSizeBytes && this.allowedTypeFiles.includes(file?.type);
  }

  private handleSendDocuments(): void {
    const allDocuments = [...this.generalDocuments].filter((doc) => doc.file);
    const documentsData: SendDocumentsData = {
      documentsWithoutIds: allDocuments.map((doc) => mapDocumentToDocumentDetail(doc))
    };
    this.sendDocuments.emit(documentsData);
    this.closeModal.emit();
  }

  private async getFileContent(file: File): Promise<string> {
    const content = await getFileContent(file);
    return content.split(',')[1]; // Just the content after de ',' character
  }

  private async handleFiles(event: FileList | any): Promise<void> {
    const files: File[] = event.length ? Array.from(event) : event.target.files;
    this.showFileError = false;

    for (const file of files) {
      if (!this.isAllowedFile(file)) {
        this.showFileError = true;
        return;
      }

      const content: string = await this.getFileContent(file);

      const newDocument: Document = {
        temporalId: generateId(),
        type: this.dropdownGeneralDocumentTypes.value,
        file,
        content
      };
      this.generalDocuments = [newDocument, ...this.generalDocuments];
    }

    this.cdr.detectChanges();
  }
}

const generateId = (): string => {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 1000000);
  return `${timestamp}-${random}`;
};

const mapDocumentToDocumentDetail = (doc: Document): DocumentDetail => ({
  documentId: '',
  name: doc.file!.name,
  type: doc.type! === 'supplied' ? DOCUMENT.TYPE.SUPLIDO : doc.type!,
  mimeType: doc.file!.type,
  content: doc.content!,
  date: new Date(),
  origin: DOCUMENT.ORIGIN.PROVEEDOR
});

type Document = {
  temporalId: string | null;
  type: string | null;
  file: File | null;
  content: string | null;
};

export type SendDocumentsData = {
  documentsWithoutIds: DocumentDetail[];
};
