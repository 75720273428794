import { OrderSummaryStatusStatus } from './enums/orderSummaryStatus.enum';
import { IOrderSummaryStatus } from './orderSummaryStatus.model.interface';

export class OrderSummaryStatus implements IOrderSummaryStatus {
  readonly status: OrderSummaryStatusStatus;
  readonly date: Date | null;

  constructor(data: IOrderSummaryStatus) {
    this.status = data.status;
    this.date = data.date ? new Date(data.date) : null; // Create a new Object to make sure it is immutable;
  }
}
