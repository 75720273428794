/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { MessageSource } from '../../domain/enums/message.enum';
import { OrderNoteNoteMessage } from '../../domain/orderNoteNoteMessage.model';
import { OrderNoteNoteMessageEntity } from '../entities/orderNoteNoteMessage.entity';
import { IOrderNoteNoteMessageMapper } from './orderNoteNoteMessage.mapper.interface';
import { OrderNoteNoteMessageDocMapper } from './orderNoteNoteMessageDoc.mapper';

@Injectable({ providedIn: 'root' })
export class OrderNoteNoteMessageMapper implements IOrderNoteNoteMessageMapper {
  constructor(private readonly orderNoteNoteMessageDocMapper: OrderNoteNoteMessageDocMapper) {}

  mapFromApi(apiData: OrderNoteNoteMessageEntity): OrderNoteNoteMessage {
    return new OrderNoteNoteMessage({
      messageId: apiData.messageId,
      readDate: apiData.readDate ? new Date(apiData.readDate) : null,
      creationDate: new Date(apiData.creationDate),
      read: apiData.read,
      content: apiData.content,
      docList: apiData.docList ? apiData.docList.map((doc) => this.orderNoteNoteMessageDocMapper.mapFromApi(doc)) : null,
      type: apiData.type,
      subject: apiData.subject,
      source: apiData.source as MessageSource,
      sender: null
    });
  }

  mapToApi(message: OrderNoteNoteMessage): OrderNoteNoteMessageEntity {
    return new OrderNoteNoteMessageEntity({
      messageId: message.messageId!,
      readDate: message.readDate ? message.readDate.toISOString() : null,
      creationDate: message.creationDate.toISOString(),
      read: message.read,
      content: message.content,
      docList: message.docList ? message.docList.map((doc) => this.orderNoteNoteMessageDocMapper.mapToApi(doc)) : null,
      type: message.type,
      subject: message.subject,
      source: message.source
    });
  }
}
