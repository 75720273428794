<div class="detail-modal__container">
  <article class="detail-modal__container__content">
    <div class="detail-modal__container__content__up">
      <!-- Header -->
      <i class="pi icon-cerrar" (click)="onCloseModal()" (keypress)="onCloseModal()"></i>
      <span class="detail-modal__container__content__up__title">{{ title }}</span>
      <p>{{ subtitle }}</p>

      <section class="detail-modal__container__content__up__bill">
        <!-- Billing Documents-->
        <div class="detail-modal__container__content__up__bill__item">
          <span>{{ 'modal.attach.bill' | transloco }}</span>
          <ng-container [ngTemplateOutlet]="BillingDocuments"></ng-container>
        </div>

        <hr class="divisor" />

        <!-- Add General Document -->
        <ng-container [ngTemplateOutlet]="AddGeneralDocument"></ng-container>

        <div class="detail-modal__container__content__up__bill__supply__wrapper">
          <!-- Supplied Documents -->
          <!-- <ng-container [ngTemplateOutlet]="SuppliedDocuments"></ng-container> -->

          <!-- General Documents -->
          <ng-container [ngTemplateOutlet]="GeneralDocuments"></ng-container>
        </div>
      </section>
    </div>

    <footer class="detail-modal__container__content__add" [style.border-top]="'1px solid var(--color-grey9)'">
      <!-- Observations -->
      <ng-container [ngTemplateOutlet]="Observations"></ng-container>

      <!-- Action buttons -->
      <ng-container [ngTemplateOutlet]="ActionButtons"></ng-container>
    </footer>
  </article>
</div>

<ng-template #AddGeneralDocument>
  <div class="detail-modal__container__content__up__bill__doc">
    <span>{{ 'modal.attach.document' | transloco }}</span>
    <button
      class="detail-modal__container__content__up__bill__doc__more"
      type="button"
      (click)="onButtonAddGeneralDocumentClick()"
      (keypress)="onButtonAddGeneralDocumentClick()"
    >
      <i class="pi icon-mas"></i>
      <span>{{ 'modal.button.document' | transloco }}</span>
    </button>
  </div>
</ng-template>

<ng-template #BillingDocuments>
  <ng-container *ngFor="let doc of billingDocuments; trackBy: trackByFn">
    <div class="detail-modal__container__content__up__bill__item__btn-container">
      <input
        #billingFileInput
        type="file"
        style="display: none"
        (click)="billingFileInput.value = ''"
        (input)="onBillingFileInput($event)"
      />
      <sl-button
        [type]="'link'"
        [icon]="doc.file ? 'check' : 'info1'"
        [textElipsis]="true"
        [slTooltip]="['communications.modal.files']"
        (click)="onButtonAddBillingFileClick(doc)"
      >
        {{ doc.file ? doc.file.name : ('modal.upload' | transloco | titlecase) }}
      </sl-button>
      <ng-container *ngIf="!doc.file">
        <div
          class="button-container__side__upload"
          (click)="onButtonAddBillingFileClick(doc)"
          (keypress)="onButtonAddBillingFileClick(doc)"
        >
          <i class="pi icon-upload"></i>
        </div>
      </ng-container>
      <ng-container *ngIf="doc.file">
        <div
          class="button-container__side__upload"
          (click)="onButtonDeleteBillingFileClick(doc)"
          (keypress)="onButtonDeleteBillingFileClick(doc)"
        >
          <i class="pi icon-eliminar"></i>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<!-- <ng-template #SuppliedDocuments>
  <ng-container *ngFor="let doc of suppliedDocuments; trackBy: trackByFn">
    <div class="detail-modal__container__content__up__bill__supply">
      <div class="detail-modal__container__content__up__bill__supply__info">
        <sl-dropdown-filter
          [showArrow]="dropdownSuppliedDocumentTypes.showArrow"
          [filterName]="dropdownSuppliedDocumentTypes.label | transloco"
          [placeholder]="dropdownSuppliedDocumentTypes.placeholder | transloco"
          [dropdownOptions]="dropdownSuppliedDocumentTypes.options"
          [disabled]="dropdownSuppliedDocumentTypes.disabled"
          [(value)]="dropdownSuppliedDocumentTypes.value"
        >
        </sl-dropdown-filter>
      </div>

      <div class="detail-modal__container__content__up__bill__item__btn-container">
        <input
          #suppliedFileInput
          type="file"
          style="display: none"
          (click)="suppliedFileInput.value = ''"
          (input)="onSuppliedFileInput($event)"
        />
        <sl-button
          [type]="'link'"
          [icon]="doc.file ? 'check' : 'info1'"
          [textElipsis]="true"
          [slTooltip]="['communications.modal.files']"
          (click)="onButtonAddSuppliedFileClick(doc)"
        >
          {{ doc.file ? doc.file.name : ('modal.upload' | transloco | titlecase) }}
        </sl-button>

        <ng-container *ngIf="!doc.file">
          <button
            class="button-container__side__upload"
            type="button"
            (click)="onButtonAddSuppliedFileClick(doc)"
            (keypress)="onButtonAddSuppliedFileClick(doc)"
          >
            <i class="pi icon-upload"></i>
          </button>
        </ng-container>
        <ng-container *ngIf="doc.file">
          <button
            class="button-container__side__upload"
            type="button"
            (click)="onButtonDeleteSuppliedDocumentClick(doc)"
            (keypress)="onButtonDeleteSuppliedDocumentClick(doc)"
          >
            <i class="pi icon-eliminar"></i>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template> -->

<ng-template #GeneralDocuments>
  <ng-container *ngFor="let doc of generalDocuments; trackBy: trackByFn">
    <div class="detail-modal__container__content__up__bill__supply">
      <div class="detail-modal__container__content__up__bill__supply__info">
        <sl-dropdown-filter
          [showArrow]="dropdownGeneralDocumentTypes.showArrow"
          [filterName]="dropdownGeneralDocumentTypes.label | transloco"
          [placeholder]="dropdownGeneralDocumentTypes.placeholder | transloco"
          [dropdownOptions]="dropdownGeneralDocumentTypes.options"
          [(value)]="doc.type"
          (valueChange)="onDropdownGeneralDocumentTypeChange(doc, $event!)"
        >
        </sl-dropdown-filter>
      </div>

      <div class="detail-modal__container__content__up__bill__item__btn-container">
        <input
          #generalFileInput
          type="file"
          style="display: none"
          (click)="generalFileInput.value = ''"
          (input)="onGeneralFileInput($event)"
        />
        <sl-button
          [type]="'link'"
          [icon]="doc.file ? 'check' : 'info1'"
          [textElipsis]="true"
          [slTooltip]="['communications.modal.files']"
          (click)="onButtonAddGeneralFileClick(doc)"
        >
          {{ doc.file ? doc.file.name : ('modal.upload' | transloco | titlecase) }}
        </sl-button>

        <ng-container *ngIf="!doc.file">
          <button
            class="button-container__side__upload"
            type="button"
            (click)="onButtonAddGeneralFileClick(doc)"
            (keypress)="onButtonAddGeneralFileClick(doc)"
          >
            <i class="pi icon-upload"></i>
          </button>
        </ng-container>

        <ng-container *ngIf="doc.file">
          <button
            class="button-container__side__upload"
            type="button"
            (click)="onButtonDeleteGeneralDocumentClick(doc)"
            (keypress)="onButtonDeleteGeneralDocumentClick(doc)"
          >
            <i class="pi icon-eliminar"></i>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #Observations>
  <sl-textarea
    [label]="observations.label | transloco"
    [placeholder]="observations.placeholder"
    [maxlength]="observations.maxLength"
    [(value)]="observations.value"
    (keydownEnter)="onTextAreaObservationsEnter()"
  ></sl-textarea>
</ng-template>

<ng-template #ActionButtons>
  <article class="button-container">
    <div class="button-container__erase">
      <sl-button [borderRound]="true" [type]="'secondary'" [size]="'l'" (click)="onButtonDeleteClick()">
        {{ 'Borrar' }}
      </sl-button>
    </div>
    <div class="button-container__side">
      <sl-button [type]="'primary'" [size]="'l'" [borderRound]="true" [disabled]="disableButtonSend()" (click)="onButtonSendClick()">
        {{ 'Enviar' }}
      </sl-button>
    </div>
  </article>
</ng-template>
