/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderBill } from '../../domain/orderBill.model';
import { OrderBillEntity } from '../entities/orderBill.entity';
import { IOrderBillMapper } from './orderBill.mapper.interface';
import { OrderBillClaimMapper } from './orderBillClaim.mapper';
import { OrderBillFileMapper } from './orderBillFile.mapper';
import { OrderBillOrderMapper } from './orderBillOrder.mapper';

@Injectable({ providedIn: 'root' })
export class OrderBillMapper implements IOrderBillMapper {
  constructor(
    private readonly orderBillClaimMapper: OrderBillClaimMapper,
    private readonly orderBillOrderMapper: OrderBillOrderMapper,
    private readonly orderBillFileMapper: OrderBillFileMapper
  ) {}

  mapFromApi(apiData: OrderBillEntity): OrderBill {
    return new OrderBill({
      originRequest: apiData.originRequest,
      claim: this.orderBillClaimMapper.mapFromApi(apiData.claim),
      order: this.orderBillOrderMapper.mapFromApi(apiData.order),
      billFiles: apiData.billFiles.map((billFile) => this.orderBillFileMapper.mapFromApi(billFile))
    });
  }

  mapToApi(orderBill: OrderBill): OrderBillEntity {
    return {
      originRequest: orderBill.originRequest,
      claim: this.orderBillClaimMapper.mapToApi(orderBill.claim),
      order: this.orderBillOrderMapper.mapToApi(orderBill.order),
      billFiles: orderBill.billFiles.map((billFile) => this.orderBillFileMapper.mapToApi(billFile))
    };
  }
}
