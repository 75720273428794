import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

/**
 * @scope **date**
 *
 * formatDate
 * @param date string | Date | Dayjs
 * @param pattern string, example: 'YYYYMMDD'
 * @see https://day.js.org/docs/en/display/format
 * @returns string
 */
export const formatDate = (date: string | Date | Dayjs, pattern: string) => dayjs(date).format(pattern);
