/** Error when invalid control is dirty, touched, or submitted. */

import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
// TODO replace this matcher because is the only one point requiring @angular/material!!
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return Boolean(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
