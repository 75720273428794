<div class="alert alert--{{ type }}">
  <i
    class="icon"
    [ngClass]="{
      'icon-info1': type === 'info',
      'icon-check': type === 'success',
      'pi pi-exclamation-triangle': type === 'warning'
    }"
  >
  </i>
  <span class="message">
    <ng-content></ng-content>
  </span>
  <button *ngIf="closable" class="close" type="button" (click)="closeAlert()" (keypress)="closeAlert()">
    <i class="icon-cerrar close__icon"></i>
  </button>
</div>
