<ng-container [ngSwitch]="isSmallerThanLaptop$ | async">
  <ng-container *ngSwitchCase="true">
    <ng-container [ngTemplateOutlet]="BurgerMenuSidebar"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="false">
    <ng-container [ngTemplateOutlet]="DefaultSidebar"></ng-container>
  </ng-container>
</ng-container>

<ng-template #DefaultSidebar>
  <nav class="sidebar default-sidebar" [style.width]="sidebarSize">
    <!-- Toggle button-->
    <div class="sidebar__toggle" [ngClass]="{ 'sidebar__toggle--center': !showSidebar }">
      <button class="toggle" type="button" (click)="onToggleSidebar()" (keydown.enter)="onToggleSidebar()">
        <i class="pi toggle__icon" [ngClass]="showSidebar ? 'pi-chevron-left' : 'pi-chevron-right'"></i>
      </button>
    </div>

    <!-- Profile Tab -->
    <div class="sidebar__profile">
      <ng-container [ngTemplateOutlet]="ProfileTab"></ng-container>
    </div>

    <!-- Tab Options -->
    <ng-container [ngTemplateOutlet]="TabOptions"></ng-container>
  </nav>
</ng-template>

<ng-template #BurgerMenuSidebar>
  <p-sidebar [visible]="showSidebar" (visibleChange)="setShowSidebar($event)" position="right" styleClass="w-30rem">
    <ng-template pTemplate="content">
      <nav class="burger-menu-sidebar">
        <!-- Place Selector -->
        <div class="burger-menu-sidebar__places">
          <sl-place-selector
            [titleColor]="'var(--color-primary7)'"
            [activeColor]="'var(--color-primary6)'"
            (placeChange)="onPlaceChange()"
          ></sl-place-selector>
        </div>

        <!-- Profile Tab -->
        <div class="burger-menu-sidebar__profile">
          <ng-container [ngTemplateOutlet]="ProfileTab"></ng-container>
        </div>

        <!-- Tab Options -->
        <ng-container [ngTemplateOutlet]="TabOptions"></ng-container>

        <!-- Language -->
        <div class="burger-menu-sidebar__language">
          <span class="language"> <i class="pi icon-idiomas"></i>{{ 'header.lang.es' | transloco }} </span>
        </div>
      </nav>
    </ng-template>
  </p-sidebar>
</ng-template>

<ng-template #ProfileTab>
  <a class="profile" (click)="onSelectedTab(profileTab.route)" (keypress)="onSelectedTab(profileTab.route)">
    <span class="profile__picture"> {{ profileTab.picture }} </span>
    <span *ngIf="showSidebar" class="profile__name" [ngClass]="{ 'profile--active': route === profileTab.route }">
      {{ profileTab.label }}
    </span>
  </a>
</ng-template>

<ng-template #TabOptions>
  <ul class="sidebar__tabs">
    <ng-container *ngFor="let tab of tabOptions; trackBy: trackByFn">
      <li>
        <a
          class="tab"
          (click)="onSelectedTab(tab.route)"
          (keydown.enter)="onSelectedTab(tab.route)"
          [ngClass]="{ 'tab--active': route === tab.route }"
        >
          <div class="tab__label">
            <i [class]="tab.icon"></i>
            <span *ngIf="showSidebar">{{ 'menu.' + tab.route | transloco | titlecase }}</span>
          </div>

          <div *ngIf="tab.counter" class="tab__counter" [ngClass]="{ 'tab__counter--small': !showSidebar }">
            {{ tab.counter }}
          </div>
          <div *ngIf="tab.notifications" class="tab__notification" [ngClass]="{ 'tab__notification--small': !showSidebar }">
            {{ tab.notifications }}
          </div>
        </a>
      </li>
    </ng-container>
  </ul>
</ng-template>
