/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationReadState } from '@sl/services';
import { SharedModule } from '@sl/shared';
import { trackBy } from '@sl/utils';

// TODO: This component
@Component({
  selector: 'sl-button-filter-communiques',
  templateUrl: './button-filter-communiques.component.html',
  styleUrls: ['./button-filter-communiques.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonFilterCommuniquesComponent implements OnInit {
  @Input() filters: FilterNotification = { ...defaultFilters };

  @Output() readonly filtersChange = new EventEmitter<FilterNotification>();
  @Output() readonly submitFilters = new EventEmitter<FilterNotification>();
  @Output() readonly discardFilters = new EventEmitter<FilterNotification>();

  visible = false;
  initialFilters!: FilterNotification;

  /* Section 1 */
  section1 = {
    legend: 'Estados'
  };

  checkListReadState = {
    options: [
      { label: 'Leídos', value: 'READ' },
      { label: 'No Leídos', value: 'UNREAD' }
    ] as CheckListOption<NotificationReadState>[],
    value: null as NotificationReadState | null
  };

  /* Section 2 */
  section2 = {
    legend: 'Búsqueda por palabra'
  };

  searchByWord = {
    label: '',
    placeholder: 'Búsqueda por palabra',
    value: null as string | null,
    focus: false // TODO: Not use this
  };

  constructor() {}

  ngOnInit(): void {
    this.initialFilters = { ...this.filters };
  }

  /* Action Buttons */

  handleSubmitFilters(): void {
    this.submitFilters.emit(this.filters);
    this.closePanel();
  }

  handleRemoveFilters(): void {
    this.resetFilters();
    this.discardFilters.emit(this.filters);
    this.closePanel();
  }

  /* Section 1 */

  onCheckListReadState(value: NotificationReadState): void {
    const isSelectedValue = value === this.checkListReadState.value;
    this.checkListReadState.value = isSelectedValue ? null : value;
    this.filters.status = this.checkListReadState.value;
    this.onChange();
  }

  /* Section 2 */

  onSearchByWordChange(): void {
    this.filters.word = this.searchByWord.value;
    this.onChange();
  }

  /* Utils */

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  /* Private */

  private onChange(): void {
    this.filtersChange.emit(this.filters);
  }

  private closePanel(): void {
    this.visible = false;
  }

  private resetFilters(): void {
    // TODO: Asign this to this.initialFilters counterpart
    this.checkListReadState.value = null;
    this.searchByWord.value = null;
    this.filters = { ...this.initialFilters };
    this.onChange();
  }
}

const defaultFilters: FilterNotification = {
  status: null,
  word: null
};

type FilterNotification = {
  status: string | null;
  word: string | null;
};

type CheckListOption<T> = {
  label: string;
  value: T;
};
