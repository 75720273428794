/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { ProviderIdentification } from '../../domain/providerIdentification.model';
import { ProviderIdentificationEntity } from '../entities/providerIdentification.entity';
import { IProviderIdentificationMapper } from './providerIdentification.mapper.interface';

@Injectable({ providedIn: 'root' })
export class ProviderIdentificationMapper implements IProviderIdentificationMapper {
  mapFromApi(apiData: ProviderIdentificationEntity): ProviderIdentification {
    return new ProviderIdentification({
      ...apiData
    });
  }

  mapToApi(providerIdentification: ProviderIdentification): ProviderIdentificationEntity {
    return new ProviderIdentificationEntity({
      ...providerIdentification
    });
  }
}
