import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { OrderStatistic } from '@sl/features';
import { GetOrderStatisticsFilter } from '@sl/services';
import { SharedModule } from '@sl/shared';

@Component({
  selector: 'sl-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatisticsComponent implements OnChanges {
  @Input() title = 'Situación global encargos';
  @Input() subtitle = 'Total encargos pendientes';
  @Input() barColor = 'var(--color-primary8)';
  @Input() valueColor = 'var(--color-black)';
  @Input() items: OrderStatistic[] = [];
  @Input() loading!: boolean | null;
  @Input() selectedYear = 'allYears';
  @Input() selectedActivity = 'allActivities';

  @Output() statisticsFilterChange = new EventEmitter<GetOrderStatisticsFilter>();

  filterItems: any[] = [];
  maxValue = 0;
  years: string[] = [];
  showOptions = false;
  activities: string[] = [];
  totalNumber = 0;
  currentYearNumber = 0;
  currentYear = new Date().getFullYear().toString();
  filteredYears: any[] = [];
  notCurrentYears: NotCurrentYear[] = [];
  currentYearMonths: OrderStatistic[] = [];
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.items = [...changes['items'].currentValue];
      this.fillActivitiesAndYears();
      this.totalNumber = this.getOrdersNumber();
      this.setMaxValue();
      //this.setCurrentYearMonths();
      if (this.selectedYear === 'allYears') {
        this.setNotCurrentYears();
        this.items = this.getCurrentYearItems(changes['items'].currentValue);
        this.currentYearNumber = this.getOrdersNumber();
      }
      this.groupByMonth();
      this.orderByMonth();
      this.cdr.detectChanges();
    }

    if (changes['selectedYear']) {
      this.selectedYear = changes['selectedYear'].currentValue || 'allYears';
    }
  }

  orderByMonth(): void {
    this.items.sort((a: any, b: any) => {
      const A = a.date.split('/')[0];
      const B = b.date.split('/')[0];
      let response = -1;
      if (parseInt(A, 10) > parseInt(B, 10)) {
        response = 1;
      }
      return response;
    });
  }

  groupByMonth(): void {
    const itemsAux: any[] = [];
    const monthNumber: string[] = [];
    this.items.forEach((statistic) => {
      if (!monthNumber.includes(statistic.date.split('/')[0])) {
        monthNumber.push(statistic.date.split('/')[0]);
        itemsAux.push({
          value: statistic.value,
          date: statistic.date,
          activity: statistic.activity
        });
      } else {
        const foundItem = itemsAux.find((savedStatistic) => savedStatistic?.date.split('/')[0] === statistic.date.split('/')[0]);
        if (foundItem) {
          foundItem.value += statistic.value;
        }
      }
    });

    this.items = [...itemsAux];
  }

  onValueChange(option: string | null, input: string): void {
    if (input === 'year') {
      this.selectedYear = option!;
    } else if (input === 'activity') {
      this.selectedActivity = option!;
    }

    const statisticsFilter: GetOrderStatisticsFilter = {
      year: this.selectedYear === 'allYears' ? null : this.selectedYear,
      activity: this.selectedActivity === 'allActivities' ? null : this.selectedActivity
    };
    this.statisticsFilterChange.emit(statisticsFilter);
    this.showOptions = false;
    this.cdr.detectChanges();
  }

  getTotal(year: string): number {
    let result = 0;
    let filter = [];
    if (this.selectedActivity === 'allActivities') {
      filter = [...this.items.filter((item) => item.date.includes(year))];
      filter.forEach((item: any) => {
        result += item.value;
      });
    } else {
      filter = [...this.items.filter((item) => item.date.includes(year) && item.activity.includes(this.selectedActivity))];
      filter.forEach((item: any) => {
        result += item.value;
      });
    }

    return result;
  }

  getMonthByNumber(monthNumber: number | string): string {
    return this.months[Number(monthNumber) - 1];
  }

  calculateStatisticWidth(itemValue: number): string {
    const valueWidth = (itemValue / this.maxValue) * 100 + '%';
    return valueWidth;
  }

  private getCurrentYearItems(items: any[]): any[] {
    return items.filter((item) => this.getYearFromDate(item.date) === this.currentYear);
  }

  private fillActivitiesAndYears(): void {
    if (this.activities.length <= 1) {
      this.activities = ['allActivities', ...this.getActivities()];
    }
    if (this.years.length <= 1) {
      this.years = ['allYears', ...this.getYears()];
    }
  }

  private getYears(): string[] {
    const years: string[] = [];
    this.items.forEach((item) => {
      if (!years.includes(this.getYearFromDate(item.date))) {
        years.push(this.getYearFromDate(item.date));
      }
    });
    years.sort();
    return years;
  }

  private getActivities(): string[] {
    const activities: string[] = [];
    this.items.forEach((item) => {
      if (!activities.includes(item.activity)) {
        activities.push(item.activity);
      }
    });
    return activities;
  }

  private getOrdersNumber(): number {
    let count = 0;
    this.items.forEach((item: any) => {
      count += item.value;
    });
    return count;
  }

  private setMaxValue(): void {
    this.maxValue = 0;
    this.items.forEach((item: any) => {
      if (item.value > this.maxValue) {
        this.maxValue = item.value;
      }
    });
  }

  private setNotCurrentYears(): void {
    this.notCurrentYears = [];
    this.items.forEach((item) => {
      const itemYear: string = this.getYearFromDate(item.date);
      if (itemYear !== this.currentYear) {
        const foundYear: NotCurrentYear | undefined = this.notCurrentYears.find((year) => year.year === itemYear);
        if (foundYear) {
          foundYear.amount += item.value;
        } else {
          const newYear: NotCurrentYear = {
            year: itemYear,
            amount: item.value
          };
          this.notCurrentYears.push(newYear);
        }
      }
    });
  }

  private getYearFromDate(date: string): string {
    return date.split('/')[1];
  }
}

interface NotCurrentYear {
  year: string;
  amount: number;
}
