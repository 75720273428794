/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@sl/core';
import { ApiService } from '@sl/services';
import { map, Observable } from 'rxjs';
import { ProviderPlacesEntity } from '../entities/providerPlaces.entity';
import { ProviderPlacesMapper } from '../mappers/providerPlaces.mapper';
import { GetPlacesParams, GetPlacesResponse, IPlaceRepository } from './place.repository.interface';
import { PlaceMocks } from './place.repository.mocks';

@Injectable({ providedIn: 'root' })
export class PlaceRepository implements IPlaceRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly placeMocks: PlaceMocks,
    private readonly providerPlaces: ProviderPlacesMapper
  ) {}

  /**
   * Using mappers because interfaces do not ensure the type of the returned object.
   * If isMocked redirects to Mocks Provider (injection is not used to have each request mocked separately)
   */

  getPlaces({ cif }: GetPlacesParams): Observable<GetPlacesResponse> {
    let response;
    if (APP_CONFIG.repository.place.getPlaces.isMocked) {
      response = this.placeMocks.getPlaces({ cif });
    } else {
      response = this.apiService
        .get<ProviderPlacesEntity>({ url: `/api/v1/providers/${cif}/places` })
        .pipe(map((res) => this.providerPlaces.mapFromApi(res)));
    }
    return response;
  }
}
