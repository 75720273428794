import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@sl/shared';
import { Communique } from '../../pages';

@Component({
  selector: 'sl-communique-card',
  templateUrl: './communique-card.component.html',
  styleUrls: ['./communique-card.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommuniqueCardComponent {
  @Input() communique!: Communique;
  @Input() isSelected = false;
  @Input() searchWord!: string;

  constructor() {}
}
