import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe name
 *
 * @export
 * @class CurrencyCustom
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'CurrencyCustom'
})
export class CurrencyCustom implements PipeTransform {
  /**
   * Convierte una cantidad separada por punto a coma.
   *
   * @param {number | string | null | undefined} currency 9999.99
   * @returns {string} '9999,99'
   */
  transform(currency: number | string | null | undefined) {
    if (currency !== null && currency !== undefined) {
      const numeroConDecimales = parseFloat(currency.toString()).toFixed(2);
      // Reemplazar puntos por comas
      const numeroConComas = numeroConDecimales.replace(/\./g, ',');
      // Separar por puntos cada 3 dígitos antes de los números decimales
      const partes = numeroConComas.split(',');
      partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      // Unir las partes de nuevo
      currency = partes.join(',');
    }
    return currency;
  }
}
