import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { FormControlValueAccessorConnector } from '../abstract-control-value-accesor-connector';

@Component({
  selector: 'sl-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaComponent extends FormControlValueAccessorConnector<string> {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() maxlength: string | number | null = null;

  @Output() readonly keydownEnter = new EventEmitter<boolean>();

  constructor(injector: Injector) {
    super(injector);
  }

  get charactersCount(): string {
    return this.maxlength ? `${this.value?.length ?? 0}/${this.maxlength}` : '';
  }

  onInput(): void {
    this.valueChange.emit(this.value);
  }

  onKeydownEnter(): void {
    this.keydownEnter.emit(true);
  }
}
