import { IOrderBillOrderStatusEntity } from './orderBillOrderStatus.entity.interface';
import { OrderBillOrderStatusReasonEntity } from './orderBillOrderStatusReason.entity';

export class OrderBillOrderStatusEntity implements IOrderBillOrderStatusEntity {
  readonly code: string;
  readonly reasons: OrderBillOrderStatusReasonEntity[];

  constructor(data: IOrderBillOrderStatusEntity) {
    this.code = data.code;
    this.reasons = data.reasons.map((r) => new OrderBillOrderStatusReasonEntity(r));
  }
}
