export * from './angular';
export * from './date';
export * from './files';
export * from './forms';
export * from './math';
export * from './string';

/**
 * This folder contains shared utilities that are used across different parts of the application.
 * It promotes code reuse and maintains consistency in the user interface and functionality.
 */
