import { IOrderNoteEntity } from './orderNote.entity.interface';
import { OrderNoteInfoEntity } from './orderNoteInfo.entity';
import { OrderNoteNoteEntity } from './orderNoteNote.entity';

export class OrderNoteEntity implements IOrderNoteEntity {
  readonly originRequest: string;
  readonly order: OrderNoteInfoEntity;
  readonly note: OrderNoteNoteEntity[];

  constructor(data: IOrderNoteEntity) {
    this.originRequest = data.originRequest;
    this.order = new OrderNoteInfoEntity(data.order);
    this.note = data.note?.map((note) => new OrderNoteNoteEntity(note));
  }
}
