import { IIssueEntity } from './issue.entity.interface';
import { IssueMessageEntity } from './issueMessage.entity';

export class IssueEntity implements IIssueEntity {
  readonly id: string;
  readonly description: string;
  readonly type: string;
  readonly nis: string;
  readonly policy: string;
  readonly date: string | null;
  readonly closingDate: string | null;
  readonly client: string;
  readonly status: string;
  readonly messages: IssueMessageEntity[];
  readonly orderId: string;
  readonly affectedName: string;

  constructor(data: IIssueEntity) {
    this.id = data.id;
    this.description = data.description;
    this.type = data.type;
    this.nis = data.nis;
    this.policy = data.policy;
    this.date = data.date;
    this.closingDate = data.closingDate;
    this.client = data.client;
    this.status = data.status;
    this.messages = data.messages.map((message) => new IssueMessageEntity(message)); // Create a new object to make sure it is immutable
    this.orderId = data.orderId;
    this.affectedName = data.affectedName;
  }
}
