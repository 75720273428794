<div class="position-container" [style.justifyContent]="positionStart ? 'flex-start' : 'flex-end'">
  <div
    class="message-container"
    [style.backgroundColor]="positionStart ? (message?.read ? 'var(--color-primary13)' : 'var(--color-type10)') : 'var(--color-secondary8)'"
  >
    <ng-container *ngIf="message">
      <div class="message-container__info">
        <div>
          <i [class]="positionStart ? (message.read ? 'pi icon-nota-leida' : 'pi icon-nota-sin-leer') : 'pi icon-nota-enviada'"></i>
          <span>{{ (positionStart ? 'message.recieved' : 'message.send') | transloco }} {{ actualDate }} {{ actualTime }}</span>
        </div>
        <span *ngIf="file?.docName" class="download" (click)="onDownloadFile(file)" (keypress)="onDownloadFile(file)">
          <i class="pi icon-adjunto"></i>
          <a class="download__link">{{ file.docName?.slice(0, 20).trim() }}{{ file.docName?.length > 20 ? '...' : '' }}</a>
        </span>
        <a
          *ngIf="positionStart && !message.read"
          (click)="markAsRead(message)"
          (keypress)="markAsRead(message)"
          [ngClass]="{ 'message-container--read': message.read, 'message-container--not-read': !message.read }"
        >
          {{ (message.read ? 'message.markedAsRead' : 'message.markAsRead') | transloco }}
        </a>
      </div>
      <div *ngIf="message.sender" class="message-container__sender">
        {{ message.sender }} {{ message.subject ? ' - ' + message.subject : '' }}
      </div>

      <p [innerHtml]="message.content | searchHighlightPipe : searchWord : ''" [innerText]="message.content"></p>
    </ng-container>
  </div>
</div>
