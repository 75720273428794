/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SharedModule } from '@sl/shared';
import { DialogExampleComponent, ModalSendBillPOCComponent, SandboxCardComponent, SandboxTemplateComponent } from '../../components';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-sandbox',
  templateUrl: './sandbox.page.html',
  styleUrls: ['./sandbox.page.scss'],
  standalone: true,
  imports: [SharedModule, DialogExampleComponent, SandboxCardComponent, SandboxTemplateComponent, ModalSendBillPOCComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SandboxPageComponent {
  constructor() {}
}
