<p-button (onClick)="showDialog()"> Open dialog </p-button>

<sl-dialog
  [modal]="dialog.modal!"
  [showHeader]="dialog.showHeader!"
  [showFooter]="dialog.showFooter!"
  [title]="'modal.billTitle' | transloco"
  [subtitle]="'modal.billSubtitle' | transloco"
  [(visible)]="dialog.visible!"
>
  <!-- <div header class="header">Custom Header</div> -->
  <div sticky class="content--sticky">
    <ng-container [ngTemplateOutlet]="Billing"></ng-container>
  </div>
  <div content class="content">
    <ng-container [ngTemplateOutlet]="Documents"></ng-container>
  </div>
  <div footer class="footer">
    <ng-container [ngTemplateOutlet]="Observations"></ng-container>
    <ng-container [ngTemplateOutlet]="ActionButtons"></ng-container>
  </div>
</sl-dialog>

<div>{{ dialog | json }}</div>

<!-- * SECTIONS -->
<ng-template #Billing>
  <article class="billing">
    <header class="billing__header">
      <h5>{{ 'modal.attach.bill' | transloco }}</h5>
      <ng-container [ngTemplateOutlet]="AddFileButton"></ng-container>
    </header>
  </article>
</ng-template>

<ng-template #Documents>
  <article class="documents">
    <header class="documents__header">
      <h5>{{ 'modal.attach.document' | transloco }}</h5>
      <ng-container [ngTemplateOutlet]="NewDocumentButton"></ng-container>
    </header>
    <section class="documents__section">
      <ng-container [ngTemplateOutlet]="DocumentList"></ng-container>
    </section>
  </article>
</ng-template>

<ng-template #Observations>
  <div class="observations">
    <ng-container [ngTemplateOutlet]="TextArea"></ng-container>
  </div>
</ng-template>

<ng-template #ActionButtons>
  <div class="action-buttons">
    <sl-button [type]="'secondary'" [size]="'l'" [borderRound]="true">
      {{ 'Borrar' }}
    </sl-button>
    <sl-button [type]="'primary'" [size]="'l'" [borderRound]="true">
      {{ 'Enviar' }}
    </sl-button>
  </div>
</ng-template>

<!-- * COMPONENTS -->
<ng-template #DocumentList>
  <article class="documentList">
    <ng-container *ngFor="let doc of ['doc1', 'doc2', 'doc3', 'doc4', 'doc5', 'doc6']; trackBy: trackByFn">
      <article #DocumentItem class="documentList__item">
        <sl-dropdown
          [label]="'dropdown-document-type.label' | transloco"
          [placeholder]="'dropdown-document-type.placeholder' | transloco"
          [options]="[
            { label: 'opt1', value: 'opt1' },
            { label: 'opt2', value: 'opt2' },
            { label: 'opt3', value: 'opt3' },
            { label: 'opt4', value: 'opt4' },
            { label: 'opt5', value: 'opt5' },
            { label: 'opt6', value: 'opt6' }
          ]"
        >
        </sl-dropdown>
        <ng-container [ngTemplateOutlet]="AddFileButton"></ng-container>
      </article>
    </ng-container>
  </article>
</ng-template>

<ng-template #AddFileButton>
  <article class="sl-add-file-button">
    <sl-button [type]="'link'" [icon]="'info1'" [textElipsis]="true" [slTooltip]="['communications.modal.files']">
      {{ 'modal.upload' | transloco | titlecase }}
    </sl-button>
    <button type="button">
      <i *ngIf="true" class="pi icon-upload"></i>
      <!-- <i *ngIf="false" class="pi icon-eliminar"></i> -->
    </button>
  </article>
</ng-template>

<ng-template #NewDocumentButton>
  <article class="sl-new-document-button">
    <sl-button [type]="'link'" [icon]="'mas'">
      <!-- <i class="pi icon-mas"></i> -->
      <span>{{ 'modal.button.document' | transloco }}</span>
    </sl-button>
  </article>
</ng-template>

<ng-template #TextArea>
  <sl-textarea [label]="'modal.observations' | transloco" [placeholder]="'Escribe aquí'" [maxlength]="'200'"></sl-textarea>
</ng-template>
