/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogConfig } from '@sl/components';
import { SharedModule } from '@sl/shared';

@Component({
  selector: 'sl-dialog-example',
  templateUrl: './dialog-example.component.html',
  styleUrls: ['./dialog-example.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogExampleComponent {
  dialog: DialogConfig = {
    visible: false,
    showHeader: true,
    showFooter: true
  };

  constructor() {}

  showDialog(): void {
    this.dialog.visible = true;
  }
}
