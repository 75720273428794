import { ISettlementAmountEntity } from './settlementAmountEntity.model.interface';

export class SettlementAmountEntity implements ISettlementAmountEntity {
  readonly amount: number | null;
  readonly netAmount: number | null;
  readonly tax: string | null;

  constructor(data: ISettlementAmountEntity) {
    this.amount = data.amount;
    this.netAmount = data.netAmount;
    this.tax = data.tax;
  }
}
