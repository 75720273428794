import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// TODO: Refactor range component
@Component({
  selector: 'sl-random-range',
  templateUrl: './random-range.component.html',
  styleUrls: ['./random-range.component.scss']
})
export class RandomRangeComponent implements OnInit {
  /* items y range deben ser divisibles para que el componente funcione */
  //@Input() items = 40;
  //@Input() range = 5;
  @Input() itemsPerCol = 2;
  @Input() intervals: (number | null)[][] = [
    [0, 4],
    [5, 9],
    [10, 15],
    [16, 30],
    [31, 50],
    [51, 100],
    [101, 200],
    [200, null]
  ];
  @Input() elementsName = 'días';
  @Input() dates: (Date | null)[] = [null, null];

  @Output() datesChange = new EventEmitter<(Date | null)[]>();

  //columns = Math.ceil(this.items / this.range / this.itemsPerCol);
  checkOption = '';

  actualDate = new Date();
  beginingDate = new Date();
  endDate = new Date();
  options: RandomRangeOptions[] = [];
  columns!: number;

  constructor() {}

  ngOnInit(): void {
    this.initOptions();
    this.columns = this.getColumnsNumber();
  }

  getIntervalLabel(interval: (number | null)[]): string {
    let label: string;
    if (interval[0] === null) {
      label = '< ' + interval[1] + ' ' + this.elementsName;
    } else if (!interval[1]) {
      label = '> ' + interval[0] + ' ' + this.elementsName;
    } else {
      label = interval[0] + ' - ' + interval[1] + ' ' + this.elementsName;
    }
    return label;
  }

  /* getColumns(): number[] {
    return Array.from({ length: this.columns }, (_, i) => i);
  } */

  /* getRanges(i: number): number[] {
    return Array.from({ length: this.calcItems(i) }, (_, j) => j);
  } */

  /* getLabel(row: number, col: number): string {
    const startRange = (col * this.range * this.itemsPerCol) + (this.range * row);
    const endRange = (col * this.range * this.itemsPerCol) + (this.range * row) + (this.range - 1);
    return `${startRange} - ${endRange} días`;
  } */

  /* handleSelectedCheck(row: number, col: number): void {
    const today = new Date();
    const beginingDate = new Date();
    const endDate = new Date();

    const start: number = (col * this.range * this.itemsPerCol) + (this.range * row);
    const end = (col * this.range * this.itemsPerCol) + (this.range * row) + (this.range - 1);

    beginingDate.setDate(today.getDate() + start);
    endDate.setDate(today.getDate() + end);

    this.checkOption = this.getLabel(row, col);
    this.dates = [beginingDate, endDate];
    this.datesChange.emit(this.dates);
  } */

  handleSelectedCheck(label: string, startDay: number | null, endDay: number | null): void {
    const today = new Date();
    let beginingDate: Date | null = new Date();
    let endDate: Date | null = new Date();

    this.checkOption = label;
    if (endDay !== null) {
      beginingDate.setDate(today.getDate() - endDay);
    } else {
      beginingDate = null;
    }

    if (startDay !== null) {
      endDate.setDate(today.getDate() - startDay);
    } else {
      endDate = null;
    }

    this.dates = [beginingDate, endDate];
    this.datesChange.emit(this.dates);
  }

  trackByFn(index: number, item: any): any {
    return item;
  }

  private initOptions() {
    this.intervals.forEach((interval) => {
      this.options.push({
        label: this.getIntervalLabel(interval),
        value: interval
      });
    });
  }

  private getColumnsNumber(): number {
    return Math.ceil(this.options.length / this.itemsPerCol);
  }

  /* private calcItems(index: number): number {
    const columnRange = this.range * this.itemsPerCol * (index + 1);
    return this.itemsPerCol - (columnRange > this.items ? 0 : columnRange % this.range);
  } */
}

interface RandomRangeOptions {
  label: string;
  value: (number | null)[];
}
