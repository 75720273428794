/* eslint-disable no-unused-vars */
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { NavigateParams } from '.';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  /* private readonly activatedRoute: ActivatedRoute */ // This does not work in a service
  constructor(private readonly router: Router, private readonly location: Location) {}

  get params(): Params {
    let route = this.router.routerState.snapshot.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.params;
  }

  get queryParams(): Params {
    let route = this.router.routerState.snapshot.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.queryParams;
  }

  async navigate({ route, extras }: NavigateParams): Promise<boolean> {
    return this.router.navigateByUrl(route, extras);
  }

  async navigateBack(): Promise<void> {
    return this.location.back();
  }

  async reloadPage(): Promise<boolean> {
    this.router.navigated = false;
    return this.router.navigate([this.router.url]);
  }

  getParam(key: string): string {
    return this.params[key];
  }

  getQueryParam(key: string): string {
    return this.queryParams[key];
  }

  getActualRoute(): string {
    return this.router.url;
  }

  // Detectar cambios de ruta
  onRouteChange(): Observable<string> {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.url)
    );
  }

  // Observar inicio de navegación
  onNavigationStart(): Observable<string> {
    return this.router.events.pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart),
      map((event: NavigationStart) => event.url)
    );
  }
}
