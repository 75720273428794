/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { ProviderPlaces } from '../../domain/providerPlaces.model';
import { ProviderPlacesEntity } from '../entities/providerPlaces.entity';
import { IProviderPlacesMapper } from './providerPlaces.mapper.interface';

@Injectable({ providedIn: 'root' })
export class ProviderPlacesMapper implements IProviderPlacesMapper {
  mapFromApi(apiData: ProviderPlacesEntity): ProviderPlaces {
    return new ProviderPlaces({
      provider: apiData.provider,
      place: apiData.place
    });
  }

  mapToApi(providerPlaces: ProviderPlaces): ProviderPlacesEntity {
    return new ProviderPlacesEntity({
      provider: providerPlaces.provider,
      place: providerPlaces.place
    });
  }
}
