/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Place, ProviderPlaces } from '@sl/features';
import { ApiService } from '@sl/services';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { GetPlacesParams, IPlaceStore, LocalStorageStore, PlacesState, ProviderPlacesState } from '.';
import { ProviderPlacesEntity } from '../../features/place/infrastructure/entities/providerPlaces.entity';
import { ProviderPlacesMapper } from '../../features/place/infrastructure/mappers/providerPlaces.mapper';

const PREFIX = '/api/v1' as const;

const initialStateProviderPlaces: ProviderPlacesState = {
  providerPlaces: null,
  error: null,
  loading: false
};

const initialStatePlaces: PlacesState = {
  places: null,
  error: null,
  loading: false
};

@Injectable({ providedIn: 'root' })
export class PlaceStore implements IPlaceStore {
  private readonly _providerPlaces$ = new BehaviorSubject<ProviderPlacesState>(initialStateProviderPlaces);
  readonly providerPlaces$ = this._providerPlaces$.asObservable();

  private readonly _places$ = new BehaviorSubject<PlacesState>(initialStatePlaces);
  readonly places$ = this._places$.asObservable();

  constructor(
    private readonly apiService: ApiService,
    private readonly providerPlacesMapper: ProviderPlacesMapper,
    private readonly localStorageStore: LocalStorageStore
  ) {}

  /** Selectors */

  selectProviderPlaces(): Observable<ProviderPlacesState> {
    return this.providerPlaces$;
  }

  selectPlaces(): Observable<PlacesState> {
    return this.places$;
  }

  getProviderPlaces(): ProviderPlaces | null {
    return this._providerPlaces$.getValue().providerPlaces;
  }

  getProviderId(): string | undefined {
    return this._providerPlaces$.getValue().providerPlaces?.provider.providerId;
  }

  getPlaces(): Place[] | null {
    return this._places$.getValue().places;
  }

  getPlaceIds(): string[] | undefined {
    return this.getPlaces()?.map(({ placeId }) => placeId);
  }

  getPlaceId(): string | undefined {
    const placeIds = this.getPlaceIds() ?? [];
    // const allPlaces = this.getProviderPlaces()?.place ?? [];
    // At the moment if all places are selected, is like no place is selected => return undefined to remove placeId from queryparams
    // eslint-disable-next-line no-undefined
    // return placeIds.length === allPlaces.length ? undefined : placeIds[0];
    return placeIds[0];
  }

  /* Reducer */

  updateProviderPlaces(newState: Partial<ProviderPlacesState>): void {
    const currentState = this._providerPlaces$.getValue();
    this._providerPlaces$.next({ ...currentState, ...newState });
  }

  updatePlaces(newState: Partial<PlacesState>): void {
    const currentState = this._places$.getValue();
    this._places$.next({ ...currentState, ...newState });
    this.localStorageStore.actionSetPlaces(newState.places ?? []);
  }

  /** Actions */

  actionGetProviderPlaces({ cif }: GetPlacesParams): void {
    this.updateProviderPlaces({ loading: true, error: null });

    this.apiService.get<ProviderPlacesEntity>({ url: `${PREFIX}/providers/${cif}/places` }).subscribe({
      next: (providerPlaces) => {
        this.updateProviderPlaces({ loading: false, providerPlaces: this.providerPlacesMapper.mapFromApi({ ...providerPlaces }) });
        this.effectGetProviderPlaces();
      },
      error: (error) => {
        this.updateProviderPlaces({ loading: false, error });
        this.handleError(error);
      }
    });
  }

  /** Effects */
  effectGetProviderPlaces(): void {
    const cachePlaceIds = this.localStorageStore.getPlaces()?.map((cachePlace) => cachePlace.placeId);
    const newPlaces = this.getProviderPlaces()!.place?.filter(({ placeId }) => cachePlaceIds?.includes(placeId)) || [];
    this.updatePlaces({ places: newPlaces });
  }

  /** Utils */

  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    return throwError(() => new Error(error));
  }
}
