/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DocumentDetail, IssueMessageSource } from '@sl/features';
import { ApiClientService, DownloadService } from '@sl/services';
import dayjs from 'dayjs';
import { IssueMessage } from 'src/app/core/features/issue/domain/issueMessage.model';
import { IssueMessageDoc } from 'src/app/core/features/issue/domain/IssueMessageDoc.model';
import { MessageSource } from 'src/app/core/features/orderNote/domain/enums/message.enum';
import { OrderNoteNoteMessage } from 'src/app/core/features/orderNote/domain/orderNoteNoteMessage.model';
import { OrderNoteNoteMessageDoc } from 'src/app/core/features/orderNote/domain/orderNoteNoteMessageDoc.model';

@Component({
  selector: 'sl-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent implements OnInit, OnChanges {
  @Input() message?: IssueMessage | OrderNoteNoteMessage;
  @Input() searchWord?: string;

  @Output() messagePosition = new EventEmitter<HTMLCollectionOf<Element>>();
  @Output() markMessageAsRead = new EventEmitter<string | null>();

  positionStart: boolean | undefined;
  file: any | null = null;
  actualDate!: string;
  actualTime!: string;
  time!: string;
  now = new Date();
  link = this.file ? URL.createObjectURL(this.file) : '';

  constructor(private readonly apiClientService: ApiClientService, private readonly downloadService: DownloadService) {}

  ngOnInit(): void {
    const { docList, creationDate } = this.message ?? {};
    if (docList) {
      this.file = docList[0];
    }

    this.positionStart = this.message?.source ? this.isManagerMessage(this.message.source) : undefined;
    this.actualDate = creationDate ? mapDatetoUTCFormat(creationDate) : '';
    this.actualTime = creationDate ? mapDateToUTCHour(creationDate) : '';
  }

  ngOnChanges(): void {
    const position = document.getElementsByClassName('highlighted-text');
    if (this.messagePosition) {
      this.messagePosition.emit(position);
    }
  }

  markAsRead(message: IssueMessage | OrderNoteNoteMessage): void {
    this.markMessageAsRead.emit(message.messageId);
  }

  onDownloadFile(file: IssueMessageDoc | OrderNoteNoteMessageDoc): void {
    if (file.docId) {
      this.apiClientService.getDocument(file.docId).subscribe({
        next: (document: any) => this.downloadDocument(document)
      });
    }
  }

  private downloadDocument(document: DocumentDetail): void {
    this.downloadService.exportFile(document.name, document.content, document.mimeType);
  }

  private isManagerMessage(source: MessageSource | IssueMessageSource): boolean {
    return source === 'ORDER-MANAGER' || source === 'PROVIDER-MANAGER';
  }
}

const mapDatetoUTCFormat = (orderDate: any): string => dayjs(orderDate).utc().format('DD/MM/YYYY');
const mapDateToUTCHour = (orderDate: any): string => dayjs(orderDate).utc().format('HH:mm');
