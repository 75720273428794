/* eslint-disable @typescript-eslint/naming-convention */
export enum OriginRequest {
  PROVEEDOR,
  AGORA
}

export const ORIGIN_REQUEST = {
  PROVEEDOR: 'PROVEEDOR',
  AGORA: 'AGORA'
};
