/* eslint-disable no-unused-vars */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  AlertComponent,
  ButtonComponent,
  ButtonFilterCommunicationsComponent,
  ButtonFilterComponent,
  CheckboxComponent,
  DateFilterComponent,
  DialogComponent,
  DropdownComponent,
  DropdownFilterComponent,
  ErrorModalComponent,
  InfoModalComponent,
  InputComponent,
  LinkComponent,
  MessageComponent,
  PaginationComponent,
  PlacesDropdownComponent,
  RandomRangeComponent,
  SearchComponent,
  SimpleTableComponent,
  SuccessModalComponent,
  TableCardsComponent,
  TableComponent,
  TableSummaryComponent,
  TabMenuComponent,
  TabMenuPocComponent,
  TagComponent,
  TextareaComponent,
  TooltipComponent
} from '@sl/components';
import { DragAndDropDirective, LongPress, TooltipDirective } from '@sl/directives';
import { CapitalizePipe, CurrencyCustom, RoundPipe, SearchHighlightPipe } from '@sl/pipes';

import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { TabMenuModule } from 'primeng/tabmenu';

import { CommunicationModalComponent } from '../modules/communications/components'; // TODO: Extract
import { TranslocoRootModule } from '../transloco-root.module';

const components: any[] = [
  AlertComponent,
  ButtonComponent,
  ButtonFilterCommunicationsComponent,
  ButtonFilterComponent,
  CheckboxComponent,
  DateFilterComponent,
  DialogComponent,
  DropdownComponent,
  DropdownFilterComponent,
  ErrorModalComponent,
  InfoModalComponent,
  InputComponent,
  LinkComponent,
  MessageComponent,
  PaginationComponent,
  PlacesDropdownComponent,
  RandomRangeComponent,
  SearchComponent,
  SimpleTableComponent,
  SuccessModalComponent,
  TableCardsComponent,
  TableComponent,
  TableSummaryComponent,
  TabMenuComponent,
  TabMenuPocComponent,
  TagComponent,
  TextareaComponent,
  TooltipComponent,
  CommunicationModalComponent
];
const directives: any[] = [TooltipDirective, DragAndDropDirective, LongPress];
const pipes: any[] = [SearchHighlightPipe, RoundPipe, CapitalizePipe, CurrencyCustom];
const primeNGModules: any[] = [
  AccordionModule,
  ButtonModule,
  CalendarModule,
  DialogModule,
  DropdownModule,
  OverlayPanelModule,
  SidebarModule,
  TabMenuModule,
  ProgressSpinnerModule
];

/**
 * Listado de módulos compartidos en toda la aplicación
 *  - CommonModule: exporta todas las directivas y los pipes básicos de angular, como NgIf, NgForOf, DecimalPipe, etc.
 *    NgClass, NgComponentOutlet, NgIf, NgPlural, NgPluralCase, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet
 *    NgStyle, NgFor, AsyncPipe, CurrencyPipe, DatePipe, DecimalPipe, I18nPluralPipe, I18nSelectPipe, JsonPipe, KeyValuePipe,
 *    LowerCasePipe, PercentPipe, SlicePipe, TitleCasePipe, UpperCasePipe (https://angular.io/api/common/CommonModule)
 *  - FormModule: exporta los providers y las directivas necesarios para los formularios controlados por plantillas.
 *  - ReactiveFormsModule: exporta la infraestructura y las directivas necesarias para los formularios reactivos.
 *  - RouterModule: agrega directivas y proveedores para la navegación dentro de la aplicación.
 *  - primeNGModules: los módulos de componentes de PrimeNG que vayamos incorporando a la aplicación
 *
 * @author Arquitectura Empresarial - Santalucía
 * @type {any[]}
 */
const modules: any[] = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, ...primeNGModules, TranslocoRootModule];

/**
 * Módulo para la gestión de los módulos compartidos en distintos puntos de la aplicación, como son las directivas
 * y pipes básicos de Angular, la gestión de formularios de template y reactivos, la gestión de rutas y los componentes de PrimeNG
 *
 * @author Arquitectura Empresarial - Santalucía
 * @exports
 * @class SharedModule
 * @typedef {SharedModule}
 */
@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [...modules],
  exports: [...modules, ...components, ...directives, ...pipes]
})
export class SharedModule {}
