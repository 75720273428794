<div
  [style.background-color]="communique.readDate ? (isSelected ? 'var(--color-primary16)' : 'var(--color-white)') : 'var(--color-type10)'"
  class="communique"
>
  <div class="communique__info">
    <div class="communique__info__title">
      <div *ngIf="!communique.readDate" class="circle-fill"></div>
      <span
        *ngIf="communique.title"
        [style.color]="communique.readDate ? 'var(--color-black)' : 'var(--color-type11)'"
        [innerHTML]="
          communique.title.length > 40 ? communique.title.slice(0, 40) + '...' : (communique.title | searchHighlightPipe : searchWord : '')
        "
      >
      </span>
    </div>

    <p
      *ngIf="communique.content"
      [style.font-weight]="!communique.readDate ? 'bold' : 'unset'"
      [innerHTML]="
        communique.content.length > 85
          ? communique.content.slice(0, 85) + '...'
          : (communique.content | searchHighlightPipe : searchWord : '')
      "
    ></p>
  </div>

  <div class="communique__document-date">
    <i *ngIf="communique.documentId" class="pi icon-adjunto"></i>
    <span>{{ communique.date }} {{ communique.hour }}</span>
  </div>
</div>
