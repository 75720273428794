<div *ngIf="!formControl" class="field">
  <label class="field__label" for="{{ name }}-id">{{ label }}</label>
  <p-dropdown
    id="{{ name }}-id"
    class="field__select"
    [name]="name"
    [placeholder]="placeholder"
    [options]="options"
    [filter]="filter"
    [virtualScroll]="virtualScroll"
    [virtualScrollItemSize]="size"
    [disabled]="disabled"
    [required]="required"
    [readonly]="readonly"
    [dropdownIcon]="'pi icon-arrow-down'"
    [(ngModel)]="value"
    (onChange)="onValueChange()"
    optionLabel="label"
    optionValue="value"
  >
    <ng-template let-option pTemplate="selectedItem">
      <i *ngIf="option?.icon" class="pi" [class]="option?.icon"></i>{{ option.label }}
    </ng-template>
    <ng-template let-option pTemplate="item">
      <i *ngIf="option?.icon" class="pi" [class]="option?.icon"></i>{{ option.label }}
    </ng-template>
  </p-dropdown>
  <small id="{{ name }}-help">{{ helperText }}</small>
</div>
