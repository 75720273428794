import { OmitFunctions } from '../../base/utilityTypes';
import { IOrderNoteNote } from './orderNoteNote.model.interface';
import { OrderNoteNoteMessage } from './orderNoteNoteMessage.model';

export class OrderNoteNote implements IOrderNoteNote {
  readonly noteId: string | null;
  readonly message: OrderNoteNoteMessage;

  constructor(data: OmitFunctions<IOrderNoteNote>) {
    this.noteId = data.noteId;
    this.message = new OrderNoteNoteMessage(data.message);
  }

  isProviderNote(): boolean {
    return this.message.isProviderMessage();
  }

  isManagerNote(): boolean {
    return this.message.isManagerMessage();
  }
}
