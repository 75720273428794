import { IOrderBilling } from './orderBilling.model.interface';

export class OrderBilling implements IOrderBilling {
  readonly amount: string;
  readonly netAmount: string;
  readonly tax: string;

  constructor(data: IOrderBilling) {
    this.amount = data.amount;
    this.netAmount = data.netAmount;
    this.tax = data.tax;
  }
}
