<sl-button-filter [(visible)]="visible">
  <form class="container">
    <div class="content">
      <ng-container [ngTemplateOutlet]="Section1"></ng-container>
      <ng-container [ngTemplateOutlet]="Section2"></ng-container>
      <!-- ??? <ng-container [ngTemplateOutlet]="Section3"></ng-container> -->
      <ng-container [ngTemplateOutlet]="Section4"></ng-container>
    </div>
    <div class="footer">
      <ng-container [ngTemplateOutlet]="Footer"></ng-container>
    </div>
  </form>
</sl-button-filter>

<ng-template #Section1>
  <fieldset class="fieldset">
    <legend class="fieldset__legend">{{ section1.legend }}</legend>
    <div class="fieldset__content">
      <!-- TODO This input -->
      <ng-container #CheckList>
        <div *ngFor="let option of checklistStatus.options; let i = index; trackBy: trackByFn" class="checkbox">
          <input
            type="checkbox"
            [value]="option.value"
            [checked]="checklistStatus.value?.includes(option.value)"
            (change)="onChecklistStatus(option.value)"
          />
          <span>{{ option.label }}</span>
        </div>
      </ng-container>
    </div>
  </fieldset>
</ng-template>

<ng-template #Section2>
  <fieldset class="fieldset">
    <legend class="fieldset__legend">{{ section2.legend }}</legend>
    <div class="fieldset__content">
      <sl-input
        [label]="inputClaimId.label"
        [placeholder]="inputClaimId.placeholder"
        [(value)]="inputClaimId.value"
        (valueChange)="onClaimId()"
      ></sl-input>
      <sl-input
        [label]="inputOrderId.label"
        [placeholder]="inputOrderId.placeholder"
        [(value)]="inputOrderId.value"
        (valueChange)="onOrderId()"
      ></sl-input>
    </div>
  </fieldset>
</ng-template>

<ng-template #Section3>
  <fieldset class="fieldset__single">
    <legend class="fieldset__legend">{{ section3.legend }}</legend>
    <div class="fieldset__content__single">
      <sl-dropdown
        [label]="dropdownType.label"
        [placeholder]="dropdownType.placeholder"
        [options]="dropdownType.options"
        [(value)]="dropdownType.value"
        (valueChange)="onDropdownType()"
      ></sl-dropdown>
    </div>
  </fieldset>
</ng-template>

<ng-template #Section4>
  <fieldset class="fieldset">
    <legend class="fieldset__legend">{{ section4.legend }}</legend>
    <div class="fieldset__content">
      <sl-dropdown
        [label]="dropdownClientId.label"
        [placeholder]="dropdownClientId.placeholder"
        [options]="dropdownClientId.options"
        [(value)]="dropdownClientId.value"
        (valueChange)="onDropdownClientId()"
      ></sl-dropdown>
    </div>
  </fieldset>
</ng-template>

<ng-template #Footer>
  <sl-button [buttonType]="'submit'" [type]="'secondary'" [size]="'s'" (click)="handleRemoveFilters()">
    {{ 'button.remove' | transloco | titlecase }}
  </sl-button>
  <sl-button [buttonType]="'submit'" [type]="'primary'" [size]="'s'" (click)="handleSubmitFilters()">
    {{ 'button.accept' | transloco | titlecase }}
  </sl-button>
</ng-template>
