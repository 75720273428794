/* eslint-disable @angular-eslint/use-pipe-transform-interface */
import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from '../../utils/string';

/**
 * Pipe to capitalize the first letter of a string and make the rest lowercase
 *
 * @param value string to be capitalize
 * @param args 'all' will capitalize each word of the string
 * @returns string with the first letter capitalized
 */
@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(value?: string, args?: 'all'): string {
    return value ? capitalize(value, args) : '';
  }
}
