/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { DocumentDetail } from '../../domain/documentDetail.model';
import { DocumentDetailEntity } from '../entities/documentDetail.entity';
import { IDocumentDetailMapper } from './documentDetail.mapper.interface';

@Injectable({ providedIn: 'root' })
export class DocumentDetailMapper implements IDocumentDetailMapper {
  mapFromApi(apiData: DocumentDetailEntity): DocumentDetail {
    return new DocumentDetail({
      documentId: apiData.documentId,
      name: apiData.name,
      type: apiData.type,
      mimeType: apiData.mimeType,
      content: apiData.content,
      date: new Date(apiData.date),
      origin: apiData.origin
    });
  }

  mapToApi(document: DocumentDetail): DocumentDetailEntity {
    return new DocumentDetailEntity({
      documentId: document.documentId,
      name: document.name,
      type: document.type,
      mimeType: document.mimeType,
      content: document.content,
      date: document.date ? mapDateToApi(document.date) : '',
      origin: document.origin
    });
  }
}

const mapDateToApi = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
