import { IOrderSummaryOrderEntity } from './orderSummaryOrder.entity.interface';

export class OrderSummaryOrderEntity implements IOrderSummaryOrderEntity {
  readonly id: string;
  readonly date: string | null;
  readonly type: string[];

  constructor(data: IOrderSummaryOrderEntity) {
    this.id = data.id;
    this.date = data.date;
    this.type = data.type;
  }
}
