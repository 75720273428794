import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Provider } from '../domain/provider.model';
import { ProviderRepository } from '../infrastructure/repositories/provider.repository';
import { GetProviderUseCaseParams, IGetProviderUseCase } from './getProvider.use-case.interface';

@Injectable({ providedIn: 'root' })
export class GetProviderUseCase implements IGetProviderUseCase {
  constructor(private readonly providerRepository: ProviderRepository) {}

  execute({ providerId, placeId }: GetProviderUseCaseParams): Observable<Provider> {
    // Add additional logic here
    return this.providerRepository.getProvider({ providerId, placeId }).pipe(catchError(this.handleError));
  }

  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    return throwError(() => new Error(error));
  }
}
