/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogConfig } from '@sl/components';
import { SharedModule } from '@sl/shared';
import { trackBy } from '@sl/utils';

@Component({
  selector: 'sl-modal-send-bill-poc',
  templateUrl: './modal-send-bill-poc.component.html',
  styleUrls: ['./modal-send-bill-poc.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalSendBillPOCComponent {
  dialog: DialogConfig = {
    visible: true,
    showHeader: true,
    showFooter: true,
    modal: true
  };

  // [supplied]="orderInfo?.concepts"
  // (sendBill)="onModalSendBill($event)"
  // (closeModal)="onCloseModal()"

  constructor() {}

  showDialog(): void {
    this.dialog.visible = true;
  }

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }
}
