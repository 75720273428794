<article class="listing">
  <header class="listing__header">
    <h2 class="listing__title">{{ 'documentation.title' | transloco }}</h2>
  </header>
  <section class="listing__table">
    <ng-container [ngTemplateOutlet]="Table"></ng-container>
  </section>
</article>

<!-- TODO: extract to components -->
<ng-template #Table>
  <article class="table">
    <header class="table__header">
      <ng-container [ngTemplateOutlet]="Filters"></ng-container>
      <ng-container [ngTemplateOutlet]="FilterTags"></ng-container>
    </header>
    <section *ngIf="(spinnerService.getLoadingObservable('documents')| async) === false" class="table__content">
      <sl-table
        [highlightText]="searchByWord.value"
        [dataTable]="filterTable"
        [showDownloadColumn]="true"
        [tableItemsName]="'documents.itemsName' | transloco"
        [showPagination]="false"
        (showErrorModal)="showErrorModal = true"
      ></sl-table>
    </section>
  </article>
</ng-template>

<ng-template #Filters>
  <div class="filters">
    <sl-dropdown-filter
      class="dropdown-filter"
      [placeholder]="searchByWord.placeholder"
      [searchIcon]="searchByWord.searchIcon"
      [(value)]="searchByWord.value"
      (valueChange)="onSearchByWord()"
    ></sl-dropdown-filter>

    <sl-date-filter
      class="dropdown-filter"
      [showRangeFilter]="false"
      (selectedDates)="onDatePickerDates($event)"
      (removeSelectedDates)="onDatePickerRemove()"
    ></sl-date-filter>
  </div>
</ng-template>

<ng-template #FilterTags>
  <div class="filter-tags"></div>
</ng-template>

<p-progressSpinner
  *ngIf="spinnerService.getLoadingObservable('documents')| async"
  [style]="{width: '100px', height: '100px'}"
  styleClass="custom-spinner"
  strokeWidth="2"
  animationDuration="1s"
></p-progressSpinner>
<sl-error-modal
  *ngIf="showErrorModal"
  [errorMessage]="'errorModal.message' | transloco"
  (closeModal)="showErrorModal = false"
></sl-error-modal>
