/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { IssueClient, IssueStatus, IssueType } from '../../domain/enums/issue.enum';
import { Issue } from '../../domain/issue.model';
import { IssueEntity } from '../entities/issue.entity';
import { IIssueMapper } from './issue.mapper.interface';
import { IssueMessageMapper } from './issueMessage.mapper';

@Injectable({ providedIn: 'root' })
export class IssueMapper implements IIssueMapper {
  constructor(private issueMessageMapper: IssueMessageMapper) {}

  mapFromApi(apiData: IssueEntity): Issue {
    return new Issue({
      id: apiData.id,
      description: apiData.description,
      type: apiData.type as IssueType,
      nis: apiData.nis,
      policy: apiData.policy,
      date: apiData.date ? new Date(mapApiToDate(apiData.date)) : null,
      closingDate: apiData.closingDate ? new Date(mapApiToDate(apiData.closingDate)) : null,
      client: apiData.client as IssueClient,
      status: apiData.status as IssueStatus,
      messages: apiData.messages.map((message) => this.issueMessageMapper.mapFromApi(message)),
      orderId: apiData.orderId,
      affectedName: apiData.affectedName
    });
  }

  mapToApi(issue: Issue): IssueEntity {
    return new IssueEntity({
      id: issue.id,
      description: issue.description,
      type: issue.type,
      nis: issue.nis,
      policy: issue.policy,
      date: issue.date ? mapDateToApi(issue.date) : null,
      closingDate: issue.closingDate ? mapDateToApi(issue.closingDate) : null,
      client: issue.client,
      status: issue.status,
      messages: issue.messages.map((message) => this.issueMessageMapper.mapToApi(message)),
      orderId: issue.orderId,
      affectedName: issue.affectedName
    });
  }
}

const mapDateToApi = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
const mapApiToDate = (orderDate: any): string => dayjs(orderDate).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
