<div
  class="blur-arrow arrow-right"
  [style.display]="canScrollRight ? 'flex' : 'none'"
  (click)="onRightArrowClick()"
  (keypress)="onRightArrowClick()"
>
  <i class="pi icon-arrow-right"></i>
</div>

<div
  class="blur-arrow arrow-left"
  [style.display]="canScrollLeft ? 'flex' : 'none'"
  (click)="onLeftArrowClick()"
  (keypress)="onLeftArrowClick()"
>
  <i class="pi icon-arrow-left"></i>
</div>

<div class="tab-container" (scroll)="onScroll()" #scrollContainer>
  <div
    *ngFor="let tab of tabs; let i = index; trackBy: trackByFn"
    class="tab-container__item"
    (click)="onTabClick(tab, i)"
    (keypress)="onTabClick(tab, i)"
    [style.border-bottom]="selectedTab === tab.label ? '4px solid ' + selectedBorder : 'none'"
    #content
  >
    <span [style.color]="selectedTab === tab.label ? selectedNameColor : nonSelectedNameColor">{{ tab.label | titlecase }}</span>
    <div *ngIf="tab.num" class="circle" [style.background-color]="notificationColor">
      {{ tab.num }}
    </div>
  </div>
</div>
