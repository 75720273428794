import { INotification } from './notification.model.interface';

export class Notification implements INotification {
  readonly notificationId: string;
  readonly title: string;
  readonly content: string;
  createdDate: Date | null;
  readonly expirationDate: Date;
  readDate: Date | null;
  readonly documentId: string;
  readonly documentName: string;

  constructor(data: INotification) {
    this.notificationId = data.notificationId;
    this.title = data.title;
    this.content = data.content;
    this.createdDate = data.createdDate ? new Date(data.createdDate) : null; // Create a new object to make sure it is immutable
    this.expirationDate = new Date(data.expirationDate); // Create a new object to make sure it is immutable
    this.readDate = data.readDate ? new Date(data.readDate) : null; // Create a new object to make sure it is immutable
    this.documentId = data.documentId;
    this.documentName = data.documentName;
  }

  // Puedes agregar métodos adicionales aquí si es necesario
}
