<div class="global-position">
  <div class="global-position__content">
    <sl-banner class="banner" [title]="profile.name" [description]="description" [firstColor]="'#0082FF'" [secondColor]="'#47D7AC'">
    </sl-banner>

    <div id="container" class="notification-cards__global" (wheel)="onWheel($event)" (scroll)="onScroll($event)">
      <p-progressSpinner
        *ngIf="loadingOrderVolumetries$ | async"
        [style]="{width: '100px', height: '100px'}"
        styleClass="custom-spinner"
        strokeWidth="2"
        animationDuration="1s"
      ></p-progressSpinner>

      <ng-container *ngIf="(loadingOrderVolumetries$ | async) === false">
        <ng-container *ngFor="let num of [].constructor(totalPages); let i = index; trackBy: trackByFn">
          <div class="notification-cards__global__container">
            <ng-container
              *ngFor="let card of orderVolumetries.slice(cardsToShow*i, cardsToShow + cardsToShow*i); let i = index; trackBy: trackByFn"
            >
              <sl-notification-card [volumetryId]="card.volumetryId" [notifications]="card.count"> </sl-notification-card>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="state-container">
      <ng-container *ngFor="let item of [].constructor(totalPages); let i = index; trackBy: trackByFn">
        <span
          class="state-container__item"
          [ngClass]="{'state-container__selected': state === i}"
          (click)="setState(i)"
          (keypress)="setState(i)"
        >
        </span>
      </ng-container>
    </div>

    <aside class="global-situation__container__responsive">
      <sl-statistics
        [loading]="loadingOrderStatistics$ | async"
        [items]="orderStatistics"
        [title]="'statistics.title' | transloco"
        [subtitle]="'statistics.subtitle' | transloco"
        [barColor]="'var(--color-type7)'"
        [valueColor]="'var(--color-white)'"
        [selectedYear]="selectedYear"
        [selectedActivity]="selectedActivity"
        (statisticsFilterChange)="onStatisticsFilterChange($event)"
      >
      </sl-statistics>
    </aside>
  </div>

  <aside class="global-situation__container">
    <sl-statistics
      [loading]="loadingOrderStatistics$ | async"
      [items]="orderStatistics"
      [title]="'statistics.title' | transloco"
      [subtitle]="'statistics.subtitle' | transloco"
      [barColor]="'var(--color-type7)'"
      [valueColor]="'var(--color-white)'"
      [selectedYear]="selectedYear"
      [selectedActivity]="selectedActivity"
      (statisticsFilterChange)="onStatisticsFilterChange($event)"
    >
    </sl-statistics>
  </aside>
</div>

<sl-error-modal
  *ngIf="showErrorModal"
  [errorMessage]="'errorModal.message' | transloco"
  (closeModal)="showErrorModal = false"
></sl-error-modal>
