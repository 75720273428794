/* eslint-disable no-unused-vars */
import { Directive, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective } from '@angular/forms';
import { MyErrorStateMatcher } from './error-state-matcher';

/** Abstract class that implements ControlValueAccessor */
@Directive()
export abstract class FormControlValueAccessorConnector<T> implements ControlValueAccessor {
  @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective | undefined;

  @Input() formControl: FormControl<T> | undefined;
  @Input() formControlName: string | undefined;

  @Input() value: T | null = null;

  @Input() disabled = false;
  @Input() readonly = false;
  @Input() required = false;

  @Output() readonly valueChange = new EventEmitter<T | null>();

  matcher = new MyErrorStateMatcher();

  onChange!: (value: T) => void;
  onTouched!: () => void;

  protected constructor(private injector: Injector) {}

  /**
   *  Use this getter in FormControl on component to make connection with provided formControl of Parent
   *
   *  this.formControl => When a formControl Obj is Provided from parent
   *  this.formControl => When name of a formControl in Parent Form is Provided
   *
   */
  get control(): FormControl<T> {
    return this.formControl || (this.controlContainer.control?.get(this.formControlName!) as FormControl<T>);
  }

  get controlContainer(): ControlContainer {
    return this.injector.get(ControlContainer);
  }

  writeValue(value: T): void {
    if (value !== this.value) {
      this.formControlDirective?.valueAccessor?.writeValue(value);
      this.value = value;
    }
  }

  registerOnChange(fn: (_: T) => void): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState!(isDisabled);
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    this.onTouched();
  }

  getValue(): T | null {
    return this.value;
  }
}
