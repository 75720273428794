/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { stringToNumber } from '../../utils';

@Component({
  selector: 'sl-table-summary',
  templateUrl: './table-summary.component.html',
  styleUrls: ['./table-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableSummaryComponent<T = string> implements OnChanges {
  @Input() config?: TableSummaryConfig<T>;

  @Input() title = this.config?.title ?? '';
  @Input() subtitle = this.config?.subtitle ?? '';
  @Input() prefix = this.config?.prefix ?? '';
  @Input() data: TableSummaryItem<T>[] = this.config?.data ?? [];
  @Input() currency = this.config?.currency ?? '';

  @Output() itemClick: EventEmitter<TableSummaryItem<T>> = new EventEmitter<TableSummaryItem<T>>();

  dataGrouped: TableSummaryItem<T>[] = [];
  total = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.dataGrouped = this.groupAndSumItems(this.data);
      this.total = this.dataGrouped.reduce((acc, { amount }) => acc + amount, 0);
    }
  }

  onItemClick(item: TableSummaryItem<T>): void {
    this.itemClick.emit(item);
  }

  trackByFn(index: number, item: TableSummaryItem<T>): string {
    return item.name;
  }

  /**
   * Group items by name and sum their amounts.
   *
   * @param {TableSummaryItem[]} items items to group
   */
  private groupAndSumItems(items: TableSummaryItem<T>[]): TableSummaryItem<T>[] {
    const groupedItems: Record<string, TableSummaryItem<T>> = {};

    items.forEach(({ name, value, amount }) => {
      groupedItems[name] ??= { name, value, amount: 0 };
      groupedItems[name].amount += amount ? stringToNumber(amount) : 0;
    });

    return Object.values(groupedItems);
  }
}

export type TableSummaryConfig<T = string> = {
  title?: string;
  subtitle?: string;
  prefix?: string;
  currency?: string;
  data?: TableSummaryItem<T>[];
};

export type TableSummaryItem<T = string> = {
  name: string;
  amount: number;
  value: T;
};
