/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderBillOrderStatusReason } from '../../domain/orderBillOrderStatusReason.model';
import { OrderBillOrderStatusReasonEntity } from '../entities/orderBillOrderStatusReason.entity';
import { IOrderBillOrderStatusReasonMapper } from './orderBillOrderStatusReason.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderBillOrderStatusReasonMapper implements IOrderBillOrderStatusReasonMapper {
  constructor() {}

  mapFromApi(apiData: OrderBillOrderStatusReasonEntity): OrderBillOrderStatusReason {
    return new OrderBillOrderStatusReason({
      code: apiData.code,
      description: apiData.description
    });
  }

  mapToApi(orderStatusReason: OrderBillOrderStatusReason): OrderBillOrderStatusReasonEntity {
    return new OrderBillOrderStatusReasonEntity({
      code: orderStatusReason.code,
      description: orderStatusReason.description
    });
  }
}
