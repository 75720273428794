<!-- Trigger -->
<button class="trigger" type="button" (click)="onToggleDropdown()" (keydown.enter)="onToggleDropdown()">
  <div class="value">
    <ng-container *ngIf="isSelectedAllOptions" [ngTemplateOutlet]="AllOptionsValue"></ng-container>
    <ng-container *ngIf="!isSelectedAllOptions" [ngTemplateOutlet]="Value"></ng-container>
  </div>

  <i [class]="showDropdown ? 'pi icon-arrow-up' : 'pi icon-arrow-down'" [style.color]="showDropdown ? activeColor : titleColor"></i>
</button>

<!-- Dropdown-->
<div *ngIf="showDropdown" class="popup">
  <ng-container [ngTemplateOutlet]="AllOptions"></ng-container>
  <ng-container [ngTemplateOutlet]="Options"></ng-container>
  <ng-container [ngTemplateOutlet]="CloseSession"></ng-container>
</div>

<ng-template #AllOptions>
  <div class="popup__title">
    <button
      class="popup__title--left"
      type="button"
      (click)="onAllOptions()"
      (keydown.enter)="onAllOptions()"
      [ngClass]="{ 'popup__title--active': isSelectedAllOptions }"
    >
      <i class="pi icon-sede"></i>
      <span class="title">
        {{ optionAll?.label }}
      </span>
    </button>
    <span class="popup__title--campus">
      {{ 'header.popup.campus' | transloco }}
    </span>
  </div>
</ng-template>

<ng-template #Options>
  <div class="popup__principal">
    <i class="pi icon-sede"></i>
    <span>
      {{ 'header.popup.title' | transloco }}
    </span>
  </div>

  <ul class="popup__content">
    <ng-container *ngFor="let option of options; trackBy: trackByFn">
      <li class="option">
        <button
          class="option__button"
          type="button"
          (click)="onOptionClick(option)"
          (keydown.enter)="onOptionClick(option)"
          [ngClass]="{ 'option__button--active': isActiveOption(option) }"
        >
          {{ option.label }}
        </button>
      </li>
    </ng-container>
    <li *ngIf="!isOptions">
      {{ 'header.popup.noOptions' | transloco }}
    </li>
  </ul>
</ng-template>

<ng-template #CloseSession>
  <div class="popup__close-session">
    <button class="close-session" type="button" (click)="onCloseSession()" (keydown.enter)="onCloseSession()">
      <i class="pi icon-cierre-sesion"></i>
      <span class="close-session__label">
        {{ 'header.popup.exit' | transloco }}
      </span>
    </button>
  </div>
</ng-template>

<ng-template #AllOptionsValue>
  <i class="pi icon-sede" [style.color]="showDropdown ? activeColor : titleColor"></i>
  <span class="title__principal" [style.color]="showDropdown ? activeColor : titleColor">
    {{ optionAll?.label }}
  </span>
  <span class="popup__title--campus">
    {{ 'header.popup.campus' | transloco }}
  </span>
</ng-template>

<ng-template #Value>
  <i class="pi icon-sede" [style.color]="showDropdown ? activeColor : titleColor"></i>
  <span class="title__principal" [style.color]="showDropdown ? activeColor : titleColor">
    {{ activeOption?.label }}
  </span>
</ng-template>
