/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { IssueMessageDoc } from '../../domain/IssueMessageDoc.model';
import { IssueMessageDocEntity } from '../entities/IssueMessageDoc.entity';
import { IIssueMessageDocMapper } from './issueMessageDoc.mapper.interface';

@Injectable({ providedIn: 'root' })
export class IssueMessageDocMapper implements IIssueMessageDocMapper {
  mapFromApi(apiData: IssueMessageDocEntity): IssueMessageDoc {
    return new IssueMessageDoc({
      docId: apiData.docId,
      docName: apiData.docName,
      docType: apiData.docType,
      creationDate: new Date(apiData.creationDate),
      metadata: apiData.metadata
    });
  }

  mapToApi(issueMessageDoc: IssueMessageDoc): IssueMessageDocEntity {
    return new IssueMessageDocEntity({
      docId: issueMessageDoc.docId,
      docName: issueMessageDoc.docName,
      docType: issueMessageDoc.docType,
      creationDate: issueMessageDoc.creationDate ? mapDateToApi(issueMessageDoc.creationDate) : '',
      metadata: issueMessageDoc.metadata
    });
  }
}

const mapDateToApi = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
