import { IOrderBillOrderStatusReason } from './orderBillOrderStatusReason.model.interface';

export class OrderBillOrderStatusReason implements IOrderBillOrderStatusReason {
  readonly code: string;
  readonly description: string;

  constructor(data: IOrderBillOrderStatusReason) {
    this.code = data.code;
    this.description = data.description;
  }
}
