/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderNoteInfo } from '../../domain/orderNoteInfo.model';
import { OrderNoteInfoEntity } from '../entities/orderNoteInfo.entity';
import { IOrderNoteInfoMapper } from './orderNoteInfo.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderNoteInfoMapper implements IOrderNoteInfoMapper {
  mapFromApi(apiData: OrderNoteInfoEntity): OrderNoteInfo {
    return new OrderNoteInfo({ providerId: apiData.providerId, orderId: apiData.orderId });
  }

  mapToApi(orderInfo: OrderNoteInfo): OrderNoteInfoEntity {
    return new OrderNoteInfoEntity({ providerId: orderInfo.providerId, orderId: orderInfo.orderId });
  }
}
