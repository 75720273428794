import { Injectable } from '@angular/core';
import { OrderSummaryType } from '../../domain/enums/orderSummaryStatus.enum';
import { OrderSummaryOrder } from '../../domain/orderSummaryOrder.model';
import { OrderSummaryOrderEntity } from '../entities/orderSummaryOrder.entity';
import { IOrderSummaryOrderMapper } from './orderSummaryOrder.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderSummaryOrderMapper implements IOrderSummaryOrderMapper {
  mapFromApi(apiData: OrderSummaryOrderEntity): OrderSummaryOrder {
    return new OrderSummaryOrder({
      id: apiData.id,
      date: apiData.date ? new Date(apiData.date) : null,
      type: apiData.type as OrderSummaryType[]
    });
  }

  mapToApi(orderSummaryOrder: OrderSummaryOrder): OrderSummaryOrderEntity {
    return new OrderSummaryOrderEntity({
      id: orderSummaryOrder.id,
      date: orderSummaryOrder.date ? new Date(orderSummaryOrder.date).toISOString() : null,
      type: orderSummaryOrder.type
    });
  }
}
