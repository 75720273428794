/**
 * Rounds a decimal number to the specified precision.
 *
 * @param {number} value - The decimal number to be rounded.
 * @param {number} precision - The number of decimal places to round the number to. Defaults to 2.
 * @returns {number} - The rounded number with the specified precision.
 * @example
 * const number = 3.14759;
 * const precision = 2;
 * const roundedNumber = round(number, precision); // 3.15
 */
export const round = (value: number, precision = 2): number => {
  const factor = 10 ** precision;
  return Number((Math.round(value * factor) / factor).toFixed(precision));
};

/**
 * Convierte una cadena de texto en un número.
 *
 * @param {string} input - La cadena de texto a convertir.
 * @returns {number | null} - El número resultante o null si la conversión falla.
 * @example
 * const stringValue = '2000,00';
 * const numericValue = stringToNumber(stringValue);
 */
export const stringToNumber = (input: string | number): number => {
  let response = input;
  if (typeof input === 'number') {
    response = input;
  } else {
    // Reemplazar ',' por '.' como separador decimal y eliminar otros caracteres no deseados
    const sanitizedInput = input.replace(/[^0-9.,-]/g, '').replace(',', '.');

    // Usar parseFloat para obtener un número
    response = parseFloat(sanitizedInput);
  }

  return response;
};
