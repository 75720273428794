/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Order, OrderMapper, OrderSummaryMapper } from '@sl/features';
import { ApiService, PlaceStore, QueryParams } from '@sl/services';
import { BehaviorSubject, firstValueFrom, Observable, throwError } from 'rxjs';
import { OrderSummary } from '../../features/order/domain/orderSummary.model';
import {
  GetOrderEntityResponse,
  GetOrdersEntityResponse,
  GetOrdersResponse
} from '../../features/order/infrastructure/repositories/order.repository.interface';
import { GetOrderParams, GetOrdersParams, IOrderStore, OrdersState, OrderState } from './order.store.interface';

const PREFIX = '/api/v1' as const;

const initialStateOrders: OrdersState = {
  list: null,
  pageIndex: null,
  pageSize: null,
  total: null,
  error: null,
  loading: false
};

const initialStateOrder: OrderState = {
  order: null,
  error: null,
  loading: false
};

@Injectable({ providedIn: 'root' })
export class OrderStore implements IOrderStore {
  private readonly _orders$ = new BehaviorSubject<OrdersState>(initialStateOrders);
  readonly orders$ = this._orders$.asObservable();
  private readonly _order$ = new BehaviorSubject<OrderState>(initialStateOrder);
  readonly order$ = this._order$.asObservable();

  constructor(
    private readonly apiService: ApiService,
    private readonly placeStore: PlaceStore,
    private readonly orderSummaryMapper: OrderSummaryMapper,
    private readonly orderMapper: OrderMapper
  ) {}

  /** Selectors */

  selectOrders(): Observable<OrdersState> {
    return this.orders$;
  }

  selectOrder(): Observable<OrderState> {
    return this.order$;
  }

  getOrders(): OrderSummary[] | null {
    return this._orders$.getValue().list;
  }

  getPageIndex(): number | null {
    return this._orders$.getValue().pageIndex;
  }

  getPageSize(): number | null {
    return this._orders$.getValue().pageSize;
  }

  getTotal(): number | null {
    return this._orders$.getValue().total;
  }

  getOrder(): Order | null {
    return this._order$.getValue().order;
  }

  /* Reducer */

  updateOrders(newState: Partial<OrdersState>): void {
    const currentState = this._orders$.getValue();
    this._orders$.next({ ...currentState, ...newState });
  }

  updateOrder(newState: Partial<OrderState>): void {
    const currentState = this._order$.getValue();
    this._order$.next({ ...currentState, ...newState });
  }

  /** Actions */

  actionGetOrders(params?: GetOrdersParams): void {
    this.updateOrders({ loading: true, error: null });

    const providerId = params?.providerId ?? this.placeStore.getProviderId();
    const ordersFilter = { placeId: this.placeStore.getPlaceId(), ...params?.ordersFilter };

    this.apiService
      .get<GetOrdersEntityResponse>({
        url: `${PREFIX}/providers/${providerId}/places/orders`,
        queryParams: ordersFilter as unknown as QueryParams
      })
      .subscribe({
        next: ({ list, ...res }) => {
          this.updateOrders({ loading: false, list: list.map((data) => this.orderSummaryMapper.mapFromApi(data)), ...res });
        },
        error: (error) => {
          this.updateOrders({ loading: false, error });
          this.handleError(error);
        }
      });
  }

  actionGetOrder(params: GetOrderParams): void {
    this.updateOrders({ loading: true, error: null });

    const providerId = params?.providerId ?? this.placeStore.getProviderId();
    const orderId = params.orderId;

    this.apiService
      .get<GetOrderEntityResponse>({
        url: `${PREFIX}/providers/${providerId}/places/orders/${orderId}`
      })
      .subscribe({
        next: (res) => {
          this.updateOrder({ loading: false, order: this.orderMapper.mapFromApi(res) });
        },
        error: (error) => {
          this.updateOrder({ loading: false, error });
          this.handleError(error);
        }
      });
  }

  async actionGetOrdersPromise(params?: GetOrdersParams): Promise<GetOrdersResponse> {
    const providerId = params?.providerId ?? this.placeStore.getProviderId();
    const ordersFilter = { placeId: this.placeStore.getPlaceId(), ...params?.ordersFilter };

    try {
      const { list, ...res } = await firstValueFrom(
        this.apiService.get<GetOrdersEntityResponse>({
          url: `${PREFIX}/providers/${providerId}/places/orders`,
          queryParams: ordersFilter as unknown as QueryParams
        })
      );
      return { list: list.map((data) => this.orderSummaryMapper.mapFromApi(data)), ...res };
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  /** Effects */

  /** Utils */

  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    return throwError(() => new Error(error));
  }
}
