import { ProviderTypeContact } from '../../domain/enums/provider.enums';
import { IProviderContactEntity } from './providerContact.entity.interface';

export class ProviderContactEntity implements IProviderContactEntity {
  readonly typeContact: ProviderTypeContact;
  readonly value: string;
  readonly description: string;

  constructor(data: IProviderContactEntity) {
    this.typeContact = data.typeContact;
    this.value = data.value;
    this.description = data.description;
  }
}
