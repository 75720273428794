/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';

import { MocksService } from '@sl/services';
import { map, Observable } from 'rxjs';
import { OrderNoteEntity } from '../entities/orderNote.entity';
import { OrderNoteInfoEntity } from '../entities/orderNoteInfo.entity';
import { OrderNoteNoteEntity } from '../entities/orderNoteNote.entity';
import { OrderNoteNoteMessageEntity } from '../entities/orderNoteNoteMessage.entity';
import { OrderNoteNoteMessageDocEntity } from '../entities/orderNoteNoteMessageDoc.entity';
import { OrderNoteMapper } from '../mappers/orderNote.mapper';
import {
  GetOrderNotesParams,
  GetOrderNotesResponse,
  IOrderNoteRepository,
  PostOrderNotesParams,
  PostOrderNotesResponse,
  PutOrderNoteParams,
  PutOrderNoteResponse
} from './orderNote.repository.interface';

@Injectable({ providedIn: 'root' })
export class OrderNoteMocks implements IOrderNoteRepository {
  private notes: Record<string, OrderNoteEntity[]> = {
    id1: NOTES_1
  };

  constructor(private readonly mocksService: MocksService, private readonly orderNoteMapper: OrderNoteMapper) {}

  /* You can return values depending on its params.*/

  getOrderNotes({ providerId, placeId, orderId }: GetOrderNotesParams): Observable<GetOrderNotesResponse> {
    const response = this.notes['id1'];
    console.log(`getOrder mock ( providerId: ${providerId} placeId: ${placeId} orderId: ${orderId} ) -> `, response);
    return this.mocksService
      .mockResponse(response)
      .pipe(map((data) => data.map((orderNoteEntity) => this.orderNoteMapper.mapFromApi(orderNoteEntity))));
  }

  postOrderNotes({ providerId, placeId, orderId, orderNote }: PostOrderNotesParams): Observable<PostOrderNotesResponse> {
    console.log(`postNote mock ( providerId: ${providerId} placeId: ${placeId} orderId: ${orderId} orderNote: ${orderNote} ) -> void`);
    return this.mocksService.mockResponse();
  }

  putOrderNote({ providerId, placeId, orderId, noteId, orderNote }: PutOrderNoteParams): Observable<PutOrderNoteResponse> {
    console.log(
      `putNote mock ( providerId: ${providerId} placeId: ${placeId} orderId: ${orderId} noteId: ${noteId} orderNote: ${orderNote} ) -> void`
    );
    return this.mocksService.mockResponse();
  }
}

/** DATA */

const documentMetadataEntity1 = new OrderNoteNoteMessageDocEntity({
  docId: '1',
  docName: 'Document 1',
  docType: 'pdf',
  creationDate: new Date('2023-01-01').toISOString(),
  metadata: 'Metadata 1'
});

const documentMetadataEntity2 = new OrderNoteNoteMessageDocEntity({
  docId: '2',
  docName: 'Document 2',
  docType: 'txt',
  creationDate: new Date('2023-01-02').toISOString(),
  metadata: 'Metadata 2'
});

const message1 = new OrderNoteNoteMessageEntity({
  messageId: '101',
  readDate: new Date('2023-01-01').toISOString(),
  creationDate: new Date('2023-01-01').toISOString(),
  read: true,
  content: 'Hello from Message 1!',
  docList: [documentMetadataEntity1],
  type: 'type1',
  subject: 'subject1',
  source: 'source1'
});

const message2 = new OrderNoteNoteMessageEntity({
  messageId: '102',
  readDate: new Date('2023-01-02').toISOString(),
  creationDate: new Date('2023-01-02').toISOString(),
  read: false,
  content: 'Hello from Message 2!',
  docList: [documentMetadataEntity2],
  type: 'type2',
  subject: 'subject2',
  source: 'source2'
});

const note1 = new OrderNoteNoteEntity({
  noteId: '201',
  message: message1
});

const note2 = new OrderNoteNoteEntity({
  noteId: '202',
  message: message2
});

const orderInfo = new OrderNoteInfoEntity({
  providerId: '301',
  orderId: '302'
});

const orderNote1 = new OrderNoteEntity({
  originRequest: '401',
  order: orderInfo,
  note: [note1]
});

const orderNote2 = new OrderNoteEntity({
  originRequest: '402',
  order: orderInfo,
  note: [note2]
});

// Mock con los datos creados
export const NOTES_1: OrderNoteEntity[] = [orderNote1, orderNote2];
