import { DocumentOrigin, DocumentType } from '../../domain/enums/document.enum';
import { IDocumentEntity } from './document.entity.interface';

export class DocumentEntity implements IDocumentEntity {
  readonly documentId: string;
  readonly name: string;
  readonly type: DocumentType;
  readonly date: string;
  readonly mimeType: string;
  readonly origin: DocumentOrigin | null;

  constructor(data: IDocumentEntity) {
    this.documentId = data.documentId;
    this.name = data.name;
    this.type = data.type;
    this.mimeType = data.mimeType;
    this.date = data.date;
    this.origin = data.origin;
  }

  // Puedes agregar métodos adicionales aquí si es necesario
}
