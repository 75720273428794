import { IOrderNoteInfo } from './orderNoteInfo.model.interface';

export class OrderNoteInfo implements IOrderNoteInfo {
  providerId: string;
  orderId: string;

  constructor(data: IOrderNoteInfo) {
    this.providerId = data.providerId;
    this.orderId = data.orderId;
  }
}
