/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { ProviderContact } from '../../domain/providerContact.model';
import { ProviderContactEntity } from '../entities/providerContact.entity';
import { IProviderContactMapper } from './providerContact.mapper.interface';

@Injectable({ providedIn: 'root' })
export class ProviderContactMapper implements IProviderContactMapper {
  mapFromApi(apiData: ProviderContactEntity): ProviderContact {
    return new ProviderContact({
      typeContact: apiData.typeContact,
      value: apiData.value,
      description: apiData.description
    });
  }

  mapToApi(contact: ProviderContact): ProviderContactEntity {
    return {
      typeContact: contact.typeContact,
      value: contact.value,
      description: contact.description
    };
  }
}
