<!-- <ng-container *transloco="let t">
  <sl-alert *ngIf="alert.show" (close)="alert.show = false" [type]="alert.type" [closable]="true">
    {{ t('profile-alert.' + alert.type + '.' + alert.message ) }}
  </sl-alert>
</ng-container> -->

<!-- Datos Generales -->
<article class="table-container">
  <header class="header">
    <h2 class="title">{{ 'profile-titles.generalData' | transloco | capitalize }}</h2>
    <div class="header__buttons">
      <sl-tag *ngIf="general.showTag" [color]="'orange'">Pendiente de aprobar solicitud<i class="pi icon-download tag__icon"></i></sl-tag>
    </div>
  </header>
  <section>
    <sl-table-list *ngIf="profileData; else noProfileData" class="max-width" title="generalData" [tableData]="generalData"></sl-table-list>
    <ng-template #noProfileData>
      <span *ngIf="(loadingProfile$ | async) === false" class="noData">{{ 'profile-alert.noData.profile' | transloco }}</span>
    </ng-template>
  </section>
</article>

<!-- Datos De Contacto Y Acceso -->
<article class="table-container">
  <header class="header">
    <h2 class="title">{{ 'profile-titles.contactAccessData' | transloco | capitalize }}</h2>
    <div class="header__buttons">
      <sl-tag *ngIf="contactAccess.showTag" [color]="'orange'">
        Pendiente de aprobar solicitud<i class="pi icon-download tag__icon"></i>
      </sl-tag>
      <!--<sl-button (click)="showEditModal('contactAccessData')" [type]="'link'" [icon]="'edit'">
        {{ 'columns-profile.modify' | transloco | capitalize }}
      </sl-button>-->
    </div>
  </header>

  <section>
    <sl-table-list *ngIf="profileData" class="max-width" title="contactData" [tableData]="contactData"></sl-table-list>
  </section>

  <!-- <section class="table-container__access-data">
    <sl-table-list *ngIf="profileData " class="max-width" title="accessData" [tableData]="accessData"></sl-table-list>
  </section> -->

  <section>
    <ng-container *ngIf="(loadingProfile$ | async) === false" [ngTemplateOutlet]="Area"></ng-container>
  </section>
</article>

<!-- Facturación -->
<article class="table-container">
  <header class="header">
    <h2 class="title">{{ 'profile-titles.billingData' | transloco | capitalize }}</h2>
    <div class="header__buttons">
      <sl-tag *ngIf="billing.showTag" [color]="'orange'">Pendiente de aprobar solicitud<i class="pi icon-download tag__icon"></i></sl-tag>
      <!--<sl-button (click)="showEditModal('billingData')" [type]="'link'" [icon]="'edit'">
        {{ 'columns-profile.modify' | transloco | capitalize }}
      </sl-button>-->
    </div>
  </header>
  <section>
    <sl-table-list
      *ngIf="billingData.length > 0; else noBillingData"
      class="max-width"
      title="billingData"
      [tableData]="billingData"
    ></sl-table-list>
    <ng-template #noBillingData>
      <span *ngIf="(loadingProfile$ | async) === false" class="noData">{{ 'profile-alert.noData.bill' | transloco }}</span>
    </ng-template>
  </section>
</article>

<!-- TODO: Extract to component -->
<ng-template #Area>
  <article class="table-container__area">
    <header class="header">
      <h3>{{ 'columns-profile.area' | transloco | capitalize }}</h3>
      <div class="header__buttons">
        <!--<sl-tag *ngIf="area.showTag" [color]="'orange'">Pendiente de aprobar solicitud<i class="pi icon-download tag__icon"></i></sl-tag>-->
        <!--<sl-button (click)="showEditModal('area')" [type]="'link'" [icon]="'edit'">
          {{ 'columns-profile.modify' | transloco | capitalize }}
        </sl-button>-->
      </div>
    </header>
    <section>
      <ng-container *ngIf="objectKeys(areaObject).length > 0; else noAreas">
        <ng-container *ngFor="let areaName of areaObjectKeys">
          <h4>{{ areaName | titlecase }}</h4>
          <article>
            <div class="data-list table-container__access-data">
              <ng-container *ngFor="let data of areaObject[areaName]; trackBy: trackByFn">
                <div class="data-list__cell">
                  <span class="data-list__left">{{ 'columns-profile.' + data.typeContact | transloco | capitalize }}:</span>
                  <span class="data-list__right">{{ data.value }}</span>
                </div>
              </ng-container>
            </div>
          </article>
        </ng-container>
      </ng-container>
      <div class="data-list__add-area">
        <!--<sl-button
          (click)="showAddModal()"
          [label]="'columns-profile.add-area' | transloco | capitalize"
          [type]="'link'"
          [icon]="'mas'"
        ></sl-button>-->
      </div>
    </section>
  </article>
</ng-template>

<ng-template #noAreas>
  <span class="noData">{{ 'profile-alert.noData.area' | transloco }}</span>
</ng-template>

<!-- Modals -->
<sl-edit-modal
  [(visible)]="modalEditData.visible"
  [data]="modalEditData.data"
  [title]="modalEditData.title"
  [sectionTitle]="modalEditData.sectionTitle"
  [subtitle]="modalEditData.subtitle"
  [document]="modalEditData.document"
  [multiAddress]="modalEditData.multiAddress"
  (saveData)="saveChanges($event)"
  (visibleChange)="closeEditModal()"
></sl-edit-modal>

<sl-add-modal
  [areas]="areasNames"
  [(visible)]="modalAddData.visible"
  [data]="modalAddData.data"
  (addDataEmitter)="saveArea($event)"
  (visibleChange)="closeAddModal()"
></sl-add-modal>

<p-progressSpinner
  *ngIf="loadingProfile$ | async"
  [style]="{width: '100px', height: '100px'}"
  styleClass="custom-spinner"
  strokeWidth="2"
  animationDuration="1s"
></p-progressSpinner>
<sl-error-modal
  *ngIf="showErrorModal"
  [errorMessage]="'errorModal.message' | transloco"
  (closeModal)="showErrorModal = false"
></sl-error-modal>
