<article class="detail-page__container__content__order">
  <section class="detail-page__container__content__order__info">
    <div class="detail-page__container__content__order__namenis">
      <span>{{ 'orders.order' | transloco }}</span>
      <span>{{ order?.orderSummary?.order?.id }}</span>
    </div>

    <div class="detail-page__container__content__order__namenis">
      <span>{{ 'orders.nis' | transloco }}</span>
      <span>{{ order?.orderSummary?.proceeding?.id }}</span>
    </div>

    <div class="detail-page__container__content__order__namenis">
      <span>{{ 'orders.insured' | transloco }}</span>
      <span>{{ order?.orderSummary?.affectedName }}</span>
    </div>
  </section>
  <section class="detail-page__container__content__order__namenis">
    <span class="left">{{ 'orders.enterprise' | transloco }}</span>
    <img
      *ngIf="order?.orderSummary?.clientId === SETTLEMENT.CLIENT_ID.IRIS"
      class="detail-page__container__content__order__logo left"
      src="../../../assets/icons/irisGlobal.png"
      alt="Iris Global logo"
    />
    <img
      *ngIf="order?.orderSummary?.clientId === SETTLEMENT.CLIENT_ID.SANTALUCIA"
      class="detail-page__container__content__order__logo left"
      src="../../../assets/icons/santalucia-Logopositivo-RGB-Helvetica_Desktop.svg"
      alt="Santalucia logo"
    />
  </section>
</article>
