import { IProvider } from './provider.model.interface';
import { ProviderContact } from './providerContact.model';

export class Provider implements IProvider {
  readonly businessName: string;
  readonly userId: string;
  readonly email: string;
  readonly phone: string;
  readonly providerId: string;
  readonly address: string;
  readonly contacts: ProviderContact[];
  readonly generalDataModified: boolean;
  readonly contactsDataModified: boolean;

  constructor(data: IProvider) {
    this.businessName = data.businessName;
    this.userId = data.userId;
    this.email = data.email;
    this.phone = data.phone;
    this.providerId = data.providerId;
    this.address = data.address;
    this.contacts = data.contacts.map((contact) => new ProviderContact(contact)); // Create a new object to make sure it is immutable
    this.generalDataModified = data.generalDataModified;
    this.contactsDataModified = data.contactsDataModified;
  }

  // You can add additional methods here if necessary

  public addContact(contact: ProviderContact): Provider {
    const updatedContacts = [...this.contacts, contact];
    return new Provider({ ...this, contacts: updatedContacts, contactsDataModified: true });
  }

  public updateData(updatedData: IProvider): Provider {
    const updatedProvider = new Provider({ ...this, ...updatedData, generalDataModified: true });
    return updatedProvider;
  }

  // Ley de Demeter (La clase que instancie este provider no debe hablar con sus propiedades)
  public totalContacts(): number {
    return this.contacts.length;
  }
}
