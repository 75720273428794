import { Place } from './place.model';
import { Provider } from './provider.model';
import { IProviderPlaces } from './providerPlaces.model.interface';

export class ProviderPlaces implements IProviderPlaces {
  readonly provider: Provider;
  readonly place: Place[];

  constructor(data: IProviderPlaces) {
    this.provider = new Provider(data.provider);
    this.place = data.place.map((place) => new Place(place));
  }
}
