import { Injectable } from '@angular/core';
import { OrderSummaryBill } from '../../domain/orderSummaryBill.model';
import { OrderSummaryBillEntity } from '../entities/orderSummaryBill.entity';
import { IOrderSummaryBillMapper } from './orderSummaryBill.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderSummaryBillMapper implements IOrderSummaryBillMapper {
  mapFromApi(apiData: OrderSummaryBillEntity): OrderSummaryBill {
    return new OrderSummaryBill({
      billId: apiData.billId ?? null,
      billAmount: apiData.billAmount ? Number(apiData.billAmount) : null,
      dateBill: apiData.dateBill ? new Date(apiData.dateBill) : null,
      billNumber: apiData.billNumber ?? null
    });
  }

  mapToApi(orderSummaryBill: OrderSummaryBill): OrderSummaryBillEntity {
    return new OrderSummaryBillEntity({
      billId: orderSummaryBill.billId ?? null,
      billAmount: orderSummaryBill.billAmount?.toString() ?? null,
      dateBill: orderSummaryBill.dateBill?.toISOString() ?? null,
      billNumber: orderSummaryBill.billNumber ?? null
    });
  }
}
