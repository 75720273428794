<div class="statistics-container" *transloco="let t">
  <h1 class="statistics-container__title">{{ title }}</h1>

  <sl-dropdown-filter
    [showArrow]="true"
    [filterName]="'dropdown.filterByYear' | transloco"
    [placeholder]="t('filter.allYears')"
    [dropdownOptions]="years"
    [value]="selectedYear !== 'allYears' ? selectedYear : t('filter.' + selectedYear)"
    (valueChange)="onValueChange($event, 'year')"
  ></sl-dropdown-filter>
  <sl-dropdown-filter
    [showArrow]="true"
    [filterName]="'dropdown.filterByActivity' | transloco"
    [placeholder]="t('filter.allActivities')"
    [dropdownOptions]="activities"
    [value]="selectedActivity !== 'allActivities' ? selectedActivity : t('filter.' + selectedActivity)"
    (valueChange)="onValueChange($event, 'activity')"
  ></sl-dropdown-filter>

  <div class="statistics-container__bars">
    <ng-container *ngIf="!loading">
      <span *ngIf="totalNumber !== 0" class="statistics-container__subtitle"
        >{{ subtitle + ': ' }}<strong>{{ totalNumber }}</strong></span
      >
      <span class="statistics-container__year">{{ selectedYear === 'allYears' ? currentYear : selectedYear }}</span>

      <div *ngIf="currentYearNumber !== 0 || totalNumber !== 0" class="statistics-items-container">
        <ng-container *ngFor="let item of items">
          <div *ngIf="item.value" class="statistics-items-container__item">
            <span>{{ getMonthByNumber(item.date.split('/')[0]).slice(0, 3) }}</span>
            <div
              [ngStyle]="{
                width: calculateStatisticWidth(item.value),
                'background-color': barColor,
                color: valueColor
              }"
              class="full"
            >
              {{ item.value }}
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="selectedYear === 'allYears' ? currentYearNumber === 0 || totalNumber === 0 : totalNumber === 0">
        <img class="empty-space" src="../../../../assets/img/empty_space_statistics.svg" alt="empty space statistics" />
        <p *transloco="let t" class="empty-space__text">{{ t('statistics.no-orders') | titlecase }}</p>
      </div>

      <div
        *ngIf="selectedYear === 'allYears' && notCurrentYears.length !== 0"
        class="statistics-items-container years"
        [style.border-top]="'1px solid var(--color-grey11)'"
      >
        <ng-container *ngFor="let year of notCurrentYears">
          <div class="statistics-items-container__item">
            <span>{{ year.year }}</span>
            <div
              *ngIf="selectedYear !== currentYear"
              [ngStyle]="{
                width: calculateStatisticWidth(year.amount),
                'background-color': barColor,
                color: valueColor
              }"
              class="full"
            >
              {{ year.amount }}
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <p-progressSpinner
      *ngIf="loading"
      [style]="{ width: '100px', height: '100px' }"
      styleClass="custom-spinner"
      strokeWidth="2"
      animationDuration="1s"
    ></p-progressSpinner>
  </div>
</div>
