export * from './api';
export * from './api-client';
export * from './auth';
export * from './download';
export * from './mocks';
export * from './navigation';
export * from './page-title-strategy';
export * from './screen-size';
export * from './spinner';
export * from './storage';
export * from './stores';
export * from './translate';

/**
 * This folder contains services that encapsulate specific functionalities/features, making them available across all parts of the app.
 * They facilitate code organization, reusability, and separation of concerns.
 *
 * ! IMPORTANT: Implement application logic here instead of in components.
 *
 */
