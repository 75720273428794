import { AddressEntity } from './adress.entity';
import { IPlaceEntity } from './place.entity.interface';

export class PlaceEntity implements IPlaceEntity {
  readonly placeId: string;
  readonly description: string;
  readonly address: AddressEntity;

  constructor(data: IPlaceEntity) {
    this.placeId = data.placeId;
    this.description = data.description;
    this.address = new AddressEntity(data.address);
  }
}
