import { IProvider } from '../../domain/provider.model.interface';
import { ProviderContact } from '../../domain/providerContact.model';
import { IProviderEntity } from './provider.entity.interface';

export class ProviderEntity implements IProviderEntity {
  readonly businessName: string;
  readonly userId: string;
  readonly email: string;
  readonly phone: string;
  readonly providerId: string;
  readonly address: string;
  readonly contacts: ProviderContact[];
  readonly generalDataModified: boolean;
  readonly contactsDataModified: boolean;

  constructor(data: IProvider) {
    this.businessName = data.businessName;
    this.userId = data.userId;
    this.email = data.email;
    this.phone = data.phone;
    this.providerId = data.providerId;
    this.address = data.address;
    this.contacts = data.contacts.map((contact) => new ProviderContact(contact)); // Create a new object to make sure it is immutable
    this.generalDataModified = data.generalDataModified;
    this.contactsDataModified = data.contactsDataModified;
  }

  // You can add additional methods here if necessary
}
