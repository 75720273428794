/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { defaultFilterIssues, FilterIssues, Pages } from '@sl/components';
import { DocumentDetail, ISSUE, Issue } from '@sl/features';
import { SpinnerService } from '@sl/services';
import dayjs from 'dayjs';
import { filter, Observable } from 'rxjs';
import { IssueMessage } from 'src/app/core/features/issue/domain/issueMessage.model';
import { IssueMessageDoc } from 'src/app/core/features/issue/domain/IssueMessageDoc.model';
import { GetIssuesFilter } from 'src/app/core/features/issue/infrastructure/repositories/issue.repository.interface';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { SendIssueMessageData, SummaryData } from '../../components';
import { IssueStore } from './../../../../core/services/stores/issue.store';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-communications',
  templateUrl: './communications.page.html',
  styleUrls: ['./communications.page.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunicationsPageComponent implements OnInit {
  issues$ = this.issueStore.selectIssues();

  issuesTable: IssueTableItem[] = [];
  totalTablePages!: number;
  pageIndex!: number;
  pageSize!: number;

  isLoading$!: Observable<any>;
  isDownloading = false;
  showErrorModal = false;

  // Filters
  dateFilter = {
    startDate: null as Date | null,
    endDate: null as Date | null
  };

  buttonFilter: FilterIssues = { ...defaultFilterIssues };

  // Table features
  modalCommunication = {
    show: false,
    issue: null as Issue | null,
    summary: null as SummaryData | null
  };

  // Api params
  issueFilter: GetIssuesFilter = {
    // pageSize: 10,
    // pageIndex: 1,
    status: 'OPEN', // Only show open issues
    startDate: null,
    endDate: null,
    claimId: null,
    policeId: null,
    insuredName: null,
    type: null,
    orderId: null,
    clientId: null
  };

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly downloadService: DownloadService,
    private readonly issueStore: IssueStore,
    private readonly translocoService: TranslocoService,
    readonly spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.getTableData();
    this.initIssuesTable();
  }

  // Filters
  onDatePickerDates(dates: Date[]): void {
    this.dateFilter.startDate = dates[0];
    this.dateFilter.endDate = dates[1];
    this.getTableData();
  }

  onDatePickerRemove(): void {
    this.dateFilter.startDate = null;
    this.dateFilter.endDate = null;
    this.getTableData();
  }

  onSubmitFilters(filters: GetIssuesFilter): void {
    this.buttonFilter = { ...filters };
    this.getTableData();
  }

  onDiscardFilters(filters: Partial<GetIssuesFilter>): void {
    this.buttonFilter = { ...filters };
    this.getTableData();
  }

  // Download
  onDownloadTable(): void {
    this.downloadAllIssues();
  }

  // Table features
  onRowClick(data: Record<string, any>): void {
    this.showModalCommunications(data);
  }

  async onSendIssueMessageModal({ documentsWithoutIds, comments, issue }: SendIssueMessageData): Promise<void> {
    await this.handleSendIssueMessage({ documentsWithoutIds, comments, issue });
  }

  onMarkIssueMessageAsRead(issue: Issue): void {
    this.markIssueMessageAsRead(issue);
  }

  onCloseModalCommunication(): void {
    this.closeModalCommunications();
  }

  onChangePage({ pageSize, pageIndex }: Pages): void {
    this.issueFilter.pageSize = pageSize;
    this.issueFilter.pageIndex = pageIndex;
    this.getTableData();
  }

  /* Private */

  private getTableData(): void {
    const startDate = this.dateFilter.startDate ? mapDateToApi(this.dateFilter.startDate) : null;
    const endDate = this.dateFilter.endDate ? mapDateToApi(this.dateFilter.endDate) : null;
    this.issueFilter = { ...this.issueFilter, ...this.buttonFilter, startDate, endDate };
    this.getIssues(this.issueFilter);
  }

  // Api
  private getIssues(issueFilter?: GetIssuesFilter): void {
    this.isLoading$ = this.spinnerService.getLoadingObservable('issues');
    this.issueStore.actionGetIssues({ issueFilter });
  }

  // private putIssue(issue: Issue): void {
  //   this.isLoading$ = this.spinnerService.getLoadingObservable(issue.id);
  //   this.issueStore.actionPutIssue({ issue });
  // }

  private async getIssuesPromise(issueFilter: GetIssuesFilter): Promise<Issue[]> {
    this.isLoading$ = this.spinnerService.getLoadingObservable('issues');
    return this.issueStore.actionGetIssuesPromise({ issueFilter });
  }

  private async putIssuePromise(issue: Issue): Promise<void> {
    this.isLoading$ = this.spinnerService.getLoadingObservable(issue.id);
    return this.issueStore.actionPutIssuePromise({ issue });
  }

  // Initialization
  private initIssuesTable(): void {
    this.issues$
      .pipe(
        untilDestroyed(this),
        filter(({ issues, loading, error }) => {
          if (error) {
            this.showErrorModal = true;
          }
          return Boolean(issues) && !loading && !error;
        })
      )
      .subscribe(({ issues }) => {
        this.issuesTable = issues!.filter(({ status }) => status === ISSUE.STATUS.OPEN).map((issue) => this.mapIssueToTableItem(issue));
        const issueUpdated = issues!.find(({ id }) => id === this.modalCommunication.issue?.id);
        if (issueUpdated) {
          this.modalCommunication.issue = issueUpdated;
        }
        // this.totalTablePages = Math.ceil(total! / pageSize!);
        // this.pageIndex = pageIndex!;
        // this.pageSize = pageSize!;
        this.cdr.detectChanges();
      });
  }

  // Download table feature
  private async downloadAllIssues(): Promise<void> {
    this.isDownloading = true;
    try {
      const { pageSize, pageIndex, ...filters } = this.issueFilter;
      const issues = await this.getIssuesPromise(filters);
      const issuesTable = issues?.map((issue) => this.mapDataToDownloadTableItem(issue));
      this.downloadTable(issuesTable);
    } catch (e) {
      console.error(e);
      this.showErrorModal = true;
    } finally {
      this.isDownloading = false;
      this.cdr.detectChanges();
    }
  }

  private downloadTable(table: any): void {
    this.downloadService.exportAsExcel(table, 'segundas_comunicaciones', 'communications');
  }

  // Send issue message feature
  private async handleSendIssueMessage({ documentsWithoutIds, comments, issue }: SendIssueMessageData): Promise<void> {
    const docList: IssueMessageDoc[] = documentsWithoutIds.map((doc) => this.mapDocumentDetailToIssueMessageDoc(doc));

    const newMessage = new IssueMessage({
      messageId: null,
      readDate: new Date(),
      creationDate: new Date(),
      read: true,
      content: comments,
      type: ISSUE.MESSAGE.TYPE.ISSUE,
      subject: null,
      source: ISSUE.MESSAGE.SOURCE.PROFESIONAL,
      sender: null,
      docList
    });

    const allMessagesAsRead = issue.messages.map((message) =>
      message.read ? message : new IssueMessage({ ...message, read: true, readDate: new Date() })
    );

    const issueWithNewMessage: Issue = { ...issue, messages: [...allMessagesAsRead, newMessage] };

    try {
      await this.putIssuePromise(issueWithNewMessage);
      this.getTableData();
    } catch (e) {
      console.error(e);
      this.showErrorModal = true;
    } finally {
      this.cdr.detectChanges();
    }
  }

  // Mark issue message as read feature
  private async markIssueMessageAsRead(issue: Issue): Promise<void> {
    try {
      await this.putIssuePromise(issue);
      this.getTableData();
    } catch (e) {
      console.error(e);
      this.showErrorModal = true;
    } finally {
      this.cdr.detectChanges();
    }
  }

  // Modal
  private showModalCommunications(data: Record<string, any>): void {
    const { value, nis, policy, hour, openDate, closingDate, closingHour, sort } = data;
    /* const closingDate = mapApiToDate(data['value'].closingDate);
    const closingHour = mapDateToHour(data['value'].closingHour); */
    const state = this.translocoService.translate('issueStatus.' + value.status).replace('issueStatus.', '');
    this.modalCommunication.summary = { nis, openDate, closingDate, state, policy, hour, closingHour, sort }; // The order is important
    this.modalCommunication.issue = value as Issue;
    this.modalCommunication.show = true;
    this.disableScroll();
  }

  private closeModalCommunications(): void {
    this.modalCommunication.summary = null;
    this.modalCommunication.issue = null;
    this.modalCommunication.show = false;
    this.enableScroll();
  }

  // Utils
  private disableScroll(): void {
    document.body.style.overflow = 'hidden';
  }

  private enableScroll(): void {
    document.body.style.overflow = 'auto';
  }

  // Mappers
  private mapIssueToTableItem = (issue: Issue): IssueTableItem => ({
    value: issue,
    enterprise: issue.client,
    orderId: issue.orderId,
    sort: this.translocoService.translate('issueType.' + issue.type).replace('issueType.', ''),
    nis: issue.nis,
    policy: issue.policy,
    openDate: issue.date ? mapApiToDate(issue.date) : '',
    hour: issue.date ? mapDateToHour(issue.date) : '',
    closingDate: issue.closingDate ? mapApiToDate(issue.closingDate) : '',
    closingHour: issue.closingDate ? mapDateToHour(issue.closingDate) : '',
    deceasedName: issue.affectedName,
    state: issue.status
  });

  private mapDocumentDetailToIssueMessageDoc = (doc: DocumentDetail): IssueMessageDoc => ({
    docId: doc.documentId,
    docName: doc.name,
    docType: doc.type,
    creationDate: doc.date,
    metadata: doc.content
  });

  private mapDataToDownloadTableItem = (item: Issue): DownloadTableItem => {
    const { value, enterprise, ...downloadTableItem } = this.mapIssueToTableItem(item);
    return { enterprise: CLIENT_ID_MAP[enterprise], ...downloadTableItem };
  };
}

const mapApiToDate = (orderDate: any): string => dayjs(orderDate).utc().format('DD/MM/YYYY');
const mapDateToApi = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
const mapDateToHour = (orderDate: any): string => dayjs(orderDate).utc().format('HH:mm');

type IssueTableItem = {
  value: Issue;
  enterprise: string;
  orderId: string;
  sort: string;
  nis: string;
  policy: string;
  openDate: string;
  hour: string;
  closingDate: string;
  closingHour: string;
  deceasedName: string;
  state: string;
};

export type DownloadTableItem = Omit<IssueTableItem, 'value'>;

const CLIENT_ID_MAP: Record<string, string> = {
  ['IG']: 'Iris Global',
  ['SL']: 'Santalucía'
};
