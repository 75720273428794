<div class="communication-modal__container" *transloco="let t">
  <p-progressSpinner
    *ngIf="isLoading$ | async"
    [style]="{ width: '100px', height: '100px' }"
    styleClass="custom-spinner"
    strokeWidth="2"
    animationDuration="1s"
  ></p-progressSpinner>

  <div class="communication-modal__container__content">
    <header class="communication-modal__container__content__header">
      <span class="communication-modal__container__content__up__title">{{ t('communications.modal.title') }}</span>
      <div class="communication-modal__container__content__up__title__left">
        <span class="communication-modal__container__content__up__nis" (click)="onOrderIdClick()" (keypress)="onOrderIdClick()">
          {{ t('communications.modal.subtitle') + ' ' + issue.orderId | titlecase }}
        </span>
        <i class="pi icon-cerrar close-icon" (click)="onCloseModalClick()" (keypress)="onCloseModalClick()"></i>
      </div>
    </header>

    <div class="communication-modal__container__content__up">
      <!-- Info-->
      <ng-container [ngTemplateOutlet]="Info"></ng-container>

      <!-- Messages-->
      <ng-container [ngTemplateOutlet]="Messages"></ng-container>
    </div>

    <div class="communication-modal__container__content__add">
      <!-- Observations -->
      <ng-container [ngTemplateOutlet]="Documents"></ng-container>

      <!-- Observations -->
      <ng-container [ngTemplateOutlet]="Observations"></ng-container>

      <!-- Action buttons -->
      <ng-container [ngTemplateOutlet]="ActionButtons"></ng-container>
    </div>
  </div>
</div>

<ng-template #Info>
  <div [style]="{ padding: '0 24px' }">
    <sl-simple-table [tableData]="summary" [rows]="4"></sl-simple-table>
  </div>
</ng-template>

<ng-template #Messages>
  <div #scrollContainer class="communication-modal__container__content__up__messages">
    <ng-container *ngFor="let message of issue.messages; trackBy: trackByFn">
      <!-- <span>{{ message | json }}</span> -->
      <sl-message [message]="message" (markMessageAsRead)="onMarkMessageAsRead($event)"></sl-message>
    </ng-container>
  </div>
</ng-template>

<ng-template #Documents>
  <ng-container *ngFor="let doc of generalDocuments; trackBy: trackByFn">
    <div class="communication-modal__container__content__add__documents">
      <div class="note__add__upload">
        <sl-dropdown-filter
          style="max-width: 270px"
          [showArrow]="dropdownGeneralDocumentTypes.showArrow"
          [filterName]="dropdownGeneralDocumentTypes.label | transloco"
          [placeholder]="dropdownGeneralDocumentTypes.placeholder | transloco"
          [dropdownOptions]="dropdownGeneralDocumentTypes.options"
          [(value)]="doc.type"
          (valueChange)="onDropdownGeneralDocumentTypeChange(doc, $event!)"
        >
        </sl-dropdown-filter>
        <div class="note__add__upload__rigth">
          <div class="note__add__upload__document">
            <input
              #generalFileInput
              type="file"
              style="display: none"
              (click)="generalFileInput.value = ''"
              (input)="onGeneralFileInput($event)"
            />
            <sl-button
              [type]="'link'"
              [icon]="doc.file ? 'check' : 'info1'"
              [slTooltip]="['communications.modal.files']"
              (click)="onButtonAddGeneralFileClick(doc)"
            >
              {{ doc.file ? doc.file.name : ('modal.upload' | transloco | titlecase) }}
            </sl-button>

            <!-- Add/Delete -->
            <ng-container *ngIf="!doc.file">
              <button
                class="button-container__side__upload"
                type="button"
                (click)="onButtonAddGeneralFileClick(doc)"
                (keypress)="onButtonAddGeneralFileClick(doc)"
              >
                <i class="pi icon-upload"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="doc.file">
              <button
                class="button-container__side__upload"
                type="button"
                (click)="onButtonDeleteGeneralFileClick(doc)"
                (keypress)="onButtonDeleteGeneralFileClick(doc)"
              >
                <i class="pi icon-eliminar"></i>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #Observations>
  <sl-textarea
    class="sl-textarea"
    [label]="observations.label | transloco"
    [placeholder]="observations.placeholder"
    [maxlength]="observations.maxLength"
    [(value)]="observations.value"
    (keydownEnter)="onTextAreaObservationsEnter()"
  ></sl-textarea>
</ng-template>

<ng-template #ActionButtons>
  <div class="button-container">
    <sl-button [type]="'secondary'" [size]="'l'" [borderRound]="true" (click)="onButtonDeleteClick()">
      {{ 'Borrar' }}
    </sl-button>
    <sl-button [type]="'primary'" [size]="'l'" [borderRound]="true" [disabled]="disableButtonSend()" (click)="onButtonSendClick()">
      {{ 'Enviar' }}
    </sl-button>
  </div>
</ng-template>
