<article class="listing">
  <header class="listing__header">
    <h4 class="listing__title">{{ 'secondCommunications.title' | transloco }}</h4>
  </header>
  <section class="listing__table">
    <ng-container [ngTemplateOutlet]="Table"></ng-container>
  </section>
</article>

<!-- TODO: extract to components -->
<ng-template #Table>
  <article class="table">
    <header class="table__header">
      <ng-container [ngTemplateOutlet]="Filters"></ng-container>
      <!-- <ng-container [ngTemplateOutlet]="FilterTags"></ng-container> -->
    </header>
    <section *ngIf="(isLoading$ | async) === false || isDownloading" class="table__content">
      <sl-table
        [dataTable]="issuesTable"
        [showRowHover]="true"
        [tableItemsName]="'communications.itemsName' | transloco"
        [totalPages]="totalTablePages"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [isTab]="true"
        (pageChange)="onChangePage($event)"
        (rowClick)="onRowClick($event)"
        [showPagination]="false"
      ></sl-table>
    </section>
  </article>
</ng-template>

<ng-template #Filters>
  <div class="filters">
    <sl-date-filter
      class="dropdown-filter"
      [showRangeFilter]="false"
      (selectedDates)="onDatePickerDates($event)"
      (removeSelectedDates)="onDatePickerRemove()"
    ></sl-date-filter>

    <div class="button-filters">
      <sl-dropdown
        [label]="dropdownStatus.label"
        [placeholder]="dropdownStatus.placeholder"
        [options]="dropdownStatus.options"
        [(value)]="dropdownStatus.value"
        (valueChange)="onFilterByStatus()"
      ></sl-dropdown>

      <!-- <sl-button-filter-communications
        [filters]="buttonFilter"
        [showFilterOrderId]="false"
        (submitFilters)="onSubmitFilters($event)"
        (discardFilters)="onDiscardFilters($event)"
      ></sl-button-filter-communications> -->
      <sl-button [type]="'link'" [icon]="'download'" [disabled]="isDownloading" (clicked)="onDownloadTable()">{{ 'Descargar' }}</sl-button>
    </div>
  </div>
</ng-template>

<ng-template #FilterTags>
  <div class="filter-tags"></div>
</ng-template>

<p-progressSpinner
  *ngIf="isLoading$ | async"
  [style]="{ width: '100px', height: '100px' }"
  styleClass="custom-spinner"
  strokeWidth="2"
  animationDuration="1s"
></p-progressSpinner>

<!-- MODALS -->
<sl-communication-modal
  *ngIf="modalCommunication.show"
  [issue]="modalCommunication.issue!"
  [summary]="modalCommunication.summary!"
  (closeModal)="onCloseModalCommunication()"
  (sendIssueMessage)="onSendIssueMessageModal($event)"
  (markIssueMessageAsRead)="onMarkIssueMessageAsRead($event)"
></sl-communication-modal>

<sl-error-modal
  *ngIf="showErrorModal"
  [errorMessage]="'errorModal.message' | transloco"
  (closeModal)="showErrorModal = false"
></sl-error-modal>
