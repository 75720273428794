<div class="data-list">
  <ng-container *ngFor="let data of tableDataEntries; trackBy: trackByFn">
    <div *ngIf="showData(data[0])" class="data-list__cell">
      <span class="data-list__left">{{ 'columns-profile.' + data[0] | transloco }}:</span>
      <span class="data-list__right">
        {{ data[1] }}
      </span>
    </div>
  </ng-container>
</div>
