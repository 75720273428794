import { OrderSummaryType } from './enums/orderSummaryStatus.enum';
import { IOrderSummaryOrder } from './orderSummaryOrder.model.interface';

export class OrderSummaryOrder implements IOrderSummaryOrder {
  readonly id: string;
  readonly date: Date | null;
  readonly type: OrderSummaryType[];

  constructor(data: IOrderSummaryOrder) {
    this.id = data.id;
    this.date = data.date ? new Date(data.date) : null;
    this.type = data.type;
  }
}
