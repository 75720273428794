/* eslint-disable @typescript-eslint/naming-convention */

export type IssueStatus = 'OPEN' | 'CLOSE' | 'ALL';
export type IssueClient = 'IG' | 'SL';
export type IssueType =
  | 'QUALITY_DEFICIENCIES' // Deficiencias en la calidad de las prestaciones
  | 'SERVICE_DISAGREEMENT' // Desacuerdo con el servicio
  | 'REJECTION_DISAGREEMENT' // Desacuerdo con rechazo
  | 'COMPENSATION_DISAGREEMENT' // Desacuerdo indemnización
  | 'PROVIDE_INFORMATION' // Facilita información para continuar
  | 'WITHOUT_CONTACT' // No han contactado desde la comunicación del siniestro
  | 'OTHER_REASONS' // Otros motivos no especificados
  | 'SERVICE_DELAY' // Retraso en el servicio/tramitación
  | 'INFORMATION_REQUEST' // Solicita información
  | 'TREATMENT_INCORRECT'; // Trato incorrecto colaborador

export type IssueMessageSource = 'PROVIDER-MANAGER' | 'PROVIDER-ADMIN' | 'PROFESIONAL' | 'ORDER-MANAGER';
export type IssueMessageType = 'ISSUE';

export const ISSUE = {
  CLIENT_ID: {
    SANTALUCIA: 'SL' as IssueClient,
    IRIS: 'IG' as IssueClient
  },
  STATUS: {
    OPEN: 'OPEN' as IssueStatus,
    CLOSE: 'CLOSE' as IssueStatus,
    ALL: 'ALL' as IssueStatus
  },
  TYPE: {
    QUALITY_DEFICIENCIES: 'QUALITY_DEFICIENCIES' as IssueType,
    SERVICE_DISAGREEMENT: 'SERVICE_DISAGREEMENT' as IssueType,
    REJECTION_DISAGREEMENT: 'REJECTION_DISAGREEMENT' as IssueType,
    COMPENSATION_DISAGREEMENT: 'COMPENSATION_DISAGREEMENT' as IssueType,
    PROVIDE_INFORMATION: 'PROVIDE_INFORMATION' as IssueType,
    WITHOUT_CONTACT: 'WITHOUT_CONTACT' as IssueType,
    OTHER_REASONS: 'OTHER_REASONS' as IssueType,
    SERVICE_DELAY: 'SERVICE_DELAY' as IssueType,
    INFORMATION_REQUEST: 'INFORMATION_REQUEST' as IssueType,
    TREATMENT_INCORRECT: 'TREATMENT_INCORRECT' as IssueType
  },
  MESSAGE: {
    SOURCE: {
      PROVIDER_MANAGER: 'PROVIDER-MANAGER' as IssueMessageSource,
      PROVIDER_ADMIN: 'PROVIDER-ADMIN' as IssueMessageSource,
      PROFESIONAL: 'PROFESIONAL' as IssueMessageSource,
      ORDER_MANAGER: 'ORDER-MANAGER' as IssueMessageSource
    },
    TYPE: {
      ISSUE: 'ISSUE' as IssueMessageType
    }
  }
} as const;
