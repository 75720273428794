import { IssueClient, IssueStatus, IssueType } from './enums/issue.enum';
import { IIssue } from './issue.model.interface';
import { IssueMessage } from './issueMessage.model';

export class Issue implements IIssue {
  readonly id: string;
  readonly description: string;
  readonly type: IssueType;
  readonly nis: string;
  readonly policy: string;
  readonly date: Date | null;
  readonly closingDate: Date | null;
  readonly client: IssueClient;
  readonly status: IssueStatus;
  readonly messages: IssueMessage[];
  readonly orderId: string;
  readonly affectedName: string;

  constructor(data: IIssue) {
    this.id = data.id;
    this.description = data.description;
    this.type = data.type;
    this.nis = data.nis;
    this.policy = data.policy;
    this.date = data.date ? new Date(data.date) : null; // Create a new Object to make sure it is immutable
    this.closingDate = data.closingDate ? new Date(data.closingDate) : null; // Create a new Object to make sure it is immutable
    this.client = data.client;
    this.status = data.status;
    this.messages = data.messages.map(
      // Add sender propertY
      (message) => (message.isManagerMessage() ? new IssueMessage({ ...message, sender: data.affectedName }) : new IssueMessage(message))
    );
    this.orderId = data.orderId;
    this.affectedName = data.affectedName;
  }
}
