import { Environment } from './environment.interface';

/**
 * Adds the 'appEnv' property if it doesn't exist to work locally.
 * Since locally we don't have the file that does this ('configmap.yaml' from the configuration repositories).
 */
export const loadLocalAppEnv = (): void => {
  if (window['appEnv' as keyof Window]) {
    return;
  }
  const appEnv: Environment = {
    production: false,
    analytics: {
      id: 'GTM-T46KGQP'
    },
    errorHandler: {
      appName: 'santalucia-proveedores-mvp'
    },
    keycloak: {
      config: {
        url: 'https://sso-sldev.santalucia.es/auth',
        realm: 'stl-ig-pvd-cif',
        clientId: 'c5f3f654-904f-11ee-8962-fbf7645f7289'
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: true,
        pkceMethod: 'S256',
        useNonce: true,
        enableLogging: true
      }
    },
    logger: {
      enableSourceMaps: false,
      logToLocalStorage: true,
      logToSessionStorage: false,
      logToConsole: true,
      serverLogLevel: 1
    },
    files: {
      maxFileSizeBytes: 10 * 1024 * 1024
    }
  };
  Object.defineProperty(window, 'appEnv', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: Object.freeze(appEnv)
  });
};
