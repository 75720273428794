/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { MocksService } from '@sl/services';
import { map, Observable } from 'rxjs';
import { OrderEntity } from '../entities/order.entity';
import { OrderSummaryEntity } from '../entities/orderSummary.entity';
import { OrderMapper } from '../mappers/order.mapper';
import { OrderSummaryMapper } from '../mappers/orderSummary.mapper';
import {
  GetOrderParams,
  GetOrderResponse,
  GetOrdersEntityResponse,
  GetOrdersParams,
  GetOrdersResponse,
  IOrderRepository
} from './order.repository.interface';

@Injectable({ providedIn: 'root' })
export class OrderMocks implements IOrderRepository {
  private order: Record<string, OrderEntity> = {
    id1: ORDER_1
  };
  private orders: Record<string, GetOrdersEntityResponse> = {
    id1: ORDERS_1
  };

  constructor(
    private readonly mocksService: MocksService,
    private readonly orderMapper: OrderMapper,
    private readonly orderSummaryMapper: OrderSummaryMapper
  ) {}

  /* You can return values depending on its params.*/

  getOrders({ filter }: GetOrdersParams): Observable<GetOrdersResponse> {
    const data = this.orders['id1'];
    return this.mocksService.mockResponse(data).pipe(
      map((response: GetOrdersEntityResponse) => ({
        ...response,
        list: response.list.map((orderSummaryEntity: OrderSummaryEntity) => this.orderSummaryMapper.mapFromApi(orderSummaryEntity))
      }))
    );
  }

  getOrder({ orderId }: GetOrderParams): Observable<GetOrderResponse> {
    const response = this.order['id1'];
    return this.mocksService.mockResponse(response).pipe(map((orderEntity: OrderEntity) => this.orderMapper.mapFromApi(orderEntity)));
  }
}

/** DATA */

export const ORDER_1: OrderEntity = {
  orderSummary: {
    unreadedNotes: 0,
    order: {
      id: '12345679',
      date: '2023-07-20',
      type: ['NEW']
    },
    policeId: '70.088.753',
    proceeding: {
      id: '70.088.753',
      date: '2018-03-20'
    },
    activity: 'Funeraria',
    mainDate: '19/03/2023',
    status: {
      status: 'billingIncidence',
      date: '2018-03-20T09:12:28Z'
    },
    clientId: 'santalucía',
    placeId: '',
    insuredId: '27328845Y',
    affectedName: 'Silvia Aparicio Llorente',
    additionalInfo: 'Descripción del encargo. Teléfono 6666666.',
    bill: {
      billId: '7B8E9FBC-7159-CF76-8510-8D8D3CE00000',
      billAmount: '1500',
      dateBill: '2023-07-15',
      billNumber: '121341234'
    },
    historyTracking: [
      {
        status: 'PENDING',
        date: '2023-07-15'
      }
    ],
    settlementId: '1234'
  },
  billing: [
    {
      amount: 'string',
      netAmount: 'string',
      tax: '24,00'
    }
  ],
  concepts: [
    {
      id: 'string',
      description: 'string',
      units: '1',
      tax: 21.0,
      netAmount: 200,
      totalAmount: 6100,
      requestCode: '1',
      supplied: false
    }
  ]
};

export const ORDERS_1: GetOrdersEntityResponse = {
  list: [ORDER_1.orderSummary],
  pageIndex: 0,
  pageSize: 10,
  total: 1
};
