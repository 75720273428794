export const APP_CONFIG = {
  repository: {
    // * PROVIDER
    place: {
      getPlaces: {
        isMocked: false
      }
    },
    provider: {
      getProvider: {
        isMocked: true
      },
      putProvider: {
        isMocked: true
      },
      getProviderData: {
        isMocked: false
      }
    },
    // * ORDER
    orderVolumetry: {
      getOrderVolumetries: {
        isMocked: false
      }
    },
    orderStatistic: {
      getOrderStatistics: {
        isMocked: true
      }
    },
    order: {
      getOrders: {
        isMocked: false
      },
      getOrder: {
        isMocked: false
      }
    },
    orderNote: {
      getOrderNotes: {
        isMocked: true
      },
      postOrderNotes: {
        isMocked: true
      },
      putOrderNote: {
        isMocked: true
      }
    },
    orderBill: {
      postOrderBills: {
        isMocked: true
      }
    },
    // * DOCUMENT
    document: {
      getDocuments: {
        isMocked: true
      },
      postDocuments: {
        isMocked: true
      },
      getDocument: {
        isMocked: true
      },
      deleteDocument: {
        isMocked: true
      }
    },
    // * ISSUES
    issue: {
      getIssues: {
        isMocked: true
      },
      putIssue: {
        isMocked: true
      }
    },
    // * BILLS
    settlement: {
      getsettlements: {
        isMocked: true
      }
    },
    // * NOTIFICATION
    notification: {
      getNotifications: {
        isMocked: true
      },
      putNotification: {
        isMocked: true
      }
    }
  }
} as const;
