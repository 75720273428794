<sl-button-filter [visible]="visible" (visibleChange)="visible = $event">
  <form class="container">
    <div class="content">
      <ng-container [ngTemplateOutlet]="Section1"></ng-container>
      <ng-container [ngTemplateOutlet]="Section2"></ng-container>
    </div>
    <div class="footer">
      <ng-container [ngTemplateOutlet]="Footer"></ng-container>
    </div>
  </form>
</sl-button-filter>

<ng-template #Section1>
  <fieldset class="fieldset">
    <legend class="fieldset__legend">{{ section1.legend }}</legend>
    <div class="fieldset__content">
      <ng-container #CheckList>
        <div *ngFor="let option of checkListReadState.options; trackBy: trackByFn" class="checkbox">
          <input
            type="checkbox"
            [value]="option.value"
            [checked]="checkListReadState.value === option.value"
            (change)="onCheckListReadState(option.value)"
          />
          <span>{{ option.label }}</span>
        </div>
      </ng-container>
      <!-- TODO Use this-->
      <!-- <ng-container *ngFor="let option of checkListReadState.options; trackBy: trackByFn">
        <sl-checkbox
          [value]="option.value"
          [checked]="option.value === checkListReadState.value"
          (modelChange)="onCheckListReadState($event)"
        >
          {{  option.label }}
        </sl-checkbox>
      </ng-container> -->
    </div>
  </fieldset>
</ng-template>

<ng-template #Section2>
  <fieldset class="fieldset">
    <legend class="fieldset__legend">{{ section2.legend }}</legend>
    <div class="fieldset__content">
      <!-- TODO: Extract to a component or use sl-input -->
      <!-- <ng-container #SearchByWord>
        <span>{{ 'filter.searchByWord' | transloco }}</span>
        <div
          class="page-container__filter__search"
          [ngClass]="{ 'page-container__filter__search__border': searchByWord.focus }"
          (focusin)="searchByWord.focus = true"
          (focusout)="searchByWord.focus = false"
        >
          <i class="pi icon-buscar"></i>
          <input type="text" [placeholder]="searchByWord.placeholder | transloco" (input)="onSearchByWordInput($event.target)" />
        </div>
      </ng-container> -->

      <sl-input
        [label]="searchByWord.label"
        [placeholder]="searchByWord.placeholder"
        [(value)]="searchByWord.value"
        (valueChange)="onSearchByWordChange()"
      ></sl-input>
    </div>
  </fieldset>
</ng-template>

<ng-template #Footer>
  <sl-button [buttonType]="'submit'" [type]="'secondary'" [size]="'s'" (click)="handleRemoveFilters()">
    {{ 'button.remove' | transloco | titlecase }}
  </sl-button>
  <sl-button [buttonType]="'submit'" [type]="'primary'" [size]="'s'" (click)="handleSubmitFilters()">
    {{ 'button.accept' | transloco | titlecase }}
  </sl-button>
</ng-template>
