/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Document } from '../../domain/document.model';
import { DocumentEntity } from '../entities/document.entity';
import { IDocumentMapper } from './document.mapper.interface';

@Injectable({ providedIn: 'root' })
export class DocumentMapper implements IDocumentMapper {
  mapFromApi(apiData: DocumentEntity): Document {
    return new Document({
      documentId: apiData.documentId,
      name: apiData.name,
      type: apiData.type,
      mimeType: apiData.mimeType,
      date: new Date(apiData.date),
      origin: apiData.origin
    });
  }

  mapToApi(document: Document): DocumentEntity {
    return new DocumentEntity({
      documentId: document.documentId,
      name: document.name,
      type: document.type,
      mimeType: document.mimeType,
      date: document.date.toISOString(),
      origin: document.origin
    });
  }
}
