import { OrderStatistic } from './../../domain/orderStatistic.model';
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderStatisticEntity } from '../entities/orderStatistic.entity';
import { IOrderStatisticMapper } from './orderStatistic.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderStatisticMapper implements IOrderStatisticMapper {
  mapFromApi(apiData: OrderStatisticEntity): OrderStatistic {
    return new OrderStatistic({
      id: apiData.id,
      value: apiData.value,
      date: apiData.date,
      activity: apiData.activity
    });
  }

  mapToApi(orderStatistic: OrderStatistic): OrderStatisticEntity {
    return {
      id: orderStatistic.id,
      value: orderStatistic.value,
      date: orderStatistic.date,
      activity: orderStatistic.activity
    };
  }
}
