export enum ProviderTypeIdentification {
  cif,
  providerId,
  nif
}
export enum ProviderTypeContact {
  email,
  phone
}
export enum ProviderBillingType {
  manual
}
