import {
  ApplicationRef,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Input,
  OnDestroy,
  ViewContainerRef
} from '@angular/core';
import { TooltipComponent } from '@sl/components';

@Directive({
  selector: '[slTooltip]'
})
export class TooltipDirective implements OnDestroy {
  @Input() slTooltip: any[] = [];

  componentRef: ComponentRef<any> | null = null;
  showTimeout?: number;
  showDelay = 300;

  constructor(private elementRef: ElementRef, private appRef: ApplicationRef, private viewContainerRef: ViewContainerRef) {}

  @HostListener('mouseenter')
  onMouseListener(): void {
    if (this.componentRef === null) {
      this.componentRef = this.viewContainerRef.createComponent(TooltipComponent);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
      this.setTooltipComponentProperties();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      window.clearInterval(this.showTimeout);
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.slTooltip[0];
      this.componentRef.instance.visible = true;
      const { left, top, bottom, width } = this.elementRef.nativeElement.getBoundingClientRect();
      if (this.slTooltip[1] || (this.slTooltip[1] && this.slTooltip[0] === null)) {
        /* this.componentRef.instance.top = Math.round(2 * top - bottom - 50);
        this.componentRef.instance.left = Math.round(left); */
        this.componentRef.instance.top = Math.round(top);
        this.componentRef.instance.left = Math.round(left + width / 2);
        this.componentRef.setInput('state', this.slTooltip[1]);
      } else {
        this.componentRef.instance.left = Math.round(left);
        this.componentRef.instance.top = Math.round(2 * top - bottom - 20);
      }
      this.componentRef.setInput('title', this.slTooltip[0]);
    }
  }
}
