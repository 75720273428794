/* eslint-disable max-len */
import { IOrderEntity } from './order.entity.interface';
import { OrderBillingEntity } from './orderBilling.entity';
import { OrderConceptEntity } from './orderConcept.entity';
import { OrderSummaryEntity } from './orderSummary.entity';

export class OrderEntity implements IOrderEntity {
  readonly orderSummary: OrderSummaryEntity;
  readonly billing: OrderBillingEntity[];
  readonly concepts: OrderConceptEntity[];

  constructor(data: IOrderEntity) {
    this.orderSummary = new OrderSummaryEntity(data.orderSummary); // Create a new object to make sure it is immutable
    this.billing = data.billing.map((orderBilling) => new OrderBillingEntity(orderBilling)); // Create a new object to make sure it is immutable
    this.concepts = data.concepts.map((orderConcept) => new OrderConceptEntity(orderConcept)); // Create a new object to make sure it is immutable
  }
}
