<div class="container">
  <label class="label" for="Textarea">{{ label }}</label>
  <textarea
    #Textarea
    class="textarea"
    [placeholder]="placeholder"
    [maxlength]="maxlength"
    [(ngModel)]="value"
    (keydown.enter)="onKeydownEnter()"
    (input)="onInput()"
  ></textarea>
  <span class="counter">{{ charactersCount }}</span>
</div>
