<div class="table-cards__container">
  <div class="table-cards__container__title">
    <span>{{ title }}</span>

    <div *ngIf="ivaType" class="table-cards__container__title__filter__container">
      <div *ngFor="let iva of ivaType; trackBy: trackByFn" class="table-cards__container__title__filter">
        {{ 'orders.summary.ivaTypeTo' | transloco }} {{ iva }}%
        <div (click)="handleIva(iva, true)" (keypress)="handleIva(iva, true)">
          <i class="pi icon-cerrar"></i>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="filteredConcepts.length">
    <div class="table-cards__container__header">
      <span
        *ngFor="let header of tableHead; trackBy: trackByFn"
        class="table-cards__container__header__item"
        [style]="{ width: header === 'subconcept' && subconceptWidth + 'px' }"
        [style.textAlign]="justifyRightColumns.includes(header) && 'right'"
        >{{ 'header.' + header | transloco }}</span
      >
    </div>
    <div class="table-cards__container__content">
      <div
        *ngFor="let concept of filteredConcepts; trackBy: trackByFn"
        [ngStyle]="{ backgroundColor: cellColor, borderColor: cellBorderColor }"
      >
        <ng-container *ngFor="let entry of getEntries(concept); trackBy: trackByFn">
          <ng-container [ngSwitch]="entry.key">
            <span *ngSwitchCase="'units'">
              {{ entry.value }}
            </span>
            <span *ngSwitchCase="'orderNumber'">
              {{ entry.value }}
            </span>
            <span *ngSwitchCase="'supplied'">
              {{ 'orders.summary.' + entry.value | transloco }}
            </span>
            <span
              *ngSwitchCase="'subconcept'"
              class="table-cards__container__content__subcontent"
              [style]="{ width: subconceptWidth + 'px', textAlign: 'left' }"
            >
              <span [style.width]="'fit-content'">{{ entry.value.split(' ')[0] }}</span>
              <p>{{ entry.value.split(' ').splice(1).join(' ') }}</p>
            </span>
            <span *ngSwitchDefault>
              {{ entry.value | CurrencyCustom }}
            </span>
            <!-- <span *ngSwitchDefault [style.textAlign]="'right'">
              {{ entry.value | CurrencyCustom }}
            </span> -->
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="billing.length">
    <div class="table-cards__container__summary">
      <ng-container *ngFor="let entry of getEntries(billing[0]); trackBy: trackByFn">
        <div class="table-cards__container__summary__container">
          <span class="table-cards__container__summary__container__title">{{ 'orders.summary.' + entry.key | transloco }}</span>
          <div class="table-cards__container__summary__container__content" [ngClass]="{ link: entry.key === 'tax' }">
            <ng-container *ngFor="let bill of billing; trackBy: trackByFn">
              <span
                *ngIf="getValue(bill, entry.key) !== null && getValue(bill, entry.key) !== undefined"
                (click)="entry.key === 'tax' && handleIva(getValue(bill, entry.key))"
                (keypress)="entry.key === 'tax' && handleIva(getValue(bill, entry.key))"
              >
                {{ (getValue(bill, entry.key) | CurrencyCustom) + (entry.key === 'tax' ? ' %' : ' €') }}
              </span>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="order?.concepts?.length || billing.length">
    <div class="table-cards__container__result">
      <span>{{ 'orders.summary.total' | transloco }}</span>
      <span>{{ (totalAmount | CurrencyCustom) ?? 0 }} €</span>
    </div>
  </ng-container>

  <ng-container *ngIf="!order?.concepts?.length && !billing.length" [ngTemplateOutlet]="noTableData"> </ng-container>
</div>

<ng-template #noTableData>
  <span class="noData">{{ 'orders.summary.noData' | transloco }} </span>
</ng-template>
