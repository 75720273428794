import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sl-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() label = '';
  @Input() type!: 'primary' | 'secondary' | 'link' | 'confirm';
  @Input() size!: 's' | 'l' | 'xl';
  @Input() disabled = false;
  @Input() buttonType = '';
  @Input() icon!: string;
  @Input() borderRound!: boolean;
  @Input() backgroundColor!: string;
  @Input() color!: string;
  @Input() textElipsis = false;

  @Output() readonly clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  onClick(): void {
    if (!this.disabled) {
      this.clicked.emit();
    }
  }
}
