<button
  class="button"
  (click)="onClick()"
  [ngClass]="{
    'button--primary': type === 'primary',
    'button--secondary': type === 'secondary',
    'button--confirm': type === 'confirm',
    'button--disabled': disabled,
    'button--xl': size === 'xl',
    'button--l': size === 'l',
    'button--s': size === 's',
    'button--link': type === 'link'
  }"
  [type]="buttonType"
  [style]="{
    'border-radius': borderRound && '22px',
    'background-color': backgroundColor,
    color: color
  }"
>
  <i *ngIf="icon" [class]="'pi icon-' + icon"></i>
  <span *ngIf="type !== 'link' && label" class="button__label">{{ label }}</span>
  <span *ngIf="type === 'link'" class="button__label" [ngClass]="{ ellipsis: textElipsis }">{{ label }}</span>
  <span *ngIf="!label" class="button__label"><ng-content></ng-content></span>
</button>
