import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AlertType } from '@sl/components';
import { ApiClientService, PlaceStore } from '@sl/services';
import { SharedModule } from '@sl/shared';
import { trackBy } from '@sl/utils';
import { Observable } from 'rxjs';
import { ProviderData } from 'src/app/core/features/provider/domain/providerData.model';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { AddModalComponent, EditModalComponent, TableListComponent } from '../../components';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
  standalone: true,
  imports: [SharedModule, AddModalComponent, EditModalComponent, TableListComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfilePageComponent implements OnInit {
  profileData!: ProviderData;
  areasNames: string[] = [];
  areaObject: any = {};
  loadingProfile$!: Observable<boolean>;
  showErrorModal = false;

  modalAddData = {
    visible: false,
    data: {
      label: '',
      email: '',
      phone: ''
    } as Contact
  };

  modalEditData = {
    visible: false,
    title: '',
    sectionTitle: '',
    subtitle: '',
    data: null as any,
    document: true,
    multiAddress: false
  };

  alert = {
    show: true,
    type: 'info' as AlertType,
    message: 'modify'
  };

  general = { showTag: false };
  contactAccess = { showTag: false };
  area = { showTag: false };
  billing = { showTag: false };
  areaObjectKeys: string[] = [];

  generalData: any[] = [];
  contactData: any[] = [];
  accessData: any[] = [];
  billingData: any[] = [];
  generalDataAllowValues = ['name', 'identification'];
  contactDataAllowValues = ['address'];
  accessDataAllowValues = ['providerId', 'identification'];
  billingDataAllowValues = ['billingAddress', 'iban', 'taxType', 'billingTax'];

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly apiClientService: ApiClientService,
    private readonly spinnerService: SpinnerService,
    private readonly placeStore: PlaceStore
  ) {}

  ngOnInit(): void {
    this.getProfileData();
  }

  getProfileData(): void {
    this.loadingProfile$ = this.spinnerService.getLoadingObservable(this.placeStore.getProviderId()!);
    this.apiClientService.getProfileData().subscribe({
      next: (response) => {
        this.profileData = response;
        this.setContacts();
        this.setData();
        this.cdr.detectChanges();
      },
      error: () => {
        this.showErrorModal = true;
        this.cdr.detectChanges();
      }
    });
  }

  showEditModal(title: string): void {
    this.initEditModal(title);
    this.modalEditData.visible = true;
  }

  closeEditModal(): void {
    this.modalEditData.data = null;
    this.modalEditData.visible = false;
  }

  showAddModal(): void {
    this.modalAddData.visible = true;
  }

  closeAddModal(): void {
    this.modalAddData.data = { label: '', email: '', phone: '' };
    this.modalAddData.visible = false;
  }

  saveChanges(editedSection: any): void {
    //let editedProvider: any = JSON.parse(JSON.stringify(this.profileData));
    const { contacts, address, bank: billing } = this.profileData;
    const editedProvider: any = { contacts, address, billing };
    //const editedProviderKeys = Object.keys(editedProvider);
    const editedDataKeys = Object.keys(editedSection.data);

    switch (editedSection.title) {
      case 'generalData':
        editedDataKeys.forEach((editedKey) => {
          this.searchKeyAndReplaceValue(editedProvider.address, editedKey, editedSection.data[editedKey]);
        });
        break;
      case 'contactAccessData':
        editedDataKeys.forEach((editedKey) => {
          this.searchKeyAndReplaceValue(editedProvider.address, editedKey, editedSection.data[editedKey]);
        });
        break;
      case 'area':
        editedProvider.contacts = [...editedSection.data];
        break;
      case 'billingData':
        editedDataKeys.forEach((editedKey) => {
          this.searchKeyAndReplaceValue(editedProvider.billing, editedKey, editedSection.data[editedKey]);
        });
        break;
      default:
        break;
    }

    this.apiClientService.putProfileData(editedProvider).subscribe({
      next: (response: any) => {
        this.profileData = { ...response };
        this.areaObject = [];
        this.setContacts();
        this.setData();
        this.cdr.detectChanges();
      }
    });
    //this.editProfileData(editedProvider)
    this.showAlert({ type: 'success', message: 'modify' });
    // this.showTag('tag');
  }

  setData(): void {
    const profileDataEntries = Object.entries(this.profileData);
    const billingDataEntries = Object.entries(this.profileData.bank);

    this.generalData = profileDataEntries.filter((data) => this.generalDataAllowValues.includes(data[0]));
    this.contactData = profileDataEntries.filter((data) => this.contactDataAllowValues.includes(data[0]));
    this.accessData = profileDataEntries.filter((data) => this.accessDataAllowValues.includes(data[0]));
    this.billingData = billingDataEntries.filter((data) => this.billingDataAllowValues.includes(data[0]));
  }

  searchKeyAndReplaceValue(objeto: any, clave: string, newValue: any): boolean {
    let response = false;
    if (objeto.hasOwnProperty(clave)) {
      objeto[clave] = newValue;
      response = true;
    }
    for (const propiedad in objeto) {
      if (objeto.hasOwnProperty(propiedad) && typeof objeto[propiedad] === 'object') {
        if (this.searchKeyAndReplaceValue(objeto[propiedad], clave, newValue)) {
          response = true;
        }
      }
    }
    return response;
  }

  saveArea(newArea: any): void {
    const { contacts, address, bank: billing } = this.profileData;
    const editedProvider: any = { contacts, address, billing };
    const newEmailContact = {
      typeContact: 'EMAIL',
      value: newArea.email,
      description: newArea.label
    };
    const newPhoneContact = {
      typeContact: 'PHONE',
      value: newArea.phone,
      description: newArea.label
    };
    const newContacts = [newEmailContact, newPhoneContact];

    editedProvider.contacts = [...editedProvider.contacts, ...newContacts];
    this.apiClientService.putProfileData(editedProvider).subscribe({
      next: (response) => {
        this.profileData = JSON.parse(JSON.stringify(response));
        this.addAreaKey(newArea.label, { typeContact: 'EMAIL', value: newArea.email });
        this.addAreaKey(newArea.label, { typeContact: 'PHONE', value: newArea.phone });
        this.showAlert({ type: 'success', message: 'area' });
      }
    });
  }

  getAreas(area: any): [string, any][] {
    return Object.entries(area);
  }

  showAlert({ type, message }: { type: AlertType; message: string }): void {
    this.alert = { type, message, show: true };
  }

  showTag(tag: string): void {
    this.general.showTag = true;
    this.contactAccess.showTag = true;
    this.area.showTag = true;
    this.billing.showTag = true;
  }

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  setContacts(): void {
    this.profileData.contacts.forEach((contact: any) => {
      this.addArea(contact.description);
      this.addAreaKey(contact.description, { typeContact: contact.typeContact, value: contact.value });
    });
  }

  addArea(areaName: string): void {
    if (!this.areasNames.includes(areaName)) {
      this.areasNames.push(areaName);
    }
  }

  addAreaKey(keyToAdd: string, content: any): void {
    if (!(keyToAdd in this.areaObject)) {
      const typeAreaData: any = [];
      typeAreaData.push(content);
      this.areaObject[keyToAdd] = typeAreaData;
    } else {
      this.areaObject[keyToAdd].push(content);
    }
    this.areaObjectKeys = [...Object.keys(this.areaObject)];
  }

  objectKeys(object: object): string[] {
    return Object.keys(object);
  }

  // Initialization
  private initEditModal(title: string): void {
    const { name, identification, address, contacts, bank } = this.profileData;
    const { streetName, postalCode, provinceName, cityName, countryName } = address;
    const { iban } = bank;
    const dataMap = {
      generalData: { name, identification: identification[0].value },
      contactAccessData: { streetName, postalCode, provinceName, cityName, countryName },
      area: { contacts },
      billingData: {
        streetName: bank.billingAddress?.streetName,
        postalCode: bank.billingAddress?.postalCode,
        provinceName: bank.billingAddress?.provinceName,
        cityName: bank.billingAddress?.cityName,
        countryName: bank.billingAddress?.countryName,
        iban
      }
    };
    const prefix = 'Desde aquí puedes solicitar la modificacion de datos';
    const subtitleMap = {
      generalData: `${prefix} generales`,
      contactAccessData: `${prefix} de acceso`,
      area: `${prefix} de área`,
      billingData: `${prefix} de facturación. 
        Es necesario aporta el documento que justifique el cambio`
    };
    const showDocument = title === 'billingData';

    this.modalEditData.title = 'Solicitud de modificación';
    this.modalEditData.subtitle = subtitleMap[title as keyof typeof subtitleMap];
    this.modalEditData.sectionTitle = title;
    this.modalEditData.document = showDocument;
    this.modalEditData.data = dataMap[title as keyof typeof dataMap];
  }
}

export interface Contact {
  email?: string;
  phone?: string;
  label?: string;
}
