import { ISettlementEntity } from './settlement.entity.interface';
import { SettlementAmountEntity } from './settlementAmountEntity.model';

export class SettlementEntity implements ISettlementEntity {
  readonly settlementId: string;
  readonly startDate: Date;
  readonly endDate: Date;
  readonly amount: SettlementAmountEntity;
  readonly documentId: string;
  readonly documentName: string;
  readonly clientId: string;
  readonly orders: string[];
  readonly batchId: string;

  constructor(data: ISettlementEntity) {
    this.settlementId = data.settlementId;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.amount = data.amount;
    this.documentId = data.documentId;
    this.documentName = data.documentName;
    this.clientId = data.clientId;
    this.orders = data.orders;
    this.batchId = data.batchId;
  }
}
