import { NavigationService } from '@sl/services';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AlertType } from '@sl/components';
import { environment } from '@sl/environment';
import { DocumentDetail, ISSUE, Order, OrderNote, OrderNoteNote } from '@sl/features';
import { ApiClientService, DownloadService, SpinnerService } from '@sl/services';
import { SharedModule } from '@sl/shared';
import { trackBy } from '@sl/utils';
import dayjs from 'dayjs';
import { filter, forkJoin, Observable } from 'rxjs';
import { MessageSource } from 'src/app/core/features/orderNote/domain/enums/message.enum';
import { OrderNoteNoteMessage } from 'src/app/core/features/orderNote/domain/orderNoteNoteMessage.model';
import { OrderNoteNoteMessageDoc } from 'src/app/core/features/orderNote/domain/orderNoteNoteMessageDoc.model';
import { IssueStore } from 'src/app/core/services/stores/issue.store';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-tab-notes',
  templateUrl: './tab-notes.component.html',
  styleUrls: ['./tab-notes.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabNotesComponent {
  @ViewChild('uploadButton') uploadButton!: ElementRef<HTMLInputElement>;
  @ViewChild('messagesContainer') messagesContainer!: ElementRef<HTMLDivElement>;

  @Input() orderId: string = this.navigationService.getParam('orderId');
  @Input() order?: Order | undefined;

  @Output() markNotesAsRead: EventEmitter<number> = new EventEmitter();

  issues$ = this.issueStore.selectIssues();

  notes!: OrderNoteNote[];
  agoraNotes: OrderNote = {
    originRequest: 'MANAGER',
    order: {
      providerId: '',
      orderId: ''
    },
    note: []
  };
  providerNotes: OrderNote = {
    originRequest: 'PROVEEDOR',
    order: {
      providerId: '',
      orderId: ''
    },
    note: []
  };
  orderNotesObservables: Observable<OrderNote[]>[] = [];

  // Search
  search = false;
  searchWord!: string;
  focusInput = false;
  messagesPosition: any;
  actualPosition = 0;

  // Documents
  documentDropdownOptions = ['JUSTIFICANTE', 'CORRESPONDENCIA', 'PRESUPUESTO', 'INFORME_TECNICO', 'OTROS_DOCUMENTOS', 'FOTOS'];
  documentType: string | null = null;
  selectedFile: File | null = null;
  maxFileSizeBytes = environment.files.maxFileSizeBytes; // 10MB
  maxFileSizeMB = this.maxFileSizeBytes / 1024 / 1024;
  allowedTypeFiles = [
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  allowedFile = true;

  // Message
  newNoteText: string | null = null;

  isSendMessageDisabled = true;
  alertDisableSendMessage = {
    label: 'order-detail-alert.cannotSendMessageAlert',
    type: 'warning' as AlertType,
    show: false,
    closable: false
  };

  showErrorModal = false;
  MessageSource = MessageSource;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly navigationService: NavigationService,
    private readonly apiClientService: ApiClientService,
    private readonly downloadService: DownloadService,
    private readonly translocoService: TranslocoService,
    private readonly issueStore: IssueStore,
    readonly spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.scrollToBottom(this.messagesContainer);
    this.getOrderNotes();
    this.checkDisableSendMessage();
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom(this.messagesContainer);
  }

  // Search
  onSearchClick(): void {
    this.searchWord = '';
    this.search = false;
  }

  onSearchClose(): void {
    this.search = true;
  }

  onSearchArrowUpClick(): void {
    this.changeMessagesPosition('up');
  }

  onSearchArrowDownClick(): void {
    this.changeMessagesPosition('down');
  }

  onMessagePosition(event: HTMLCollectionOf<Element>): void {
    this.getMessagesPosition(event);
  }

  // Mark as read
  onMarkAllAsReadClick(): void {
    this.markAsRead(true);
  }

  onMarkMessageAsRead(messageId: string | null): void {
    this.markAsRead(false, messageId);
  }

  // Download notes
  onDownloadNotesClick(): void {
    const notesToCSV = this.notes.map(({ message }) => {
      const { creationDate, read, content, source } = message;
      const date = creationDate ? mapDatetoDownloadDate(creationDate) : '';
      const hour = creationDate ? mapDateToDownloadHour(creationDate) : '';
      const sourceTranslation = this.translocoService.translate(`MessageSource.${source}`).replace('MessageSource.', '');
      const readTranslation = this.translocoService.translate(`MessageRead.${read}`).replace('MessageRead.', '');
      return { date, hour, content, read: readTranslation, source: sourceTranslation };
    });
    this.downloadService.exportAsExcel(notesToCSV, `${this.orderId}_notes`);
  }

  onUploadNoteDocumentClick(): void {
    this.handleUpload();
  }

  onDeleteNoteDocumentClick(): void {
    this.deleteFile();
    this.updateButtonDisabled();
  }

  onNoteDocumentChange(event: any): void {
    this.handleSelectedFile(event);
  }

  onDocumentTypeChange(): void {
    this.updateButtonDisabled();
  }

  // Message
  onNewNoteTextValueChange(): void {
    this.updateButtonDisabled();
  }

  // Action Buttons
  onDeleteMessageClick(): void {
    this.cleanNoteData();
    this.updateButtonDisabled();
  }

  onSendMessageClick(): void {
    this.sendMessage();
  }

  // Utils
  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  /* Private */

  private updateButtonDisabled(): void {
    this.isSendMessageDisabled = !this.newNoteText || (this.documentType === null && this.selectedFile !== null);
  }

  private getMessagesPosition(event: HTMLCollectionOf<Element>): void {
    this.messagesPosition = [];
    Array.from(event).forEach((data: any) => {
      this.messagesPosition.push(data.offsetTop);
    });
    this.actualPosition = this.messagesPosition.length - 1;
    this.messagesContainer.nativeElement.scrollTop = this.messagesPosition[this.actualPosition];
  }

  private changeMessagesPosition(pos: 'up' | 'down'): void {
    if (pos === 'up' && this.actualPosition >= 1) {
      this.actualPosition -= 1;
    }
    if (pos === 'down' && this.actualPosition < this.messagesPosition.length - 1) {
      this.actualPosition += 1;
    }
    this.messagesContainer.nativeElement.scrollTop = this.messagesPosition[this.actualPosition] - 463;
  }

  private markAsRead(all: boolean, messageId?: string | null): void {
    if (all) {
      let count = 0;
      this.agoraNotes.note.forEach((n) => {
        if (!n.message.read) {
          this.setOrderNoteObservables(this.getNewOrderNote(n), n.noteId!);
          count += 1;
        }
      });
      this.markNotesAsRead.emit(count);
      this.updateAllNotes();
    } else {
      const foundNote = this.agoraNotes.note.find((note) => note.message.messageId === messageId);
      if (foundNote) {
        this.putOrderNote(this.getNewOrderNote(foundNote), foundNote.noteId!);
        this.markNotesAsRead.emit(1);
      }
    }
  }

  private async sendMessage(): Promise<void> {
    // Delete when attach document is avaible
    this.providerNotes.note = [this.getNewNoteBody()];
    this.postOrderNotes(this.providerNotes);
    this.cdr.detectChanges();

    // Uncomment when attach document is avaible
    /* if (!this.selectedFile) {
      this.providerNotes.note = [this.getNewNoteBody()];
      this.postOrderNotes(this.providerNotes);
      this.cdr.detectChanges();
      return;
    }

    const document: DocumentDetail = {
      documentId: null,
      name: this.selectedFile!.name,
      mimeType: this.selectedFile!.type,
      type: this.documentType!,
      content: await this.getFileContent(this.selectedFile),
      date: new Date(),
      origin: 'PROVEEDOR'
    };

    this.providerNotes.note = [this.getNewNoteBody(document)];
    this.postOrderNotes(this.providerNotes);
    this.deleteFile();
    this.cdr.detectChanges(); */
  }

  private handleUpload(): void {
    this.uploadButton.nativeElement.click();
  }

  private deleteFile(): void {
    this.selectedFile = null;
    this.uploadButton.nativeElement.value = '';
  }

  private handleSelectedFile(event: any): void {
    event.preventDefault();
    const file: File = event.target.files[0];
    this.selectedFile = null;
    if (this.isAllowedFile(file)) {
      this.selectedFile = file;
      this.allowedFile = true;
    } else {
      this.allowedFile = false;
    }
    this.updateButtonDisabled();
  }

  private cleanNoteData(): void {
    this.newNoteText = '';
    this.documentType = null;
    this.selectedFile = null;
    this.updateButtonDisabled();
  }

  // Api

  private getOrderNotes(): void {
    this.apiClientService.getOrderNotes(this.orderId).subscribe({
      next: (orderNotes) => {
        this.initNotes(orderNotes ?? []);
        this.cdr.detectChanges();
      },
      error: () => {
        this.showErrorModal = true;
        this.cdr.detectChanges();
      }
    });
  }

  private postOrderNotes(providerNotes: OrderNote): void {
    providerNotes.order.orderId = this.orderId;
    providerNotes.order.providerId = this.order?.orderSummary.placeId ?? '';
    this.apiClientService.postOrderNotes(this.orderId, providerNotes).subscribe({
      next: (orderNotes: OrderNote[]) => {
        this.addNewNote(orderNotes ?? []);
        this.markAsRead(true);
        this.cleanNoteData();
        this.cdr.detectChanges();
      },
      error: () => {
        this.cleanNoteData();
        this.showErrorModal = true;
        this.cdr.detectChanges();
      }
    });
  }

  private putOrderNote(body: OrderNote, messageId: string): void {
    this.apiClientService.putOrderNote(this.orderId, body, messageId).subscribe({
      next: (orderNotes) => {
        this.updateNote(orderNotes ?? []);
        this.cdr.detectChanges();
      },
      error: () => (this.showErrorModal = true)
    });
  }

  private setOrderNoteObservables(body: OrderNote, messageId: string): void {
    this.orderNotesObservables.push(this.apiClientService.putOrderNote(this.orderId, body, messageId));
  }

  private updateAllNotes(): void {
    forkJoin(this.orderNotesObservables).subscribe({
      next: (response) => {
        response.forEach((res) => {
          this.updateNote(res);
        });
      },
      error: () => (this.showErrorModal = true)
    });
  }

  // Initialization

  private initNotes(orderNotes: OrderNote[]): void {
    this.notes = [];
    orderNotes.forEach(({ note }) => {
      note.forEach((n) => {
        if (n.message.isProviderMessage()) {
          this.providerNotes.note = [...note];
        }
        if (n.message.isManagerMessage()) {
          this.agoraNotes.note = [...note];
        }

        this.notes.push(n);
      });
    });
    this.sortNotesByDate();
  }

  private checkDisableSendMessage(): void {
    this.issues$
      .pipe(
        untilDestroyed(this),
        filter(({ issues, loading, error }) => {
          if (error) {
            this.isSendMessageDisabled = true;
            this.alertDisableSendMessage.show = true;
            this.showErrorModal = true;
            this.cdr.detectChanges();
          }
          return Boolean(issues) && !loading;
        })
      )
      .subscribe(({ issues }) => {
        const isOpenIssues = issues!.filter((issue) => issue.status === ISSUE.STATUS.OPEN).length > 0;
        this.updateButtonDisabled();
        this.alertDisableSendMessage.show = isOpenIssues;
        this.cdr.detectChanges();
      });
  }

  private addNewNote(orderNotes: OrderNote[]): void {
    const newNotes: OrderNoteNote[] = [];
    orderNotes.forEach(({ note }) => {
      if (this.isProviderMessage(note[0].message.source)) {
        this.providerNotes.note.push(note[0]);
      }
      if (this.isManagerMessage(note[0].message.source)) {
        this.agoraNotes.note.push(note[0]);
      }
      note.forEach((n) => {
        newNotes.push(n);
      });
      this.notes = [...this.notes, ...newNotes];
    });
  }

  private isProviderMessage(source: MessageSource): boolean {
    return source === MessageSource.PROFESIONAL || source === MessageSource.PROVIDER_ADMIN;
  }

  private isManagerMessage(source: MessageSource): boolean {
    return source === MessageSource.PROVIDER_MANAGER || source === MessageSource.ORDER_MANAGER;
  }

  private updateNote(orderNotes: OrderNote[]): void {
    const newNote = orderNotes[0].note[0];
    this.agoraNotes.note = this.agoraNotes.note.map((n) =>
      n.noteId === newNote.noteId
        ? new OrderNoteNote({ ...n, message: new OrderNoteNoteMessage({ ...n.message, read: true, readDate: newNote.message.readDate }) })
        : n
    );

    this.notes = this.notes.map((n) =>
      n.noteId === newNote.noteId
        ? new OrderNoteNote({ ...n, message: new OrderNoteNoteMessage({ ...n.message, read: true, readDate: newNote.message.readDate }) })
        : n
    );

    this.cdr.detectChanges();
  }

  // Utils

  private scrollToBottom(element: ElementRef<HTMLElement>): void {
    try {
      element.nativeElement.scrollTop = element.nativeElement.scrollHeight;
    } catch (err) {}
  }

  private sortNotesByDate(): void {
    this.notes?.sort((a: any, b: any) => {
      let result = 0;
      if (a.message.creationDate > b.message.creationDate) {
        result = 1;
      } else if (a.message.creationDate < b.message.creationDate) {
        result = -1;
      }
      return result;
    });
  }

  // Uncomment when attach document is avaible
  /* private async getFileContent(file: File): Promise<string> {
    const content = await getFileContent(file);
    return content.split(',')[1]; // Just the content after de ',' character
  } */

  // Utils
  private isAllowedFile(file: File): boolean {
    return file?.size <= this.maxFileSizeBytes && this.allowedTypeFiles.includes(file?.type);
  }

  private getNewNoteBody(document?: DocumentDetail): OrderNoteNote {
    const docList: OrderNoteNoteMessageDoc[] = document
      ? [
          {
            docId: document.documentId!,
            docName: document.name,
            docType: document.type,
            creationDate: document.date,
            metadata: document.content
          }
        ]
      : [];
    return new OrderNoteNote({
      noteId: null,
      message: new OrderNoteNoteMessage({
        messageId: null,
        creationDate: new Date(),
        readDate: new Date(),
        read: true,
        content: this.newNoteText ?? '',
        type: 'NOTA-GENERAL',
        source: MessageSource.PROFESIONAL,
        subject: 'subject',
        sender: null,
        docList
      })
    });
  }

  private getNewOrderNote(note: OrderNoteNote): OrderNote {
    return {
      originRequest: 'PROVEEDOR',
      order: {
        providerId: this.order?.orderSummary.placeId ?? '',
        orderId: this.orderId
      },
      note: [
        new OrderNoteNote({
          ...note,
          message: new OrderNoteNoteMessage({
            ...note.message,
            read: true,
            readDate: new Date()
          })
        })
      ]
    };
  }
}

const mapDatetoDownloadDate = (orderDate: any): string => dayjs(orderDate).format('DD/MM/YYYY');
const mapDateToDownloadHour = (orderDate: any): string => dayjs(orderDate).format('HH:mm');
