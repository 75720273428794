import { IOrderVolumetry } from './orderVolumetry.model.interface';

export class OrderVolumetry implements IOrderVolumetry {
  readonly volumetryId: string;
  readonly count: number;

  constructor(data: IOrderVolumetry) {
    this.volumetryId = data.volumetryId;
    this.count = data.count;
  }
}
