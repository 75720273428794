import { IOrderStatisticEntity } from './orderStatistic.entity.interface';

export class OrderStatisticEntity implements IOrderStatisticEntity {
  readonly id: string;
  readonly value: number;
  readonly date: string;
  readonly activity: string;

  constructor(data: IOrderStatisticEntity) {
    this.id = data.id;
    this.value = data.value;
    this.date = data.date;
    this.activity = data.activity;
  }
}
