import { NotificationMapper } from './../../features/notification/infrastructure/mappers/notification.mapper';
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Notification, NotificationEntity } from '@sl/features';
import { ApiService, PlaceStore, QueryParams } from '@sl/services';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { GetNotificationsParams, INotificationStore, NotificationState, PutNotificationParams } from './notification.store.interface';

const PREFIX = '/api/v1' as const;

const initialStateNotification: NotificationState = {
  notifications: null,
  error: null,
  loading: false
};

@Injectable({ providedIn: 'root' })
export class NotificationStore implements INotificationStore {
  private readonly _notifications$ = new BehaviorSubject<NotificationState>(initialStateNotification);
  readonly notifications$ = this._notifications$.asObservable();

  constructor(
    private readonly apiService: ApiService,
    private readonly placeStore: PlaceStore,
    private readonly notificationMapper: NotificationMapper
  ) {}

  /** Selectors */

  selectNotifications(): Observable<NotificationState> {
    return this.notifications$;
  }

  getNotifications(): Notification[] | null {
    return this._notifications$.getValue().notifications;
  }

  /* Reducer */

  updateNotifications(newState: Partial<NotificationState>): void {
    const currentState = this._notifications$.getValue();
    this._notifications$.next({ ...currentState, ...newState });
  }

  /** Actions */

  actionGetNotifications(params?: GetNotificationsParams): void {
    this.updateNotifications({ loading: true, error: null });

    const providerId = params?.providerId ?? this.placeStore.getProviderId();
    const notificationFilter = { placeId: this.placeStore.getPlaceId(), ...params?.notificationFilter };

    this.apiService
      .get<NotificationEntity[]>({
        url: `${PREFIX}/providers/${providerId}/places/notifications`,
        queryParams: notificationFilter as unknown as QueryParams
      })
      .subscribe({
        next: (notifications) => {
          this.updateNotifications({ loading: false, notifications: [...notifications].map((n) => this.notificationMapper.mapFromApi(n)) });
        },
        error: (error) => {
          this.updateNotifications({ loading: false, error });
          this.handleError(error);
        }
      });
  }

  actionPutNotification(params: PutNotificationParams): void {
    this.updateNotifications({ loading: true, error: null });

    const providerId = params?.providerId ?? this.placeStore.getProviderId();
    const notificationId = params?.notificationId;
    const notification = params?.notification;
    const notificationEntity = this.notificationMapper.mapToApi(params?.notification);

    this.apiService
      .put<NotificationEntity>({
        url: `${PREFIX}/providers/${providerId}/places/notifications/${notificationId}`,
        body: notificationEntity
      })
      .subscribe({
        next: () => {
          const notifications = this.getNotifications()?.map((n) => (n.notificationId === notificationId ? { ...n, ...notification } : n));
          this.updateNotifications({ loading: false, notifications: notifications ? [...notifications] : null });
        },
        error: (error) => this.updateNotifications({ loading: false, error })
      });
  }

  /** Effects */

  /** Utils */

  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    return throwError(() => new Error(error));
  }
}
