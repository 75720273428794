import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { trackBy } from '@sl/utils';

@Component({
  selector: 'sl-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTableComponent implements OnChanges {
  @Input() tableData!: Record<string, any>;
  @Input() rows!: any;
  @Input() rowSpace = '';
  @Input() columnSpace = '';
  @Input() title = '';

  columnNumber = 0;
  names!: string[];
  descriptions: any;

  constructor() {}

  ngOnChanges(): void {
    this.names = Object.keys(this.tableData);
    this.descriptions = Object.values(this.tableData);
    this.columnNumber = Math.ceil(this.names.length / this.rows);
  }

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }
}
