import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sl-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent<T> {
  @Input() type = 'checkbox';
  @Input() checked = false;
  @Input() label = '';
  @Input() value: T = null as T;

  @Output() modelChange = new EventEmitter<T>();

  constructor() {}

  onChange(): void {
    this.modelChange.emit(this.value);
  }
}

export interface CheckboxOption<T = any> {
  label: string;
  value: T;
  icon?: string;
}
