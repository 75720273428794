/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Document } from '@sl/features';
import { ApiClientService, SpinnerService } from '@sl/services';
import { SharedModule } from '@sl/shared';
import { removeDiacritics } from '@sl/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BehaviorSubject } from 'rxjs';
import { GetDocumentsFilter } from 'src/app/core/features/document/infrastructure/repositories/document.repository.interface';
import { ButtonFilterDocumentationComponent } from '../../components';
dayjs.extend(utc);

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-documentation',
  templateUrl: './documentation.page.html',
  styleUrls: ['./documentation.page.scss'],
  standalone: true,
  imports: [SharedModule, ButtonFilterDocumentationComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentationPageComponent implements OnInit {
  private _documents$ = new BehaviorSubject<Document[] | null>(null);
  documents$ = this._documents$.asObservable();

  table: DocumentTableItem[] = [];
  filterTable: DocumentTableItem[] = [];
  showErrorModal = false;

  // Filters
  dateFilter = {
    startDate: null as Date | null,
    endDate: null as Date | null
  };

  searchByWord = {
    searchIcon: true,
    placeholder: 'Buscar por...',
    value: null as string | null
  };

  // Api params
  documentParams: GetDocumentsFilter = {
    from: null,
    to: null
  };

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly apiClientService: ApiClientService,
    public readonly spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.getTableData();
    this.initTable();
  }

  onDatePickerDates(dates: Date[]): void {
    this.dateFilter.startDate = dates[0];
    this.dateFilter.endDate = dates[1];
    this.getTableData();
  }

  onDatePickerRemove(): void {
    this.dateFilter.startDate = null;
    this.dateFilter.startDate = null;
    this.getTableData();
  }

  onSearchByWord(): void {
    if (this.searchByWord.value) {
      this.filterTable = this.table.filter((doc) =>
        Object.values(doc).some((item) => removeDiacritics(item?.toLowerCase() ?? '').includes(this.searchByWord.value!.toLowerCase()))
      );
    } else {
      this.filterTable = [...this.table];
    }
  }

  private getTableData(): void {
    const from = this.dateFilter.startDate ? mapDateToApi(this.dateFilter.startDate) : null;
    const to = this.dateFilter.endDate ? mapDateToApi(this.dateFilter.endDate) : null;
    this.documentParams = { ...this.documentParams, from, to };
    this.getDocuments(this.documentParams);
  }

  // Api
  private getDocuments(documentFilter?: GetDocumentsFilter): void {
    this.apiClientService.getDocuments(documentFilter).subscribe({
      next: (response) => {
        this._documents$.next(response);
        this.cdr.detectChanges();
      },
      error: () => {
        this.showErrorModal = true;
        this._documents$.next(null);
        this.cdr.detectChanges();
      }
    });
  }

  // Initialization
  private initTable(): void {
    this.documents$.subscribe((documents) => {
      this.table = documents?.map((doc) => mapDocumentToDocumentTableItem(doc)) ?? [];
      this.filterTable = [...this.table];
      this.cdr.detectChanges();
    });
  }
}

const mapApiToDate = (orderDate: any): string => dayjs(orderDate).utc().format('DD/MM/YYYY');
const mapDateToApi = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
const mapDateToHour = (orderDate: any): string => dayjs(orderDate).utc().format('HH:mm');
const mapDocumentToDocumentTableItem = (doc: Document): DocumentTableItem => ({
  documentId: doc.documentId,
  name: doc.name,
  date: doc.date ? mapApiToDate(doc.date) : null,
  hour: doc.date ? mapDateToHour(doc.date) : null
});

type DocumentTableItem = {
  documentId: string | null;
  name: string | null;
  date: string | null;
  hour: string | null;
};
