/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ScreenSizeService } from '@sl/services';
import { MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

import dayjs from 'dayjs';

@Component({
  selector: 'sl-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFilterComponent implements OnInit, OnChanges {
  @ViewChild('Panel') private readonly _panel!: OverlayPanel;

  @Input() showRangeFilter = true;
  @Input() placeholder = 'Rango de fechas';
  @Input() dates: (Date | null)[] = [];

  @Output() readonly datesChange = new EventEmitter<(Date | null)[]>();
  @Output() readonly selectedDates = new EventEmitter();
  @Output() readonly removeSelectedDates = new EventEmitter();

  isMobile$ = this.screenSizeService.isMobile();
  isMobile = false;

  popover = {
    visible: false
  };

  dialog = {
    visible: false,
    modal: true,
    draggable: false,
    resizable: false
  };

  menuItem = {
    calendar: { label: 'Elige fechas' },
    randomRange: { label: 'Selecciona rango aleatorio' }
  };
  menuItems: MenuItem[] = Object.values(this.menuItem);
  activeMenuItem: MenuItem = this.menuItems[0];

  calendar = {
    inline: true,
    numberOfMonths: 2,
    selectionMode: 'range',
    readonlyInput: true
  };
  datesDisplay = '';

  constructor(private screenSizeService: ScreenSizeService) {}

  get isRemoveDisabled(): boolean {
    return this.dates.length === 0 && !this.datesDisplay;
  }

  get isApplyDisabled(): boolean {
    return !Boolean(this.dates.length);
  }

  ngOnInit(): void {
    this.initMenu();
    this.initCalendar();
    this.popoverInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dates']?.currentValue !== changes['dates']?.previousValue) {
      this.setDatesDisplay(this.dates);
    }
  }

  onDatesChange(): void {
    this.datesChange.emit(this.dates);
  }

  applySelection(): void {
    this.setDatesDisplay(this.dates);
    this.selectedDates.emit(this.dates);
    if (!this.isMobile) {
      this.closePopover();
    }
    this.showDialog(false);
  }

  cancelSelection(): void {
    if (!this.isMobile) {
      this.closePopover();
    }
    this.showDialog(false);
  }

  removeSelection(): void {
    this.dates = [];
    this.datesDisplay = '';
    this.removeSelectedDates.emit();
  }

  trackByFn(index: number, item: any): any {
    return item;
  }

  // * POPOVER
  togglePopover(event: Event): void {
    this._panel.toggle(event);
  }

  openPopover(event: Event): void {
    this._panel.show(event);
  }

  closePopover(): void {
    this._panel.hide();
  }

  showDialog(visible: boolean): void {
    this.dialog.visible = visible;
  }

  // * PRIVATE METHODS
  private initMenu(): void {
    if (!this.showRangeFilter) {
      this.menuItems = this.menuItems.filter((item) => item !== this.menuItem.randomRange);
    }

    this.activeMenuItem = this.menuItems[0];
  }

  private initCalendar(): void {
    this.screenSizeService.isMobile().subscribe((isMobile) => (this.calendar.numberOfMonths = isMobile ? 1 : 2));
  }

  private setDatesDisplay(datesDisplay: (Date | null)[]) {
    this.datesDisplay = this.formatDates(datesDisplay);
  }

  private popoverInit(): void {
    this.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
      if (!isMobile) {
        this.dialog.visible = false;
      }
      if (isMobile) {
        this.popover.visible = false;
      }
    });
  }

  private formatDates(dates: (Date | null)[]): string {
    const formattedDates: string[] = [];
    dates.forEach((date) => date && formattedDates.push(dayjs(date).format('DD/MM/YYYY')));
    return formattedDates.join(' - ');
  }
}
