import { IOrderBillOrderStatusReasonEntity } from './orderBillOrderStatusReason.entity.interface';

export class OrderBillOrderStatusReasonEntity implements IOrderBillOrderStatusReasonEntity {
  readonly code: string;
  readonly description: string;

  constructor(data: IOrderBillOrderStatusReasonEntity) {
    this.code = data.code;
    this.description = data.description;
  }
}
