import { ISettlementAmount } from './settlementAmount.model.interface';

export class SettlementAmount implements ISettlementAmount {
  readonly amount: number | null;
  readonly netAmount: number | null;
  readonly tax: number | null;

  constructor(data: ISettlementAmount) {
    this.amount = data.amount;
    this.netAmount = data.netAmount;
    this.tax = data.tax;
  }
}
