import { ProviderBillingType } from './enums/provider.enums';
import { ProviderAddress } from './providerAddress.model';
import { IProviderBank } from './providerBank.model.interface';

export class ProviderBank implements IProviderBank {
  readonly bankAccount: string;
  readonly entity: string;
  readonly office: string;
  readonly entityControlNumber: string;
  readonly accountControlNumber: string;
  readonly account: string;
  readonly billingAddress: ProviderAddress | null;
  readonly billingType: ProviderBillingType;
  readonly billingTax: string;
  readonly billingNextPeriod: string;
  readonly billingNextRef: string;
  readonly iban: string;
  readonly bic: string;
  readonly taxType: string | null;

  constructor(data: IProviderBank) {
    this.bankAccount = data.bankAccount;
    this.entity = data.entity;
    this.office = data.office;
    this.entityControlNumber = data.entityControlNumber;
    this.accountControlNumber = data.accountControlNumber;
    this.account = data.account;
    this.billingAddress = data.billingAddress ? new ProviderAddress(data.billingAddress) : null;
    this.billingType = data.billingType;
    this.billingTax = data.billingTax;
    this.billingNextPeriod = data.billingNextPeriod;
    this.billingNextRef = data.billingNextRef;
    this.iban = data.iban;
    this.bic = data.bic;
    this.taxType = data.taxType;
  }
}
