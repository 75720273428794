/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { environment } from '@sl/environment';
import { DocumentDetail, Issue } from '@sl/features';
import { NavigationService, SpinnerService } from '@sl/services';
import { getFileContent, trackBy } from '@sl/utils';
import { Observable } from 'rxjs';
import { DOCUMENT } from 'src/app/core/features/document/domain/enums/document.enum';
import { IssueMessage } from 'src/app/core/features/issue/domain/issueMessage.model';
import { MessageSource } from 'src/app/core/features/orderNote/domain/enums/message.enum';

@Component({
  selector: 'sl-communication-modal',
  templateUrl: './communication-modal.component.html',
  styleUrls: ['./communication-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunicationModalComponent implements OnChanges {
  @ViewChild('generalFileInput') generalFileInput!: ElementRef<HTMLInputElement>;

  @Input() summary!: SummaryData;
  @Input() issue!: Issue;

  @Output() readonly closeModal = new EventEmitter();
  @Output() readonly sendIssueMessage = new EventEmitter<SendIssueMessageData>();
  @Output() readonly markIssueMessageAsRead = new EventEmitter<Issue>();

  generalDocuments: Document[] = [];
  generalDocument: Document | null = null;

  // Inputs
  dropdownGeneralDocumentTypes = {
    label: 'dropdown-document-type.label',
    placeholder: 'dropdown-document-type.placeholder',
    showArrow: true,
    options: ['JUSTIFICANTE', 'CORRESPONDENCIA', 'PRESUPUESTO', 'INFORME_TECNICO', 'OTROS_DOCUMENTOS', 'FOTOS']
  };
  observations = {
    label: 'modal.observations',
    placeholder: 'Escribe aquí',
    maxLength: 200,
    value: null as string | null
  };

  // Constrains
  maxFileSizeBytes = environment.files.maxFileSizeBytes; // 10MB
  maxFileSizeMB = this.maxFileSizeBytes / 1024 / 1024;
  allowedTypeFiles = [
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  showFileError = false;

  MessageSource = MessageSource;

  isLoading$!: Observable<boolean>;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly navigationService: NavigationService,
    private readonly spinnerService: SpinnerService
  ) {}

  ngOnChanges({ issue }: SimpleChanges): void {
    if (issue) {
      this.initGeneralDocuments();
      this.initObservations();
      this.isLoading$ = this.spinnerService.getLoadingObservable(this.issue.id);
      this.cdr.detectChanges();
    }
  }

  // General Documents
  onButtonAddGeneralDocumentClick(): void {
    const newDocument: Document = {
      temporalId: generateId(),
      type: null,
      file: null,
      content: null
    };
    this.generalDocuments = [...this.generalDocuments, newDocument];
  }

  onDropdownGeneralDocumentTypeChange(document: Document, value: string): void {
    this.generalDocuments = this.generalDocuments.map((doc) => (doc.temporalId === document.temporalId ? { ...doc, type: value } : doc));
  }

  async onGeneralFileInput(event: FileList | any): Promise<void> {
    event.preventDefault();
    const files: File[] = event.length ? Array.from(event) : event.target.files;
    const file = files[0];
    this.showFileError = false;

    if (!this.isAllowedFile(file)) {
      this.showFileError = true;
      return;
    }

    const content: string = await this.getFileContent(file);

    this.generalDocuments = this.generalDocuments.map((doc) =>
      doc.temporalId === this.generalDocument?.temporalId ? { ...doc, file, content } : doc
    );
    this.generalDocument = null;
    this.cdr.detectChanges();
  }

  onButtonAddGeneralFileClick(document: Document): void {
    this.generalDocument = { ...document };
    this.generalFileInput.nativeElement.click();
  }

  onButtonDeleteGeneralFileClick(document: Document): void {
    this.generalDocuments = this.generalDocuments.map((doc) =>
      doc.temporalId === document.temporalId ? { ...doc, file: null, content: null } : doc
    );
  }

  // Observations
  onTextAreaObservationsEnter(): void {
    this.handleSendIssueMessage();
  }

  // Action Buttons
  onButtonSendClick(): void {
    this.handleSendIssueMessage();
  }

  onButtonDeleteClick(): void {
    this.observations.value = '';
  }

  disableButtonSend(): boolean {
    return this.generalDocuments.some((data) => data.file && !data.type) || !this.observations.value;
  }

  // Go to detail feature
  onOrderIdClick(): void {
    this.closeModal.emit();
    this.goToOrderDetail();
  }

  // Mark as read feature
  onMarkMessageAsRead(messageId: string | null): void {
    this.markAsRead(messageId);
  }

  // Shared
  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  onCloseModalClick(): void {
    this.closeModal.emit();
  }

  // Initialization
  private initGeneralDocuments(): void {
    const newDocument: Document = {
      temporalId: generateId(),
      type: null,
      file: null,
      content: null
    };
    this.generalDocuments = [newDocument];
  }

  private initObservations(): void {
    this.observations.value = null;
  }

  // Features
  private goToOrderDetail(): void {
    this.navigationService.navigate({ route: `orders/${this.issue.orderId}` });
  }

  private handleSendIssueMessage(): void {
    const allDocuments = [...this.generalDocuments].filter((doc) => doc.file);
    const messageData: SendIssueMessageData = {
      documentsWithoutIds: allDocuments.map((doc) => mapDocumentToDocumentDetail(doc)),
      comments: this.observations.value ?? '',
      issue: this.issue
    };
    this.sendIssueMessage.emit({ ...messageData });
    this.cdr.detectChanges();
  }

  private markAsRead(messageId: string | null): void {
    const messages = this.issue.messages.map((m) =>
      m.messageId === messageId ? new IssueMessage({ ...m, read: true, readDate: new Date() }) : m
    );
    this.markIssueMessageAsRead.emit({ ...this.issue, messages });
    this.cdr.detectChanges();
  }

  // Utils
  private isAllowedFile(file: File): boolean {
    return file?.size <= this.maxFileSizeBytes && this.allowedTypeFiles.includes(file?.type);
  }

  private async getFileContent(file: File): Promise<string> {
    const content = await getFileContent(file);
    return content.split(',')[1]; // Just the content after de ',' character
  }
}

const generateId = (): string => {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 1000000);
  return `${timestamp}-${random}`;
};

const mapDocumentToDocumentDetail = (doc: Document): DocumentDetail => ({
  documentId: '',
  name: doc.file!.name,
  type: doc.type! === 'supplied' ? DOCUMENT.TYPE.SUPLIDO : doc.type!,
  mimeType: doc.file!.type,
  content: doc.content!,
  date: new Date(),
  origin: DOCUMENT.ORIGIN.PROVEEDOR
});

export interface SummaryData {
  sort: string;
  nis: string;
  policy: string;
  openDate: string;
  closingDate: string;
  hour: string;
  closingHour: string;
  state: string;
}

type Document = {
  temporalId: string | null;
  type: string | null;
  file: File | null;
  content: string | null;
};

export type SendIssueMessageData = {
  documentsWithoutIds: DocumentDetail[];
  comments: string;
  issue: Issue;
};
