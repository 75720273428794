import { IOrderNoteInfoEntity } from './orderNoteInfo.entity.interface';

export class OrderNoteInfoEntity implements IOrderNoteInfoEntity {
  readonly providerId: string;
  readonly orderId: string;

  constructor(data: IOrderNoteInfoEntity) {
    this.providerId = data.providerId;
    this.orderId = data.orderId;
  }
}
