import { DocumentOrigin, DocumentType } from '../../domain/enums/document.enum';

import { IDocumentDetailEntity } from './documentDetail.entity.interface';

export class DocumentDetailEntity implements IDocumentDetailEntity {
  readonly documentId: string | null;
  readonly name: string;
  readonly type: DocumentType;
  readonly content: string;
  readonly date: string;
  readonly mimeType: string;
  readonly origin: DocumentOrigin | null;

  constructor(data: IDocumentDetailEntity) {
    this.documentId = data.documentId;
    this.name = data.name;
    this.type = data.type;
    this.mimeType = data.mimeType;
    this.content = data.content;
    this.date = data.date;
    this.origin = data.origin;
  }

  // Puedes agregar métodos adicionales aquí si es necesario
}
