/* eslint-disable no-unused-vars */
import { ActivatedRouteSnapshot, DetachedRouteHandle, NavigationBehaviorOptions, RouteReuseStrategy } from '@angular/router';

export interface INavigationService {
  navigate({ route, extras }: NavigateParams): Promise<boolean>;
}

export interface NavigateParams {
  route: string;
  extras?: NavigationBehaviorOptions;
}

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private routeHandles: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.routeHandles[this.getKey(route)] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return false;
  }

  private getKey(route: ActivatedRouteSnapshot): string {
    const url = route.url.map((segment) => segment.toString()).join('/');
    return url;
  }
}
