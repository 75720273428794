/* eslint-disable no-unused-vars */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ISSUE, IssueClient, IssueType } from 'src/app/core/features/issue';
import { GetIssuesFilter } from 'src/app/core/features/issue/infrastructure/repositories/issue.repository.interface';
import { trackBy } from 'src/app/shared/utils';
import { DropdownOption } from '../common/dropdown/dropdown.component';

@Component({
  selector: 'sl-button-filter-communications',
  templateUrl: './button-filter-communications.component.html',
  styleUrls: ['./button-filter-communications.component.scss']
})
export class ButtonFilterCommunicationsComponent implements OnInit, OnChanges {
  @Input() filters: FilterIssues = { ...defaultFilter };
  @Input() showFilterOrderId = true;

  @Output() readonly filtersChange = new EventEmitter<FilterIssues>();
  @Output() readonly submitFilters = new EventEmitter<FilterIssues>();
  @Output() readonly discardFilters = new EventEmitter<FilterIssues>();

  initialFilters!: FilterIssues;
  visible = false;

  /* Section 1 */
  section1 = {
    legend: 'Filtrar por nº'
  };

  inputOrder = {
    label: 'Buscar por',
    placeholder: 'Nº Encargo',
    value: null as string | null
  };

  inputSinister = {
    label: 'Buscar por',
    placeholder: 'Nº Siniestro (NIS)',
    value: null as string | null
  };

  inputPolicy = {
    label: 'Buscar por',
    placeholder: 'Nº Póliza',
    value: null as string | null
  };

  /* Section 2 */
  section2 = {
    legend: 'Asegurado'
  };

  inputNameAndSurname = {
    label: 'Buscar por',
    placeholder: 'Nombre y Apellidos',
    value: null as string | null
  };

  /* Section 3 */
  section3 = {
    legend: 'Tipo de comunicación'
  };

  dropdownType = {
    label: 'Filtrar',
    placeholder: 'Tipo de comunicación',
    options: Object.values(ISSUE.TYPE).map((issueType) => ({
      label: this.translocoService.translate('issueType.' + issueType).replace('issueType.', ''),
      value: issueType
    })) as DropdownOption<IssueType>[],
    value: null as IssueType | null
  };

  /* Section 4 */
  section4 = {
    legend: 'Cliente'
  };

  dropdownClientId = {
    label: 'Filtrar',
    placeholder: 'Cliente',
    options: [
      { label: 'Santalucía', value: ISSUE.CLIENT_ID.SANTALUCIA },
      { label: 'Iris Global', value: ISSUE.CLIENT_ID.IRIS }
    ] as DropdownOption<IssueClient>[],
    value: null as IssueClient | null
  };

  constructor(private readonly translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.initialFilters = { ...this.filters };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters']) {
      this.inputPolicy.value = this.filters.policeId ?? null;
      this.inputOrder.value = this.filters.orderId ?? null;
      this.inputSinister.value = this.filters.claimId ?? null;
      this.inputNameAndSurname.value = this.filters.insuredName ?? null;
      this.dropdownType.value = (this.filters.type as IssueType) ?? null;
      this.dropdownClientId.value = (this.filters.clientId as IssueClient) ?? null;
    }
  }

  /* Action Buttons */

  handleSubmitFilters(): void {
    this.submitFilters.emit(this.filters);
    this.closePanel();
  }

  handleRemoveFilters(): void {
    this.resetFilters();
    this.discardFilters.emit(this.filters);
    this.closePanel();
  }

  /* Section 1 */

  onInputOrder(): void {
    this.filters.orderId = this.inputOrder.value || null;
    this.onChange();
  }

  onInputSinister(): void {
    this.filters.claimId = this.inputSinister.value || null;
    this.onChange();
  }

  onInputPolicy(): void {
    this.filters.policeId = this.inputPolicy.value || null;
    this.onChange();
  }

  /* Section 2 */

  onInputNameAndSurname(): void {
    this.filters.insuredName = this.inputNameAndSurname.value || null;
    this.onChange();
  }

  /* Section 3 */

  onDropdownType(): void {
    this.filters.type = this.dropdownType.value || null;
    this.onChange();
  }

  /* Section 4 */

  onDropdownClientId(): void {
    this.filters.clientId = this.dropdownClientId.value || null;
    this.onChange();
  }

  /* Utils */

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  /* Private */

  private onChange(): void {
    this.filtersChange.emit(this.filters);
  }

  private closePanel(): void {
    this.visible = false;
  }

  private resetFilters(): void {
    this.inputOrder.value = null;
    this.inputSinister.value = null;
    this.inputPolicy.value = null;
    this.inputNameAndSurname.value = null;
    this.dropdownType.value = null;
    this.dropdownClientId.value = null;
    this.filters = { ...defaultFilter };
    this.onChange();
  }
}

export type FilterIssues = Pick<GetIssuesFilter, 'clientId' | 'type' | 'claimId' | 'policeId' | 'insuredName' | 'orderId' | 'status'>;

const defaultFilter: FilterIssues = {
  clientId: null,
  type: null,
  claimId: null,
  policeId: null,
  insuredName: null,
  orderId: null,
  status: null
} as const;

export const defaultFilterIssues: FilterIssues = { ...defaultFilter };
