<div class="detail-modal__container">
  <article class="detail-modal__container__content">
    <div class="detail-modal__container__content__up">
      <!-- Header -->
      <i class="pi icon-cerrar" (click)="onCloseModal()" (keypress)="onCloseModal()"></i>
      <span class="detail-modal__container__content__up__title">{{ title }}</span>
      <p>{{ subtitle }}</p>

      <!-- Messages-->
      <ng-container [ngTemplateOutlet]="Messages"></ng-container>
    </div>
    <div class="detail-modal__container__content__add">
      <!-- General Documents - Uncommnet when Agora is able to process docs-->
      <!-- <ng-container [ngTemplateOutlet]="GeneralDocuments"></ng-container> -->

      <!-- Observations -->
      <ng-container [ngTemplateOutlet]="Observations"></ng-container>

      <!-- Action buttons -->
      <ng-container [ngTemplateOutlet]="ActionButtons"></ng-container>
    </div>
  </article>
</div>

<ng-template #Messages>
  <article class="detail-modal__container__content__up__messages">
    <ng-container *ngFor="let message of messages; trackBy: trackByFn">
      <sl-message *ngIf="message.content" [message]="message"></sl-message>
    </ng-container>
  </article>
</ng-template>

<ng-template #GeneralDocuments>
  <ng-container *ngFor="let doc of generalDocuments; trackBy: trackByFn">
    <span *ngIf="showFileError(doc)" class="detail-modal__container__content__up__info__error"> {{ 'modal.fileError' | transloco }} </span>
    <div class="detail-modal__container__content__up__bill__supply">
      <div class="note__add__upload">
        <sl-dropdown-filter
          [showArrow]="dropdownGeneralDocumentTypes.showArrow"
          [filterName]="dropdownGeneralDocumentTypes.label | transloco"
          [placeholder]="dropdownGeneralDocumentTypes.placeholder | transloco"
          [dropdownOptions]="dropdownGeneralDocumentTypes.options"
          [(value)]="doc.type"
          (valueChange)="onDropdownGeneralDocumentTypeChange(doc, $event!)"
        >
        </sl-dropdown-filter>
      </div>

      <div class="note__add__upload__document">
        <input
          #generalFileInput
          type="file"
          style="display: none"
          (click)="generalFileInput.value = ''"
          (input)="onGeneralFileInput($event)"
        />
        <sl-button
          [type]="'link'"
          [icon]="doc.file ? 'check' : 'info1'"
          [slTooltip]="['communications.modal.files']"
          (click)="onButtonAddGeneralFileClick(doc)"
        >
          {{ doc.file ? doc.file.name : ('modal.upload' | transloco | titlecase) }}
        </sl-button>

        <ng-container *ngIf="!doc.file">
          <button
            class="button-container__side__upload"
            type="button"
            (click)="onButtonAddGeneralFileClick(doc)"
            (keypress)="onButtonAddGeneralFileClick(doc)"
          >
            <i class="pi icon-upload"></i>
          </button>
        </ng-container>

        <ng-container *ngIf="doc.file">
          <button
            class="button-container__side__upload"
            type="button"
            (click)="onButtonDeleteGeneralDocumentClick(doc)"
            (keypress)="onButtonDeleteGeneralDocumentClick(doc)"
          >
            <i class="pi icon-eliminar"></i>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #Observations>
  <sl-textarea
    [label]="observations.label | transloco"
    [placeholder]="observations.placeholder"
    [maxlength]="observations.maxLength"
    [(value)]="observations.value"
    (keydownEnter)="onTextAreaObservationsEnter()"
  ></sl-textarea>
</ng-template>

<ng-template #ActionButtons>
  <article class="button-container">
    <div class="button-container__erase">
      <sl-button [borderRound]="true" [type]="'secondary'" [size]="'l'" (click)="onButtonDeleteClick()">
        {{ 'Borrar' }}
      </sl-button>
    </div>
    <div class="button-container__side">
      <sl-button [type]="'primary'" [size]="'l'" [borderRound]="true" [disabled]="disableButtonSend()" (click)="onButtonSendClick()">
        {{ 'Enviar' }}
      </sl-button>
    </div>
  </article>
</ng-template>
