import { ProviderTypeIdentification } from './enums/provider.enums';
import { IProviderIdentification } from './providerIdentification.model.interface';

export class ProviderIdentification implements IProviderIdentification {
  readonly typeIdentification: ProviderTypeIdentification;
  readonly value: string;

  constructor(data: IProviderIdentification) {
    this.typeIdentification = data.typeIdentification;
    this.value = data.value;
  }
}
