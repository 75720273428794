/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderVolumetry } from '@sl/features';
import { ApiService, PlaceStore, QueryParams } from '@sl/services';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { OrderVolumetryMapper } from '../../features/orderVolumetry/infrastructure/mappers/orderVolumetry.mapper';
import { GetOrderVolumetriesParams, IVolumetryStore, OrderVolumetryState } from './volumetry.store.interface';

const PREFIX = '/api/v1' as const;

const initialStateOrderVolumetries: OrderVolumetryState = {
  orderVolumetries: null,
  error: null,
  loading: false
};

@Injectable({ providedIn: 'root' })
export class VolumetryStore implements IVolumetryStore {
  private readonly _orderVolumetries$ = new BehaviorSubject<OrderVolumetryState>(initialStateOrderVolumetries);
  readonly orderVolumetries$ = this._orderVolumetries$.asObservable();

  constructor(
    private readonly apiService: ApiService,
    private readonly placeStore: PlaceStore,
    private readonly orderVolumetryMapper: OrderVolumetryMapper
  ) {}

  /** Selectors */

  selectOrderVolumetries(): Observable<OrderVolumetryState> {
    return this.orderVolumetries$;
  }

  getOrderVolumetries(): OrderVolumetry[] | null {
    return this._orderVolumetries$.getValue().orderVolumetries;
  }

  /* Reducer */

  updateOrderVolumetries(newState: Partial<OrderVolumetryState>): void {
    const currentState = this._orderVolumetries$.getValue();
    this._orderVolumetries$.next({ ...currentState, ...newState });
  }

  /** Actions */

  actionGetOrderVolumetries(params?: GetOrderVolumetriesParams): void {
    this.updateOrderVolumetries({ loading: true, error: null });

    const providerId = params?.providerId ?? this.placeStore.getProviderId();
    const volumetriesFilter = { placeId: this.placeStore.getPlaceId(), ...params?.volumetriesFilter };

    this.apiService
      .get<OrderVolumetry[]>({
        url: `${PREFIX}/providers/${providerId}/places/orders/volumetries`,
        queryParams: volumetriesFilter as unknown as QueryParams
      })
      .subscribe({
        next: (orderVolumetries) => {
          this.updateOrderVolumetries({
            loading: false,
            orderVolumetries: [...orderVolumetries].map((o) => this.orderVolumetryMapper.mapFromApi(o))
          });
        },
        error: (error) => {
          this.updateOrderVolumetries({ loading: false, error });
          this.handleError(error);
        }
      });
  }

  /** Effects */

  /** Utils */

  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    return throwError(() => new Error(error));
  }
}
