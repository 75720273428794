export * from './components/footer/footer.component';
export * from './components/header/header.component';
export * from './components/place-selector/place-selector.component';
export * from './components/sidebar-menu/sidebar-menu.component';
export * from './sidebar-layout/sidebar-layout.component';

/**
 * This folder typically contains components specific to the application's core logic.
 * They can be components that are used in multiple modules or components that are critical to the operation of the application as a whole.
 *
 * If the component can not be used in another application, this is its folder.
 */
