/* eslint-disable no-unused-vars */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sl-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @Input() showClose = false;
  @Input() color: TagColor = 'blue';
  @Input() radius: TagRadius = 'round';

  @Output() readonly clickTag = new EventEmitter<Event>();
  @Output() readonly clickClose = new EventEmitter<void>();

  constructor() {}

  onClickTag(event: Event): void {
    this.clickTag.emit(event);
  }

  onClickClose(): void {
    this.clickClose.emit();
  }
}

export type TagColor = 'orange' | 'green' | 'blue' | 'grey';
export type TagRadius = 'round' | 'sharp';
