import { IOrderVolumetryEntity } from './orderVolumetry.entity.interface';

export class OrderVolumetryEntity implements IOrderVolumetryEntity {
  readonly volumetryId: string;
  readonly count: number;

  constructor(data: IOrderVolumetryEntity) {
    this.volumetryId = data.volumetryId;
    this.count = data.count;
  }

  // You can add additional methods here if necessary
}
