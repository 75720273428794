import { IOrderBillingEntity } from './orderBilling.entity.interface';

export class OrderBillingEntity implements IOrderBillingEntity {
  readonly amount: string;
  readonly netAmount: string;
  readonly tax: string;

  constructor(data: IOrderBillingEntity) {
    this.amount = data.amount;
    this.netAmount = data.netAmount;
    this.tax = data.tax;
  }
}
