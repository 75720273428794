/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { MocksService } from '@sl/services';
import { map, Observable } from 'rxjs';
import { Address } from '../../domain/address.model';
import { Provider } from '../../domain/provider.model';
import { ProviderPlaces } from '../../domain/providerPlaces.model';
import { PlaceEntity } from '../entities/place.entity';
import { ProviderPlacesMapper } from '../mappers/providerPlaces.mapper';
import { GetPlacesParams, GetPlacesResponse, IPlaceRepository } from './place.repository.interface';

@Injectable({ providedIn: 'root' })
export class PlaceMocks implements IPlaceRepository {
  private providerPlaces: Record<string, ProviderPlaces> = {
    id1: PROVIDER_PLACES_1
  };

  constructor(private readonly mocksService: MocksService, private readonly providerPlacesMapper: ProviderPlacesMapper) {}

  /* You can return values depending on its params.*/

  getPlaces({ cif }: GetPlacesParams): Observable<GetPlacesResponse> {
    const response = this.providerPlaces['id1'];
    console.log(`getPlaces mock ( cif: ${cif} ) -> `, response);
    return this.mocksService.mockResponse(response).pipe(map((res) => this.providerPlacesMapper.mapFromApi(res)));
  }
}

/** DATA */

export const PROVIDER_1: Provider = {
  providerId: '00123000',
  providerName: 'Juan Perez Lopez'
};

export const PLACES_1: PlaceEntity[] = [
  {
    placeId: '00123001',
    description: 'Interfunerarias Madrid',
    address: {
      streetName: 'Av Constitución, 3',
      postalCode: '28001',
      provinceName: 'Madrid',
      cityName: 'Las Rozas',
      countryName: 'España'
    } as Address
  },
  {
    placeId: 'pl0002',
    description: 'Interfunerarias Barcelona',
    address: {
      streetName: 'Av de La Rambla, 12',
      postalCode: '08001',
      provinceName: 'Barcelona',
      cityName: 'Terrassa',
      countryName: 'España'
    } as Address
  }
];

export const PROVIDER_PLACES_1: ProviderPlaces = {
  provider: PROVIDER_1,
  place: PLACES_1
};
