/* eslint-disable no-unused-vars */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GlobalPositionPageComponent } from './pages';

const routes: Routes = [
  {
    path: '',
    component: GlobalPositionPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GlobalPositionRoutingModule {}
