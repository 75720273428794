import { SettlementAmountMapper } from './settlementAmount.mapper';
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';

import { SettlementClientId } from '../../domain/enums/settlement.enums';
import { Settlement } from '../../domain/settlement.model';
import { SettlementEntity } from '../entities/settlement.entity';
import { ISettlementMapper } from './settlement.mapper.interface';

@Injectable({ providedIn: 'root' })
export class SettlementMapper implements ISettlementMapper {
  constructor(private readonly settlementAmountMapper: SettlementAmountMapper) {}

  mapFromApi(apiData: SettlementEntity): Settlement {
    return new Settlement({
      settlementId: apiData.settlementId,
      startDate: apiData.startDate,
      endDate: apiData.endDate,
      amount: this.settlementAmountMapper.mapFromApi(apiData.amount),
      documentId: apiData.documentId,
      documentName: apiData.documentName,
      clientId: apiData.clientId as SettlementClientId,
      orders: apiData.orders,
      batchId: apiData.batchId
    });
  }

  mapToApi(settlement: Settlement): SettlementEntity {
    return new SettlementEntity({
      settlementId: settlement.settlementId,
      startDate: settlement.startDate,
      endDate: settlement.endDate,
      amount: this.settlementAmountMapper.mapToApi(settlement.amount),
      documentId: settlement.documentId,
      documentName: settlement.documentName,
      clientId: settlement.clientId,
      orders: settlement.orders,
      batchId: settlement.batchId
    });
  }
}
