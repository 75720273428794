/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Order } from '@sl/features';
import { OrderBilling } from 'src/app/core/features/order/domain/orderBilling.model';
import { OrderConcept } from 'src/app/core/features/order/domain/orderConcept.model';
import { trackBy } from '../../utils/angular';

@Component({
  selector: 'sl-table-cards',
  templateUrl: './table-cards.component.html',
  styleUrls: ['./table-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCardsComponent implements OnChanges {
  @Input() tableHead = ['subconcept', 'units', 'IVAtax', 'IVAtype', 'IVAamount', 'total', 'orderNumber', 'supplied'];
  @Input() order: Order | undefined;
  @Input() cellColor = 'rgb(196, 241, 226)';
  @Input() cellBorderColor = 'aquamarine';
  @Input() title = 'title';

  justifyRightColumns = ['']; //Include tableHead names to justify to the right
  ivaType: string[] = [];
  filteredConcepts: FilteredConcept[] = [];
  billing: Billing[] = [];
  totalAmount = 0;
  subconceptWidth = 330;

  Object = Object;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['order'].currentValue && this.order) {
      this.initFilteredConcepts();
      this.initBilling();
      this.totalAmount = this.getTotalAmount();
    }
  }

  handleIva(iva: any, remove = false): void {
    if (!this.ivaType.find((x) => x === iva)) {
      this.ivaType.push(iva);
    } else if (this.ivaType.find((x) => x === iva) && remove) {
      this.ivaType = this.ivaType.filter((x) => x !== iva);
    }
    this.initFilteredConcepts();
    if (this.ivaType.length > 0) {
      this.filteredConcepts = this.filteredConcepts.filter((data) => this.ivaType.includes(data.tax.toString()));
    }
    this.totalAmount = this.getTotalAmount();
  }

  /* Utils*/

  getEntries(obj: object): { key: string; value: any }[] {
    return Object.entries(obj).map(([key, value]) => ({ key, value }));
  }

  getValue<T>(obj: T, key: string): any {
    return obj[key as keyof T];
  }

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  /* Private */

  private initFilteredConcepts(): void {
    this.filteredConcepts = this.order?.concepts.map((concept) => this.mapOrcerConceptToFilteredConcept(concept)) ?? [];
  }

  private initBilling(): void {
    this.billing = this.order?.billing.map((orderBilling) => this.mapOrderBillingToBilling(orderBilling)) ?? [];
  }

  private mapOrcerConceptToFilteredConcept(orcerConcept: OrderConcept): FilteredConcept {
    return {
      subconcept: (orcerConcept.id ?? '') + ' ' + (orcerConcept.description ?? ''),
      units: orcerConcept.units,
      netIvaAmount: orcerConcept.netAmount,
      tax: orcerConcept.tax,
      //ivaAmount: (orcerConcept.tax * orcerConcept.netAmount) / 100,
      ivaAmount: parseFloat((orcerConcept.totalAmount - orcerConcept.netAmount).toFixed(2)),
      total: parseFloat(orcerConcept.totalAmount.toFixed(2)),
      orderNumber: orcerConcept.requestCode,
      supplied: orcerConcept.supplied
    };
  }

  private getTotalAmount(): number {
    return this.filteredConcepts.reduce((acc, concept) => acc + parseFloat(concept.total.toFixed(2)), 0);
  }

  private mapOrderBillingToBilling(orderBilling: OrderBilling): Billing {
    // The order of the properties is important
    return {
      taxBase: orderBilling.netAmount ?? '0',
      tax: orderBilling.tax ?? '0',
      amount: orderBilling.netAmount && orderBilling.tax ? parseFloat(orderBilling.netAmount) * (parseFloat(orderBilling.tax) / 100) : 0,
      total: orderBilling.amount ?? '0'
    };
  }
}

interface FilteredConcept {
  subconcept: string;
  units: string;
  netIvaAmount: number;
  tax: number;
  ivaAmount: number;
  total: number;
  orderNumber: string;
  supplied: boolean;
}

interface Billing {
  amount: number;
  taxBase: string;
  tax: string;
  total: string;
}
