import { IIssueMessageEntity } from './issueMessage.entity.interface';
import { IssueMessageDocEntity } from './IssueMessageDoc.entity';

export class IssueMessageEntity implements IIssueMessageEntity {
  readonly messageId: string | null;
  readonly readDate: string | null;
  readonly creationDate: string | null;
  readonly read: boolean;
  readonly content: string;
  readonly docList: IssueMessageDocEntity[] | null;
  readonly type: string | null;
  readonly subject: string | null;
  readonly source: string;

  constructor(data: IIssueMessageEntity) {
    this.messageId = data.messageId;
    this.readDate = data.readDate;
    this.creationDate = data.creationDate;
    this.read = data.read;
    this.content = data.content;
    this.docList = data.docList ? data.docList.map((doc) => new IssueMessageDocEntity(doc)) : null;
    this.type = data.type;
    this.subject = data.subject;
    this.source = data.source;
  }
}
