/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DropdownOption } from '@sl/components';
import { SETTLEMENT, SettlementClientId } from '@sl/features';
import { SharedModule } from '@sl/shared';
import { trackBy } from '@sl/utils';
import { GetOrdersFilter } from 'src/app/core/features/order/infrastructure/repositories/order.repository.interface';

@Component({
  selector: 'sl-button-filter-liquidations-pending',
  templateUrl: './button-filter-liquidations-pending.component.html',
  styleUrls: ['./button-filter-liquidations-pending.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonFilterLiquidationsPendingComponent implements OnInit, OnChanges {
  @Input() filters: FilterPending = { ...defaultFilter };

  @Output() readonly filtersChange = new EventEmitter<FilterPending>();
  @Output() readonly submitFilters = new EventEmitter<FilterPending>();
  @Output() readonly discardFilters = new EventEmitter<FilterPending>();

  initialFilters!: FilterPending;
  visible = false;

  /* Section 1 */
  section1 = {
    legend: 'Filtrar por nº'
  };

  inputPolicy = {
    label: 'Buscar por',
    placeholder: 'Nº Póliza',
    value: null as string | null
  };

  inputOrder = {
    label: 'Buscar por',
    placeholder: 'Nº Encargo',
    value: null as string | null
  };

  inputSinister = {
    label: 'Buscar por',
    placeholder: 'Nº Siniestro (NIS)',
    value: null as string | null
  };

  inputBill = {
    label: 'Buscar por',
    placeholder: 'Nº Factura',
    value: null as string | null
  };

  /* Section 2 */
  section2 = {
    legend: 'Asegurado'
  };

  inputNameAndSurname = {
    label: 'Buscar por',
    placeholder: 'Nombre y Apellidos',
    value: null as string | null
  };

  /* Section 3 */
  section3 = {
    legend: 'Cliente'
  };

  dropdownClient = {
    label: 'Filtrar',
    placeholder: 'Cliente',
    options: [
      { label: 'Santalucía', value: SETTLEMENT.CLIENT_ID.SANTALUCIA },
      { label: 'Iris Global', value: SETTLEMENT.CLIENT_ID.IRIS }
    ] as DropdownOption<SettlementClientId>[],
    value: null as SettlementClientId | null
  };

  constructor() {}

  ngOnInit(): void {
    this.initialFilters = { ...this.filters };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters']) {
      this.inputPolicy.value = this.filters.policeId ?? null;
      this.inputOrder.value = this.filters.orderId ?? null;
      this.inputSinister.value = this.filters.claimId ?? null;
      this.inputBill.value = this.filters.billId ?? null;
      this.inputNameAndSurname.value = this.filters.insuredName ?? null;
      this.dropdownClient.value = (this.filters.clientId as SettlementClientId) ?? null;
    }
  }

  /* Action Buttons */

  handleSubmitFilters(): void {
    this.submitFilters.emit(this.filters);
    this.closePanel();
  }

  handleRemoveFilters(): void {
    this.resetFilters();
    this.discardFilters.emit(this.filters);
    this.closePanel();
  }

  /* Section 1 */

  onInputPolicy(): void {
    this.filters.policeId = this.inputPolicy.value || null;
    this.onChange();
  }

  onInputOrder(): void {
    this.filters.orderId = this.inputOrder.value || null;
    this.onChange();
  }

  onInputSinister(): void {
    this.filters.claimId = this.inputSinister.value || null;
    this.onChange();
  }

  onInputBill(): void {
    this.filters.billId = this.inputBill.value || null;
    this.onChange();
  }

  /* Section 2 */

  onInputNameAndSurname(): void {
    this.filters.insuredName = this.inputNameAndSurname.value || null;
    this.onChange();
  }

  /* Section 3 */

  onDropdownClient(): void {
    this.filters.clientId = this.dropdownClient.value || null;
    this.onChange();
  }

  /* Utils */

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  /* Private */

  private onChange(): void {
    this.filtersChange.emit(this.filters);
  }

  private closePanel(): void {
    this.visible = false;
  }

  private resetFilters(): void {
    this.inputPolicy.value = null;
    this.inputOrder.value = null;
    this.inputSinister.value = null;
    this.inputBill.value = null;
    this.inputNameAndSurname.value = null;
    this.dropdownClient.value = null;
    this.filters = { ...defaultFilter };
    this.onChange();
  }
}

export type FilterPending = Pick<GetOrdersFilter, 'policeId' | 'claimId' | 'orderId' | 'billId' | 'insuredName' | 'clientId'>;

const defaultFilter: FilterPending = {
  policeId: null,
  claimId: null,
  orderId: null,
  billId: null,
  insuredName: null,
  clientId: null
} as const;

export const defaultFilterPending: FilterPending = { ...defaultFilter };
