import { Injectable } from '@angular/core';
import { OrderSummaryProceeding } from '../../domain/orderSummaryProceeding.model';
import { OrderSummaryProceedingEntity } from '../entities/orderSummaryProceeding.entity';
import { IOrderSummaryProceedingMapper } from './orderSummaryProceeding.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderSummaryProceedingMapper implements IOrderSummaryProceedingMapper {
  mapFromApi(apiData: OrderSummaryProceedingEntity): OrderSummaryProceeding {
    return new OrderSummaryProceeding({
      id: apiData.id,
      date: apiData.date ? new Date(apiData.date) : null
    });
  }

  mapToApi(orderGeneralProceeding: OrderSummaryProceeding): OrderSummaryProceedingEntity {
    return new OrderSummaryProceedingEntity({
      id: orderGeneralProceeding.id,
      date: orderGeneralProceeding.date?.toISOString() ?? null
    });
  }
}
