/* eslint-disable @typescript-eslint/naming-convention */

export type SettlementStatus = 'closed' | 'pending';
export type SettlementClientId = 'SL' | 'IG';

export const SETTLEMENT = {
  CLIENT_ID: {
    SANTALUCIA: 'SL' as SettlementClientId,
    IRIS: 'IG' as SettlementClientId
  },
  STATUS: {
    CLOSED: 'closed' as SettlementStatus,
    PENDING: 'pending' as SettlementStatus
  }
} as const;
