/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { IssueMessageSource, IssueMessageType } from '../../domain/enums/issue.enum';
import { IssueMessage } from '../../domain/issueMessage.model';
import { IssueMessageEntity } from '../entities/issueMessage.entity';
import { IIssueMessageMapper } from './issueMessage.mapper.interface';
import { IssueMessageDocMapper } from './issueMessageDoc.mapper';

@Injectable({ providedIn: 'root' })
export class IssueMessageMapper implements IIssueMessageMapper {
  constructor(private readonly issueMessageDocMapper: IssueMessageDocMapper) {}

  mapFromApi(apiData: IssueMessageEntity): IssueMessage {
    return new IssueMessage({
      messageId: apiData.messageId,
      readDate: apiData.readDate ? new Date(apiData.readDate) : null,
      creationDate: apiData.creationDate ? new Date(apiData.creationDate) : null,
      read: apiData.read,
      content: apiData.content,
      docList: apiData.docList ? apiData.docList.map((doc) => this.issueMessageDocMapper.mapFromApi(doc)) : null,
      type: apiData.type as IssueMessageType,
      subject: apiData.subject,
      source: apiData.source as IssueMessageSource,
      sender: null
    });
  }

  mapToApi(message: IssueMessage): IssueMessageEntity {
    return new IssueMessageEntity({
      messageId: message.messageId,
      readDate: message.readDate ? mapDateToApi(message.readDate) : null,
      creationDate: message.creationDate ? mapDateToApi(message.creationDate) : null,
      read: message.read,
      content: message.content,
      docList: message.docList ? message.docList.map((doc) => this.issueMessageDocMapper.mapToApi(doc)) : null,
      type: message.type,
      subject: message.subject,
      source: message.source
    });
  }
}

const mapDateToApi = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
