import { IOrderBillFileEntity } from './orderBillFile.entity.interface';

export class OrderBillFileEntity implements IOrderBillFileEntity {
  readonly docId: string;
  readonly docName: string;
  readonly docType: string;
  readonly creationDate: string;
  readonly metadata: string;

  constructor(data: IOrderBillFileEntity) {
    this.docId = data.docId;
    this.docName = data.docName;
    this.docType = data.docType;
    this.creationDate = data.creationDate;
    this.metadata = data.metadata;
  }
}
