/* eslint-disable @typescript-eslint/naming-convention */

export enum OrderSummaryStatusStatus {
  Pending = 'PENDING',
  Modified = 'MODIFIED',
  Revise = 'REVIEWPENDING',
  Billed = 'BILLED',
  BillIssue = 'BILLISSUE'
}

export enum OrderSummaryType {
  New = 'NEW',
  NewNote = 'NEWNOTE',
  OutofTime = 'OUTOFTIME',
  Issue = 'ISSUE'
}

export enum OrderSummaryClientId {
  Santalucia = 'SL',
  Iris = 'IG'
}
