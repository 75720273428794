/* eslint-disable no-undefined */
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import * as XLSX from 'xlsx';
import { ExcelData, IDownloadService } from './download.service.interface';

@Injectable({ providedIn: 'root' })
export class DownloadService implements IDownloadService {
  constructor(private readonly translocoService: TranslocoService) {}

  exportAsExcel(dataToExport: any, excelFileName: string, table?: string, description?: string): void {
    if (!dataToExport?.length) {
      return;
    }

    const newDataToExport: any[] = [];
    dataToExport.forEach((obj: any) => {
      const translatedObj: any = {};
      Object.keys(obj).forEach((key) => {
        if (!obj[this.translocoService.translate('columns.' + key)]) {
          translatedObj[this.translocoService.translate('columns.' + key)] =
            key === 'state' ? (obj[key] ? this.translocoService.translate(table + '.' + obj[key]?.toLowerCase()) : '') : obj[key];
        }
      });
      newDataToExport.push(translatedObj);
    });

    const excelDataArray = [[...Object.keys(newDataToExport[0])]];
    newDataToExport.forEach((data: ExcelData) => excelDataArray.push([...Object.values(data)]));
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    let worksheet: XLSX.WorkSheet;

    if (description) {
      worksheet = XLSX.utils.aoa_to_sheet([[description]]);
      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 }); //Se deja una fila vacía como separación
      XLSX.utils.sheet_add_aoa(worksheet, excelDataArray, { origin: -1 }); //0 para que lo agregue al principio del contenido
    } else {
      worksheet = XLSX.utils.aoa_to_sheet(excelDataArray);
    }
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, excelFileName + '.xlsx');
  }

  exportAsCSV(data: object[], fileName: string): void {
    const csv = this.convertToCSV(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    this.donwload(blob, fileName, '.csv');
  }

  exportFile(fileName: string, content: string, mimeType: string): void {
    const byteArray = new Uint8Array(
      atob(content)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: 'application/' + mimeType });
    this.donwload(blob, fileName, `.${mimeType}`);
  }

  private convertToCSV(data: object[], separator = ';'): string {
    const header = this.generateCSVHeader(data[0]).join(separator);
    const rows = data.map((obj) => this.generateCSVRow(obj).join(separator));
    return [header, ...rows].join('\n');
  }

  private generateCSVHeader(data: object, keySeparator = '-'): string[] {
    const stack = [{ obj: data, currentKey: '' }];
    const result: string[] = [];

    while (stack.length > 0) {
      // Condition necessary for Kiuwan potential infinite loops rule
      if (stack.length <= 0) {
        break;
      }

      const { obj, currentKey } = stack.pop()!;

      for (const key of Object.keys(obj)) {
        const value = obj[key as keyof object];
        const newKey = currentKey ? `${currentKey}${keySeparator}${key}` : key;

        if (value && typeof value === 'object' && !Array.isArray(value)) {
          stack.push({ obj: value, currentKey: newKey });
        } else {
          result.push(newKey);
        }
      }
    }

    return result;
  }

  private generateCSVRow(data: object): string[] {
    const stack: object[] = [data];
    const result: string[] = [];

    while (stack.length > 0) {
      // Condition necessary for Kiuwan potential infinite loops rule
      if (stack.length <= 0) {
        break;
      }

      const current = stack.pop();

      if (current && typeof current === 'object') {
        for (const value of Object.values(current)) {
          if (Array.isArray(value)) {
            result.push(JSON.stringify(value));
          } else if (value instanceof Date) {
            result.push(value.toISOString());
          } else if (value && typeof value === 'object') {
            stack.push(value);
          } else {
            result.push(value);
          }
        }
      }
    }

    return result;
  }

  private donwload(blob: Blob, fileName: string, extension: string): void {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}${extension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
}
