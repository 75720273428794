/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SharedModule } from '@sl/shared';
import { trackBy } from '@sl/utils';
import dayjs from 'dayjs';
import { OrderSummaryStatusStatus } from 'src/app/core/features/order/domain/enums/orderSummaryStatus.enum';
import { OrderSummaryStatus } from 'src/app/core/features/order/domain/orderSummaryStatus.model';

@Component({
  selector: 'sl-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderTrackingComponent implements OnInit, OnChanges {
  @Input() historyTracking: OrderSummaryStatus[] | undefined = [
    {
      status: OrderSummaryStatusStatus.Pending,
      date: new Date()
    },
    {
      status: OrderSummaryStatusStatus.Billed,
      date: null
    },
    {
      status: OrderSummaryStatusStatus.Modified,
      date: null
    },
    {
      status: OrderSummaryStatusStatus.Revise,
      date: null
    }
  ];
  @Input() currentStatus: OrderSummaryStatus | undefined = {
    status: OrderSummaryStatusStatus.Modified,
    date: new Date()
  };

  PENDING_CIRCLE_NUMBER = 1;

  orderTrackings: OrderTracking[] = [
    /* {
      num: 1,
      status: OrderSummaryStatusStatus.Pending,
      title: this.getStatusLiteral(OrderSummaryStatusStatus.Pending),
      subtitle: this.getStatusLiteral(OrderSummaryStatusStatus.Pending),
      date: null,
      visited: true,
      current: true,
      show: true
    } */
    /*  {
      num: 2,
      status: OrderSummaryStatusStatus.Modified,
      title: this.getStatusLiteral(OrderSummaryStatusStatus.Modified),
      subtitle: this.getStatusLiteral(OrderSummaryStatusStatus.Modified),
      date: null,
      visited: false,
      current: false,
      show: true
    },
    {
      num: 3,
      status: OrderSummaryStatusStatus.Revise,
      title: this.getStatusLiteral(OrderSummaryStatusStatus.Revise),
      subtitle: this.getStatusLiteral(OrderSummaryStatusStatus.Revise),
      date: null,
      visited: false,
      current: false,
      show: true
    } */
    // ,
    // {
    //   num: 4,
    //   status: OrderSummaryStatusStatus.Billed,
    //   title: this.getStatusLiteral(OrderSummaryStatusStatus.Billed),
    //   subtitle: this.getStatusLiteral(OrderSummaryStatusStatus.Billed),
    //   date: null,
    //   visited: false,
    //   current: false,
    //   show: true
    // }
  ];

  OrderSummaryStatusStatus = OrderSummaryStatusStatus;

  constructor() {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.initLiterals();
  }

  ngOnInit(): void {
    this.includeNewTrackins();
    this.initOrderTrackings();
    this.initLiterals();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['historyTracking']?.currentValue || changes['currentStatus']?.currentValue) {
      this.includeNewTrackins();
      this.initOrderTrackings();
    }
  }

  // Utils
  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  // Private
  private includeNewTrackins(): void {
    this.orderTrackings = [];

    this.historyTracking?.forEach((tracking, index) => {
      const newTracking: OrderTracking = {
        status: tracking.status,
        title: this.getStatusLiteral(tracking.status),
        subtitle: this.getStatusLiteral(tracking.status),
        date: tracking.date ? mapApiToDate(tracking.date) : null,
        visited: false,
        current: false,
        show: false
      };
      this.orderTrackings.push(newTracking);
    });
  }

  private initOrderTrackings(): void {
    this.orderTrackings = this.orderTrackings?.map((orderTracking) => {
      const auxTracking = {
        ...orderTracking,
        visited: orderTracking.status === OrderSummaryStatusStatus.Pending ? true : orderTracking.date ? true : false,
        current: this.currentStatus?.status === orderTracking.status,
        show: true
      };
      return auxTracking;
    });
  }

  private initLiterals(): void {
    //Se cambia el texto para resoluciones pequeñas
    this.orderTrackings = this.orderTrackings?.map((step) => ({
      ...step,
      title: this.getStatusLiteral(step.status),
      subtitle: this.getStatusLiteral(step.status)
    }));
  }

  // Utils
  private getStatusLiteral(status: OrderSummaryStatusStatus): string {
    return window.innerWidth > 850 ? STATUS_LITERAL_MAP[status] : STATUS_LITERAL_SMALL_MAP[status];
  }
}

const mapApiToDate = (orderDate: any): string => dayjs(orderDate).utc().format('DD/MM/YYYY');

const STATUS_LITERAL_MAP: Record<OrderSummaryStatusStatus, string> = {
  [OrderSummaryStatusStatus.Pending]: 'PENDING',
  [OrderSummaryStatusStatus.Modified]: 'MODIFIED',
  [OrderSummaryStatusStatus.Revise]: 'REVIEWPENDING',
  [OrderSummaryStatusStatus.Billed]: 'BILLED',
  [OrderSummaryStatusStatus.BillIssue]: 'BILLISSUE'
};

const STATUS_LITERAL_SMALL_MAP: Record<OrderSummaryStatusStatus, string> = {
  [OrderSummaryStatusStatus.Pending]: 'PENDING',
  [OrderSummaryStatusStatus.Modified]: 'MODIFIED',
  [OrderSummaryStatusStatus.Revise]: 'PendingAbbrevation',
  [OrderSummaryStatusStatus.Billed]: 'BILLED',
  [OrderSummaryStatusStatus.BillIssue]: 'BILLISSUE'
};

interface OrderTracking {
  status: OrderSummaryStatusStatus;
  title: string;
  subtitle: string;
  date: string | null;
  visited: boolean;
  current: boolean;
  show: boolean;
}
