import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { FormControlValueAccessorConnector } from '../abstract-control-value-accesor-connector';

@Component({
  selector: 'sl-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent<T> extends FormControlValueAccessorConnector<string> {
  @Input() placeholder!: string;
  @Input() icon!: string;
  @Input() iconColor = 'var(--color-primary5)';

  focusClass = false;

  constructor(injector: Injector) {
    super(injector);
  }

  onValueChange(value: string | null): void {
    this.value = value;
    this.valueChange.emit(this.value);
  }
}
