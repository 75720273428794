import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@sl/shared';

@Component({
  selector: 'sl-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() firstColor = '';
  @Input() secondColor = '';
  @Input() image = '';

  constructor() {}
}
