import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { SpinnerService } from '../services/spinner/spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private readonly spinnerService: SpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lastPosition = req.url.split('/').length - 1;
    const key = req.url.split('/')[lastPosition];
    this.spinnerService.startLoading(key);
    return next.handle(req).pipe(finalize(() => this.spinnerService.endLoading(key)));
  }
}
