import { IDocumentDetail } from './documentDetail.model.interface';
import { DocumentOrigin, DocumentType } from './enums/document.enum';

export class DocumentDetail implements IDocumentDetail {
  readonly documentId: string | null;
  readonly name: string;
  readonly type: DocumentType;
  readonly mimeType: string;
  readonly content: string;
  readonly date: Date;
  readonly origin: DocumentOrigin | null;

  constructor(data: IDocumentDetail) {
    this.documentId = data.documentId;
    this.name = data.name;
    this.type = data.type;
    this.mimeType = data.mimeType;
    this.content = data.content;
    this.date = data.date; // Create a new Object to make sure it is immutable
    this.origin = data.origin;
  }

  // Puedes agregar métodos adicionales aquí si es necesario
}
