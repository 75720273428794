import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Translation, translocoConfig, TranslocoLoader, TranslocoModule, TRANSLOCO_CONFIG, TRANSLOCO_LOADER } from '@ngneat/transloco';
import { environment } from '@sl/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  /**
   * Creates an instance of TranslocoHttpLoader.
   *
   * @author Arquitectura Empresarial - Santalucía
   * @param {HttpClient} http El servicio para la gestión de las llamadas HTTP
   */
  constructor(private http: HttpClient) {}

  /**
   * Devuelve el fichero json correspondiente al locale que le pasamos como parámetro
   *
   * @author Arquitectura Empresarial - Santalucía
   * @param {string} lang El locale del que queremos recuperar el fichero de traducción
   * @returns {Observable<any>} El fichero de traducción del locale que le pasamos como parámatro
   */
  getTranslation(lang: string): Observable<any> {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

/**
 * Módulo para la gestión del multi-idioma
 *
 * @author Arquitectura Empresarial - Santalucía
 * @exports
 * @class TranslocoRootModule
 * @typedef {TranslocoRootModule}
 */
@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'es'],
        defaultLang: 'es',
        // Remove this option if your application
        // doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {}
