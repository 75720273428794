import { Injectable } from '@angular/core';
import { IStorageService } from '@sl/services';
import { Observable, of, switchMap } from 'rxjs';

/** LocalStorage and SessionStorage management service */
@Injectable({ providedIn: 'root' })
export class StorageService implements IStorageService {
  clear(): Observable<void> {
    return of(window.localStorage.clear());
  }
  setLocalStorageItem<T = string>(tokenKey: string, token: T): Observable<void> {
    return of(window.localStorage.removeItem(tokenKey)).pipe(
      switchMap(() => of(window.localStorage.setItem(tokenKey, JSON.stringify(token))))
    );
  }
  getLocalStorageItem<T = string>(tokenKey: string): Observable<T | null> {
    return of(this.parse<T>(window.localStorage.getItem(tokenKey)));
  }
  setSessionStorageItem<T = string>(tokenKey: string, token: T): Observable<void> {
    return of(window.sessionStorage.removeItem(tokenKey)).pipe(
      switchMap(() => of(window.sessionStorage.setItem(tokenKey, JSON.stringify(token))))
    );
  }
  getSessionStorageItem<T = string>(tokenKey: string): Observable<T | null> {
    return of(this.parse<T>(window.sessionStorage.getItem(tokenKey)));
  }

  private parse<T = string>(data: string | null): T | null {
    let result;
    try {
      result = data ? (JSON.parse(data) as T) : null;
    } catch (err) {
      result = data as T;
    }
    return result;
  }
}
