<div *ngIf="visible" class="tooltip__container" [style.left]="left + 'px'" [style.top]="top + 'px'">
  <ng-container *transloco="let t">
    <p *ngIf="title" class="tooltip__margin">{{ t(title) | titlecase }}</p>
    <hr *ngIf="state !== null && title" class="tooltip__margin tooltip__separator" />
    <div *ngIf="state !== null" class="tooltip__margin tooltip__message">
      <i class="pi" [ngClass]="{ 'icon-fuera-plazo': state === 'OUTOFTIME', 'icon-alert1': state === 'ISSUE' }"></i
      >{{ 'orders.' + state.toLowerCase() | transloco }}
    </div>
  </ng-container>
</div>

<!-- <div *ngIf="visible" class="tooltip__container" [style.left]="left + 'px'" [style.top]="top + 'px'">
  <ng-container *transloco="let t">
    <p *ngIf="title" class="tooltip__margin">{{ t(title) | titlecase }}</p>
    <hr *ngIf="state !== null && title" class="tooltip__margin tooltip__separator" />
    <div *ngIf="state !== null" class="tooltip__margin tooltip__message">
      <i class="pi" [ngClass]="{ 'icon-fuera-plazo': state === 'pending', 'icon-alert1': state === 'alert' }"></i>
      <section class="tooltip__description">
        <span *ngFor="let key of objectKeys(description)">{{ key }}: {{ getDescription(key) }}</span>
      </section>
    </div>
  </ng-container>
</div> -->
