/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { MocksService } from '@sl/services';
import { map, Observable } from 'rxjs';
import { ProviderBillingType, ProviderTypeContact, ProviderTypeIdentification } from '../../domain/enums/provider.enums';
import { ProviderEntity } from '../entities/provider.entity';
import { ProviderDataEntity } from '../entities/providerData.entity';
import { ProviderMapper } from '../mappers/provider.mapper';
import { ProviderDataMapper } from '../mappers/providerData.mapper';
import {
  GetProviderDataParams,
  GetProviderDataResponse,
  GetProviderParams,
  GetProviderResponse,
  IProviderRepository,
  PutProviderParams,
  PutProviderResponse
} from './provider.repository.interface';

@Injectable({ providedIn: 'root' })
export class ProviderMocks implements IProviderRepository {
  private provider: Record<string, ProviderEntity> = {
    id1: PROVIDER_1
  };

  private providerData: Record<string, ProviderDataEntity> = {
    id1: PROVIDER_DATA_1
  };

  constructor(
    private readonly mocksService: MocksService,
    private readonly providerMapper: ProviderMapper,
    private readonly providerDataMapper: ProviderDataMapper
  ) {}

  /* You can return values depending on its params.*/

  getProvider({ providerId, placeId }: GetProviderParams): Observable<GetProviderResponse> {
    const response = this.provider['id1'];
    return this.mocksService.mockResponse(response).pipe(map((res: ProviderEntity) => this.providerMapper.mapFromApi(res)));
  }

  getProviderData({ providerId }: GetProviderDataParams): Observable<GetProviderDataResponse> {
    const response = this.providerData['id1'];
    console.log(`getProvider mock ( providerId: ${providerId} ) -> `, response);
    return this.mocksService.mockResponse(response).pipe(map((res: ProviderDataEntity) => this.providerDataMapper.mapFromApi(res)));
  }

  putProvider({ providerId, placeId, provider }: PutProviderParams): Observable<PutProviderResponse> {
    console.log(`putProvider mock ( providerId: ${providerId} placeId: ${placeId} provider: ${provider} ) -> void`);
    return this.mocksService.mockResponse();
  }
}

/** DATA */

export const PROVIDER_1: ProviderEntity = {
  businessName: 'iris Global null',
  userId: '12345678X',
  email: 'iris@global.com',
  phone: '625347896',
  providerId: 'p00001',
  address: 'C/ inventada 2, Madrid',
  contacts: [
    {
      typeContact: ProviderTypeContact.email,
      value: 'example@gmail.com',
      description: 'Administración'
    }
  ],
  generalDataModified: false,
  contactsDataModified: false
};

export const PROVIDER_DATA_1: ProviderDataEntity = {
  name: 'Interfunerarias Madrid',
  lastName: 'Interfunerarias Madrid',
  secondLastName: 'Interfunerarias Madrid',
  provinceEntityINE: '123456',
  municipaliyEntityINE: '123456',
  colletiveEntityINE: '123456',
  singularEntityINE: '123456',
  nucleusEntityINE: '123456',
  identification: [
    {
      typeIdentification: ProviderTypeIdentification.cif,
      value: '12345678X'
    }
  ],
  providerId: 'p00001',
  address: {
    streetName: 'Av Constitución, 3',
    postalCode: '28001',
    provinceName: 'Asturias',
    cityName: 'Gijón',
    countryName: 'España'
  },
  contacts: [
    {
      typeContact: ProviderTypeContact.email,
      value: 'example@gmail.com',
      description: 'Administración'
    }
  ],
  bank: {
    bankAccount: '9.1210004184502e+21',
    entity: '4502',
    office: '12',
    entityControlNumber: '1',
    accountControlNumber: '1',
    account: '450200051332',
    billingAddress: {
      streetName: 'Av Constitución, 3',
      postalCode: '28001',
      provinceName: 'Asturias',
      cityName: 'Gijón',
      countryName: 'España'
    },
    billingType: ProviderBillingType.manual,
    billingTax: '7%',
    billingNextPeriod: '15/07/23 - 31/07/23',
    billingNextRef: 'r00001',
    iban: 'ES9121000418450200051332',
    bic: '9121000418450200051332',
    taxType: 'IVA Suplidos'
  }
};
