/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderNote } from '../../domain/orderNote.model';
import { OrderNoteEntity } from '../entities/orderNote.entity';
import { IOrderNoteMapper } from './orderNote.mapper.interface';
import { OrderNoteInfoMapper } from './orderNoteInfo.mapper';
import { OrderNoteNoteMapper } from './orderNoteNote.mapper';

@Injectable({ providedIn: 'root' })
export class OrderNoteMapper implements IOrderNoteMapper {
  constructor(private readonly orderNoteNoteMapper: OrderNoteNoteMapper, private readonly orderNoteInfoMapper: OrderNoteInfoMapper) {}

  mapFromApi(apiData: OrderNoteEntity): OrderNote {
    return new OrderNote({
      originRequest: apiData.originRequest,
      order: this.orderNoteInfoMapper.mapFromApi(apiData.order),
      note: apiData.note.map((note) => this.orderNoteNoteMapper.mapFromApi(note))
    });
  }

  mapToApi(orderNote: OrderNote): OrderNoteEntity {
    return new OrderNoteEntity({
      originRequest: orderNote.originRequest,
      order: this.orderNoteInfoMapper.mapToApi(orderNote.order),
      note: orderNote.note.map((note) => this.orderNoteNoteMapper.mapToApi(note))
    });
  }
}
