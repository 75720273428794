import { IOrderBillOrderStatus } from './orderBillOrderStatus.model.interface';
import { OrderBillOrderStatusReason } from './orderBillOrderStatusReason.model';

export class OrderBillOrderStatus implements IOrderBillOrderStatus {
  readonly code: string;
  readonly reasons: OrderBillOrderStatusReason[];

  constructor(data: IOrderBillOrderStatus) {
    this.code = data.code;
    this.reasons = data.reasons.map((orderStatusReason) => new OrderBillOrderStatusReason(orderStatusReason));
    // Create a new object to make sure it is immutable;
  }
}
