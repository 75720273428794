/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GetProviderDataParams, IProviderDataStore, ProviderDataState, PutProviderDataParams } from '.';
import { ProviderData } from '../../features/provider/domain/providerData.model';
import { ProviderDataEntity } from '../../features/provider/infrastructure/entities/providerData.entity';
import { ProviderDataMapper } from '../../features/provider/infrastructure/mappers/providerData.mapper';
import { ApiService } from '../../services/api';

const PREFIX = '/api/v1' as const;

const initialProviderDataState: ProviderDataState = {
  providerData: null,
  error: null,
  loading: false
};

@Injectable({ providedIn: 'root' })
export class ProviderDataStore implements IProviderDataStore {
  private readonly _providerData$ = new BehaviorSubject<ProviderDataState>(initialProviderDataState);
  readonly providerData$ = this._providerData$.asObservable();

  constructor(private readonly apiService: ApiService, private readonly providerDataMapper: ProviderDataMapper) {}

  /** Selectors */

  selectProviderData(): Observable<ProviderDataState> {
    return this.providerData$;
  }

  getProviderData(): ProviderData | null {
    return this._providerData$.getValue().providerData;
  }

  /* Reducer */

  updateProviderData(newState: Partial<ProviderDataState>): void {
    const currentState = this._providerData$.getValue();
    this._providerData$.next({ ...currentState, ...newState });
  }

  /** Actions */

  actionGetProviderData({ providerId }: GetProviderDataParams): void {
    this.updateProviderData({ loading: true, error: null });

    this.apiService.get<ProviderDataEntity>({ url: `${PREFIX}/providers/${providerId}` }).subscribe({
      next: (providerData) => this.updateProviderData({ loading: false, providerData: this.providerDataMapper.mapFromApi(providerData) }),
      error: (error) => this.updateProviderData({ loading: false, error })
    });
  }

  actionPutProviderData({ providerId, providerData }: PutProviderDataParams): void {
    this.updateProviderData({ loading: true, error: null });

    const providerDataEntity = this.providerDataMapper.mapToApi(providerData);

    this.apiService
      .put<ProviderDataEntity>({
        url: `${PREFIX}/providers/${providerId}`,
        body: providerDataEntity
      })
      .subscribe({
        next: () => this.updateProviderData({ loading: false, providerData: new ProviderData(providerData) }),
        error: (error) => this.updateProviderData({ loading: false, error })
      });
  }
}
