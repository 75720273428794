<ng-container *ngIf="tableRows.length" [ngTemplateOutlet]="Table"></ng-container>
<ng-container *ngIf="showPagination && dataTable.length" [ngTemplateOutlet]="Pagination"></ng-container>
<ng-container *ngIf="!tableRows.length" [ngTemplateOutlet]="NoData"></ng-container>

<!-- Table -->
<ng-template #Table>
  <div *transloco="let t" class="scroll-table" [ngClass]="{ 'scroll-table--scrollable': !showPagination }">
    <table class="table">
      <!-- Header Row-->
      <tr class="sticky">
        <th *ngIf="showSelectColumn" class="table__header"></th>
        <ng-container *ngFor="let header of tableHeader; trackBy: trackByFn">
          <th *ngIf="header.show" class="table__header">
            <span class="data--header">{{ t('columns.' + header.name) }}</span>
          </th>
        </ng-container>
        <th *ngIf="showDownloadColumn" class="table__header sticky-right"><span></span></th>
      </tr>

      <!-- Rows -->
      <ng-container *ngFor="let data of tableRows; let i = index; trackBy: trackByFn">
        <tr
          class="table__row"
          [ngClass]="{
            'table__row--new': isNew(data),
            'table__row--selected': isSelected(data),
            'table__row--selectable': showCursorPointer,
            'table__row--hover': showRowHover,
            'table__row--not-read': data[data.length - 2][1] === 'unread'
          }"
          (click)="onRowClick(data)"
        >
          <!-- Checkbox Cell-->
          <td *ngIf="showSelectColumn">
            <input (click)="onCheckboxClick(data)" type="checkbox" />
          </td>

          <!-- Data Cell-->
          <!-- String | number -->
          <ng-container *ngFor="let item of data; let i = index; trackBy: trackByFn">
            <td [style.display]="showDataCell(item[0]) ? '' : 'none'">
              <ng-container *ngIf="item[0] !== 'enterprise' && item[0] !== 'client' && item[0] !== 'state' && item[0] !== 'orderType'">
                <ng-container [ngSwitch]="typeOf(item[1])">
                  <ng-container *ngSwitchCase="'string'">
                    <span class="data--cell" [innerHtml]="item[1] | searchHighlightPipe : highlightText : ''"></span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'number'">
                    <span class="data--cell" [innerHtml]="item[1] | round : 2 : ',' | searchHighlightPipe : highlightText : ''"></span>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <span class="data--cell">{{ item[1] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- Logo -->
              <span *ngIf="item[0] === 'enterprise' || item[0] === 'client'" class="logo">
                <img [src]="getEnterpriseImage(item[1])" alt="Enterprise image" />
              </span>

              <!-- Tag -->
              <span *ngIf="item[0] === 'state'" class="tag" [ngClass]="'tag--' + item[1].toLowerCase()">
                {{ t('state.' + item[1].toLowerCase()) }}
              </span>

              <!-- Icon -->
              <span *ngIf="item[0] === 'orderType'" class="icon">
                <div *ngFor="let orderType of item[1]; trackBy: trackByFn">
                  <!-- TODO: Para encargos NEW o NEWNOTE, ¿qué icono ponemos? -->
                  <i
                    class="icon__type"
                    [ngClass]="{
                      'icon-fuera-plazo': orderType === 'OUTOFTIME',
                      'icon-alert1': orderType === 'ISSUE',
                      'TODO-NEW': orderType === 'NEW',
                      'TODO-NEWNOTE': orderType === 'NEWNOTE'
                    }"
                    [slTooltip]="[null, orderType]"
                  ></i>
                </div>
              </span>
            </td>
          </ng-container>

          <!-- Download Cell-->
          <td *ngIf="showDownloadColumn" class="sticky-right">
            <span class="icon">
              <i class="icon__download icon-download" (click)="onDownloadRowClick(data)" (keypress)="onDownloadRowClick(data)"></i>
            </span>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</ng-template>

<ng-template #Pagination>
  <sl-pagination
    [totalPages]="totalPages"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (pageChange)="onChangePage($event)"
  ></sl-pagination>
</ng-template>

<ng-template #NoData>
  <span class="noData">{{ 'dataTable.noData' | transloco : { tableItems: tableItemsName } }}</span>
</ng-template>

<p-progressSpinner
  *ngIf="loading$ | async"
  [style]="{ width: '100px', height: '100px' }"
  styleClass="custom-spinner"
  strokeWidth="2"
  animationDuration="1s"
></p-progressSpinner>
