/* eslint-disable no-unused-vars */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages').then((m) => m.OrdersPageComponent)
  },
  {
    path: ':orderId',
    loadComponent: () => import('./pages').then((m) => m.DetailPageComponent)
  },
  {
    path: '**',
    loadComponent: () => import('./pages').then((m) => m.OrdersPageComponent)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule {}
