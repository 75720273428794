/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'sl-places-dropdown',
  templateUrl: './places-dropdown.component.html',
  styleUrls: ['./places-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlacesDropdownComponent<T> {
  @Input() titleColor = 'var(--color-white)';
  @Input() activeColor = 'var(--color-type5)';

  @Input() optionAll: PlaceOption<T[]> | null = null;
  @Input() options: PlaceOption<T>[] = [];
  @Input() value: T | T[] | null = null;

  @Input() multiple = false;

  @Output() readonly valueChange = new EventEmitter<T | T[] | null>();
  @Output() readonly closeSession = new EventEmitter<boolean>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  Array = Array;

  showDropdown = false;
  rendererUnlistenClick!: () => void;

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2, private readonly cdr: ChangeDetectorRef) {}

  get isOptions(): boolean {
    return Boolean(this.options?.length);
  }

  get isSelectedAllOptions(): boolean {
    return Array.isArray(this.value) && this.value.length === 0; // this.value.length === this.options.length;
  }

  get activeOption(): PlaceOption<T> | undefined {
    return this.options.find((option) => this.isActiveOption(option));
  }

  onAllOptions(): void {
    // this.value = this.options.map((option) => option.value!);
    this.value = this.optionAll?.value ?? [];
    this.onValueChange();
  }

  onOptionClick(option: PlaceOption<T>): void {
    this.writeValue(option.value);
    this.onValueChange();
  }

  onValueChange(): void {
    this.valueChange.emit(this.value);
    this.closeDropdown();
  }

  onCloseSession(): void {
    this.closeSession.emit(true);
    this.closeDropdown();
  }

  onToggleDropdown(): void {
    return this.showDropdown ? this.closeDropdown() : this.openDropdown();
  }

  openDropdown(): void {
    this.showDropdown = true;
    this.rendererUnlistenClick = this.renderer.listen('document', 'click', this.handleDocumentClick.bind(this));
    this.cdr.markForCheck();
  }

  closeDropdown(): void {
    this.showDropdown = false;
    this.cdr.markForCheck();
  }

  isActiveOption(option: PlaceOption<T>): boolean {
    return this.value === option.value || (Array.isArray(this.value) && this.value.includes(option.value!) && !this.isSelectedAllOptions);
  }

  trackByFn(index: number, option: PlaceOption<T>): string {
    return option.label;
  }

  private writeValue(value: T | null): void {
    if (!this.multiple) {
      this.value = value;
      return;
    }

    if (this.multiple && value) {
      this.value = Array.isArray(this.value) ? this.value : [];
      this.value = this.value?.includes(value) ? this.value.filter((v) => v !== value) : [...this.value, value];
    }
  }

  // * PRIVATE METHODS
  private handleDocumentClick(event: MouseEvent): void {
    if (this.isClickOutsideFilters(event)) {
      this.closeDropdown();
      this.rendererUnlistenClick();
    }
  }

  private isClickOutsideFilters(event: MouseEvent): boolean {
    const isClickInsideFilter = this.isClickInside(this.el, event);

    return !isClickInsideFilter;
  }

  private isClickInside(ref: ElementRef<HTMLElement>, event: MouseEvent): boolean {
    const { left, right, top, bottom } = ref.nativeElement.getBoundingClientRect();
    const { clientX, clientY } = event;
    return clientX >= left && clientX <= right && clientY >= top && clientY <= bottom;
  }
}

export interface PlaceOption<T = any> {
  label: string;
  value: T | null;
  icon?: string;
}
