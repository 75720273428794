import { IOrderBillEntity } from './orderBill.entity.interface';
import { OrderBillClaimEntity } from './orderBillClaim.entity';
import { OrderBillFileEntity } from './orderBillFile.entity';
import { OrderBillOrderEntity } from './orderBillOrder.entity';

export class OrderBillEntity implements IOrderBillEntity {
  readonly originRequest: string;
  readonly claim: OrderBillClaimEntity;
  readonly order: OrderBillOrderEntity;
  readonly billFiles: OrderBillFileEntity[];

  constructor(data: IOrderBillEntity) {
    this.originRequest = data.originRequest;
    this.claim = new OrderBillClaimEntity(data.claim);
    this.order = new OrderBillOrderEntity(data.order);
    this.billFiles = data.billFiles.map((billFile) => new OrderBillFileEntity(billFile));
  }
}
