/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService, NavigationService, ScreenSizeService, TranslateService } from '@sl/services';
import { ROUTES, SharedModule } from '@sl/shared';
import { PlaceSelectorComponent } from '../place-selector/place-selector.component';

@Component({
  selector: 'sl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [SharedModule, PlaceSelectorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Output() burgerMenuClick = new EventEmitter();

  isLogged = false;
  route = this.navigationService.getActualRoute().split('/')[1];
  languages = this.translate.getLanguages();

  logo = {
    url: '../../../../assets/icons/irisGlobalWhite.png',
    alt: 'Iris Global logo'
  };

  isSmallerThanLaptop$ = this.screenSizeService.isSmallerThanLaptop();

  constructor(
    private readonly authService: AuthService,
    private readonly translate: TranslateService,
    private readonly navigationService: NavigationService,
    private readonly screenSizeService: ScreenSizeService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  get isSmallerThanLaptop(): boolean {
    return this.screenSizeService.isSmallerThanLaptopInstant();
  }

  // TODO: Do it properly
  get hasPreviousPage(): boolean {
    return this.route !== ROUTES.GLOBAL_POSITION.PATH;
  }

  ngOnInit(): void {
    this.initIsLoggedIn();
    this.initRoute();
  }

  onBurgerMenuClick(): void {
    this.burgerMenuClick.emit();
  }

  onBackClick(): void {
    this.navigationService.navigateBack();
  }

  // TODO: change language feature
  getLanguageImage(lang?: string): string {
    //to do: añadir banderas a assets
    const assets = '../../../../assets/icons/';
    let response = assets + 'es.png';
    if (lang) {
      response = assets + lang.toLowerCase() + '.png';
    }
    return response;
  }

  getLanguage(language: string): boolean {
    return language === this.translate.getActiveLang();
  }

  onLanguageClick(language: string): void {
    console.log('TODO: Not implemented yet. onLanguageClick:', language);
    // this.translate.setActiveLang(language);
  }

  // * Initialization

  private initIsLoggedIn(): void {
    this.authService.isLoggedIn$.subscribe((isLogged) => {
      this.isLogged = isLogged;
      this.cdr.detectChanges();
    });
  }

  private initRoute(): void {
    this.navigationService.onRouteChange().subscribe((route) => {
      this.route = route.split('/')[1];
      this.cdr.detectChanges();
    });
  }
}
