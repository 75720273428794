import { MonoTypeOperatorFunction, Observable, retry, timer } from 'rxjs';

/** Api service*/
export interface IApiService {
  get<T>({ url }: GetParams<T>): Observable<T>;
  put<T extends object>({ url, body }: PutParams<T>): Observable<void>;
  post<T>({ url, body }: PostParams<T>): Observable<T>;
  delete<T>({ url }: DeleteParams<T>): Observable<void>;
}

export interface GetParams<T> {
  url: string;
  queryParams?: QueryParams | undefined;
}

export interface PutParams<T extends object> {
  url: string;
  body: T;
  queryParams?: QueryParams | undefined;
}

export interface PostParams<T> {
  url: string;
  body: any;
  queryParams?: QueryParams | undefined;
}

export interface DeleteParams<T> {
  url: string;
  queryParams?: QueryParams | undefined;
}

export type QueryParams = {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> | null | undefined;
};

export type QueryParamsNonNullable = {
  [K in keyof QueryParams]: Exclude<QueryParams[K], null | undefined>;
};

/**
 * Retrying requests with exponential backoff.
 *
 * Exponential backoff is a technique in which you retry an API after failure,
 * making the time in between retries longer after each consecutive failure,
 *  with a maximum number of retries after which the request is considered to have failed.
 *
 * @param {number} maxTries - The maximum number of retry attempts.
 * @param {number} initialDelay - The initial delay (in milliseconds) before the first retry.
 * @returns {MonoTypeOperatorFunction<unknown>} An RxJS operator that adds exponential backoff retry behavior.
 * @example
 *  // Retry the API request up to 3 times with an initial delay of 250 milliseconds.
 * this.apiService.get().pipe(backoff(3, 250)).subscribe()
 */
export const backoff = (maxTries: number, initialDelay: number): MonoTypeOperatorFunction<unknown> =>
  retry({
    count: maxTries,
    delay: (error, retryCount) => timer(initialDelay * retryCount ** 2)
  });
