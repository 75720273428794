/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { ApplicationRef, Injectable } from '@angular/core';
import { AmsKeycloakService, IAmsKeycloakEvent } from 'ams-core-front';
import { KeycloakProfile } from 'keycloak-js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IAuthService, User } from '.';
import { LocalStorageStore } from '../stores';

/** Auth management service */
@Injectable({ providedIn: 'root' })
export class AuthService implements IAuthService {
  private readonly _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  readonly isLoggedIn$ = this._isLoggedIn$.asObservable();

  private readonly _user$ = new BehaviorSubject<User | null>(null);
  readonly user$ = this._user$.asObservable();

  subscriptions: Subscription = new Subscription();

  eventsKeycloak: IAmsKeycloakEvent[] = [];
  eventsKeycloakCount = 0;

  /**
   *
   * @author Arquitectura Empresarial - Santalucía
   * @param {AmsKeycloakService} amsKeycloakService El servicio para la gestión de Keycloak
   * @param localStorageStore
   * @param {ApplicationRef} applicationRef El detector de cambios de Angular
   */
  constructor(
    private readonly localStorageStore: LocalStorageStore,
    private readonly amsKeycloakService: AmsKeycloakService,
    private readonly applicationRef: ApplicationRef
  ) {
    // Gestionamos los eventos de amsKeycloakService que se van produciendo
    this.eventUpdate();
    this.loadProfileKeycloak();
  }

  login(): void {
    this.amsKeycloakService.login();
    this.setIsLoggedIn(true);
  }

  logout(): void {
    this.amsKeycloakService.logout();
    this.setIsLoggedIn(false);
    this.localStorageStore.actionClear();

    // TODO: Reset application
  }

  getIsLoggedIn(): boolean {
    return this._isLoggedIn$.getValue();
  }

  setIsLoggedIn(isLoggedIn: boolean): void {
    this._isLoggedIn$.next(isLoggedIn);
  }

  getUser(): User | null {
    return this._user$.getValue();
  }

  setUser(user: User): void {
    this._user$.next(user);
  }

  /**
   * Gestión de eventos de amsKeycloakService
   *
   * @author Arquitectura Empresarial - Santalucía
   */
  private eventUpdate(): void {
    this.subscriptions.add(
      this.amsKeycloakService.keycloakEvents$.subscribe({
        next: (event) => {
          event['id' as keyof IAmsKeycloakEvent] = this.eventsKeycloakCount += 1;
          this.eventsKeycloak.push(event);
          // Lanzamos la detección de cambios puesto que usamos onPush como estrategia de cambios
          this.applicationRef.tick();
        }
      })
    );
  }

  /**
   * Solicita los datos de usuario y contraseña para el logado en amsKeycloakService
   *
   * @author Arquitectura Empresarial - Santalucía
   */
  private loadProfileKeycloak(): void {
    this.amsKeycloakService
      .getKeycloakInstance()
      .loadUserProfile()
      .then((profile: KeycloakProfile) => {
        this.setUser(profile);
        this.setIsLoggedIn(true);
        // Lanzamos la detección de cambios puesto que usamos onPush como estrategia de cambios
        this.applicationRef.tick();
      })
      .catch((err: string | undefined) => {
        throw new Error(err);
      });
  }

  /**
   * Método para devolver el id para el trackBy del ngFor de amsKeycloakService
   *
   * @author Arquitectura Empresarial - Santalucía
   * @param {number} index The index of the item within the iterable.
   * @param {*} item The item in the iterable.
   * @returns {*} The id
   */
  trackByItems(index: number, item: any): any {
    return item.id || index;
  }
}
