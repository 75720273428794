import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogConfig } from '@sl/components';
import { SharedModule } from '@sl/shared';

@Component({
  selector: 'sl-add-modal',
  templateUrl: './add-modal.component.html',
  styleUrls: ['./add-modal.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddModalComponent {
  @Input() data: any;
  @Input() areas!: string[];
  @Input() visible = false;

  @Output() readonly visibleChange = new EventEmitter();
  @Output() readonly addDataEmitter = new EventEmitter();

  auxArray: any;
  //areas = ['area1', 'area2', 'area3', 'area4'];
  neccessaryData = false;

  dialog: DialogConfig = {
    modal: true,
    showHeader: true,
    showFooter: true,
    title: 'Nueva área',
    subtitle: 'Añadir datos de nueva área'
  };

  onVisibleChange(visible: boolean): void {
    this.visible = visible;
    this.visibleChange.emit(visible);
  }

  onSaveData(): void {
    this.addDataEmitter.emit(this.data);
    this.closeModal();
  }

  getKeys(): string[] {
    return Object.keys(this.data);
  }

  getAreas(): string[] {
    return this.areas;
  }

  onAddArea(value: any): void {
    if (!this.areas.includes(value)) {
      this.areas.push(value);
    }
  }

  isAllNeccessaryData() {
    this.neccessaryData = false;
    if (this.data.label !== '' && (this.data.email || this.data.phone)) {
      this.neccessaryData = true;
    }
  }

  private closeModal(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
