import { IOrderBillOrderEntity } from './orderBillOrder.entity.interface';
import { OrderBillOrderStatusEntity } from './orderBillOrderStatus.entity';

export class OrderBillOrderEntity implements IOrderBillOrderEntity {
  readonly orderNumber: string;
  readonly proNumber: string;
  readonly comments: string;
  readonly selfCheckingId: string;
  readonly status: OrderBillOrderStatusEntity;

  constructor(data: IOrderBillOrderEntity) {
    this.orderNumber = data.orderNumber;
    this.proNumber = data.proNumber;
    this.comments = data.comments;
    this.selfCheckingId = data.selfCheckingId;
    this.status = new OrderBillOrderStatusEntity(data.status);
  }
}
