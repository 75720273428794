/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@sl/core';

import { ApiService } from '@sl/services';
import { map, Observable } from 'rxjs';
import { OrderMapper } from '../mappers/order.mapper';
import { OrderSummaryMapper } from '../mappers/orderSummary.mapper';
import {
  GetOrderEntityResponse,
  GetOrderParams,
  GetOrderResponse,
  GetOrdersEntityResponse,
  GetOrdersParams,
  GetOrdersResponse,
  IOrderRepository
} from './order.repository.interface';
import { OrderMocks } from './order.repository.mocks';

@Injectable({ providedIn: 'root' })
export class OrderRepository implements IOrderRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly orderMocks: OrderMocks,
    private readonly orderMapper: OrderMapper,
    private readonly orderSummaryMapper: OrderSummaryMapper
  ) {}

  /**
   * Using mappers because interfaces do not ensure the type of the returned object.
   * If isMocked redirects to Provider Mocks Provider (injection is not used to have each request mocked separately)
   */

  getOrders({ filter }: GetOrdersParams): Observable<GetOrdersResponse> {
    let response;
    if (APP_CONFIG.repository.order.getOrders.isMocked) {
      response = this.orderMocks.getOrders({ filter });
    }
    response = this.apiService
      .get<GetOrdersEntityResponse>({
        url: '/api/v1/orders',
        queryParams: { ...filter }
      })
      .pipe(
        map((res) => ({
          ...res,
          list: res.list.map((data) => this.orderSummaryMapper.mapFromApi(data))
        }))
      );
    return response;
  }

  getOrder({ orderId }: GetOrderParams): Observable<GetOrderResponse> {
    let response;
    if (APP_CONFIG.repository.order.getOrder.isMocked) {
      response = this.orderMocks.getOrder({ orderId });
    }
    response = this.apiService
      .get<GetOrderEntityResponse>({ url: `/api/v1/orders/${orderId}` })
      .pipe(map((res) => this.orderMapper.mapFromApi(res)));
    return response;
  }
}
