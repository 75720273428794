/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';

import { OrderConcept } from '../../domain/orderConcept.model';
import { OrderConceptEntity } from '../entities/orderConcept.entity';
import { IOrderConceptMapper } from './orderConcept.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderConceptMapper implements IOrderConceptMapper {
  mapFromApi(apiData: OrderConceptEntity): OrderConcept {
    return new OrderConcept({
      id: apiData.id,
      description: apiData.description,
      units: apiData.units,
      tax: apiData.tax,
      netAmount: apiData.netAmount,
      totalAmount: apiData.totalAmount,
      requestCode: apiData.requestCode,
      supplied: apiData.supplied
    });
  }

  mapToApi(orderConcept: OrderConcept): OrderConceptEntity {
    return new OrderConceptEntity({
      id: orderConcept.id,
      description: orderConcept.description,
      units: orderConcept.units,
      tax: orderConcept.tax,
      netAmount: orderConcept.netAmount,
      totalAmount: orderConcept.totalAmount,
      requestCode: orderConcept.requestCode,
      supplied: orderConcept.supplied
    });
  }
}
