<ng-container [ngSwitch]="isMobile$ | async">
  <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="Popover"></ng-container>
  <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="Modal"></ng-container>
</ng-container>

<ng-template #Popover>
  <sl-button #Toggle class="trigger__button" (click)="togglePopover($event)" [type]="'link'" [icon]="visible ? 'menos' : 'mas'">
    {{ label }}
  </sl-button>

  <p-overlayPanel #Panel (onShow)="onVisibleChange(true)" (onHide)="onVisibleChange(false)">
    <ng-container [ngTemplateOutlet]="Content"></ng-container>
  </p-overlayPanel>
</ng-template>

<ng-template #Modal>
  <sl-button class="trigger__button" (click)="showDialog(true)" [type]="'link'" [icon]="visible ? 'menos' : 'mas'">
    {{ label }}
  </sl-button>
  <p-dialog
    #Dialog
    [visible]="visible"
    (visibleChange)="onVisibleChange($event)"
    [modal]="dialog.modal"
    [draggable]="dialog.draggable"
    [resizable]="dialog.resizable"
  >
    <ng-container [ngTemplateOutlet]="Content"></ng-container>
  </p-dialog>
</ng-template>

<ng-template #Content>
  <ng-content></ng-content>
</ng-template>
