<ng-container *transloco="let t">
  <div class="container">
    <sl-header (burgerMenuClick)="onBurgerMenuClick()"></sl-header>
    <main class="main">
      <sl-sidebar-menu class="sidebar" (routeChange)="onRouteChange($event)" [(showSidebar)]="showSidebar"></sl-sidebar-menu>
      <ng-content></ng-content>
    </main>
  </div>
  <sl-footer></sl-footer>
</ng-container>
