/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Notification } from '../../domain/notification.model';
import { NotificationEntity } from '../entities/notification.entity';
import { INotificationMapper } from './notification.mapper.interface';

@Injectable({ providedIn: 'root' })
export class NotificationMapper implements INotificationMapper {
  mapFromApi(apiData: NotificationEntity): Notification {
    return new Notification({
      notificationId: apiData.notificationId,
      title: apiData.title,
      content: apiData.content,
      createdDate: apiData.createdDate ? new Date(apiData.createdDate) : null,
      expirationDate: new Date(apiData.expirationDate),
      readDate: apiData.readDate ? new Date(apiData.readDate) : null,
      documentId: apiData.documentId,
      documentName: apiData.documentName
    });
  }

  mapToApi(notification: Notification): NotificationEntity {
    return {
      notificationId: notification.notificationId,
      title: notification.title,
      content: notification.content,
      createdDate: notification.createdDate ? mapDateToApi(notification.createdDate) : null,
      expirationDate: mapDateToApi(notification.expirationDate),
      readDate: notification.readDate ? mapDateToApi(notification.readDate) : null,
      documentId: notification.documentId,
      documentName: notification.documentName
    };
  }
}

const mapDateToApi = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
