/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Place } from '../../domain/place.model';
import { PlaceEntity } from '../entities/place.entity';
import { IPlaceMapper } from './place.mapper.interface';

@Injectable({ providedIn: 'root' })
export class PlaceMapper implements IPlaceMapper {
  mapFromApi(apiData: PlaceEntity): Place {
    return new Place({
      placeId: apiData.placeId,
      description: apiData.description,
      address: apiData.address
    });
  }

  mapToApi(place: Place): PlaceEntity {
    return new PlaceEntity({
      placeId: place.placeId,
      description: place.description,
      address: place.address
    });
  }
}
