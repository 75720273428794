import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ApiClientService, DownloadService, SpinnerService } from '@sl/services';
import { trackBy } from '@sl/utils';
import { Observable } from 'rxjs';
import { OrderSummaryType } from 'src/app/core/features/order/domain/enums/orderSummaryStatus.enum';
import { Pages } from '../pagination/pagination.component';

@Component({
  selector: 'sl-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnChanges {
  @Input() dataTable: Record<string, any>[] = [];
  @Input() tableItemsName = 'datos';

  @Input() totalPages!: number;
  @Input() pageSize = 8;
  @Input() pageIndex = 1;

  @Input() highlightText: string | null = '';

  @Input() showPagination = true;
  @Input() showSelectColumn = false;
  @Input() showDownloadColumn = false;
  @Input() showCursorPointer = false;
  @Input() showRowHover = false;
  @Input() isTab = false;

  @Output() readonly pageChange = new EventEmitter<Pages>();
  @Output() readonly rowClick = new EventEmitter<Record<string, any>>();
  @Output() readonly downloadRowClick = new EventEmitter<Record<string, any>>();
  @Output() readonly showErrorModal = new EventEmitter(); // Do not use as it has to be extracted

  tableHeader: TableHeader[] = [];
  tableRows: [string, any][][] = [];

  selectedRows: Set<any> = new Set<any>();

  loading$!: Observable<boolean>; // Do not use as it has to be extracted

  generalHideCells = ['value', 'closingDate', 'closingHour'];
  tabHideCells = ['nis', 'deceasedName'];

  constructor(
    private readonly apiClientService: ApiClientService,
    private readonly downloadService: DownloadService,
    readonly spinnerService: SpinnerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataTable']) {
      this.tableRows = [];
      this.initTable(changes['dataTable'].currentValue);
    }
  }

  onCheckboxClick(data: [string, any][]): void {
    if (this.selectedRows.has(data)) {
      this.selectedRows.delete(data);
    } else {
      this.selectedRows.add(data);
    }
  }

  onRowClick(row: [string, any][]): void {
    const data = mapRowToObject(row);
    this.rowClick.emit(data);
  }

  onDownloadRowClick(row: [string, any][]): void {
    // TODO: Extract this case to documents tables
    const documentIdCell = row.find((element) => element[0] === 'documentNumber' || element[0] === 'documentId');
    if (documentIdCell) {
      const docId = documentIdCell[1];
      this.loading$ = this.spinnerService.getLoadingObservable(docId);
      this.apiClientService.getDocument(docId).subscribe({
        next: (document) => this.downloadService.exportFile(document.name, document.content, document.mimeType),
        error: () => this.showErrorModal.emit()
      });
      return;
    }

    const data = mapRowToObject(row);
    this.downloadRowClick.emit(data);
  }

  onChangePage(pages: Pages): void {
    this.pageChange.emit(pages);
  }

  /* Utils */

  showDataCell(key: string): boolean {
    if (this.isTab) {
      this.generalHideCells = this.generalHideCells.concat(this.tabHideCells);
    }
    return !this.generalHideCells.includes(key);
  }

  isSelected(data: any): boolean {
    return this.selectedRows?.has(data);
  }

  isNew(row: [string, any][]): boolean {
    return row.some((cell) => cell[0] === 'orderType' && cell[1]?.includes(OrderSummaryType.New));
  }

  getEnterpriseImage(enterprise: string): string {
    return '../../../../../assets/icons/' + enterprise.toLowerCase() + 'Icon.png';
  }

  typeOf(item: any): string {
    return typeof item;
  }

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  /* Private */

  // Initialization
  private initTable(dataTable: Record<string, any>[]): void {
    if (!dataTable?.length) {
      this.tableRows = [];
      return;
    }

    this.tableHeader = getParamColumns(Object.keys(dataTable[0]), this.isTab);

    // If property name is 'value' don't show
    this.tableHeader = this.tableHeader.filter((aux) => aux.name !== 'value');

    this.tableRows = dataTable?.map((aux) => Object.entries(aux));
    this.pageSize = this.showPagination ? this.pageSize : this.tableRows?.length;
  }
}

const getParamColumns = (response: string[], isTab: Boolean): TableHeader[] => {
  const newListColumns: TableHeader[] = [];
  response.forEach((columnName) =>
    newListColumns.push({
      name: columnName,
      show: true,
      editable: false,
      filter: false,
      output: false,
      numeric: false
    })
  );
  let generalHideColumns = ['prueba', 'closingDate', 'closingHour'];
  const tabHideColumns = ['nis', 'deceasedName'];

  if (isTab) {
    generalHideColumns = generalHideColumns.concat(tabHideColumns);
  }
  //Para cambiar propiedad de mostrar

  generalHideColumns.forEach((colName) => {
    const foundCol = newListColumns.find((col) => col.name === colName);
    if (foundCol) {
      foundCol.show = false;
    }
  });

  /* if (isTab) {
    tabHideColumns.forEach((colName) => {
      const foundCol = newListColumns.find((col) => col.name === colName);
      if (foundCol) {
        foundCol.show = false;
      }
    });
  } */

  //para cambiar propiedad de editar
  const editableColumns = ['prueba'];
  editableColumns.forEach((colName) => {
    const foundCol = newListColumns.find((col) => col.name === colName);
    if (foundCol) {
      foundCol.editable = true;
    }
  });

  //Para cambiar propiedad de filtro
  const filterColumns = ['numOrder', 'numPolicy', 'numDossier', 'status'];
  filterColumns.forEach((colName) => {
    const foundCol = newListColumns.find((col) => col.name === colName);
    if (foundCol) {
      foundCol.filter = true;
    }
  });

  return newListColumns;
};

const mapRowToObject = (row: [string, any][]): Record<string, any> =>
  row.reduce((data: Record<string, any>, [key, value]: [string, any]) => {
    data[key] = value;
    return data;
  }, {});

type TableHeader = {
  name: string;
  show: boolean;
  editable: boolean;
  filter: boolean;
  output: boolean;
  numeric: boolean;
};
