<div class="footer" *transloco="let t">
  <div class="footer__logo-container link__text">
    <div class="footer__logo-container__iris">{{ t('footer.iris') }}</div>
    <img src="../../../../assets/icons/irisGlobal.png" class="footer__logo-container__logo" alt="Iris global logo" />
    <div class="footer__logo-container__links">
      <span class="link link__social">{{ t('footer.social') }}</span>
      <section class="link__container">
        <span *ngFor="let button of socialLinks" class="link">
          <a class="link__social" [href]="button.url" target="_blank" [class]="'pi ' + button.icon"></a
        ></span>
      </section>
    </div>
  </div>
  <div class="footer__info-container">
    <div class="footer__info-container__iris">{{ t('footer.iris') }}</div>
    <div class="footer__info-container__links">
      <span *ngFor="let link of legalLinks" class="link">
        <a class="link__legal" [href]="link.url" target="_blank">{{ t(link.name) }}</a></span
      >
    </div>
  </div>
</div>
