import { IOrderSummaryStatusEntity } from './orderSummaryStatus.entity.interface';

export class OrderSummaryStatusEntity implements IOrderSummaryStatusEntity {
  readonly status: string;
  readonly date: string | null;

  constructor(data: IOrderSummaryStatusEntity) {
    this.status = data.status;
    this.date = data.date;
  }
}
