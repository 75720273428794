/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { IScreenSizeService } from '@sl/services';
import { BehaviorSubject, debounceTime, distinctUntilChanged, fromEvent, map, Observable, startWith } from 'rxjs';

export const BREAKPOINTS = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536
} as const;

type Breakpoint = keyof typeof BREAKPOINTS;

@Injectable({ providedIn: 'root' })
export class ScreenSizeService implements IScreenSizeService {
  private readonly pixelBreakpoints = BREAKPOINTS;

  private readonly isMobile$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isSmallTablet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isTablet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isLaptop$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isDesktop$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isBigScreen$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly isSmallerThanMobile$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isSmallerThanSmallTablet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isSmallerThanTablet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isSmallerThanLaptop$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isSmallerThanDesktop$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isSmallerThanBigScreen$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly isGreaterThanMobile$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isGreaterThanSmallTablet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isGreaterThanTablet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isGreaterThanLaptop$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isGreaterThanDesktop$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isGreaterThanBigScreen$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly windowResize$: Observable<number> = fromEvent(window, 'resize').pipe(
    debounceTime(200),
    map(() => this.width),
    distinctUntilChanged(),
    startWith(this.width)
  );

  constructor() {
    this.isMobile$ = this.isScreen('xs');
    this.isSmallTablet$ = this.isScreen('sm');
    this.isTablet$ = this.isScreen('md');
    this.isLaptop$ = this.isScreen('lg');
    this.isDesktop$ = this.isScreen('xl');
    this.isBigScreen$ = this.isScreen('xxl');

    // The next line is not useful since there is no screen smaller than Mobile by the moment
    this.isSmallerThanMobile$ = this.isScreenSmallerThan('xs');
    this.isSmallerThanSmallTablet$ = this.isScreenSmallerThan('sm');
    this.isSmallerThanTablet$ = this.isScreenSmallerThan('md');
    this.isSmallerThanLaptop$ = this.isScreenSmallerThan('lg');
    this.isSmallerThanDesktop$ = this.isScreenSmallerThan('xl');
    this.isSmallerThanBigScreen$ = this.isScreenSmallerThan('xxl');

    this.isGreaterThanMobile$ = this.isScreenGreaterOrEqualThan(this.nextBreakpoint('xs')!);
    this.isGreaterThanSmallTablet$ = this.isScreenGreaterOrEqualThan(this.nextBreakpoint('sm')!);
    this.isGreaterThanTablet$ = this.isScreenGreaterOrEqualThan(this.nextBreakpoint('md')!);
    this.isGreaterThanLaptop$ = this.isScreenGreaterOrEqualThan(this.nextBreakpoint('lg')!);
    this.isGreaterThanDesktop$ = this.isScreenGreaterOrEqualThan(this.nextBreakpoint('xl')!);
    // The next line will throw an error since there is no screen greater than BigScreen by the moment
    this.isGreaterThanBigScreen$ = this.isScreenGreaterOrEqualThan(this.nextBreakpoint('xxl')!);
  }

  get width(): number {
    return window.innerWidth > window.outerWidth ? window.outerWidth : window.innerWidth;
  }

  isScreen(breakpoint: Breakpoint): BehaviorSubject<boolean> {
    const isScreen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    const minWidth = this.screenSize(breakpoint);
    const maxWidth = this.nextScreenSize(breakpoint);

    this.windowResize$.subscribe((size) => {
      if (!maxWidth) {
        isScreen$.next(minWidth <= size);
      } else {
        isScreen$.next(minWidth <= size && size < maxWidth);
      }
    });

    return isScreen$;
  }

  isScreenGreaterThan(breakpoint: Breakpoint): BehaviorSubject<boolean> {
    const isScreen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    const minWidth = this.screenSize(breakpoint);

    this.windowResize$.subscribe((size) => {
      isScreen$.next(minWidth < size);
    });

    return isScreen$;
  }

  isScreenGreaterOrEqualThan(breakpoint: Breakpoint): BehaviorSubject<boolean> {
    const isScreen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    const minWidth = this.screenSize(breakpoint);

    this.windowResize$.subscribe((size) => {
      isScreen$.next(minWidth <= size);
    });

    return isScreen$;
  }

  isScreenSmallerThan(breakpoint: Breakpoint): BehaviorSubject<boolean> {
    const isScreen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    const maxWidth = this.screenSize(breakpoint);

    this.windowResize$.subscribe((size) => {
      isScreen$.next(size < maxWidth);
    });

    return isScreen$;
  }

  isScreenSmallerOrEqualThan(breakpoint: Breakpoint): BehaviorSubject<boolean> {
    const isScreen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    const maxWidth = this.screenSize(breakpoint);

    this.windowResize$.subscribe((size) => {
      isScreen$.next(size <= maxWidth);
    });

    return isScreen$;
  }

  getPixelBreakpoints(): typeof BREAKPOINTS {
    return this.pixelBreakpoints;
  }

  /** Screen sizes observables */

  isMobile(): Observable<boolean> {
    return this.isMobile$.asObservable();
  }

  isSmallTablet(): Observable<boolean> {
    return this.isSmallTablet$.asObservable();
  }

  isTablet(): Observable<boolean> {
    return this.isTablet$.asObservable();
  }

  isLaptop(): Observable<boolean> {
    return this.isLaptop$.asObservable();
  }

  isDesktop(): Observable<boolean> {
    return this.isDesktop$.asObservable();
  }

  isBigScreen(): Observable<boolean> {
    return this.isBigScreen$.asObservable();
  }

  /** Screen sizes smaller ranges observables */

  isSmallerThanMobile(): Observable<boolean> {
    return this.isSmallerThanMobile$.asObservable();
  }

  isSmallerThanSmallTablet(): Observable<boolean> {
    return this.isSmallerThanSmallTablet$.asObservable();
  }

  isSmallerThanTablet(): Observable<boolean> {
    return this.isSmallerThanTablet$.asObservable();
  }

  isSmallerThanLaptop(): Observable<boolean> {
    return this.isSmallerThanLaptop$.asObservable();
  }

  isSmallerThanDesktop(): Observable<boolean> {
    return this.isSmallerThanDesktop$.asObservable();
  }

  isSmallerThanBigScreen(): Observable<boolean> {
    return this.isSmallerThanBigScreen$.asObservable();
  }

  /** Screen sizes greater ranges observables */

  isGreaterThanMobile(): Observable<boolean> {
    return this.isGreaterThanMobile$.asObservable();
  }

  isGreaterThanTablet(): Observable<boolean> {
    return this.isGreaterThanTablet$.asObservable();
  }

  isGreaterThanSmallTablet(): Observable<boolean> {
    return this.isGreaterThanSmallTablet$.asObservable();
  }

  isGreaterThanLaptop(): Observable<boolean> {
    return this.isGreaterThanLaptop$.asObservable();
  }

  isGreaterThanDesktop(): Observable<boolean> {
    return this.isGreaterThanDesktop$.asObservable();
  }

  isGreaterThanBigScreen(): Observable<boolean> {
    return this.isGreaterThanBigScreen$.asObservable();
  }

  /** Screen sizes instant values */

  isMobileInstant(): boolean {
    return this.isMobile$.getValue();
  }

  isSmallTabletInstant(): boolean {
    return this.isSmallTablet$.getValue();
  }

  isTabletInstant(): boolean {
    return this.isTablet$.getValue();
  }

  isLaptopInstant(): boolean {
    return this.isLaptop$.getValue();
  }

  isDesktopInstant(): boolean {
    return this.isDesktop$.getValue();
  }

  isBigScreenInstant(): boolean {
    return this.isBigScreen$.getValue();
  }

  /** Screen sizes smaller ranges instant values */

  isSmallerThanMobileInstant(): boolean {
    return this.isSmallerThanMobile$.getValue();
  }

  isSmallerThanSmallTabletInstant(): boolean {
    return this.isSmallerThanSmallTablet$.getValue();
  }

  isSmallerThanTabletInstant(): boolean {
    return this.isSmallerThanTablet$.getValue();
  }

  isSmallerThanLaptopInstant(): boolean {
    return this.isSmallerThanLaptop$.getValue();
  }

  isSmallerThanDesktopInstant(): boolean {
    return this.isSmallerThanDesktop$.getValue();
  }

  isSmallerThanBigScreenInstant(): boolean {
    return this.isSmallerThanBigScreen$.getValue();
  }

  /** Screen sizes greater ranges instant values */

  isGreaterThanMobileInstant(): boolean {
    return this.isGreaterThanMobile$.getValue();
  }

  isGreaterThanTabletInstant(): boolean {
    return this.isGreaterThanTablet$.getValue();
  }

  isGreaterThanSmallTabletInstant(): boolean {
    return this.isGreaterThanSmallTablet$.getValue();
  }

  isGreaterThanLaptopInstant(): boolean {
    return this.isGreaterThanLaptop$.getValue();
  }

  isGreaterThanDesktopInstant(): boolean {
    return this.isGreaterThanDesktop$.getValue();
  }

  isGreaterThanBigScreenInstant(): boolean {
    return this.isGreaterThanBigScreen$.getValue();
  }

  /** Helper functions */

  screenSize = (breakpoint: Breakpoint): number => this.pixelBreakpoints[breakpoint];

  nextScreenSize = (breakpoint: Breakpoint): number | null => {
    const nextBreakpoint = this.nextBreakpoint(breakpoint);
    return nextBreakpoint ? this.pixelBreakpoints[nextBreakpoint] : null;
  };

  previousScreenSize = (breakpoint: Breakpoint): number | null => {
    const prevBreakpoint = this.previousBreakpoint(breakpoint);
    return prevBreakpoint ? this.pixelBreakpoints[prevBreakpoint] : null;
  };

  nextBreakpoint = (breakpoint: Breakpoint): Breakpoint | null => {
    const keys = Object.keys(this.pixelBreakpoints);
    const index = keys.indexOf(breakpoint);
    return index !== -1 && index < keys.length - 1 ? (keys[index + 1] as Breakpoint) : null;
  };

  previousBreakpoint = (breakpoint: Breakpoint): Breakpoint | null => {
    const keys = Object.keys(this.pixelBreakpoints);
    const index = keys.indexOf(breakpoint);
    return index !== -1 && index > 0 ? (keys[index - 1] as Breakpoint) : null;
  };
}
