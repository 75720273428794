import { OmitFunctions } from '../../base/utilityTypes';
import { MessageSource } from './enums/message.enum';
import { IOrderNoteNoteMessage } from './orderNoteNoteMessage.model.interface';
import { OrderNoteNoteMessageDoc } from './orderNoteNoteMessageDoc.model';

export class OrderNoteNoteMessage implements IOrderNoteNoteMessage {
  readonly messageId: string | null;
  readonly readDate: Date | null;
  readonly creationDate: Date;
  read: boolean;
  readonly content: string;
  docList: OrderNoteNoteMessageDoc[] | null;
  readonly type: string | null;
  readonly subject: string;
  source: MessageSource;
  readonly sender: string | null;

  constructor(data: OmitFunctions<IOrderNoteNoteMessage>) {
    this.messageId = data.messageId;
    this.readDate = data.readDate;
    this.creationDate = data.creationDate;
    this.read = data.read;
    this.content = data.content;
    this.docList = data.docList ? data.docList.map((doc) => new OrderNoteNoteMessageDoc(doc)) : null;
    this.type = data.type;
    this.subject = data.subject;
    this.source = data.source;
    this.sender = data.sender ?? null;
  }

  isProviderMessage(): boolean {
    return this.source === MessageSource.PROFESIONAL || this.source === MessageSource.PROVIDER_ADMIN;
  }

  isManagerMessage(): boolean {
    return this.source === MessageSource.PROVIDER_MANAGER || this.source === MessageSource.ORDER_MANAGER;
  }
}
