import { Address } from './address.model';
import { IPlace } from './place.model.interface';

export class Place implements IPlace {
  readonly placeId: string;
  readonly description: string;
  readonly address: Address;

  constructor(data: IPlace) {
    this.placeId = data.placeId;
    this.description = data.description;
    this.address = new Address(data.address);
  }
}
