/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { ApiService, PlaceStore, QueryParams } from '@sl/services';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { OrderStatistic, OrderStatisticEntity, OrderStatisticMapper } from '../../features/orderStatistic';
import { GetOrderStatisticsParams, IStatisticsStore, OrderStatisticsState } from './statistics.store.interface';

const PREFIX = '/api/v1' as const;

const initialStateOrderStatistics: OrderStatisticsState = {
  orderStatistics: null,
  error: null,
  loading: false
};

@Injectable({ providedIn: 'root' })
export class StatisticsStore implements IStatisticsStore {
  private readonly _orderStatistics$ = new BehaviorSubject<OrderStatisticsState>(initialStateOrderStatistics);
  readonly orderStatistics$ = this._orderStatistics$.asObservable();

  constructor(
    private readonly apiService: ApiService,
    private readonly placeStore: PlaceStore,
    private readonly orderStatisticMapper: OrderStatisticMapper
  ) {}

  /** Selectors */

  selectOrderStatistics(): Observable<OrderStatisticsState> {
    return this.orderStatistics$;
  }

  getOrderStatistics(): OrderStatistic[] | null {
    return this._orderStatistics$.getValue().orderStatistics;
  }

  /* Reducer */

  updateOrderStatistics(newState: Partial<OrderStatisticsState>): void {
    const currentState = this._orderStatistics$.getValue();
    this._orderStatistics$.next({ ...currentState, ...newState });
  }

  /** Actions */

  actionGetOrderStatistics(params?: GetOrderStatisticsParams): void {
    this.updateOrderStatistics({ loading: true, error: null });

    const providerId = params?.providerId ?? this.placeStore.getProviderId();
    const statisticsFilter = { placeId: this.placeStore.getPlaceId(), ...params?.statisticsFilter };

    this.apiService
      .get<OrderStatisticEntity[]>({
        url: `${PREFIX}/providers/${providerId}/places/orders/statistics`,
        queryParams: statisticsFilter as unknown as QueryParams
      })
      .subscribe({
        next: (orderStatistics) => {
          this.updateOrderStatistics({
            loading: false,
            orderStatistics: [...orderStatistics].map((o) => this.orderStatisticMapper.mapFromApi(o))
          });
        },
        error: (error) => {
          this.updateOrderStatistics({ loading: false, error });
          this.handleError(error);
        }
      });
  }

  /** Effects */

  /** Utils */

  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    return throwError(() => new Error(error));
  }
}
