import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'sl-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent implements OnChanges {
  @Input() title: string | undefined | null;
  @Input() state: string | null = null;

  left = 100;
  top = 0;
  visible = false;
  icon: string | null = null;

  constructor(private cdref: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.cdref.detectChanges();
    this.visible = true;
  }

  objectKeys(object: object): string[] {
    return Object.keys(object);
  }
}
