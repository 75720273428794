import { IOrderSummaryBill } from './orderSummaryBill.model.interface';

export class OrderSummaryBill implements IOrderSummaryBill {
  readonly billId: string | null;
  readonly billAmount: number | null;
  readonly dateBill: Date | null;
  readonly billNumber: string | null;

  constructor(data: IOrderSummaryBill) {
    this.billId = data.billId;
    this.billAmount = data.billAmount;
    this.dateBill = data.dateBill ? new Date(data.dateBill) : null;
    this.billNumber = data.billNumber;
  }
}
