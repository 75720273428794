<div class="pagination">
  <div>
    <div class="pagination__text">
      {{ 'pagination.showing' | transloco }}
      <div>
        <button class="dropdown__button" (click)="dropdownOpen = !dropdownOpen" type="button">
          {{ pageSize }}
        </button>
        <div class="dropdown__menu" *ngIf="dropdownOpen">
          <button
            *ngFor="let i of [].constructor(6); let j = index"
            class="dropdown__menu__option"
            type="button"
            (click)="selectedOption(j + 5)"
          >
            {{ j + 5 }}
          </button>
        </div>
      </div>
      {{ 'pagination.results' | transloco }}
    </div>
  </div>

  <div class="pagination__select">
    <div (click)="prevPage()" (keypress)="prevPage()"><</div>
    <ng-container *ngFor="let i of [].constructor(totalPages); let j = index">
      <span *ngIf="showDots(1) && j + 1 === 2">
        {{ 'pagination.dots' | transloco }}
      </span>
      <span
        *ngIf="showPage(j + 1)"
        [ngClass]="{ selectedPage: pageIndex === j + 1 }"
        (click)="onSelectedPageNumber(j + 1)"
        (keypress)="onSelectedPageNumber(j + 1)"
      >
        {{ j + 1 }}
      </span>
      <span *ngIf="showDots(totalPages) && j + 1 === totalPages - 1">
        {{ 'pagination.dots' | transloco }}
      </span>
    </ng-container>
    <div (click)="nextPage()" (keypress)="nextPage()">></div>
  </div>
</div>
