import { NotificationReadState } from './../../../../core/services/stores/notification.store.interface';
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentDetail, Notification } from '@sl/features';
import {
  ApiClientService,
  DownloadService,
  GetNotificationsFilter,
  NotificationActiveState,
  NotificationStore,
  SpinnerService
} from '@sl/services';
import { SharedModule } from '@sl/shared';
import { removeDiacritics, trackBy } from '@sl/utils';
import dayjs from 'dayjs';
import { filter, Observable } from 'rxjs';
import { ButtonFilterCommuniquesComponent, CommuniqueCardComponent } from '../../components';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-communiques',
  templateUrl: './communiques.page.html',
  styleUrls: ['./communiques.page.scss'],
  standalone: true,
  imports: [SharedModule, CommuniqueCardComponent, ButtonFilterCommuniquesComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommuniquesPageComponent implements OnInit {
  @Output() closeModal = new EventEmitter();

  notifications$ = this.notificationStore.selectNotifications();

  communiques: Communique[] = [];
  filteredCommuniques: Communique[] = [];
  selectedCommunique: Communique | undefined;

  isDetail = false;
  showDetail = false;

  isLoading$!: Observable<any>;
  showErrorModal = false;
  downloadDocumentId!: string;

  // Filters
  dateFilter = {
    startDate: null as Date | null,
    endDate: null as Date | null
  };

  checkboxActiveStatus = {
    options: ['ACTIVE', 'INACTIVE'] as NotificationActiveState[],
    value: null as NotificationActiveState | null
  };

  // TODO: Extract to communiques-button-filter
  showFilters = false;
  checkboxReadState = {
    options: ['READ', 'UNREAD'] as NotificationReadState[],
    value: null as NotificationReadState | null
  };
  searchByWord = {
    placeholder: 'filter.searchByWord',
    focusInput: false,
    value: ''
  };

  // Api params
  notificationParams: GetNotificationsFilter = {
    from: null,
    to: null,
    status: []
  };

  // TODO: Use ScreenSizeService instead
  windowWidth!: number;
  mobile = 599;

  constructor(
    private readonly notificationStore: NotificationStore,
    private readonly cdr: ChangeDetectorRef,
    private readonly downloadService: DownloadService,
    private readonly apiClientService: ApiClientService,
    readonly spinnerService: SpinnerService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.windowWidth = event.target.innerWidth; // Actualiza la variable con el nuevo ancho de la ventana.
    if (this.windowWidth > this.mobile) {
      this.isDetail = false;
    }
  }

  ngOnInit(): void {
    this.getCommuniques();
    this.initCommuniques();
    this.windowWidth = window.innerWidth;
  }

  // Dates
  onDatePickerDates(dates: Date[]): void {
    this.dateFilter.startDate = dates[0];
    this.dateFilter.endDate = dates[1];
    this.getCommuniques();
  }

  onDatePickerRemove(): void {
    this.dateFilter.startDate = null;
    this.dateFilter.endDate = null;
    this.getCommuniques();
  }

  // Checboxes
  onCheckboxActiveStatusFilter(value: NotificationActiveState): void {
    const isSelectedValue = value === this.checkboxActiveStatus.value;
    this.checkboxActiveStatus.value = isSelectedValue ? null : value;
    this.getCommuniques();
  }

  // More filters
  // TODO: Extract to button-filter-communiques.component
  onCheckboxReadStateChange(value: NotificationReadState): void {
    const isSelectedValue = value === this.checkboxReadState.value;
    this.checkboxReadState.value = isSelectedValue ? null : value;
  }

  onSearchByWord(): void {
    if (this.searchByWord.value) {
      this.filteredCommuniques = this.communiques?.filter(
        ({ title, content }) =>
          removeDiacritics(title?.toLowerCase() ?? '').includes(this.searchByWord.value.toLowerCase()) ||
          removeDiacritics(content?.toLowerCase() ?? '').includes(this.searchByWord.value.toLowerCase())
      );
    } else {
      this.filteredCommuniques = [...this.communiques];
    }
  }

  onSubmitFilters(): void {
    this.getCommuniques();
  }

  onDiscardFilters(): void {
    this.checkboxActiveStatus.value = null;
    this.checkboxReadState.value = null;
    this.searchByWord.value = '';
    this.getCommuniques();
    this.showFilters = false;
  }

  // Detail
  onButtonBackDetailClick(): void {
    this.isDetail = false;
    this.showDetail = false;
  }

  onCommuniqueCardClick(communiqueIndex: number): void {
    if (!this.communiques[communiqueIndex].readDate) {
      this.communiques[communiqueIndex].readDate = new Date();
      this.putNotification(this.communiques[communiqueIndex]);
    }
    this.selectedCommunique = this.communiques[communiqueIndex];
    this.showDetail = true;
    if (this.windowWidth < this.mobile) {
      this.isDetail = true;
    }
  }

  onDownloadDocument(communique: Communique | undefined): void {
    this.downloadDocumentId = communique!.documentId;
    this.getDocumentDetail(this.downloadDocumentId).subscribe({
      next: (document) => this.downloadService.exportFile(document.name, document.content, document.mimeType),
      error: () => (this.showErrorModal = true)
    });
  }

  // Utils
  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  private getCommuniques(): void {
    const from = this.dateFilter.startDate ? mapDateToApi(this.dateFilter.startDate) : null;
    const to = this.dateFilter.endDate ? mapDateToApi(this.dateFilter.endDate) : null;
    const activeStatusFilter = this.checkboxActiveStatus.value ? [this.checkboxActiveStatus.value] : [];
    const readStateFilter = this.checkboxReadState.value ? [this.checkboxReadState.value] : [];
    const status = [...activeStatusFilter, ...readStateFilter];
    this.notificationParams = { ...this.notificationParams, from, to, status };
    this.getNotifications(this.notificationParams);
    this.showFilters = false;
  }

  // Api
  private getDocumentDetail(documentId: string): Observable<DocumentDetail> {
    return this.apiClientService.getDocument(documentId);
  }

  private getNotifications(notificationFilter?: GetNotificationsFilter): void {
    this.notificationStore.actionGetNotifications({ notificationFilter });
  }

  private putNotification(communique: Communique): void {
    const { notificationId } = communique;
    const notification = new Notification(communique);
    this.notificationStore.actionPutNotification({ notificationId, notification });
  }

  // Initialization
  private initCommuniques(): void {
    this.isLoading$ = this.spinnerService.getLoadingObservable('notifications');
    this.notifications$
      .pipe(
        untilDestroyed(this),
        filter(({ notifications, loading, error }) => {
          if (error) {
            this.showErrorModal = true;
          }
          return Boolean(notifications) && !loading && !error;
        })
      )
      .subscribe(({ notifications }) => {
        this.communiques = notifications?.map((n) => mapNotificationToCommunique(n)) ?? [];
        this.filteredCommuniques = [...this.communiques];
        this.onSearchByWord();
        this.cdr.detectChanges();
      });
  }
}

const mapApiToDate = (orderDate: any): string => dayjs(orderDate).utc().format('DD/MM/YYYY');
const mapDateToApi = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
const mapDateToHour = (orderDate: any): string => dayjs(orderDate).utc().format('HH:mm');
const mapNotificationToCommunique = (n: Notification): Communique => ({
  ...n,
  date: n.createdDate ? mapApiToDate(n.createdDate.toJSON()) : '',
  hour: n.createdDate ? mapDateToHour(n.createdDate.toJSON()) : ''
});

export interface Communique extends Notification {
  date: string;
  hour: string;
}
