/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DropdownOption, Pages, TableSummaryConfig, TableSummaryItem } from '@sl/components';
import { DocumentDetail, Settlement } from '@sl/features';
import { ApiClientService, SpinnerService } from '@sl/services';
import { SharedModule } from '@sl/shared';
import { addThousandSeparators, trackBy } from '@sl/utils';
import dayjs from 'dayjs';
import { BehaviorSubject, filter, firstValueFrom, Subject, takeUntil } from 'rxjs';
import { OrderSummaryClientId } from 'src/app/core/features/order/domain/enums/orderSummaryStatus.enum';
import { OrderSummary } from 'src/app/core/features/order/domain/orderSummary.model';
import { GetOrdersFilter, GetOrdersResponse } from 'src/app/core/features/order/infrastructure/repositories/order.repository.interface';
import { SETTLEMENT, SettlementClientId, SettlementStatus } from 'src/app/core/features/settlement/domain/enums/settlement.enums';
import {
  GetSettlementsFilter,
  GetSettlementsResponse
} from 'src/app/core/features/settlement/infrastructure/repositories/settlement.repository.interface';
import { DownloadService } from 'src/app/core/services/download/download.service';
import {
  ButtonFilterLiquidationsClosedComponent,
  ButtonFilterLiquidationsPendingComponent,
  defaultFilterClosed,
  defaultFilterPending,
  FilterClosed,
  FilterPending
} from '../../components';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-liquidations',
  templateUrl: './liquidations.page.html',
  styleUrls: ['./liquidations.page.scss'],
  standalone: true,
  imports: [SharedModule, ButtonFilterLiquidationsClosedComponent, ButtonFilterLiquidationsPendingComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiquidationsPageComponent implements OnInit, OnDestroy {
  private _settlementsPending$ = new BehaviorSubject<GetOrdersResponse | null>(null);
  settlementsPending$ = this._settlementsPending$.asObservable();
  private _settlementsClosed$ = new BehaviorSubject<GetSettlementsResponse | null>(null);
  settlementsClosed$ = this._settlementsClosed$.asObservable();
  // This is needed because there is not getSettlementsSummary by now, and tableSummary should display all the data
  private _settlementsPendingAll$ = new BehaviorSubject<GetOrdersResponse | null>(null);
  settlementsPendingAll$ = this._settlementsPendingAll$.asObservable();
  private _settlementsClosedAll$ = new BehaviorSubject<GetSettlementsResponse | null>(null);
  settlementsClosedAll$ = this._settlementsClosedAll$.asObservable();

  destroySummaryRequest$: Subject<boolean> = new Subject();
  destroyOrdersRequest$: Subject<boolean> = new Subject();
  destroySettlementsRequest$: Subject<boolean> = new Subject();

  table: (SettlementTableItem | OrderSummaryTableItem)[] = [];
  tableSummary: TableSummaryConfig = {};

  showErrorModal = false;
  isDownloading = false;

  totalTablePages!: number;
  pageIndex!: number;
  pageSize!: number;

  // Filters
  dateFilter = {
    startDate: null as Date | null,
    endDate: null as Date | null
  };

  dropdownStatusFilter = {
    label: 'Filtrar por',
    placeholder: 'Pendientes/Realizadas',
    options: [
      { label: 'Liquidaciones pendientes', value: SETTLEMENT.STATUS.PENDING },
      { label: 'Liquidaciones realizadas', value: SETTLEMENT.STATUS.CLOSED }
    ] as DropdownOption<SettlementStatus>[],
    value: SETTLEMENT.STATUS.PENDING as SettlementStatus | null
  };

  buttonFilterPending: FilterPending = { ...defaultFilterPending };
  buttonFilterClosed: FilterClosed = { ...defaultFilterClosed };

  filterTags = {
    prefix: 'Pendiente de liquidar por',
    showClose: true,
    tags: [] as Tag<string>[]
  };

  // Api params
  orderParams: GetOrdersFilter = {
    pageSize: 10,
    pageIndex: 1,
    claimId: null,
    orderId: null,
    status: 'BILLED',
    startDate: null,
    endDate: null,
    billId: null,
    type: null
  };
  settlementParams: GetSettlementsFilter = {
    pageSize: 10,
    pageIndex: 1,
    claimId: null,
    orderId: null,
    startDate: null,
    endDate: null,
    batchId: null,
    policeId: null,
    clientId: null,
    billId: null,
    insuredName: null,
    amount: null
  };

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly apiClientService: ApiClientService,
    private readonly downloadService: DownloadService,
    public readonly spinnerService: SpinnerService
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get SETTLEMENT() {
    return SETTLEMENT;
  }

  ngOnInit(): void {
    this.getTableData();
    this.getTableSummaryData();
    this.initTable();
    this.initTableSummary();
  }

  ngOnDestroy(): void {
    this.destroySummaryRequest$.complete();
    this.destroySummaryRequest$.unsubscribe();
    this.destroyOrdersRequest$.complete();
    this.destroyOrdersRequest$.unsubscribe();
    this.destroySettlementsRequest$.complete();
    this.destroySettlementsRequest$.unsubscribe();
  }

  // Filters
  onDatePickerDates(dates: Date[]): void {
    this.dateFilter.startDate = dates[0];
    this.dateFilter.endDate = dates[1];
    this.resetPageIndex();
    this.getTableData();
    this.getTableSummaryData();
  }

  onDatePickerRemove(): void {
    this.dateFilter.startDate = null;
    this.dateFilter.endDate = null;
    this.resetPageIndex();
    this.getTableData();
    this.getTableSummaryData();
  }

  onDropdownStatusFilter(value: SettlementStatus | null): void {
    this.dropdownStatusFilter.value = value;
    this.clearButtonFilter();
    this.clearFilterTags();
    this.resetPageIndex();
    this.getTableData();
    this.getTableSummaryData();
  }

  onSubmitFiltersPending(filters: Partial<GetSettlementsFilter>): void {
    this.buttonFilterPending = { ...filters };
    this.clearFilterTags();
    this.resetPageIndex();
    this.getTableData();
    this.getTableSummaryData();
  }

  onDiscardFiltersPending(filters: Partial<GetSettlementsFilter>): void {
    this.buttonFilterPending = { ...filters };
    this.clearFilterTags();
    this.resetPageIndex();
    this.getTableData();
    this.getTableSummaryData();
  }

  onSubmitFiltersClosed(filters: Partial<GetOrdersFilter>): void {
    this.buttonFilterClosed = { ...filters };
    this.clearFilterTags();
    this.resetPageIndex();
    this.getTableData();
    this.getTableSummaryData();
  }

  onDiscardFiltersClosed(filters: Partial<GetOrdersFilter>): void {
    this.buttonFilterClosed = { ...filters };
    this.clearFilterTags();
    this.resetPageIndex();
    this.getTableData();
    this.getTableSummaryData();
  }

  // Table summary
  onTableSummaryItemClick(item: TableSummaryItem): void {
    const tag: Tag<string> = this.createFilterTag(item.name, item.value);
    this.buttonFilterPending = { ...this.buttonFilterPending, clientId: tag.value };
    this.buttonFilterClosed = { ...this.buttonFilterClosed, clientId: tag.value };
    this.addFilterTag(tag);
    this.resetPageIndex();
    this.getTableData();
  }

  onFilterTagClose(tag: Tag<string>): void {
    this.buttonFilterPending = { ...this.buttonFilterPending, clientId: null };
    this.buttonFilterClosed = { ...this.buttonFilterClosed, clientId: null };
    this.deleteFilterTag(tag);
    this.resetPageIndex();
    this.getTableData();
  }

  // Download
  onDownloadTableClick(): void {
    this.downloadAllSettlements();
  }

  // Table
  async onDownloadRowClick(data: Record<string, any>): Promise<void> {
    const { value } = data as OrderSummaryTableItem | SettlementTableItem;
    const billDocId = 'bill' in value ? value.bill.billId : value.documentId;

    if (!billDocId) {
      this.showErrorModal = true;
      this.cdr.detectChanges();
      return;
    }

    try {
      const document = await this.getDocumentPromise(billDocId);
      this.downloadService.exportFile(document.name, document.content, document.mimeType);
    } catch (error) {
      this.showErrorModal = true;
      this.cdr.detectChanges();
    }
  }

  onChangePage({ pageSize, pageIndex }: Pages): void {
    this.orderParams.pageSize = pageSize;
    this.orderParams.pageIndex = pageIndex;
    this.settlementParams.pageSize = pageSize;
    this.settlementParams.pageIndex = pageIndex;
    this.getTableData();
  }

  // Utils
  trackByFn(index: number, item: any): string {
    return trackBy(index, item);
  }

  // Private
  private getTableData(): void {
    const startDate = this.dateFilter.startDate ? mapDateToApi(this.dateFilter.startDate) : null;
    const endDate = this.dateFilter.endDate ? mapDateToApi(this.dateFilter.endDate) : null;

    if (this.dropdownStatusFilter.value === SETTLEMENT.STATUS.PENDING) {
      this.orderParams = { ...this.orderParams, ...this.buttonFilterPending, startDate, endDate };
      this.getOrders(this.orderParams);
    }
    if (this.dropdownStatusFilter.value === SETTLEMENT.STATUS.CLOSED) {
      this.settlementParams = { ...this.settlementParams, ...this.buttonFilterClosed, startDate, endDate };
      this.getSettlements(this.settlementParams);
    }
    if (!this.dropdownStatusFilter.value) {
      throw new Error('No settlement status selected');
    }
  }

  private getTableSummaryData(): void {
    const startDate = this.dateFilter.startDate ? mapDateToApi(this.dateFilter.startDate) : null;
    const endDate = this.dateFilter.endDate ? mapDateToApi(this.dateFilter.endDate) : null;

    if (this.dropdownStatusFilter.value === SETTLEMENT.STATUS.PENDING) {
      this.orderParams = { ...this.orderParams, ...this.buttonFilterPending, startDate, endDate };
      this.getAllOrders(this.orderParams);
    }
    if (this.dropdownStatusFilter.value === SETTLEMENT.STATUS.CLOSED) {
      this.settlementParams = { ...this.settlementParams, ...this.buttonFilterClosed, startDate, endDate };
      this.getAllSettlements(this.settlementParams);
    }
    if (!this.dropdownStatusFilter.value) {
      throw new Error('No settlement status selected');
    }
  }

  private async getAllTableDataPromise(): Promise<GetOrdersResponse | GetSettlementsResponse> {
    let result;
    if (this.dropdownStatusFilter.value === SETTLEMENT.STATUS.PENDING) {
      const { pageSize, pageIndex, ...filters } = this.orderParams;
      result = this.getOrdersPromise(filters);
    }
    if (this.dropdownStatusFilter.value === SETTLEMENT.STATUS.CLOSED) {
      const { pageSize, pageIndex, ...filters } = this.settlementParams;
      result = this.getSettlementsPromise(filters);
    }
    if (!result) {
      throw new Error('No settlement status selected');
    }
    return result;
  }

  // Api
  private getOrders(params?: GetOrdersFilter): void {
    this.destroySettlementsRequest$.next(true);
    this.apiClientService
      .getOrders(params)
      .pipe(takeUntil(this.destroyOrdersRequest$))
      .subscribe({
        next: (response) => {
          this._settlementsPending$.next(response);
          this.cdr.detectChanges();
        },
        error: () => {
          this.showErrorModal = true;
          this._settlementsPending$.next(null);
          // If get tableData fails, update tableSummary
          this.destroySummaryRequest$.next(true);
          this._settlementsPendingAll$.next(null);
          this.cdr.detectChanges();
        }
      });
  }

  private getSettlements(params?: GetSettlementsFilter): void {
    this.destroyOrdersRequest$.next(true);
    this.apiClientService
      .getSettlements(params)
      .pipe(takeUntil(this.destroySettlementsRequest$))
      .subscribe({
        next: (response) => {
          this._settlementsClosed$.next(response);
        },
        error: () => {
          this.showErrorModal = true;
          this._settlementsClosed$.next(null);
          // If get tableData fails, update tableSummary
          this.destroySummaryRequest$.next(true);
          this._settlementsClosedAll$.next(null);
          this.cdr.detectChanges();
        }
      });
  }

  private getAllOrders(params?: GetOrdersFilter): void {
    const { pageSize, pageIndex, ...filters } = params ?? {};
    this.apiClientService
      .getOrders(filters)
      .pipe(takeUntil(this.destroySummaryRequest$))
      .subscribe({
        next: (response) => {
          this._settlementsPendingAll$.next(response);
          this.cdr.detectChanges();
        },
        error: () => {
          this.showErrorModal = true;
          this._settlementsPendingAll$.next(null);
          this.cdr.detectChanges();
        }
      });
  }

  private getAllSettlements(params?: GetSettlementsFilter): void {
    const { pageSize, pageIndex, ...filters } = params ?? {};
    this.apiClientService
      .getSettlements(filters)
      .pipe(takeUntil(this.destroySummaryRequest$))
      .subscribe({
        next: (response) => {
          this._settlementsClosedAll$.next(response);
        },
        error: () => {
          this.showErrorModal = true;
          this._settlementsClosedAll$.next(null);
          this.cdr.detectChanges();
        }
      });
  }

  // Api Promises
  private async getOrdersPromise(params?: GetOrdersFilter): Promise<GetOrdersResponse> {
    return await firstValueFrom(this.apiClientService.getOrders(params));
  }

  private async getSettlementsPromise(params?: GetSettlementsFilter): Promise<GetSettlementsResponse> {
    return await firstValueFrom(this.apiClientService.getSettlements(params));
  }

  private async getDocumentPromise(documentId: string): Promise<DocumentDetail> {
    return await firstValueFrom(this.apiClientService.getDocument(documentId));
  }

  // Initialization
  private initTable(): void {
    this.settlementsPending$
      .pipe(
        untilDestroyed(this),
        filter((res): res is GetOrdersResponse => {
          this.table = [];
          return res !== null;
        })
      )
      .subscribe(({ list, total, pageSize, pageIndex }) => {
        this.table = list?.map((order) => mapOrderSummaryToTableItem(order));
        this.totalTablePages = Math.ceil(total / pageSize);
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.cdr.detectChanges();
      });
    this.settlementsClosed$
      .pipe(
        untilDestroyed(this),
        filter((res): res is GetSettlementsResponse => {
          this.table = [];
          return res !== null;
        })
      )
      .subscribe(({ list, total, pageSize, pageIndex }) => {
        this.table = list?.map((settlement) => mapSettlementToTableItem(settlement));
        this.totalTablePages = Math.ceil(total / pageSize);
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.cdr.detectChanges();
      });
  }

  private initTableSummary(): void {
    this.settlementsPendingAll$
      .pipe(
        untilDestroyed(this),
        filter((res): res is GetOrdersResponse => {
          this.tableSummary = {};
          return res !== null;
        })
      )
      .subscribe(({ list }) => {
        const data = list?.map((item) => mapOrderToTableSummaryItem(item));
        this.tableSummary = { ...TABLE_SUMMARY_CONFIG[SETTLEMENT.STATUS.PENDING], data };
        this.cdr.detectChanges();
      });
    this.settlementsClosedAll$
      .pipe(
        untilDestroyed(this),
        filter((res): res is GetSettlementsResponse => {
          this.tableSummary = {};
          return res !== null;
        })
      )
      .subscribe(({ list }) => {
        const data = list?.map((item) => mapSettlementToTableSummaryItem(item));
        this.tableSummary = { ...TABLE_SUMMARY_CONFIG[SETTLEMENT.STATUS.CLOSED], data };
        this.cdr.detectChanges();
      });
  }

  // Filters
  private clearButtonFilter(): void {
    this.buttonFilterPending = { ...defaultFilterPending };
    this.buttonFilterClosed = { ...defaultFilterClosed };
  }

  private resetPageIndex(): void {
    this.orderParams.pageIndex = 1;
    this.settlementParams.pageIndex = 1;
  }

  // Filter Tags feature
  private createFilterTag(label: string, value: string): Tag<string> {
    const { prefix, showClose } = this.filterTags;
    return { id: label, prefix, showClose, label, value };
  }

  private addFilterTag(tag: Tag<string>): void {
    const isTagInTags = Boolean(this.filterTags.tags.filter((t) => t.label === tag.label).length);
    if (isTagInTags) {
      return;
    }
    // Not multiple tags due to sync buttonFilterPending clientId dropdown
    // this.filterTags.tags = [...this.filterTags.tags, tag];
    this.filterTags.tags = [tag];
  }

  private deleteFilterTag(tag: Tag<string>): void {
    this.filterTags.tags = this.filterTags.tags.filter((t) => t !== tag);
  }

  private clearFilterTags(): void {
    this.filterTags.tags = [];
  }

  // Download table feature
  private async downloadAllSettlements(): Promise<void> {
    this.isDownloading = true;
    try {
      const { list } = await this.getAllTableDataPromise();
      const downloadTable = list?.map((item) => mapDataToDownloadTableItem(item, this.dropdownStatusFilter.value!));
      this.downloadTable(downloadTable);
    } catch (e) {
      console.error(e);
      this.showErrorModal = true;
    } finally {
      this.isDownloading = false;
      this.cdr.detectChanges();
    }
  }

  private downloadTable(data: any[], fileName?: string): void {
    const excelData = JSON.parse(JSON.stringify(data));
    this.downloadService.exportAsExcel(excelData, fileName ?? 'listado_de_liquidaciones', 'liquidations');
  }
}

//const mapDateToHour = (orderDate: any): string => dayjs(orderDate).format('HH:mm');
const mapApiToDate = (orderDate: any): string => dayjs(orderDate).utc().format('DD/MM/YYYY');
const mapDateToApi = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss[Z]');

// Table
const mapOrderSummaryToTableItem = (item: OrderSummary): OrderSummaryTableItem => ({
  value: item,
  client: item.clientId,
  policyNumber: item.policeId,
  orderNumber: item.order.id,
  nis: item.proceeding.id,
  billNumber: item?.bill?.billNumber ?? '',
  billDate: item?.bill?.dateBill ? mapApiToDate(item.bill.dateBill) : '',
  amount: item.bill.billAmount ?? null,
  state: item.status.status
});
const mapSettlementToTableItem = (item: Settlement): SettlementTableItem => {
  const tempBatchId = item.orders ? addThousandSeparators(item.orders[0]) : null;
  return {
    value: item,
    client: item.clientId,
    batchId: item.batchId ?? tempBatchId,
    liquidationDate: item.endDate
      ? mapApiToDate(item.startDate) + ' - ' + mapApiToDate(item.endDate)
      : item.startDate
      ? mapApiToDate(item.startDate)
      : '',
    amount: item.amount.amount ?? null,
    state: 'LIQUIDATED'
  };
};
const mapDataToTableItem = (item: OrderSummary | Settlement, status: SettlementStatus): SettlementTableItem | OrderSummaryTableItem =>
  status === SETTLEMENT.STATUS.PENDING ? mapOrderSummaryToTableItem(item as OrderSummary) : mapSettlementToTableItem(item as Settlement);
const mapDataToDownloadTableItem = (item: OrderSummary | Settlement, status: SettlementStatus): DownloadTableItem => {
  const { value, client, ...downloadTableItem } = mapDataToTableItem(item, status);
  return { client: CLIENT_ID_MAP[client as OrderSummaryClientId], ...downloadTableItem };
};
export type OrderSummaryTableItem = {
  value: OrderSummary;
  client: string;
  policyNumber: string;
  orderNumber: string;
  nis: string;
  billNumber: string;
  billDate: string;
  amount: number | null;
  state: string;
};
export type SettlementTableItem = {
  value: Settlement;
  client: string;
  batchId: string | null;
  liquidationDate: string;
  amount: number | null;
  state: string;
};
export type DownloadTableItem = Omit<OrderSummaryTableItem | SettlementTableItem, 'value'>;

// TableSummary
const mapOrderToTableSummaryItem = ({ clientId, bill }: OrderSummary): TableSummaryItem => ({
  name: CLIENT_ID_MAP[clientId as OrderSummaryClientId],
  amount: bill?.billAmount ?? 0,
  value: clientId
});
const mapSettlementToTableSummaryItem = ({ clientId, amount }: Settlement): TableSummaryItem => ({
  name: CLIENT_ID_MAP[clientId as SettlementClientId],
  amount: amount?.amount ?? 0,
  value: clientId
});
const TABLE_SUMMARY_CONFIG: Record<SettlementStatus, TableSummaryConfig> = {
  pending: {
    title: 'Total pendiente de liquidar',
    subtitle: 'Importe total a liquidar',
    prefix: 'Pendiente de liquidar por',
    currency: '€',
    data: []
  } as TableSummaryConfig,
  closed: {
    title: 'Total liquidado',
    subtitle: 'Importe total liquidado',
    prefix: 'Liquidado por',
    currency: '€',
    data: []
  } as TableSummaryConfig
};
const CLIENT_ID_MAP: Record<OrderSummaryClientId | SettlementClientId, string> = {
  ['IG']: 'Iris Global',
  ['SL']: 'Santalucía'
};
export type Tag<T> = { id: string; prefix: string; label: string; showClose: boolean; value: T };
