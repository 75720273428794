/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undefined */
import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlValueAccessorConnector } from '../abstract-control-value-accesor-connector';

const PROVIDERS = [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownComponent),
    multi: true
  }
];

@Component({
  selector: 'sl-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: PROVIDERS
})
export class DropdownComponent<T> extends FormControlValueAccessorConnector<T> {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() helperText = '';

  @Input() options: DropdownOption<T>[] = [];
  @Input() name = '';

  @Input() size = 0;
  @Input() filter = false;
  @Input() multiple = false; // TODO: multiple

  get virtualScroll(): boolean {
    return Boolean(this.size);
  }

  onValueChange(): void {
    this.valueChange.emit(this.value);
  }

  trackByFn(index: number, option: DropdownOption<T>): string {
    return option.label;
  }
}

export interface DropdownOption<T = any> {
  label: string;
  value: T | null;
  icon?: string;
}
