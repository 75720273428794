/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { NavigationService } from '@sl/services';
/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FooterComponent, HeaderComponent, SidebarMenuComponent } from '@sl/layout';
import { SharedModule } from '@sl/shared';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
  standalone: true,
  imports: [SharedModule, HeaderComponent, FooterComponent, SidebarMenuComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutSidebarComponent {
  showSidebar = true;

  constructor(private readonly navigationService: NavigationService) {}

  onRouteChange(route: string): void {
    this.navigationService.navigate({ route });
  }

  onBurgerMenuClick(): void {
    this.showSidebar = !this.showSidebar;
  }
}
