import { IOrderBillOrder } from './orderBillOrder.model.interface';
import { OrderBillOrderStatus } from './orderBillOrderStatus.model';

export class OrderBillOrder implements IOrderBillOrder {
  readonly orderNumber: string;
  readonly proNumber: string;
  readonly comments: string;
  readonly selfCheckingId: string;
  readonly status: OrderBillOrderStatus;

  constructor(data: IOrderBillOrder) {
    this.orderNumber = data.orderNumber;
    this.proNumber = data.proNumber;
    this.comments = data.comments;
    this.selfCheckingId = data.selfCheckingId;
    this.status = new OrderBillOrderStatus(data.status);
  }
}
