import { IOrderSummaryBillEntity } from './orderSummaryBill.entity.interface';

export class OrderSummaryBillEntity implements IOrderSummaryBillEntity {
  readonly billId: string | null;
  readonly billAmount: string | null;
  readonly dateBill: string | null;
  readonly billNumber: string | null;

  constructor(data: IOrderSummaryBillEntity) {
    this.billId = data.billId;
    this.billAmount = data.billAmount;
    this.dateBill = data.dateBill;
    this.billNumber = data.billNumber;
  }
}
