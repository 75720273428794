/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { ProviderData } from '../../domain/providerData.model';
import { ProviderDataEntity } from '../entities/providerData.entity';
import { ProviderContactMapper } from './providerContact.mapper';
import { IProviderDataMapper } from './providerData.mapper.interface';
import { ProviderIdentificationMapper } from './providerIdentification.mapper';

@Injectable({ providedIn: 'root' })
export class ProviderDataMapper implements IProviderDataMapper {
  constructor(
    private readonly providerContactMapper: ProviderContactMapper,
    private readonly providerIdentificationMapper: ProviderIdentificationMapper
  ) {}

  mapFromApi(apiData: ProviderDataEntity): ProviderData {
    return new ProviderData({
      ...apiData,
      identification: apiData.identification.map((i) => this.providerIdentificationMapper.mapFromApi(i)),
      contacts: apiData.contacts.map((c) => this.providerContactMapper.mapFromApi(c))
    });
  }

  mapToApi(provider: ProviderData): ProviderDataEntity {
    return {
      ...provider,
      identification: provider.identification.map((i) => this.providerIdentificationMapper.mapToApi(i)),
      contacts: provider.contacts.map((c) => this.providerContactMapper.mapToApi(c))
    };
  }
}
