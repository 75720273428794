import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sl-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessModalComponent implements OnInit, OnDestroy {
  @Input() successMessage!: string;
  @Output() closeModal = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
  }

  ngOnDestroy(): void {
    document.body.style.overflow = 'auto';
  }

  onCloseModal() {
    this.closeModal.emit();
  }
}
