/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlaceOption } from '@sl/components';
import { Place } from '@sl/features';
import { AuthService, NavigationService, PlaceStore } from '@sl/services';
import { ROUTES, SharedModule } from '@sl/shared';
import { filter } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-place-selector',
  templateUrl: './place-selector.component.html',
  styleUrls: ['./place-selector.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceSelectorComponent implements OnInit {
  @Input() titleColor = 'var(--color-white)';
  @Input() activeColor = 'var(--color-type5)';

  @Output() readonly placeChange = new EventEmitter<Place[] | null>();
  @Output() readonly closeSession = new EventEmitter<boolean>();

  optionAll: PlaceOption<Place[]> | null = null;
  placeOptions: PlaceOption<Place>[] = [];
  placesOption: Place[] | null = null;
  places: Place[] | null = null;

  modalConfirmAllPlaces = {
    show: false,
    title: 'Cambio de centro',
    subtitle: 'Todos los centros',
    description: 'El cambio de centro implica el acceso únicamente al entorno de trabajo de ese centro.'
  };
  modalConfirmPlace = {
    show: false,
    title: 'Cambio de centro',
    subtitle: '',
    description: 'El cambio de centro implica el acceso únicamente al entorno de trabajo de ese centro.'
  };
  // modalRemovePlace = {
  //   show: false,
  //   title: 'Cambio de centro',
  //   subtitle: '',
  //   description: 'El cambio de centro implica el acceso únicamente al entorno de trabajo de ese centro.'
  // };

  constructor(
    private readonly authService: AuthService,
    private readonly placeStore: PlaceStore,
    private readonly navigationService: NavigationService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initPlaceOptions();
    this.initPlaces();
  }

  onPlaceOptionChange(place: Place | Place[] | null): void {
    if (!place) {
      return;
    }

    this.placesOption = Array.isArray(place) ? place : [place];

    if (this.placesOption.length === 0) {
      this.showModalConfirmAllPlaces();
    }

    if (this.placesOption.length === 1) {
      this.showModalConfirmPlace(this.placesOption[0]);
    }

    // if (this.placesOption.length > 1) {
    //   this.showModalConfirmAllPlaces();
    // }

    // When multiple
    // const addingPlace = places.find((p) => !this.placesOption?.some((option) => option === p));
    // const removingPlace = this.placesOption?.find((p) => !places.some((option) => option === p));
    //
    // if (addingPlace) {
    //   this.showModalConfirmPlace(addingPlace);
    // }
    // if (removingPlace) {
    //   this.showModalRemovePlace(removingPlace);
    // }
  }

  onPlaceChange(): void {
    this.placeChange.emit(this.placesOption);
    this.navigationService.navigate({ route: ROUTES.GLOBAL_POSITION.PATH });
  }

  onCloseSession(): void {
    this.authService.logout();
    this.closeSession.emit(true);
  }

  /** Modal confirm all places */
  onModalConfirmAllPlacesAccept(): void {
    this.closeModalConfirmAllPlaces();
    this.placeStore.updatePlaces({ places: this.placesOption });
    this.onPlaceChange();
  }

  onModalConfirmAllPlacesDismiss(): void {
    this.resetPlaceOption();
    this.closeModalConfirmAllPlaces();
  }

  onModalConfirmAllPlacesClose(): void {
    this.resetPlaceOption();
    this.closeModalConfirmAllPlaces();
  }

  /** Modal confirm place */
  onModalConfirmPlaceAccept(): void {
    this.closeModalConfirmPlace();
    this.placeStore.updatePlaces({ places: this.placesOption });
    this.onPlaceChange();
  }

  onModalConfirmPlaceDismiss(): void {
    this.resetPlaceOption();
    this.closeModalConfirmPlace();
  }

  onModalConfirmPlaceClose(): void {
    this.resetPlaceOption();
    this.closeModalConfirmPlace();
  }

  /** Modal remove place */
  // onModalRemovePlaceAccept(): void {
  //   this.closeModalRemovePlace();
  //   this.placeStore.updatePlaces({ places: this.placesOption });
  //   this.placeChange.emit(this.placesOption);
  // }

  // onModalRemovePlaceDismiss(): void {
  //   this.resetPlaceOption();
  //   this.closeModalRemovePlace();
  // }

  // onModalRemovePlaceClose(): void {
  //   this.resetPlaceOption();
  //   this.closeModalRemovePlace();
  // }

  /** Private methods */
  private resetPlaceOption(): void {
    this.placesOption = this.places;
  }

  private showModalConfirmAllPlaces(): void {
    this.modalConfirmAllPlaces = { ...this.modalConfirmAllPlaces, show: true };
  }

  private closeModalConfirmAllPlaces(): void {
    this.modalConfirmAllPlaces = { ...this.modalConfirmAllPlaces, show: false };
  }

  private showModalConfirmPlace(place: Place): void {
    this.modalConfirmPlace = { ...this.modalConfirmPlace, subtitle: place.description, show: true };
  }

  private closeModalConfirmPlace(): void {
    this.modalConfirmPlace = { ...this.modalConfirmPlace, subtitle: '', show: false };
  }

  // private showModalRemovePlace(place: Place): void {
  //   this.modalRemovePlace = { ...this.modalRemovePlace, subtitle: place.description, show: true };
  // }

  // private closeModalRemovePlace(): void {
  //   this.modalRemovePlace = { ...this.modalRemovePlace, subtitle: '', show: false };
  // }

  private initPlaceOptions(): void {
    this.placeStore
      .selectProviderPlaces()
      .pipe(
        untilDestroyed(this),
        filter(({ providerPlaces, loading, error }) => Boolean(providerPlaces) && !loading && !error)
      )
      .subscribe(({ providerPlaces }) => {
        const optionAllLabel = providerPlaces?.provider.providerName.replace('Sede', '') ?? '';
        this.optionAll = { label: optionAllLabel, value: [] };
        this.placeOptions = this.mapPlacesToPlaceOptions(providerPlaces!.place);
        this.cdr.detectChanges();
      });
  }

  private initPlaces(): void {
    this.placeStore
      .selectPlaces()
      .pipe(
        untilDestroyed(this),
        filter(({ places, loading, error }) => Boolean(places) && !loading && !error)
      )
      .subscribe(({ places }) => {
        this.places = places;
        this.placesOption = places; // sync differents place-selectors
        this.cdr.detectChanges();
      });
  }

  private mapPlacesToPlaceOptions(places: Place[]): PlaceOption<Place>[] {
    return places?.map((place) => ({ label: place.description, value: place })) ?? [];
  }
}
