import { SETTLEMENT } from './../../../../core/features/settlement/domain/enums/settlement.enums';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SharedModule } from '@sl/shared';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Order } from '@sl/features';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-detail-summary',
  templateUrl: './detail-summary.component.html',
  styleUrls: ['./detail-summary.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailSummaryComponent {
  @Input() order: Order | undefined;

  constructor() {}

  get SETTLEMENT(): any {
    return SETTLEMENT;
  }
}
