import { AmsLoggerLevel } from 'ams-core-front';
import { loadLocalAppEnv } from './appEnv';
import { Environment } from './environment.interface';

loadLocalAppEnv(); // Adds the 'appEnv' property if it doesn't exist to work in local
declare const appEnv: Environment;
const MAX_FILE_SIZE_BYTES = appEnv?.files?.maxFileSizeBytes ?? 10 * 1024 * 1024;

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: false,
  // Configuración del módulo de Google Analytics
  analytics: {
    id: 'GTM-T46KGQP'
  },
  // Configuración del módulo de control de errores
  errorHandler: {
    appName: 'santalucia-proveedores-mvp'
  },
  keycloak: {
    config: {
      url: 'https://sso-sldev.santalucia.es/auth',
      realm: 'stl-ig-pvd-cif',
      clientId: 'c5f3f654-904f-11ee-8962-fbf7645f7289'
    },
    grantType: 'client_credentials',
    initOptions: {
      onLoad: 'login-required',
      checkLoginIframe: true,
      pkceMethod: 'S256',
      useNonce: false,
      enableLogging: true
    }
  },
  // Configuración del módulo del logger
  logger: {
    logToLocalStorage: false,
    logToSessionStorage: false,
    logToConsole: true,
    serverLogLevel: AmsLoggerLevel.DEBUG
  },
  // Configuración de archivos de entrada/salida
  files: {
    maxFileSizeBytes: MAX_FILE_SIZE_BYTES
  }
};
