<div class="detail-page__container">
  <div class="detail-page__container__content">
    <p-progressSpinner
      *ngIf="loadingDetail$ | async"
      [style]="{width: '100px', height: '100px'}"
      styleClass="custom-spinner"
      strokeWidth="2"
      animationDuration="1s"
    ></p-progressSpinner>

    <!-- <sl-alert
      *ngIf="billSentAlert.show"
      (close)="billSentAlert.show = false"
      [type]="billSentAlert.type"
      [closable]="billSentAlert.closable"
    >
      {{ billSentAlert.label | transloco }}
    </sl-alert> -->

    <nav class="detail-page__container__content__back" (click)="onButtonBack()" (keydown.enter)="onButtonBack()">
      <i class="pi icon-arrow-left"></i>
      <span>{{'orders.back' | transloco}}</span>
    </nav>

    <sl-detail-summary [order]="order"></sl-detail-summary>

    <aside class="detail-page__container__follow horizontal">
      <sl-order-tracking
        [historyTracking]="order?.orderSummary?.historyTracking"
        [currentStatus]="order?.orderSummary?.status"
      ></sl-order-tracking>
    </aside>

    <section>
      <sl-tab-menu [tabs]="tabs" (tabChange)="onTabChange($event)"></sl-tab-menu>
      <ng-container [ngSwitch]="tab">
        <ng-container *ngSwitchCase="0">
          <sl-tab-detail [order]="order" [orderId]="orderId" (sentBill)="onSentBill()" (modifyOrder)="onModifyOrder()"></sl-tab-detail>
        </ng-container>
        <ng-container *ngSwitchCase="1">
          <sl-tab-notes [order]="order" [orderId]="orderId" (markNotesAsRead)="onMarkNotesAsRead($event)"></sl-tab-notes>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <sl-tab-documents [orderId]="orderId"></sl-tab-documents>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <sl-tab-issues [orderId]="orderId"></sl-tab-issues>
        </ng-container>
      </ng-container>
    </section>
  </div>

  <aside class="detail-page__container__follow vertical">
    <span>{{'orders.follow' | transloco}}</span>
    <sl-order-tracking
      [historyTracking]="order?.orderSummary?.historyTracking"
      [currentStatus]="order?.orderSummary?.status"
    ></sl-order-tracking>
  </aside>
</div>

<sl-error-modal
  *ngIf="showErrorModal"
  [errorMessage]="'errorModal.message' | transloco"
  (closeModal)="showErrorModal = false"
></sl-error-modal>
