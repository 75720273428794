/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { environment } from '@sl/environment';
import { DocumentDetail, Order } from '@sl/features';
// import { Document as ApiDocument, DocumentDetail, Order } from '@sl/features';
import { SharedModule } from '@sl/shared';
import { getFileContent, trackBy } from '@sl/utils';
import { DOCUMENT } from 'src/app/core/features/document/domain/enums/document.enum';
import { OrderConcept } from 'src/app/core/features/order/domain/orderConcept.model';

@Component({
  selector: 'sl-modal-modify-bill',
  templateUrl: './modal-modify-bill.component.html',
  styleUrls: ['./modal-modify-bill.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalModifyBillComponent implements OnChanges {
  @ViewChild('billingFileInput') billingFileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('suppliedFileInput') suppliedFileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('generalFileInput') generalFileInput!: ElementRef<HTMLInputElement>;

  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() order: Order | undefined;

  @Output() readonly closeModal = new EventEmitter();
  @Output() readonly sendBill = new EventEmitter<ModifyBillData>();

  billingDocuments: Document[] = [];
  suppliedDocuments: Document[] = [];
  generalDocuments: Document[] = [];
  billingDocument: Document | null = null;
  suppliedDocument: Document | null = null;
  generalDocument: Document | null = null;

  // Inputs
  dropdownSuppliedDocumentTypes = {
    label: 'dropdown-document-type.label',
    placeholder: 'dropdown-document-type.placeholder',
    showArrow: true,
    disabled: true,
    options: ['supplied'],
    value: 'supplied' as string | null
  };
  dropdownGeneralDocumentTypes = {
    label: 'dropdown-document-type.label',
    placeholder: 'dropdown-document-type.placeholder',
    showArrow: true,
    options: ['JUSTIFICANTE', 'CORRESPONDENCIA', 'PRESUPUESTO', 'INFORME_TECNICO', 'OTROS_DOCUMENTOS', 'FOTOS']
  };
  observations = {
    label: 'modal.observations',
    placeholder: 'Escribe aquí',
    maxLength: 200,
    value: null as string | null
  };

  // Constrains
  maxFileSizeBytes = environment.files.maxFileSizeBytes; // 10MB
  maxFileSizeMB = this.maxFileSizeBytes / 1024 / 1024;
  allowedTypeFiles = [
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  showFileError = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['order']?.currentValue !== changes['order']?.previousValue) {
      this.initBillingDocuments();
      this.initSuppliedDocuments();
      // this.getDocuments();
      this.cdr.detectChanges();
    }
  }

  // Billing Documents
  async onBillingFileInput(event: any): Promise<void> {
    event.preventDefault();
    const files: File[] = event.length ? Array.from(event) : event.target.files;
    const file = files[0];
    this.showFileError = false;

    if (!this.isAllowedFile(file)) {
      this.showFileError = true;
      return;
    }

    const content: string = await this.getFileContent(file);

    this.billingDocuments = this.billingDocuments.map((doc) =>
      doc.temporalId === this.billingDocument?.temporalId ? { ...doc, file, content } : doc
    );
    this.billingDocument = null;
    this.cdr.detectChanges();
  }

  onButtonAddBillingFileClick(document: Document): void {
    this.billingDocument = { ...document };
    this.billingFileInput.nativeElement.click();
  }

  onButtonDeleteBillingFileClick(document: Document): void {
    this.billingDocuments = this.billingDocuments.map((doc) =>
      doc.temporalId === document.temporalId ? { ...doc, file: null, content: null } : doc
    );
  }

  // Supplied Documents
  async onSuppliedFileInput(event: any): Promise<void> {
    event.preventDefault();
    const files: File[] = event.length ? Array.from(event) : event.target.files;
    const file = files[0];
    this.showFileError = false;

    if (!this.isAllowedFile(file)) {
      this.showFileError = true;
      return;
    }

    const content: string = await this.getFileContent(file);

    this.suppliedDocuments = this.suppliedDocuments.map((doc) =>
      doc.temporalId === this.suppliedDocument?.temporalId ? { ...doc, file, content } : doc
    );
    this.suppliedDocument = null;
    this.cdr.detectChanges();
  }

  onButtonAddSuppliedFileClick(document: Document): void {
    this.suppliedDocument = { ...document };
    this.suppliedFileInput.nativeElement.click();
  }

  onButtonDeleteSuppliedDocumentClick(document: Document): void {
    this.suppliedDocuments = this.suppliedDocuments.map((doc) =>
      doc.temporalId === document.temporalId ? { ...doc, file: null, content: null } : doc
    );
  }

  // General Documents
  onButtonAddGeneralDocumentClick(): void {
    const newDocument: Document = {
      temporalId: generateId(),
      type: null,
      file: null,
      content: null
    };
    this.generalDocuments = [...this.generalDocuments, newDocument];
  }

  onDropdownGeneralDocumentTypeChange(document: Document, value: string): void {
    this.generalDocuments = this.generalDocuments.map((doc) => (doc.temporalId === document.temporalId ? { ...doc, type: value } : doc));
  }

  async onGeneralFileInput(event: FileList | any): Promise<void> {
    event.preventDefault();
    const files: File[] = event.length ? Array.from(event) : event.target.files;
    const file = files[0];
    this.showFileError = false;

    if (!this.isAllowedFile(file)) {
      this.showFileError = true;
      return;
    }

    const content: string = await this.getFileContent(file);

    this.generalDocuments = this.generalDocuments.map((doc) =>
      doc.temporalId === this.generalDocument?.temporalId ? { ...doc, file, content } : doc
    );
    this.generalDocument = null;
    this.cdr.detectChanges();
  }

  onButtonAddGeneralFileClick(document: Document): void {
    this.generalDocument = { ...document };
    this.generalFileInput.nativeElement.click();
  }

  onButtonDeleteGeneralDocumentClick(document: Document): void {
    this.generalDocuments = this.generalDocuments.filter(({ temporalId }) => document.temporalId !== temporalId);
  }

  // Observations
  onTextAreaObservationsEnter(): void {
    this.handleSendBill();
  }

  // Action Buttons
  onButtonSendClick(): void {
    this.handleSendBill();
  }

  onButtonDeleteClick(): void {
    this.observations.value = '';
  }

  disableButtonSend(): boolean {
    return (
      this.billingDocuments.some((data) => data.file === null || !data.type) ||
      /* this.suppliedDocuments.some((data) => data.file === null || !data.type) || */
      this.generalDocuments.some((data) => data.file === null || !data.type) ||
      !this.observations.value
    );
  }

  // Shared
  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  onCloseModal(): void {
    this.closeModal.emit();
  }

  // Api
  // private getDocuments(): void {
  //   this.apiClientService.getDocuments(this.order?.orderSummary.order.id).subscribe({
  //     next: (documents) => {
  //       this.billingDocuments = documents?.filter(({ type }) => type === 'Factura').map((doc) => mapApiDocumentToDocument(doc)) ?? [];
  //       this.suppliedDocuments = documents?.filter(({ type }) => type === 'Suplido').map((doc) => mapApiDocumentToDocument(doc)) ?? [];
  //       if (!this.billingDocuments.length) {
  //         this.initBillingDocuments();
  //       }
  //     }
  //   });
  // }

  // Initialization
  private initBillingDocuments(): void {
    const billingDocument: Document = {
      temporalId: generateId(),
      type: 'FACTURA',
      file: null,
      content: null
    };
    this.billingDocuments = [billingDocument];
  }

  private initSuppliedDocuments(): void {
    this.order?.concepts.forEach((concept) => {
      if (concept.supplied) {
        this.suppliedDocuments = [...this.suppliedDocuments, mapConceptToDocument(concept)];
      }
    });
  }

  // Utils
  private isAllowedFile(file: File): boolean {
    return file?.size <= this.maxFileSizeBytes && this.allowedTypeFiles.includes(file?.type);
  }

  private handleSendBill(): void {
    const allDocuments = [...this.billingDocuments, ...this.suppliedDocuments, ...this.generalDocuments].filter((doc) => doc.file);
    const billData: ModifyBillData = {
      documentsWithoutIds: allDocuments.map((doc) => mapDocumentToDocumentDetail(doc)),
      comments: this.observations.value ?? ''
    };
    this.sendBill.emit(billData);
    this.closeModal.emit();
  }

  private async getFileContent(file: File): Promise<string> {
    const content = await getFileContent(file);
    return content.split(',')[1]; // Just the content after de ',' character
  }
}

const generateId = (): string => {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 1000000);
  return `${timestamp}-${random}`;
};
const mapConceptToDocument = (concept: OrderConcept): Document => ({
  temporalId: generateId(),
  type: concept.supplied ? 'supplied' : null,
  file: null,
  content: null
});
const mapDocumentToDocumentDetail = (doc: Document): DocumentDetail => ({
  documentId: '',
  name: doc.file!.name,
  type: doc.type! === 'supplied' ? DOCUMENT.TYPE.SUPLIDO : doc.type!,
  mimeType: doc.file!.type,
  content: doc.content!,
  date: new Date(),
  origin: DOCUMENT.ORIGIN.PROVEEDOR
});

// const mapApiDocumentToDocument = (doc: ApiDocument): Document => ({
//   temporalId: generateId(),
//   type: doc.type,
//   file: null,
//   content: null
// });

type Document = {
  temporalId: string | null;
  type: string | null;
  file: File | null;
  content: string | null;
};

export type ModifyBillData = {
  documentsWithoutIds: DocumentDetail[];
  comments: string;
};
