import { IOrderSummaryProceeding } from './orderSummaryProceeding.model.interface';

export class OrderSummaryProceeding implements IOrderSummaryProceeding {
  readonly id: string;
  readonly date: Date | null;

  constructor(data: IOrderSummaryProceeding) {
    this.id = data.id;
    this.date = data.date ? new Date(data.date) : null;
  }
}
