/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AlertType } from '@sl/components';
import { DocumentDetail, ISSUE, Order, OrderBill, OrderBillFile, OrderNote, OrderNoteNote } from '@sl/features';
import { ApiClientService, NavigationService, SpinnerService } from '@sl/services';
import { SharedModule } from '@sl/shared';
import { trackBy } from '@sl/utils';
import dayjs from 'dayjs';
import { filter, firstValueFrom, Observable } from 'rxjs';
import { OrderSummaryStatusStatus } from 'src/app/core/features/order/domain/enums/orderSummaryStatus.enum';
import { ORIGIN_REQUEST } from 'src/app/core/features/orderBill/domain/enums/orderPostBill.enums';
import { MessageSource } from 'src/app/core/features/orderNote/domain/enums/message.enum';
import { OrderNoteNoteMessage } from 'src/app/core/features/orderNote/domain/orderNoteNoteMessage.model';
import { OrderNoteNoteMessageDoc } from 'src/app/core/features/orderNote/domain/orderNoteNoteMessageDoc.model';
import { IssueStore } from 'src/app/core/services/stores/issue.store';
import {
  ModalModifyBillComponent,
  ModalModifyOrderComponent,
  ModalSendBillComponent,
  ModifyBillData,
  ModifyOrderData,
  SendBillData
} from '..';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-tab-detail',
  templateUrl: './tab-detail.component.html',
  styleUrls: ['./tab-detail.component.scss'],
  standalone: true,
  imports: [SharedModule, ModalModifyOrderComponent, ModalSendBillComponent, ModalModifyBillComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabDetailComponent implements OnChanges {
  @Input() order?: Order | undefined;
  @Input() orderId: string = this.navigationService.getParam('orderId');

  @Output() sentBill = new EventEmitter<boolean>();
  @Output() modifyOrder = new EventEmitter<boolean>();

  issues$ = this.issueStore.selectIssues();
  personalData?: PersonalData;

  successMessage!: string;
  showSuccessModal = false;

  showModal: 'modifyOrder' | 'bill' | 'modifyBill' | '' = '';
  loadingDetail$!: Observable<boolean>;
  showErrorModal = false;
  totalAmount: number | undefined;

  isDisableBilling = true;
  alertDisableBilling = {
    label: 'order-detail-alert.cannotSendBillAlert',
    type: 'warning' as AlertType,
    show: false,
    closable: false
  };

  OrderSummaryStatusStatus = OrderSummaryStatusStatus;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly navigationService: NavigationService,
    private readonly apiClientService: ApiClientService,
    private readonly issueStore: IssueStore,
    readonly spinnerService: SpinnerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['order']?.currentValue !== changes['order']?.previousValue) {
      this.initPersonalData();
      this.totalAmount = this.getTotalAmount();
      this.checkDisableBilling();
    }
  }

  ngOnInit(): void {
    if (!this.order) {
      this.getOrderDetail();
    }
  }

  onModifyOrderClick(): void {
    this.handleShowModal('modifyOrder');
  }

  onSendBillClick(): void {
    this.handleShowModal('bill');
  }

  onModifyBillClick(): void {
    this.handleShowModal('modifyBill');
  }

  async onModalSendBill({ documentsWithoutIds, comments }: SendBillData): Promise<void> {
    await this.handleSendBill({ documentsWithoutIds, comments });
  }

  async onSendModifyOrder({ documentsWithoutIds, comments }: ModifyOrderData): Promise<void> {
    await this.handleModifyOrder({ documentsWithoutIds, comments });
  }

  async onSendModifyBill({ documentsWithoutIds, comments }: ModifyBillData): Promise<void> {
    await this.handleSendBill({ documentsWithoutIds, comments });
  }

  onCloseModal(): void {
    this.showModal = '';
    this.enableScroll();
  }

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  // Private
  private handleShowModal(name: typeof this.showModal): void {
    this.showModal = name;
    this.disableScroll();
  }

  private disableScroll(): void {
    document.body.style.overflow = 'hidden';
  }

  private enableScroll(): void {
    document.body.style.overflow = 'auto';
  }

  // Api
  private getOrderDetail(): void {
    this.loadingDetail$ = this.spinnerService.getLoadingObservable(this.orderId);
    this.apiClientService.getOrder(this.orderId).subscribe({
      next: (order) => {
        this.order = order;
        this.cdr.detectChanges();
      },
      error: () => (this.showErrorModal = true)
    });
  }

  private postOrderBills(orderBill: OrderBill): void {
    this.loadingDetail$ = this.spinnerService.getLoadingObservable('bills');
    this.apiClientService.postOrderBills(this.orderId, orderBill).subscribe({
      next: () => {
        this.successMessage = 'billMessage';
        this.showSuccessModal = true;
        this.sentBill.emit(true);
        this.cdr.detectChanges();
      },
      error: () => {
        this.showErrorModal = true;
        this.cdr.detectChanges();
      }
    });
  }

  private getTotalAmount(): number | undefined {
    return this.order?.concepts.reduce((acc, concept) => acc + parseFloat(concept.totalAmount.toFixed(2)), 0);
  }

  /* private async postDocumentPromise(documentDetail: DocumentDetail): Promise<any> {
    this.loadingDetail$ = this.spinnerService.getLoadingObservable('documents');
    return await firstValueFrom(this.apiClientService.postDocument(this.orderId, documentDetail));
  } */

  private async postOrderNotesPromise(orderNote: OrderNote): Promise<any> {
    this.loadingDetail$ = this.spinnerService.getLoadingObservable('notes');
    return await firstValueFrom(this.apiClientService.postOrderNotes(this.orderId, orderNote));
  }

  // Initialization
  private initPersonalData(): void {
    if (this.order) {
      this.personalData = mapOrderToPersonalData(this.order);
    }
  }

  private checkDisableBilling(): void {
    this.issues$
      .pipe(
        untilDestroyed(this),
        filter(({ issues, loading, error }) => {
          if (error) {
            this.isDisableBilling = true;
            this.alertDisableBilling.show = true;
            this.showErrorModal = true;
            this.cdr.detectChanges();
          }
          return Boolean(issues) && !loading;
        })
      )
      .subscribe(({ issues }) => {
        const isOpenIssues = issues!.filter((issue) => issue.status === ISSUE.STATUS.OPEN).length > 0;
        this.isDisableBilling = isOpenIssues;
        this.alertDisableBilling.show = isOpenIssues;
        this.cdr.detectChanges();
      });
  }

  // Utils
  private async handleSendBill({ documentsWithoutIds, comments }: SendBillData | ModifyBillData): Promise<void> {
    try {
      //const billFiles: OrderBillFile[] = await Promise.all(documentsWithoutIds.map(async (doc) => await this.processPostDocument(doc)));
      const billFiles: OrderBillFile[] = documentsWithoutIds.map((docWithoutId) => mapDocumentDetailToOrderBillFile(docWithoutId));

      const orderBill: OrderBill = {
        originRequest: ORIGIN_REQUEST.PROVEEDOR,
        claim: { idNumber: '' },
        order: {
          orderNumber: this.orderId,
          proNumber: this.order?.orderSummary.placeId ?? '',
          comments,
          selfCheckingId: '',
          status: {
            code: this.order?.orderSummary.status.status ?? '',
            reasons: [{ code: '', description: '' }]
          }
        },
        billFiles
      };

      this.postOrderBills(orderBill);
    } catch (e) {
      console.error(e);
      this.showErrorModal = true;
      return;
    }
  }

  private async handleModifyOrder({ documentsWithoutIds, comments }: ModifyOrderData): Promise<void> {
    try {
      const docList: OrderNoteNoteMessageDoc[] = documentsWithoutIds.map((doc) => mapDocumentDetailToOrderNoteNoteMessageDoc(doc));

      const orderNote: OrderNote = {
        originRequest: ORIGIN_REQUEST.PROVEEDOR,
        order: {
          providerId: this.order?.orderSummary.placeId ?? '',
          orderId: this.orderId
        },
        note: [
          new OrderNoteNote({
            noteId: null,
            message: new OrderNoteNoteMessage({
              messageId: null,
              readDate: null,
              creationDate: new Date(),
              read: true,
              content: comments,
              docList,
              type: 'NOTA-GENERAL',
              subject: 'subject',
              source: MessageSource.PROFESIONAL,
              sender: null
            })
          })
        ]
      };

      await this.postOrderNotesPromise(orderNote);
      this.modifyOrder.emit(true);
    } catch (e) {
      console.error(e);
      this.showErrorModal = true;
      return;
    }
  }

  //Uncomment if it is necessary to separate documents from bill documents
  /* private async processPostDocument(docWithoutId: DocumentDetail): Promise<OrderBillFile> {
    let result;
    if (docWithoutId.type === 'FACTURA') {
      // If type 'FACTURA', postdocument instead is made in the postbill request
      // then this item should have no 'id' and the content set in the 'metadata' property
      const billFileWithContentAndNoId: OrderBillFile = mapDocumentDetailToOrderBillFile(docWithoutId);
      result = { ...billFileWithContentAndNoId };
    } else {
      const docWithId: Document = await this.postDocumentPromise(docWithoutId);
      const billFileWithoutContent: OrderBillFile = mapDocumentToOrderBillFile(docWithId);
      // At the moment, the date that comes with postDocument is broken so previous docWithoutId.date replace it here
      result = { ...billFileWithoutContent, creationDate: docWithoutId.date };
    }
    return result;
  } */
}

const mapOrderToPersonalData = ({ orderSummary }: Order): PersonalData => ({
  insuredId: orderSummary?.insuredId,
  activity: orderSummary?.activity,
  orderDate: mapApiToDate(orderSummary?.order.date),
  proceedingDate: mapApiToDate(orderSummary?.proceeding.date)
});
const mapDocumentDetailToOrderBillFile = (doc: DocumentDetail): OrderBillFile => ({
  docId: doc.documentId ?? '',
  docName: doc.name,
  docType: doc.type, // ? doc.mimeType
  creationDate: doc.date,
  metadata: doc.content
});
/* const mapDocumentToOrderBillFile = (doc: Document): OrderBillFile => ({
  docId: doc.documentId ?? '',
  docName: doc.name,
  docType: doc.type, // ? doc.mimeType
  creationDate: doc.date,
  metadata: ''
}); */
const mapDocumentDetailToOrderNoteNoteMessageDoc = (doc: DocumentDetail): OrderNoteNoteMessageDoc => ({
  docId: doc.documentId,
  docName: doc.name,
  docType: doc.type,
  creationDate: doc.date,
  metadata: doc.content
});
const mapApiToDate = (orderDate: any): string => dayjs(orderDate).utc().format('DD/MM/YYYY');
//const mapDatetoUTCFormat = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
// const mapDateToApi = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DD');
// const mapDateToHour = (orderDate: any): string => dayjs(orderDate).format('HH:mm');

export type PersonalData = {
  insuredId: string | null;
  activity: string | null;
  orderDate: string | null;
  proceedingDate: string | null;
};
