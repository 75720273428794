<div class="notification-card__container" [style.background]="notifications > 0 ? 'var(--color-primary11)' : 'var(--color-grey7)'">
  <div class="notification-card__container__top">
    <div class="notification-card__container__top__notifications">
      <div
        class="notification-card__container__top__icon"
        [style.background]="notifications > 0 ? (type > 3 ? 'var(--color-primary6)' : 'var(--color-type5)') : 'var(--color-grey9)'"
      >
        <i
          class="pi"
          [ngClass]="{ 'icon-notification-simple': type > 3, 'icon-fuera-plazo': type <= 3 }"
          [style.color]="notifications > 0 ? (type > 3 ? 'var(--color-white)' : 'var(--color-primary7)') : 'var(--color-grey2)'"
        ></i>
        <div *ngIf="notifications > 0 && type > 3" class="notification-card__container__top__icon__circle">
          {{ notifications }}
        </div>
      </div>
      <div
        *ngIf="type <= 3"
        [style.color]="notifications > 0 ? 'var(--color-primary7)' : 'var(--color-grey9)'"
        class="notification-card__container__top__notifications__number"
      >
        {{ notifications }}
      </div>
    </div>
    <span *ngIf="notifications > 0" (click)="navigateToLink(volumetryId)" (keypress)="navigateToLink(volumetryId)"
      ><i class="pi icon-arrow-right"></i
    ></span>
  </div>
  <span [style.color]="notifications > 0 ? 'var(--color-black)' : 'var(--color-grey10)'">{{
    'notificationCard.title.' + volumetryId | transloco
  }}</span>
  <p [style.color]="notifications > 0 ? 'var(--color-grey2)' : 'var(--color-grey11)'">
    {{ 'notificationCard.subtitle.' + volumetryId | transloco }}
  </p>
</div>
