/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderNoteNote } from '../../domain/orderNoteNote.model';
import { OrderNoteNoteEntity } from '../entities/orderNoteNote.entity';
import { IOrderNoteNoteMapper } from './orderNoteNote.mapper.interface';
import { OrderNoteNoteMessageMapper } from './orderNoteNoteMessage.mapper';

@Injectable({ providedIn: 'root' })
export class OrderNoteNoteMapper implements IOrderNoteNoteMapper {
  constructor(private readonly orderNoteNoteMessageMapper: OrderNoteNoteMessageMapper) {}

  mapFromApi(apiData: OrderNoteNoteEntity): OrderNoteNote {
    return new OrderNoteNote({ noteId: apiData.noteId, message: this.orderNoteNoteMessageMapper.mapFromApi(apiData.message) });
  }

  mapToApi(note: OrderNoteNote): OrderNoteNoteEntity {
    return new OrderNoteNoteEntity({ noteId: note.noteId, message: this.orderNoteNoteMessageMapper.mapToApi(note.message) });
  }
}
