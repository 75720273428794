import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sl-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorModalComponent implements OnInit, OnDestroy {
  @Input() errorMessage!: string;
  @Output() closeModal = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
  }

  ngOnDestroy(): void {
    document.body.style.overflow = 'auto';
  }

  onCloseModal() {
    this.closeModal.emit();
  }
}
