import { IOrderNoteNoteMessageEntity } from './orderNoteNoteMessage.entity.interface';
import { OrderNoteNoteMessageDocEntity } from './orderNoteNoteMessageDoc.entity';

export class OrderNoteNoteMessageEntity implements IOrderNoteNoteMessageEntity {
  readonly messageId: string | null;
  readonly readDate: string | null;
  readonly creationDate: string;
  readonly read: boolean;
  readonly content: string;
  readonly docList: OrderNoteNoteMessageDocEntity[] | null;
  readonly type: string | null;
  readonly subject: string;
  readonly source: string;

  constructor(data: IOrderNoteNoteMessageEntity) {
    this.messageId = data.messageId;
    this.readDate = data.readDate;
    this.creationDate = data.creationDate;
    this.read = data.read;
    this.content = data.content;
    this.docList = data.docList ? data.docList.map((doc) => new OrderNoteNoteMessageDocEntity(doc)) : null;
    this.type = data.type;
    this.subject = data.subject;
    this.source = data.source;
  }
}
