import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ROUTES } from '@sl/constants';
import { AuthService, NavigationService, PlaceStore, User } from '@sl/services';
import { filter } from 'rxjs';

/**
 * Componente de demostración de arquitectura empresarial de Santalucía. Implementa algunas de las funcionalidades que proporciona
 * la librería ams-core-front integrada en el prototipo de aplicación generado desde ams-front-schematics, como son:
 *
 *  - El servicio de demostración para hacer llamadas al API de Santalucía (ApiService) - amsHttpClientService.get,
 *    amsHttpClientService.post... Este servicio de demo incluye la gestión de cache para llamadas HTTP
 *  - El servicio para escribir entradas en el Logger (AmsLoggerService) - amsLoggerService.info, amsLoggerService.warn...
 *  - El servicio para ver las entradas en el Logger (AmsLoggerViewService) - amsLoggerViewService.logMessages$
 *  - El servicio para ver los errores registrados en la aplicación (AmsErrorHandlerService) - amsErrorHandlerService.errors$
 *  - El servicio para la gestión de las analíticas (AmsAnalyticsService) - amsAnalyticsService.pushTag
 *  - El servicio para la gestión de keycloak (AmsKeycloakService) - amsKeycloakService.keycloakEvents$,
 *    amsKeycloakService..getKeycloakInstance()...
 *
 * Además se incorporan ejemplos de gestión de las suscripciones o uso de la estrategia de detención OnPush:
 *
 *  - @UntilDestroy (https://github.com/ngneat/until-destroy#readme)
 *  - AsyncPipe (https://angular.io/api/common/AsyncPipe)
 *  - ChangeDetectionStrategy (https://angular.io/api/core/ChangeDetectionStrategy)
 *    changeDetection: ChangeDetectionStrategy.OnPush, ChangeDetectorRef.detectChanges();
 *
 * ----------------------------------------------------------------------------------------------------------------
 * ---------------------- SE DEBE ELIMINAR TODO EL CÓDIGO DE EJEMPLO QUE NO SEAN NECESARIO ------------------------
 * ----------------------------------------------------------------------------------------------------------------
 *
 * @author Arquitectura Empresarial - Santalucía
 * @exports
 * @class AppComponent
 * @typedef {AppComponent}
 * @implements {OnDestroy}
 */
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnDestroy {
  showErrorModal = false;

  constructor(
    private readonly authService: AuthService,
    private readonly placeStore: PlaceStore,
    private readonly navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.initApp();
  }

  initApp(): void {
    this.navigationService.navigate({ route: ROUTES.GLOBAL_POSITION.PATH });
    this.authService.user$.pipe(filter((user): user is User => Boolean(user))).subscribe((user) => {
      if (!user.username) {
        this.showErrorModal = true;
        return;
      }
      this.placeStore.actionGetProviderPlaces({ cif: user.username });
    });
  }

  ngOnDestroy(): void {
    this.authService.logout();
  }
}
