import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@sl/app';
import { environment } from '@sl/environment';
import { AmsConsoleUtilsService } from 'ams-core-front';

// Sólo en el entorno de producción
if (environment.production) {
  enableProdMode();

  // Se deshabilitan los métodos log en consola y los alerts
  AmsConsoleUtilsService.overrideAlertMethods();
  AmsConsoleUtilsService.overrideConsoleMethods();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    providers: [{ provide: LOCALE_ID, useValue: 'es-ES' }]
  })
  .catch((err: any) => console.error(err));
