/* eslint-disable @typescript-eslint/naming-convention */
export type DocumentOrigin = 'PROVEEDOR' | 'AGENTE';
export type DocumentType = 'SUPLIDO' | 'CORRESPONDENCIA' | string;

export const DOCUMENT = {
  ORIGIN: {
    PROVEEDOR: 'PROVEEDOR' as DocumentOrigin,
    AGENTE: 'AGENTE' as DocumentOrigin
  },
  TYPE: {
    SUPLIDO: 'SUPLIDO' as DocumentType,
    CORRESPONDENCIA: 'CORRESPONDENCIA' as DocumentType
  }
};
