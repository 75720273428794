/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ITranslateService } from '.';

// The language follows BCP 47 format
export type BCP47 = string;

export const APP_DEFAULT_LANGUAGE: BCP47 = 'es-ES' as const;
export const APP_SUPPORTED_LANGUAGES: readonly BCP47[] = [APP_DEFAULT_LANGUAGE] as const;

@Injectable({ providedIn: 'root' })
export class TranslateService implements ITranslateService {
  private _language$ = new BehaviorSubject<BCP47>(APP_DEFAULT_LANGUAGE);
  readonly language$ = this._language$.asObservable();

  // TODO: Adapt transloco in this service
  constructor(private readonly transloco: TranslocoService) {
    this.iniLanguage();
  }

  getLanguages(): string[] {
    return this.transloco.getAvailableLangs() as string[];
  }

  getActiveLang(): string {
    return this.transloco.getActiveLang();
  }

  setActiveLang(lang: string): void {
    this.setLanguage(lang);
    this.transloco.setActiveLang(lang);
  }

  setLanguage(language: BCP47): void {
    this._language$.next(language);
  }

  getLanguage(): BCP47 {
    return this._language$.getValue();
  }

  translate(key: string): Observable<string> {
    console.log('TODO: Translate:', key);
    return of(key);
    // return this.transloco.selectTranslate(key);
  }

  instant(key: string): string {
    console.log('TODO: Translate instant:', key);
    return key;
    // return this.transloco.translate<string>(key);
  }

  private iniLanguage(): void {
    const navigatorLanguage = navigator.language;
    const defaultLanguage = this.isSupportedLanguage(navigatorLanguage) ? navigatorLanguage : APP_DEFAULT_LANGUAGE;
    this._language$.next(defaultLanguage);
  }

  private isSupportedLanguage(lang?: string): lang is typeof APP_SUPPORTED_LANGUAGES[number] {
    return APP_SUPPORTED_LANGUAGES.includes(lang as typeof APP_SUPPORTED_LANGUAGES[number]);
  }
}
