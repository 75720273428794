/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AlertType } from '@sl/components';
import { Document, DocumentDetail, ISSUE } from '@sl/features';
import { ApiClientService, NavigationService, SpinnerService } from '@sl/services';
import { SharedModule } from '@sl/shared';
import { removeDiacritics, toTitleCase, trackBy } from '@sl/utils';
import dayjs from 'dayjs';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { DOCUMENT } from 'src/app/core/features/document/domain/enums/document.enum';
import { GetDocumentsFilter } from 'src/app/core/features/document/infrastructure/repositories/document.repository.interface';
import { IssueStore } from 'src/app/core/services/stores/issue.store';
import { ModalAddDocumentsComponent, SendDocumentsData } from '..';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-tab-documents',
  templateUrl: './tab-documents.component.html',
  styleUrls: ['./tab-documents.component.scss'],
  standalone: true,
  imports: [SharedModule, ModalAddDocumentsComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabDocumentsComponent {
  @Input() orderId: string = this.navigationService.getParam('orderId');

  private _documents$ = new BehaviorSubject<Document[] | null>(null);
  documents$ = this._documents$.asObservable();

  issues$ = this.issueStore.selectIssues();

  table: DocumentTableItem[] = [];
  tableFiltered: DocumentTableItem[] = [];

  tab = 2;

  loadingDetail$!: Observable<boolean>;
  showSuccessDocumentModal = false;
  showErrorModal = false;

  // Features
  modalAddDocuments = {
    title: 'modal.documentTitle',
    subtitle: 'modal.documentSubtitle',
    show: false
  };

  isSendDocumentsDisabled = true;
  alertDisableSendDocuments = {
    label: 'order-detail-alert.cannotSendDocumentsAlert',
    type: 'warning' as AlertType,
    show: false,
    closable: false
  };

  // Filters
  searchByWord = {
    icon: 'pi icon-buscar',
    placeholder: 'Buscar por...',
    value: null as string | null
  };

  // Api params
  documentParams: GetDocumentsFilter = {
    orderId: this.orderId
  };

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly navigationService: NavigationService,
    private readonly apiClientService: ApiClientService,
    private readonly translocoService: TranslocoService,
    private readonly issueStore: IssueStore,
    readonly spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.getTableData();
    this.initTable();
    this.checkDisableSendDocuments();
  }

  // Filters
  onSearchByWord(): void {
    if (this.searchByWord.value) {
      this.tableFiltered = this.table.filter((doc) =>
        Object.values(doc).some((item) => removeDiacritics(item?.toLowerCase() ?? '').includes(this.searchByWord.value!.toLowerCase()))
      );
    } else {
      this.tableFiltered = [...this.table];
    }
  }

  // Features
  onNewDocumentClick(): void {
    this.modalAddDocuments.show = true;
    this.disableScroll();
  }

  onCloseModal(): void {
    this.modalAddDocuments.show = false;
    this.enableScroll();
  }

  async onSendDocuments({ documentsWithoutIds }: SendDocumentsData): Promise<void> {
    await this.handleSendDocuments({ documentsWithoutIds });
  }

  // Util
  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  /* Private */
  private disableScroll(): void {
    document.body.style.overflow = 'hidden';
  }

  private enableScroll(): void {
    document.body.style.overflow = 'auto';
  }

  private getTableData(): void {
    // const from = this.dateFilter.startDate ? mapDateToApi(this.dateFilter.startDate) : null;
    // const to = this.dateFilter.endDate ? mapDateToApi(this.dateFilter.endDate) : null;
    // this.documentParams = { ...this.documentParams, from, to };
    this.documentParams = { ...this.documentParams };
    this.getDocuments(this.documentParams);
  }

  // Api
  private getDocuments(documentFilter?: GetDocumentsFilter): void {
    this.loadingDetail$ = this.spinnerService.getLoadingObservable('documents');
    this.apiClientService.getDocuments(documentFilter).subscribe({
      next: (response) => {
        this._documents$.next(response);
        this.cdr.detectChanges();
      },
      error: () => {
        this.showErrorModal = true;
        this._documents$.next(null);
        this.cdr.detectChanges();
      }
    });
  }

  private postDocument(documentBody: DocumentDetail): void {
    this.loadingDetail$ = this.spinnerService.getLoadingObservable('documents');
    this.apiClientService.postDocument(this.orderId, documentBody).subscribe({
      next: (doc) => {
        doc.origin ??= DOCUMENT.ORIGIN.PROVEEDOR;
        this.addDocument(doc);
        this.showSuccessDocumentModal = true;
        this.cdr.detectChanges();
      },
      error: () => (this.showErrorModal = true)
    });
  }

  // Initialization
  private initTable(): void {
    this.documents$.subscribe((documents) => {
      this.table = documents?.map((doc) => this.mapDocumentToDocumentTableItem(doc)) ?? [];
      this.tableFiltered = [...this.table];
      this.cdr.detectChanges();
    });
  }

  private checkDisableSendDocuments(): void {
    this.issues$
      .pipe(
        untilDestroyed(this),
        filter(({ issues, loading, error }) => {
          if (error) {
            this.isSendDocumentsDisabled = true;
            this.alertDisableSendDocuments.show = true;
            this.showErrorModal = true;
            this.cdr.detectChanges();
          }
          return Boolean(issues) && !loading;
        })
      )
      .subscribe(({ issues }) => {
        const isOpenIssues = issues!.filter((issue) => issue.status === ISSUE.STATUS.OPEN).length > 0;
        this.isSendDocumentsDisabled = isOpenIssues;
        this.alertDisableSendDocuments.show = isOpenIssues;
        this.cdr.detectChanges();
      });
  }

  private addDocument(document: Document): void {
    this.table = [this.mapDocumentToDocumentTableItem(document), ...this.table];
    this.tableFiltered = [...this.table];
    this.cdr.detectChanges();
  }

  // Utils
  private async handleSendDocuments({ documentsWithoutIds }: SendDocumentsData): Promise<void> {
    try {
      await Promise.all(documentsWithoutIds.map(async (doc) => await this.postDocument(doc)));
    } catch (e) {
      console.error(e);
      this.showErrorModal = true;
      return;
    }
  }

  private mapDocumentToDocumentTableItem = (doc: Document): DocumentTableItem => ({
    type: this.translocoService.translate('docType.' + doc.type).replace('docType.', ''),
    documentNumber: doc.documentId,
    description: doc.name,
    origin: doc.origin ? toTitleCase(doc.origin) : '',
    creationDate: doc.date ? mapApiToDate(doc.date) : '',
    hour: doc.date ? mapDateToHour(doc.date) : ''
  });
}

const mapApiToDate = (orderDate: any): string => dayjs(orderDate).utc().format('DD/MM/YYYY');
const mapDateToHour = (orderDate: any): string => dayjs(orderDate).utc().format('HH:mm');

type DocumentTableItem = {
  type: string;
  documentNumber: string;
  description: string;
  creationDate: string;
  hour: string;
  origin: string | null;
};

//  type DocumentTableItem = {
//   documentId: string | null;
//   name: string | null;
//   date: string | null;
//   hour: string | null;
//   origin: string | null;
// };
