<sl-dialog
  [modal]="dialog.modal!"
  [showHeader]="dialog.showHeader!"
  [showFooter]="dialog.showFooter!"
  [title]="title!"
  [subtitle]="subtitle!"
  [(visible)]="visible!"
  (visibleChange)="onVisibleChange($event)"
>
  <!-- <div header class="header">Custom Header</div> -->
  <!-- <div sticky class="content--sticky" style="border: 1px solid blue">contenido sticky</div> -->
  <div content class="content">
    <ng-container [ngTemplateOutlet]="EditForm"></ng-container>
  </div>
  <div footer class="footer">
    <ng-container [ngTemplateOutlet]="FooterActions"></ng-container>
  </div>
</sl-dialog>

<ng-template #EditForm>
  <div class="modal__content__container">
    <ng-container *ngIf="sectionTitle === 'area'">
      <div *ngFor="let areaDescription of descriptionsAreas">
        <h4>{{ areaDescription | titlecase }}</h4>
        <div *ngFor="let areaData of areaEditedData">
          <ng-container *ngIf="areaData.description === areaDescription && areaData.typeContact === 'EMAIL'">
            <p class="modal__content__input__label">
              {{ 'add-modal.email' | transloco }}
            </p>
            <input class="modal__content__input" [(ngModel)]="areaData.value" />
          </ng-container>
          <ng-container *ngIf="areaData.description === areaDescription && areaData.typeContact === 'PHONE'">
            <p class="modal__content__input__label">
              {{ 'add-modal.phone' | transloco }}
            </p>
            <input class="modal__content__input" [(ngModel)]="areaData.value" />
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="sectionTitle !== 'area'">
      <div *ngFor="let key of editedDataKeys">
        <ng-container *ngIf="key !== 'iban' && key !== 'bankAccount'">
          <p class="modal__content__input__label">
            {{ 'columns-profile.' + key | transloco }}
          </p>
          <input class="modal__content__input" [(ngModel)]="editedData[key]" />
        </ng-container>

        <ng-container *ngIf="key === 'iban' || key === 'bankAccount'">
          <span class="modal__content__input__label">
            {{ 'columns-profile.' + key | transloco }}
          </span>
          <div class="iban-wrapper">
            <ng-container *ngFor="let key of objectKeys(ibanData)">
              <input
                type="text"
                [ngClass]="{ invalid: !isLengthEqualTo(ibanData[key], key !== 'account' ? 4 : 12) }"
                [maxlength]="key !== 'account' ? 4 : 12"
                class="modal__content__input"
                (ngModelChange)="setAllowedIban(key, $event)"
                [(ngModel)]="ibanData[key]"
              />
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #FooterActions>
  <div *ngIf="document" class="document-content">
    <span *ngIf="!allowedDocument">{{ 'edit-modal.documentError' | transloco }}</span>
    <div class="document-content__file">
      <div *ngIf="selectedFile" class="document-content__file__approval">
        <i class="pi icon-check"></i>
        <span>{{ selectedFile.name }}</span>
        <i class="pi icon-eliminar" (click)="deleteDocument()" (keypress)="deleteDocument()"></i>
      </div>

      <div class="document-content__file__upload">
        <i class="pi icon-info1"></i>
        <span (click)="uploadDocument()" (keypress)="uploadDocument()">{{ 'edit-modal.document' | transloco }}</span>
        <i class="pi icon-download"></i>
        <input #fileInput type="file" (change)="handleDocument($event)" style="display: none" />
      </div>
    </div>
  </div>
  <div class="modal__content__button__container">
    <!--<button class="modal__content__button" (click)="onSaveData()" type="button">
      {{ 'edit-modal.save' | transloco }}
    </button>-->
    <sl-button
      (clicked)="onSaveData()"
      [disabled]="!allowedDocument || !allowedIban"
      [label]="'edit-modal.save' | transloco"
      [borderRound]="true"
      [type]="'confirm'"
    ></sl-button>
  </div>
</ng-template>
