export * from './localStorage.store';
export * from './localStorage.store.interface';
export * from './notification.store';
export * from './notification.store.interface';
export * from './place.store';
export * from './place.store.interface';
export * from './provider.store';
export * from './provider.store.interface';
export * from './statistics.store';
export * from './statistics.store.interface';
export * from './volumetry.store';
export * from './volumetry.store.interface';

/**
 * This folder contains the global state of the application.
 * Implements classes for centralizing and maintaining application data and logic.
 *
 * Each store act as a single source of truth for the app.
 *
 */
