import { IssueEntity } from './../../features/issue/infrastructure/entities/issue.entity';
import { IssueMapper } from './../../features/issue/infrastructure/mappers/issue.mapper';
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Issue } from '@sl/features';
import { ApiService, PlaceStore, QueryParams } from '@sl/services';
import { BehaviorSubject, firstValueFrom, Observable, throwError } from 'rxjs';
import { GetIssuesEntityResponse, GetIssuesResponse } from '../../features/issue/infrastructure/repositories/issue.repository.interface';
import { GetIssuesParams, IIssueStore, IssuesState, PutIssueParams } from './issue.store.interface';

const PREFIX = '/api/v1' as const;

const initialStateIssues: IssuesState = {
  issues: null,
  error: null,
  loading: false
};

@Injectable({ providedIn: 'root' })
export class IssueStore implements IIssueStore {
  private readonly _issues$ = new BehaviorSubject<IssuesState>(initialStateIssues);
  readonly issues$ = this._issues$.asObservable();
  private readonly _sidebarIssues$ = new BehaviorSubject<IssuesState>(initialStateIssues);
  readonly sidebarIssues$ = this._sidebarIssues$.asObservable();

  constructor(
    private readonly apiService: ApiService,
    private readonly placeStore: PlaceStore,
    private readonly issueMapper: IssueMapper
  ) {}

  /** Selectors */

  selectIssues(): Observable<IssuesState> {
    return this.issues$;
  }

  getIssues(): Issue[] | null {
    return this._issues$.getValue().issues;
  }

  selectSidebarIssues(): Observable<IssuesState> {
    return this.sidebarIssues$;
  }

  getSidebarIssues(): Issue[] | null {
    return this._sidebarIssues$.getValue().issues;
  }

  /* Reducer */

  updateIssues(newState: Partial<IssuesState>): void {
    const currentState = this._issues$.getValue();
    this._issues$.next({ ...currentState, ...newState });
  }

  updateSidebarIssues(newState: Partial<IssuesState>): void {
    const currentState = this._issues$.getValue();
    this._sidebarIssues$.next({ ...currentState, ...newState });
  }

  /** Actions */

  actionGetIssues(params?: GetIssuesParams, paramIssue?: boolean): void {
    this.updateIssues({ loading: true, error: null });

    const providerId = params?.providerId ?? this.placeStore.getProviderId();
    const issueFilter = { placeId: this.placeStore.getPlaceId(), ...params?.issueFilter };

    this.apiService
      .get<GetIssuesEntityResponse>({
        url: `${PREFIX}/providers/${providerId}/places/issues`,
        queryParams: issueFilter as unknown as QueryParams
      })
      .subscribe({
        next: (issues) => {
          const update = { loading: false, issues: [...issues].map((i) => this.issueMapper.mapFromApi(i)) };
          this.updateIssues(update);
          if (paramIssue) {
            this.updateSidebarIssues(update);
          }
        },
        error: (error) => {
          this.updateIssues({ loading: false, error });
          if (paramIssue) {
            this.updateSidebarIssues({ loading: false, error });
          }
          this.handleError(error);
        }
      });
  }

  actionPutIssue(params: PutIssueParams): void {
    this.updateIssues({ loading: true, error: null });

    const providerId = params?.providerId ?? this.placeStore.getProviderId();
    const issueId = params?.issue.id ?? params?.issueId;
    const issue = params?.issue;
    const issueEntity = this.issueMapper.mapToApi(params?.issue);

    this.apiService
      .put<IssueEntity>({
        url: `${PREFIX}/providers/${providerId}/places/issues/${issueId}`,
        body: issueEntity
      })
      .subscribe({
        next: () => {
          const issues = this.getIssues()?.map((n) => (n.id === issueId ? { ...n, ...issue } : n));
          this.updateIssues({ loading: false, issues: issues ? [...issues] : null });
        },
        error: (error) => this.updateIssues({ loading: false, error })
      });
  }

  async actionGetIssuesPromise(params?: GetIssuesParams): Promise<GetIssuesResponse> {
    const providerId = params?.providerId ?? this.placeStore.getProviderId();
    const issueFilter = { placeId: this.placeStore.getPlaceId(), ...params?.issueFilter };

    try {
      const issues = await firstValueFrom(
        this.apiService.get<GetIssuesEntityResponse>({
          url: `${PREFIX}/providers/${providerId}/places/issues`,
          queryParams: issueFilter as unknown as QueryParams
        })
      );
      return issues.map((i) => this.issueMapper.mapFromApi(i));
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async actionPutIssuePromise(params: PutIssueParams): Promise<void> {
    const providerId = params?.providerId ?? this.placeStore.getProviderId();
    const issueId = params?.issue.id ?? params?.issueId;
    const issueEntity = this.issueMapper.mapToApi(params?.issue);

    try {
      return await firstValueFrom(
        this.apiService.put<IssueEntity>({
          url: `${PREFIX}/providers/${providerId}/places/issues/${issueId}`,
          body: issueEntity
        })
      );
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  /** Effects */

  /** Utils */

  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    return throwError(() => new Error(error));
  }
}
