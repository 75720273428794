/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';

import { AlertType, TabMenu } from '@sl/components';
import { ISSUE, Order } from '@sl/features';
import { ApiClientService, NavigationService, SpinnerService } from '@sl/services';
import { SharedModule } from '@sl/shared';

import { GetIssuesFilter } from 'src/app/core/features/issue/infrastructure/repositories/issue.repository.interface';
import { IssueStore } from 'src/app/core/services/stores/issue.store';
import {
  DetailSummaryComponent,
  OrderTrackingComponent,
  TabDetailComponent,
  TabDocumentsComponent,
  TabIssuesComponent,
  TabNotesComponent
} from '../../components';

const TAB = {
  DETAIL: { LABEL: 'detalle' },
  NOTES: { LABEL: 'notas' },
  DOCUMENTS: { LABEL: 'documentos' },
  ISSUES: { LABEL: '2ª Comunicaciones' }
};

// TODO: Extract components from here

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
  standalone: true,
  imports: [
    SharedModule,
    OrderTrackingComponent,
    DetailSummaryComponent,
    TabDetailComponent,
    TabDocumentsComponent,
    TabIssuesComponent,
    TabNotesComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailPageComponent implements OnInit {
  issues$ = this.issueStore.selectIssues();

  // * NAVEGATION
  tabs: TabMenu[] = [
    {
      label: TAB.DETAIL.LABEL,
      num: 0
    },
    {
      label: TAB.NOTES.LABEL,
      num: 0
    },
    {
      label: TAB.DOCUMENTS.LABEL,
      num: 0
    },
    {
      label: TAB.ISSUES.LABEL,
      num: 0
    }
  ];
  tab = 0;

  // * SHARED
  orderId = this.navigationService.getParam('orderId');
  order?: Order;

  showErrorModal = false;
  billIssue = false;

  billSentAlert = {
    label: 'profile-alert.communicationDetail',
    type: 'success' as AlertType,
    show: false,
    closable: true
  };

  loadingDetail$!: Observable<boolean>;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly navigationService: NavigationService,
    private readonly apiClientService: ApiClientService,
    private readonly issueStore: IssueStore,
    private readonly spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.getOrderDetail();
    this.getIssues(); // Needed for the num indicator in tab menu
    this.initTabIssues();
  }

  onModifyOrder(): void {
    const tabNotes = this.tabs.findIndex((tab) => tab.label === TAB.NOTES.LABEL);
    this.navigateToTab(tabNotes);
  }

  onSentBill(): void {
    this.billSentAlert.show = true;
    this.getOrderDetail();
  }

  onMarkNotesAsRead(notesReaded: number): void {
    const { unreadedNotes } = this.order?.orderSummary ?? {};
    if (unreadedNotes) {
      this.initTabNotes(unreadedNotes - notesReaded);
    }
  }

  // * NAVEGATION
  onTabChange(tab: number): void {
    this.navigateToTab(tab);
  }

  onButtonBack(): void {
    this.navigationService.navigateBack();
  }

  // Api
  private getOrderDetail(): void {
    this.loadingDetail$ = this.spinnerService.getLoadingObservable(this.orderId);
    this.apiClientService.getOrder(this.orderId).subscribe({
      next: (order) => {
        this.order = order;
        this.initTabNotes(order.orderSummary.unreadedNotes);
        this.cdr.detectChanges();
      },
      error: () => (this.showErrorModal = true)
    });
  }

  private getIssues(issueFilter?: GetIssuesFilter): void {
    this.issueStore.actionGetIssues({ issueFilter: { orderId: this.orderId, ...issueFilter } });
  }

  // Initialization
  private initTabIssues(): void {
    this.issues$
      .pipe(
        untilDestroyed(this),
        filter(({ issues, loading, error }) => Boolean(issues) && !loading && !error)
      )
      .subscribe(({ issues }) => {
        const openIssuesCount = issues!.filter((c) => c.status === ISSUE.STATUS.OPEN).length;
        this.tabs = this.tabs.map((tab) => (tab.label === TAB.ISSUES.LABEL ? { ...tab, num: openIssuesCount } : tab));
        this.cdr.detectChanges();
      });
  }

  private initTabNotes(unreadedNotes: number): void {
    this.tabs = this.tabs.map((tab) => (tab.label === TAB.NOTES.LABEL ? { ...tab, num: unreadedNotes } : tab));
  }

  // Navigation
  private navigateToTab(tab: number): void {
    this.tab = tab;
  }
}
