<article class="tab-container">
  <header class="tab-container__top">
    <span class="tab-container__top__title">{{ 'notes.title' | transloco }}</span>

    <div class="tab-container__top__note">
      <button *ngIf="!search" class="tab-container__top__note__item" type="button" (click)="onSearchClose()" (keypress)="onSearchClose()">
        <i class="pi icon-buscar"></i>
        <span>{{ 'notes.search' | transloco }}</span>
      </button>
      <div
        *ngIf="search"
        class="tab-container__top__note__item__search"
        [ngClass]="{ 'tab-container__top__note__item__search--focus': focusInput }"
        (focusin)="focusInput = true"
        (focusout)="focusInput = false"
      >
        <div class="tab-container__top__note__item__search__left">
          <i class="pi icon-cerrar" (click)="onSearchClick()" (keyup)="onSearchClick()"></i>
          <input [(ngModel)]="searchWord" placeholder="Buscar por palabra" />
        </div>

        <div class="tab-container__top__note__item__search__right">
          <span *ngIf="this.messagesPosition.length > 0" class="tab-container__top__note__item__search__right__count">
            {{ actualPosition + '/' + this.messagesPosition.length }}
          </span>
          <i class="pi icon-arrow-up" (click)="onSearchArrowUpClick()" (keypress)="onSearchArrowUpClick()"></i>
          <i class="pi icon-arrow-down" (click)="onSearchArrowDownClick()" (keypress)="onSearchArrowDownClick()"></i>
        </div>
      </div>

      <button class="tab-container__top__note__item" type="button" (click)="onMarkAllAsReadClick()" (keypress)="onMarkAllAsReadClick()">
        <i class="pi icon-check"></i>
        <span>{{ 'notes.mark' | transloco }}</span>
      </button>

      <button class="tab-container__top__note__item" type="button" (click)="onDownloadNotesClick()" (keypress)="onDownloadNotesClick()">
        <i class="pi icon-download"></i>
        <span>{{ 'notes.download' | transloco }}</span>
      </button>
    </div>
  </header>

  <section #scrollMe class="messages-container" #messagesContainer>
    <ng-container>
      <ng-container *ngFor="let note of notes; trackBy: trackByFn">
        <sl-message
          *ngIf="note.message.content"
          [message]="note.message"
          [searchWord]="searchWord"
          (messagePosition)="onMessagePosition($event)"
          (markMessageAsRead)="onMarkMessageAsRead($event)"
        ></sl-message>
      </ng-container>
    </ng-container>
  </section>

  <section class="note__add">
    <span *ngIf="!allowedFile && selectedFile === null" class="docInfoError"> -{{ 'modal.fileError' | transloco }} </span>
    <!-- Uncomment when attach document is avaible-->
    <!-- <div class="note__add__upload">
      <sl-dropdown-filter
        filterOptions.selected1
        [filterName]="'Tipo de adjunto'"
        [showArrow]="true"
        [placeholder]="'filter.documentPlaceholder' | transloco"
        [dropdownOptions]="documentDropdownOptions"
        [(value)]="documentType"
        (valueChange)="onDocumentTypeChange()"
      ></sl-dropdown-filter>

      <div class="note__add__upload__rigth">
        <input #uploadButton type="file" style="display: none" (click)="uploadButton.value = ''" (input)="onNoteDocumentChange($event)" />
        <sl-button [slTooltip]="['communications.modal.files']" [type]="'link'" [icon]="'info1'" (click)="onUploadNoteDocumentClick()">
          {{ selectedFile ? selectedFile.name : ('modal.upload' | transloco | titlecase) }}
        </sl-button>
        <button
          *ngIf="!selectedFile"
          class="button-container__side__upload"
          type="button"
          (click)="onUploadNoteDocumentClick()"
          (keypress)="onUploadNoteDocumentClick()"
        >
          <i class="pi icon-upload"></i>
        </button>
        <button
          *ngIf="selectedFile"
          class="button-container__side__upload"
          type="button"
          (click)="onDeleteNoteDocumentClick()"
          (keypress)="onDeleteNoteDocumentClick()"
        >
          <i class="pi icon-eliminar"></i>
        </button>
      </div>
    </div> -->

    <sl-textarea
      [label]="'modal.newNote' | transloco"
      placeholder="Escribe aquí"
      maxlength="200"
      [(value)]="newNoteText"
      (keydownEnter)="onSendMessageClick()"
      (valueChange)="onNewNoteTextValueChange()"
    ></sl-textarea>

    <sl-alert
      *ngIf="alertDisableSendMessage.show"
      (close)="alertDisableSendMessage.show = false"
      [type]="alertDisableSendMessage.type"
      [closable]="alertDisableSendMessage.closable"
    >
      {{ alertDisableSendMessage.label | transloco }}
    </sl-alert>

    <div class="button-container">
      <sl-button [borderRound]="true" [type]="'secondary'" [size]="'l'" (click)="onDeleteMessageClick()"> {{ 'Borrar' }} </sl-button>
      <aside class="button-container__side">
        <input #uploadButton type="file" (click)="uploadButton.value = ''" (input)="onNoteDocumentChange($event)" style="display: none" />
        <sl-button (clicked)="onSendMessageClick()" [type]="'primary'" [borderRound]="true" [disabled]="isSendMessageDisabled" [size]="'l'">
          {{ 'button.send' | transloco }}
        </sl-button>
      </aside>
    </div>
  </section>

  <p-progressSpinner
    *ngIf="spinnerService.getLoadingObservable('notes') | async"
    [style]="{ width: '100px', height: '100px' }"
    styleClass="custom-spinner"
    strokeWidth="2"
    animationDuration="1s"
  ></p-progressSpinner>
</article>

<sl-error-modal
  *ngIf="showErrorModal"
  [errorMessage]="'errorModal.message' | transloco"
  (closeModal)="showErrorModal = false"
></sl-error-modal>
