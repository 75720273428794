import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISpinnerService } from './spinner.service.interface';

/** Spinner management service */
@Injectable({ providedIn: 'root' })
export class SpinnerService implements ISpinnerService {
  requestCount = 0;
  private isLoadingSubjects: Record<string, BehaviorSubject<boolean>> = {};

  getLoadingObservable(key: string): Observable<boolean> {
    if (!this.isLoadingSubjects[key]) {
      this.isLoadingSubjects[key] = new BehaviorSubject<boolean>(false);
    }
    return this.isLoadingSubjects[key].asObservable();
  }

  startLoading(key: string): void {
    if (key === 'documents') {
      this.startOrStopDocumentSubject(key, true);
    } else {
      this.getOrCreateSubject(key).next(true);
    }
  }

  endLoading(key: string): void {
    if (key === 'documents') {
      this.startOrStopDocumentSubject(key, false);
    } else {
      this.getOrCreateSubject(key).next(false);
    }
  }

  private getOrCreateSubject(key: string): BehaviorSubject<boolean> {
    if (!this.isLoadingSubjects[key]) {
      this.isLoadingSubjects[key] = new BehaviorSubject<boolean>(false);
    }
    return this.isLoadingSubjects[key];
  }

  private startOrStopDocumentSubject(key: string, loading: boolean): void {
    if (!this.isLoadingSubjects[key]) {
      this.isLoadingSubjects[key] = new BehaviorSubject<boolean>(false);
      this.requestCount = 1;
      this.isLoadingSubjects[key].next(true);
    } else {
      this.increaseCountOrStop(key, loading);
    }
  }

  private increaseCountOrStop(key: string, loading: boolean) {
    if (loading) {
      if (this.requestCount === 0) {
        this.isLoadingSubjects[key].next(true);
      }
      this.requestCount += 1;
    } else {
      this.requestCount -= 1;
      if (this.requestCount === 0) {
        this.isLoadingSubjects[key].next(false);
      }
    }
  }
}
