<sl-button-filter [(visible)]="visible">
  <form class="container">
    <div class="content">
      <ng-container [ngTemplateOutlet]="Section1"></ng-container>
      <ng-container [ngTemplateOutlet]="Section2"></ng-container>
      <ng-container [ngTemplateOutlet]="Section3"></ng-container>
    </div>
    <div class="footer">
      <ng-container [ngTemplateOutlet]="Footer"></ng-container>
    </div>
  </form>
</sl-button-filter>

<ng-template #Section1>
  <fieldset class="fieldset">
    <legend class="fieldset__legend">{{ section1.legend }}</legend>
    <div class="fieldset__content">
      <sl-input
        [label]="inputPolicy.label"
        [placeholder]="inputPolicy.placeholder"
        [(value)]="inputPolicy.value"
        (valueChange)="onInputPolicy()"
      ></sl-input>
      <sl-input
        [label]="inputOrder.label"
        [placeholder]="inputOrder.placeholder"
        [(value)]="inputOrder.value"
        (valueChange)="onInputOrder()"
      ></sl-input>
      <sl-input
        [label]="inputSinister.label"
        [placeholder]="inputSinister.placeholder"
        [(value)]="inputSinister.value"
        (valueChange)="onInputSinister()"
      ></sl-input>
      <sl-input
        [label]="inputBill.label"
        [placeholder]="inputBill.placeholder"
        [(value)]="inputBill.value"
        (valueChange)="onInputBill()"
      ></sl-input>
    </div>
  </fieldset>
</ng-template>

<ng-template #Section2>
  <fieldset class="fieldset">
    <legend class="fieldset__legend">{{ section2.legend }}</legend>
    <div class="fieldset__content fieldset__content--1fr">
      <sl-input
        [label]="inputNameAndSurname.label"
        [placeholder]="inputNameAndSurname.placeholder"
        [(value)]="inputNameAndSurname.value"
        (valueChange)="onInputNameAndSurname()"
      ></sl-input>
    </div>
  </fieldset>
</ng-template>

<ng-template #Section3>
  <fieldset class="fieldset">
    <legend class="fieldset__legend">{{ section3.legend }}</legend>
    <div class="fieldset__content">
      <sl-dropdown
        [label]="dropdownClient.label"
        [placeholder]="dropdownClient.placeholder"
        [options]="dropdownClient.options"
        [(value)]="dropdownClient.value"
        (valueChange)="onDropdownClient()"
      ></sl-dropdown>
    </div>
  </fieldset>
</ng-template>

<ng-template #Footer>
  <sl-button [buttonType]="'submit'" [type]="'secondary'" [size]="'s'" (click)="handleRemoveFilters()">
    {{ 'button.remove' | transloco | titlecase }}
  </sl-button>
  <sl-button [buttonType]="'submit'" [type]="'primary'" [size]="'s'" (click)="handleSubmitFilters()">
    {{ 'button.accept' | transloco | titlecase }}
  </sl-button>
</ng-template>
