/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ScreenSizeService } from '@sl/services';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'sl-button-filter',
  templateUrl: './button-filter.component.html',
  styleUrls: ['./button-filter.component.scss']
})
export class ButtonFilterComponent implements OnInit, OnChanges {
  @ViewChild('Panel') private readonly _panel!: OverlayPanel;

  @Input() label = 'Filtros';
  @Input() visible = false;
  @Output() readonly visibleChange = new EventEmitter<boolean>();

  dialog = {
    modal: true,
    draggable: false,
    resizable: false
  };

  isMobile$ = this.screenSizeService.isMobile();

  constructor(private screenSizeService: ScreenSizeService) {}

  ngOnInit(): void {
    this.isMobile$.subscribe((isMobile) => {
      this.writeVisible(false);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible']) {
      if (!changes['visible'].currentValue) {
        this.closePopover();
      }
    }
  }

  onVisibleChange(value: boolean): void {
    this.writeVisible(value);
  }

  togglePopover(event: Event): void {
    this.visibleChange.emit(this.visible);
    this._panel.toggle(event);
  }

  openPopover(event: Event): void {
    this._panel.show(event);
  }

  closePopover(): void {
    this._panel?.hide();
  }

  showDialog(visible: boolean): void {
    this.writeVisible(visible);
  }

  private writeVisible(value: boolean): void {
    this.visible = value;
    this.visibleChange.emit(this.visible);
  }
}
