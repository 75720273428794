/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'sl-tab-menu-poc',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabMenuPocComponent {
  @Input() items!: MenuItem[];
  @Input() activeItem!: MenuItem;
  @Input() scrollable = true;

  @Output() readonly activeItemChange = new EventEmitter<MenuItem>();

  constructor() {}

  onActiveItemChange(value: MenuItem): void {
    this.activeItem = value;
    this.activeItemChange.emit(this.activeItem);
  }
}
