/* eslint-disable no-unused-vars */
import { Pipe, PipeTransform } from '@angular/core';
import { stringToNumber } from '../../utils';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {
  transform(value: number | string, precision = 2, separator = '.'): string {
    const factor = 10 ** precision;
    const num = stringToNumber(value.toString()) ?? 0;
    return (Math.round(num * factor) / factor).toFixed(precision).replace('.', separator);
  }
}
