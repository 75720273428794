import { IOrderSummaryEntity } from './orderSummary.entity.interface';
import { OrderSummaryBillEntity } from './orderSummaryBill.entity';
import { OrderSummaryOrderEntity } from './orderSummaryOrder.entity';
import { OrderSummaryProceedingEntity } from './orderSummaryProceeding.entity';
import { OrderSummaryStatusEntity } from './orderSummaryStatus.entity';

export class OrderSummaryEntity implements IOrderSummaryEntity {
  readonly unreadedNotes: number | null;
  readonly activity: string;
  readonly additionalInfo: string;
  readonly affectedName: string;
  readonly bill: OrderSummaryBillEntity;
  readonly clientId: string;
  readonly placeId: string;
  readonly insuredId: string;
  readonly mainDate: string | null;
  readonly order: OrderSummaryOrderEntity;
  readonly policeId: string;
  readonly proceeding: OrderSummaryProceedingEntity;
  readonly status: OrderSummaryStatusEntity;
  readonly historyTracking: OrderSummaryStatusEntity[] | null;
  readonly settlementId: string;

  constructor(data: IOrderSummaryEntity) {
    this.unreadedNotes = data.unreadedNotes;
    this.activity = data.activity;
    this.policeId = data.policeId;
    this.bill = data.bill;
    this.order = data.order;
    this.mainDate = data.mainDate;
    this.status = new OrderSummaryStatusEntity(data.status); // Create a new Object to make sure it is immutable;
    this.proceeding = data.proceeding;
    this.clientId = data.clientId;
    this.placeId = data.placeId;
    this.insuredId = data.insuredId;
    this.affectedName = data.affectedName;
    this.additionalInfo = data.additionalInfo;
    this.historyTracking = data.historyTracking;
    this.settlementId = data.settlementId;
  }
}
