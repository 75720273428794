/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderBillFile } from '../../domain/orderBillFile.model';
import { OrderBillFileEntity } from '../entities/orderBillFile.entity';
import { IOrderBillFileMapper } from './orderBillFile.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderBillFileMapper implements IOrderBillFileMapper {
  constructor() {}

  mapFromApi(apiData: OrderBillFileEntity): OrderBillFile {
    return new OrderBillFile({
      docId: apiData.docId,
      docName: apiData.docName,
      docType: apiData.docType,
      creationDate: new Date(apiData.creationDate),
      metadata: apiData.metadata
    });
  }

  mapToApi(billFile: OrderBillFile): OrderBillFileEntity {
    return new OrderBillFileEntity({
      docId: billFile.docId,
      docName: billFile.docName,
      docType: billFile.docType,
      creationDate: billFile.creationDate.toISOString(),
      metadata: billFile.metadata
    });
  }
}
