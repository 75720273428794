import { IOrder } from './order.model.interface';
import { OrderBilling } from './orderBilling.model';
import { OrderConcept } from './orderConcept.model';
import { OrderSummary } from './orderSummary.model';

export class Order implements IOrder {
  readonly orderSummary: OrderSummary;
  readonly billing: OrderBilling[];
  readonly concepts: OrderConcept[];

  constructor(data: IOrder) {
    this.orderSummary = new OrderSummary(data.orderSummary); // Create a new object to make sure it is immutable
    this.billing = data.billing.map((orderBilling) => new OrderBilling(orderBilling));
    this.concepts = data.concepts.map((orderConcept) => new OrderConcept(orderConcept));
  }
}
