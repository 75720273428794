/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';

import { OrderVolumetry } from '../../domain/orderVolumetry.model';
import { OrderVolumetryEntity } from '../entities/orderVolumetry.entity';
import { IOrderVolumetryMapper } from './orderVolumetry.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderVolumetryMapper implements IOrderVolumetryMapper {
  mapFromApi(apiData: OrderVolumetryEntity): OrderVolumetry {
    return new OrderVolumetry({
      volumetryId: apiData.volumetryId,
      count: apiData.count
    });
  }

  mapToApi(orderVolumetry: OrderVolumetry): OrderVolumetryEntity {
    return {
      volumetryId: orderVolumetry.volumetryId,
      count: orderVolumetry.count
    };
  }
}
