import { IAddress } from './address.model.interface';

export class Address implements IAddress {
  readonly streetName: string;
  readonly postalCode: string;
  readonly provinceName: string;
  readonly cityName: string;
  readonly countryName: string;

  constructor(data: IAddress) {
    this.streetName = data.streetName;
    this.postalCode = data.postalCode;
    this.provinceName = data.provinceName;
    this.cityName = data.cityName;
    this.countryName = data.countryName;
  }
}
