import { IOrderBill } from './orderBill.model.interface';
import { OrderBillClaim } from './orderBillClaim.model';
import { OrderBillFile } from './orderBillFile.model';
import { OrderBillOrder } from './orderBillOrder.model';

export class OrderBill implements IOrderBill {
  readonly originRequest: string;
  readonly claim: OrderBillClaim;
  readonly order: OrderBillOrder;
  readonly billFiles: OrderBillFile[];

  constructor(data: IOrderBill) {
    this.originRequest = data.originRequest;
    this.claim = new OrderBillClaim(data.claim);
    this.order = new OrderBillOrder(data.order);
    this.billFiles = data.billFiles.map((billFile) => new OrderBillFile(billFile)); // Create a new Object to make sure it is immutable;
  }
}
