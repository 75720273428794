/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ScreenSizeService } from '@sl/services';

@Component({
  selector: 'sl-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent {
  @Input() config?: Partial<DialogConfig>;

  @Input() header = this.config?.header ?? '';
  @Input() title = this.config?.title ?? '';
  @Input() subtitle = this.config?.subtitle ?? '';
  @Input() position = this.config?.position ?? 'center';

  @Input() draggable = this.config?.draggable ?? false;
  @Input() maximizable = this.config?.maximizable ?? false;
  @Input() modal = this.config?.modal ?? false;
  @Input() resizable = this.config?.resizable ?? false;
  @Input() visible = this.config?.visible ?? false;

  @Input() showHeader = this.config?.showHeader ?? false;
  @Input() showFooter = this.config?.showFooter ?? false;

  @Input() style = this.config?.style ?? { width: '50vw' };
  @Input() breakpoints = this.config?.breakpoints ?? {};

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onShow = new EventEmitter<boolean>();
  @Output() onHide = new EventEmitter<boolean>();

  constructor(private readonly screenSizeService: ScreenSizeService) {
    const { xs, sm, md, lg, xl, xxl } = this.screenSizeService.getPixelBreakpoints();
    // Order is important
    this.breakpoints = {
      [`${xxl}px`]: '50vw',
      [`${xl}px`]: '50vw',
      [`${lg}px`]: '75vw',
      [`${md}px`]: '75vw',
      [`${sm}px`]: '90vw',
      [`${xs}px`]: '90vw'
    };
  }

  onVisibleChange(visible: boolean): void {
    this.visible = visible;
    this.visibleChange.emit(visible);
  }

  onDialogShow(show: boolean): void {
    this.onShow.emit(show);
  }

  onDialogHide(hide: boolean): void {
    this.onHide.emit(hide);
  }
}

export type DialogConfig = {
  header?: string;
  title?: string;
  subtitle?: string;
  visible?: boolean;
  position?: 'center' | 'left' | 'top' | 'bottom' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
  breakpoints?: { [key: string]: string };
  style?: { [key: string]: string };
  maximizable?: boolean;
  draggable?: boolean;
  resizable?: boolean;
  modal?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
};
