import { IOrderNoteNoteMessageDoc } from './orderNoteNoteMessageDoc.model.interface';

export class OrderNoteNoteMessageDoc implements IOrderNoteNoteMessageDoc {
  readonly docId: string | null;
  readonly docName: string;
  readonly docType: string;
  readonly creationDate: Date;
  readonly metadata: string;

  constructor(data: IOrderNoteNoteMessageDoc) {
    this.docId = data.docId;
    this.docName = data.docName;
    this.docType = data.docType;
    this.creationDate = data.creationDate;
    this.metadata = data.metadata;
  }
}
