import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ProviderPlaces } from '../domain/providerPlaces.model';
import { PlaceRepository } from '../infrastructure/repositories/place.repository';
import { GetPlacesUseCaseParams, IGetPlacesUseCase } from './getPlaces.use-case.interface';

@Injectable({ providedIn: 'root' })
export class GetPlacesUseCase implements IGetPlacesUseCase {
  constructor(private readonly placeRepository: PlaceRepository) {}

  execute({ cif }: GetPlacesUseCaseParams): Observable<ProviderPlaces> {
    // Add additional logic here
    return this.placeRepository.getPlaces({ cif }).pipe(catchError(this.handleError));
  }

  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    return throwError(() => new Error(error));
  }
}
