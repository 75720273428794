import { IOrderSummaryProceedingEntity } from './orderSummaryProceeding.entity.interface';

export class OrderSummaryProceedingEntity implements IOrderSummaryProceedingEntity {
  readonly id: string;
  readonly date: string | null;

  constructor(data: IOrderSummaryProceedingEntity) {
    this.id = data.id;
    this.date = data.date;
  }
}
