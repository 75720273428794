import { ProviderTypeContact } from './enums/provider.enums';
import { IProviderContact } from './providerContact.model.interface';

export class ProviderContact implements IProviderContact {
  readonly typeContact: ProviderTypeContact;
  readonly value: string;
  readonly description: string;

  constructor(data: IProviderContact) {
    this.typeContact = data.typeContact;
    this.value = data.value;
    this.description = data.description;
  }
}
