import { IProviderPlaces } from '../../domain/providerPlaces.model.interface';
import { PlaceEntity } from './place.entity';
import { ProviderEntity } from './provider.entity';
import { IProviderPlacesEntity } from './providerPlaces.entity.interface';

export class ProviderPlacesEntity implements IProviderPlacesEntity {
  readonly provider: ProviderEntity;
  readonly place: PlaceEntity[];

  constructor(data: IProviderPlaces) {
    this.provider = new ProviderEntity(data.provider);
    this.place = data.place.map((place) => new PlaceEntity(place));
  }
}
