import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'sl-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownFilterComponent implements OnInit, OnChanges {
  @Input() showArrow!: boolean;
  @Input() filterName = '';
  @Input() placeholder = '';
  @Input() backgroundColor!: string;
  @Input() dropdownOptions: string[] = [];
  @Input() searchIcon = false;
  @Input() disabled = false;
  @Input() value: string | null = null;

  @Output() readonly valueChange = new EventEmitter<string | null>();

  showDropdown = false;
  translatedOptions: string[] = [];
  rendererUnlistenClick!: () => void;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
    private readonly translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.translateDropdownOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dropdownOptions']) {
      this.translateDropdownOptions();
    }
    if (changes['value']) {
      this.value = this.translate(changes['value'].currentValue);
    }
  }

  toggleDropdown(): void {
    if (!this.disabled) {
      return this.showDropdown ? this.closeDropdown() : this.openDropdown();
    }
  }

  openDropdown(): void {
    this.showDropdown = true;
    this.rendererUnlistenClick = this.renderer.listen('document', 'click', this.handleDocumentClick.bind(this));
    this.cdr.markForCheck();
  }

  closeDropdown(): void {
    this.showDropdown = false;
    this.cdr.markForCheck();
  }

  handleKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.openDropdown();
    }
  }

  onValueChange(): void {
    this.valueChange.emit(this.value);
  }

  private translateDropdownOptions(): void {
    this.translatedOptions = this.dropdownOptions.map((option) =>
      option.includes('all') ? this.translate(`filter.${option}`) : this.translate(option)
    );
  }

  private translate(option: string): string {
    let response = option;
    try {
      response = this.translocoService.translate(option);
    } catch (e) {
      response = option;
    }
    return response;
  }

  // * PRIVATE METHODS
  private handleDocumentClick(event: MouseEvent): void {
    if (this.isClickOutsideFilters(event)) {
      this.closeDropdown();
      this.rendererUnlistenClick();
    }
  }

  private isClickOutsideFilters(event: MouseEvent): boolean {
    const isClickInsideFilter = this.isClickInside(this.el, event);

    return !isClickInsideFilter;
  }

  private isClickInside(ref: ElementRef<HTMLElement>, event: MouseEvent): boolean {
    const { left, right, top, bottom } = ref.nativeElement.getBoundingClientRect();
    const { clientX, clientY } = event;
    return clientX >= left && clientX <= right && clientY >= top && clientY <= bottom;
  }
}
