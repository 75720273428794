import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { FormControlValueAccessorConnector } from '../abstract-control-value-accesor-connector';

// TODO: This component
@Component({
  selector: 'sl-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent extends FormControlValueAccessorConnector<string> {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() name = '';
  @Input() helperText = '';

  @Input() type: InputType = 'text';
  @Input() icon = '';

  constructor(injector: Injector) {
    super(injector);
  }

  onModelChange(value: string): void {
    this.value = value;
    this.valueChange.emit(this.value);
  }
}

export type InputType = 'text' | 'number' | 'password' | 'url' | 'tel' | 'email';
export type EventType = 'input' | 'change' | 'keyup' | 'enter' | 'blur';
