import { Injectable } from '@angular/core';
import { OrderSummaryStatusStatus } from '../../domain/enums/orderSummaryStatus.enum';
import { OrderSummaryStatus } from '../../domain/orderSummaryStatus.model';
import { OrderSummaryStatusEntity } from '../entities/orderSummaryStatus.entity';
import { IOrderSummaryStatusMapper } from './orderSummaryStatus.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderSummaryStatusMapper implements IOrderSummaryStatusMapper {
  mapFromApi(apiData: OrderSummaryStatusEntity): OrderSummaryStatus {
    return new OrderSummaryStatus({
      status: apiData.status as OrderSummaryStatusStatus,
      date: apiData.date ? new Date(apiData.date) : null
    });
  }

  mapToApi(orderGeneralStatus: OrderSummaryStatus): OrderSummaryStatusEntity {
    return new OrderSummaryStatusEntity({
      status: orderGeneralStatus.status,
      date: orderGeneralStatus.date?.toISOString() ?? null
    });
  }
}
