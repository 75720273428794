import { IProvider } from './provider.model.interface';

export class Provider implements IProvider {
  readonly providerId: string;
  readonly providerName: string;

  constructor(data: IProvider) {
    this.providerId = data.providerId;
    this.providerName = data.providerName;
  }
}
