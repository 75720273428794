import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { AppComponent, AppRoutingModule } from '@sl/app';
import { CoreModule } from '@sl/core';
import { PageTitleStrategy } from '@sl/services';
import { SharedModule } from '@sl/shared';
import { TranslocoRootModule } from './transloco-root.module';

/**
 * Módulo inicial de la aplicación
 *
 * @author Arquitectura Empresarial - Santalucía
 * @exports
 * @class AppModule
 * @typedef {AppModule}
 */
@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule, // Módulo principal que contiene las instancias de los módulos de las librerías de Santalucía y el Service worker
    HttpCacheInterceptorModule.forRoot(), // Módulo para la gestión de la cache en las llamadas HTTP Rest
    SharedModule, // Módulo para la gestión de los módulos compartidos en distintos puntos de la aplicación (directivas, pipes...)
    TranslocoRootModule // Módulo para la gestión del multi-idioma
  ],
  providers: [{ provide: TitleStrategy, useClass: PageTitleStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {}
