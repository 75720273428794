import { IOrderNote } from './orderNote.model.interface';
import { OrderNoteInfo } from './orderNoteInfo.model';
import { OrderNoteNote } from './orderNoteNote.model';

export class OrderNote implements IOrderNote {
  readonly originRequest: string;
  readonly order: OrderNoteInfo;
  note: OrderNoteNote[];

  constructor(data: IOrderNote) {
    this.originRequest = data.originRequest;
    this.order = new OrderNoteInfo(data.order);
    this.note = data.note?.map((note) => new OrderNoteNote(note));
  }
}
