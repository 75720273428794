import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ProviderRepository } from '../infrastructure/repositories/provider.repository';
import { IPutProviderUseCase, PutProviderUseCaseParams } from './putProvider.use-case.interface';

@Injectable({ providedIn: 'root' })
export class PutProviderUseCase implements IPutProviderUseCase {
  constructor(private readonly providerRepository: ProviderRepository) {}

  execute({ providerId, placeId, provider }: PutProviderUseCaseParams): Observable<void> {
    // Add additional logic here
    return this.providerRepository.putProvider({ providerId, placeId, provider }).pipe(catchError(this.handleError));
  }

  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    return throwError(() => new Error(error));
  }
}
