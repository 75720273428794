<div class="header" [ngClass]="{ 'header--small': !isLogged, 'header--wide': isLogged }">
  <div class="header__title" [ngClass]="{ 'header__title--left': isLogged }">
    <i *ngIf="hasPreviousPage" class="pi icon-arrow-left" (click)="onBackClick()" (keydown.enter)="onBackClick()"></i>
    <img class="logo" [src]="logo.url" [alt]="logo.alt" />
    <span *ngIf="isLogged" class="title">{{ 'menu.' + route.split('?')[0] | transloco }}</span>
    <i (click)="onBurgerMenuClick()" (keypress)="onBurgerMenuClick()" class="pi icon-menu"></i>
  </div>

  <ng-container *ngIf="(isSmallerThanLaptop$ | async) === false">
    <div class="content-right">
      <button class="content-right__lang" type="button" (click)="onLanguageClick('es-Es')">
        <i class="pi icon-idiomas" [style.color]="'white'"></i>{{ 'header.lang.es' | transloco }}
      </button>
      <sl-place-selector></sl-place-selector>
    </div>
  </ng-container>
</div>
