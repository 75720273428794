/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderSummaryClientId } from '../../domain/enums/orderSummaryStatus.enum';
import { OrderSummary } from '../../domain/orderSummary.model';
import { OrderSummaryEntity } from '../entities/orderSummary.entity';
import { IOrderSummaryMapper } from './orderSummary.mapper.interface';
import { OrderSummaryBillMapper } from './orderSummaryBill.mapper';
import { OrderSummaryOrderMapper } from './orderSummaryOrder.mapper';
import { OrderSummaryProceedingMapper } from './orderSummaryProceeding.mapper';
import { OrderSummaryStatusMapper } from './orderSummaryStatus.mapper';

@Injectable({ providedIn: 'root' })
export class OrderSummaryMapper implements IOrderSummaryMapper {
  constructor(
    private readonly orderSummaryStatusMapper: OrderSummaryStatusMapper,
    private readonly orderSummaryBillMapper: OrderSummaryBillMapper,
    private readonly orderSummaryOrderMapper: OrderSummaryOrderMapper,
    private readonly orderSummaryProceedingMapper: OrderSummaryProceedingMapper
  ) {}

  mapFromApi(apiData: OrderSummaryEntity): OrderSummary {
    return new OrderSummary({
      ...apiData,
      bill: this.orderSummaryBillMapper.mapFromApi(apiData.bill),
      proceeding: this.orderSummaryProceedingMapper.mapFromApi(apiData.proceeding),
      mainDate: apiData.mainDate ? new Date(apiData.mainDate) : null,
      order: this.orderSummaryOrderMapper.mapFromApi(apiData.order),
      status: this.orderSummaryStatusMapper.mapFromApi(apiData.status),
      historyTracking: apiData.historyTracking?.map((tracking) => this.orderSummaryStatusMapper.mapFromApi(tracking)) ?? [],
      clientId: apiData.clientId as OrderSummaryClientId,
      unreadedNotes: apiData.unreadedNotes ?? 0
    });
  }

  mapToApi(orderSummary: OrderSummary): OrderSummaryEntity {
    return new OrderSummaryEntity({
      ...orderSummary,
      bill: this.orderSummaryBillMapper.mapToApi(orderSummary.bill),
      proceeding: this.orderSummaryProceedingMapper.mapToApi(orderSummary.proceeding),
      mainDate: orderSummary.mainDate?.toISOString() ?? null,
      order: this.orderSummaryOrderMapper.mapToApi(orderSummary.order),
      status: this.orderSummaryStatusMapper.mapToApi(orderSummary.status),
      historyTracking: orderSummary.historyTracking.map((tracking) => this.orderSummaryStatusMapper.mapToApi(tracking))
    });
  }
}
