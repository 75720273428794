<p-button (onClick)="showDialog()"> Open dialog </p-button>

<sl-dialog
  [(visible)]="dialog.visible!"
  [showHeader]="true"
  [showFooter]="true"
  [title]="'titulo titulo titulo'"
  [subtitle]="'subtitlesubtit lesubtitlesubti tlesubtitlesubtitle'"
>
  <!-- <div header class="header">Custom Header</div> -->
  <!-- <div sticky class="content--sticky" style="border: 1px solid blue">contenido sticky</div> -->
  <div content class="content" style="width: 100%; height: 1000px; border: 1px solid red">contenido</div>
  <div footer class="footer">Footer</div>
</sl-dialog>

<div>{{ dialog | json }}</div>
