import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sl-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {
  @Input() pageSize = 8;
  @Input() totalPages = 5;
  @Input() pageIndex = 1;

  @Output() readonly pageChange = new EventEmitter<Pages>();

  dropdownOpen = false;
  ellipsis = true;

  constructor() {}

  onSelectedPageNumber(selectedNumber: number): void {
    if (selectedNumber === this.pageIndex) {
      return;
    }
    this.pageIndex = selectedNumber;
    this.getNewPage();
  }

  prevPage(): void {
    if (this.pageIndex === 1) {
      return;
    }
    this.pageIndex = this.pageIndex - 1;
    this.getNewPage();
  }

  nextPage(): void {
    if (this.pageIndex === this.totalPages) {
      return;
    }
    this.pageIndex = this.pageIndex + 1;
    this.getNewPage();
  }

  numDistance(index: number): number {
    return Math.abs(index - this.pageIndex);
  }

  selectedOption(option: number): void {
    this.pageSize = option;
    this.getNewPage();
    this.dropdownOpen = !this.dropdownOpen;
  }

  showPage(page: any): boolean {
    let response = false;
    this.ellipsis = false;
    if (page === 1 || page === this.pageIndex || page === this.pageIndex - 1 || page === this.pageIndex + 1 || page === this.totalPages) {
      this.ellipsis = true;
      response = true;
    }
    return response;
  }

  showDots(pageNumber: number): boolean {
    let response = false;
    if (this.pageIndex >= pageNumber + 3) {
      response = true;
    } else if (this.pageIndex <= pageNumber - 3) {
      response = true;
    }
    return response;
  }

  private getNewPage(): void {
    this.pageChange.emit({
      pageSize: this.pageSize,
      pageIndex: this.pageIndex
    });
  }
}

export interface Pages {
  pageSize: number;
  pageIndex: number;
}
