import { OmitFunctions } from '../../base/utilityTypes';
import { ISSUE, IssueMessageSource, IssueMessageType } from './enums/issue.enum';
import { IIssueMessage } from './issueMessage.model.interface';
import { IssueMessageDoc } from './IssueMessageDoc.model';

export class IssueMessage implements IIssueMessage {
  readonly messageId: string | null;
  readonly readDate: Date | null;
  readonly creationDate: Date | null;
  readonly read: boolean;
  readonly content: string;
  readonly docList: IssueMessageDoc[] | null;
  readonly type: IssueMessageType | null;
  readonly subject: string | null;
  readonly source: IssueMessageSource;
  readonly sender: string | null;

  constructor(data: OmitFunctions<IIssueMessage>) {
    this.messageId = data.messageId;
    this.readDate = data.readDate ? new Date(data.readDate) : null; // Create a new object to make sure it is immutable;
    this.creationDate = data.creationDate ? new Date(data.creationDate) : null; // Create a new object to make sure it is immutable;
    this.read = data.read;
    this.content = data.content;
    this.docList = data.docList ? data.docList.map((doc) => new IssueMessageDoc(doc)) : null;
    this.type = data.type;
    this.subject = data.subject;
    this.source = data.source;
    this.sender = data.sender;
  }

  isProviderMessage(): boolean {
    return this.source === ISSUE.MESSAGE.SOURCE.PROFESIONAL || this.source === ISSUE.MESSAGE.SOURCE.PROVIDER_ADMIN;
  }

  isManagerMessage(): boolean {
    return this.source === ISSUE.MESSAGE.SOURCE.PROVIDER_MANAGER || this.source === ISSUE.MESSAGE.SOURCE.ORDER_MANAGER;
  }
}
