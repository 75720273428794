<article class="tab-container">
  <header class="tab-container__top">
    <span class="tab-container__top__title">{{ 'documentation.title' | transloco }}</span>
  </header>

  <sl-alert
    *ngIf="alertDisableSendDocuments.show"
    (close)="alertDisableSendDocuments.show = false"
    [type]="alertDisableSendDocuments.type"
    [closable]="alertDisableSendDocuments.closable"
  >
    {{ alertDisableSendDocuments.label | transloco }}
  </sl-alert>

  <div class="tab-container__top__search">
    <sl-search
      [icon]="searchByWord.icon"
      [placeholder]="searchByWord.placeholder"
      [(value)]="searchByWord.value"
      (valueChange)="onSearchByWord()"
    ></sl-search>
    <!-- <div class="tab-container__top__note">
      <sl-button [type]="'link'" [icon]="'mas'" [disabled]="isSendDocumentsDisabled" (clicked)="onNewDocumentClick()">
        {{ 'Nuevo documento' }}
      </sl-button>
    </div> -->
  </div>
  <sl-table
    *ngIf="(spinnerService.getLoadingObservable('documents') | async) === false"
    [highlightText]="searchByWord.value"
    [dataTable]="tableFiltered"
    [showDownloadColumn]="true"
    [showPagination]="false"
    [tableItemsName]="'documents.itemsName' | transloco"
    (showErrorModal)="showErrorModal = true"
  ></sl-table>

  <p-progressSpinner
    *ngIf="spinnerService.getLoadingObservable('documents') | async"
    [style]="{ width: '100px', height: '100px' }"
    styleClass="custom-spinner"
    strokeWidth="2"
    animationDuration="1s"
  ></p-progressSpinner>
</article>

<sl-modal-add-documents
  *ngIf="modalAddDocuments.show"
  [title]="modalAddDocuments.title | transloco"
  [subtitle]="modalAddDocuments.subtitle | transloco"
  (sendDocuments)="onSendDocuments($event)"
  (closeModal)="onCloseModal()"
></sl-modal-add-documents>

<sl-success-modal
  *ngIf="showSuccessDocumentModal"
  [successMessage]="'successModal.documentMessage' | transloco"
  (closeModal)="showSuccessDocumentModal = false"
></sl-success-modal>

<sl-error-modal
  *ngIf="showErrorModal"
  [errorMessage]="'errorModal.message' | transloco"
  (closeModal)="showErrorModal = false"
></sl-error-modal>
