import { IProviderEntity } from './provider.entity.interface';

export class ProviderEntity implements IProviderEntity {
  readonly providerId: string;
  readonly providerName: string;

  constructor(data: IProviderEntity) {
    this.providerId = data.providerId;
    this.providerName = data.providerName;
  }
}
