import { ProviderAddress } from './providerAddress.model';
import { ProviderBank } from './providerBank.model';
import { ProviderContact } from './providerContact.model';
import { IProviderData } from './providerData.model.interface';
import { ProviderIdentification } from './providerIdentification.model';

export class ProviderData implements IProviderData {
  readonly name: string;
  readonly lastName: string;
  readonly secondLastName: string;
  readonly provinceEntityINE: string;
  readonly municipaliyEntityINE: string;
  readonly colletiveEntityINE: string;
  readonly singularEntityINE: string;
  readonly nucleusEntityINE: string;
  readonly identification: ProviderIdentification[];
  readonly providerId: string;
  readonly address: ProviderAddress;
  readonly contacts: ProviderContact[];
  readonly bank: ProviderBank;

  constructor(data: IProviderData) {
    this.name = data.name;
    this.lastName = data.lastName;
    this.secondLastName = data.secondLastName;
    this.provinceEntityINE = data.provinceEntityINE;
    this.municipaliyEntityINE = data.municipaliyEntityINE;
    this.colletiveEntityINE = data.colletiveEntityINE;
    this.singularEntityINE = data.singularEntityINE;
    this.nucleusEntityINE = data.nucleusEntityINE; // Create a new object to make sure it is immutable
    this.identification = data.identification.map((identification) => new ProviderIdentification(identification));
    this.providerId = data.providerId;
    this.address = new ProviderAddress(data.address); // Create a new object to make sure it is immutable
    this.contacts = data.contacts.map((contact) => new ProviderContact(contact)); // Create a new object to make sure it is immutable
    this.bank = new ProviderBank(data.bank); // Create a new object to make sure it is immutable
  }

  public addContact(contact: ProviderContact): ProviderData {
    const updatedContacts = [...this.contacts, contact];
    return new ProviderData({ ...this, contacts: updatedContacts, contactsDataModified: true });
  }

  public updateData(updatedData: IProviderData): ProviderData {
    const updatedProvider = new ProviderData({ ...this, ...updatedData, generalDataModified: true });
    return updatedProvider;
  }

  // Ley de Demeter (La clase que instancie este provider no debe hablar con sus propiedades)
  public totalContacts(): number {
    return this.contacts.length;
  }
}
