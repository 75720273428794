/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Provider } from '../../domain/provider.model';
import { ProviderContact } from '../../domain/providerContact.model';
import { ProviderEntity } from '../entities/provider.entity';
import { IProviderMapper } from './provider.mapper.interface';
import { ProviderContactMapper } from './providerContact.mapper';

@Injectable({ providedIn: 'root' })
export class ProviderMapper implements IProviderMapper {
  constructor(private providerContactMapper: ProviderContactMapper) {}

  mapFromApi(apiData: ProviderEntity): Provider {
    return new Provider({
      businessName: apiData.businessName,
      userId: apiData.userId,
      email: apiData.email,
      phone: apiData.phone,
      providerId: apiData.providerId,
      address: apiData.address,
      contacts: apiData.contacts.map((providerContact: ProviderContact) => this.providerContactMapper.mapFromApi(providerContact)),
      generalDataModified: apiData.generalDataModified,
      contactsDataModified: apiData.contactsDataModified
    });
  }

  mapToApi(provider: Provider): ProviderEntity {
    return {
      businessName: provider.businessName,
      userId: provider.userId,
      email: provider.email,
      phone: provider.phone,
      providerId: provider.providerId,
      address: provider.address,
      contacts: provider.contacts.map((providerContact: ProviderContact) => this.providerContactMapper.mapToApi(providerContact)),
      generalDataModified: provider.generalDataModified,
      contactsDataModified: provider.contactsDataModified
    };
  }
}
