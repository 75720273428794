/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { environment } from '@sl/environment';
import { DocumentDetail, Order } from '@sl/features';
import { SharedModule } from '@sl/shared';
import { getFileContent, trackBy } from '@sl/utils';
import { DOCUMENT } from 'src/app/core/features/document/domain/enums/document.enum';
import { MessageSource } from 'src/app/core/features/orderNote/domain/enums/message.enum';
import { OrderNoteNoteMessage } from 'src/app/core/features/orderNote/domain/orderNoteNoteMessage.model';

@Component({
  selector: 'sl-modal-modify-order',
  templateUrl: './modal-modify-order.component.html',
  styleUrls: ['./modal-modify-order.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalModifyOrderComponent implements OnChanges {
  @ViewChild('generalFileInput') generalFileInput!: ElementRef<HTMLInputElement>;

  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() order: Order | undefined;
  @Input() messages: OrderNoteNoteMessage[] = [];

  @Output() readonly closeModal = new EventEmitter();
  @Output() readonly sendModifyOrder = new EventEmitter<ModifyOrderData>();

  generalDocuments: Document[] = [];
  generalDocument: Document | null = null;

  // Inputs
  dropdownGeneralDocumentTypes = {
    label: 'dropdown-document-type.label',
    placeholder: 'dropdown-document-type.placeholder',
    showArrow: true,
    options: ['JUSTIFICANTE', 'CORRESPONDENCIA', 'PRESUPUESTO', 'INFORME_TECNICO', 'OTROS_DOCUMENTOS', 'FOTOS']
  };
  observations = {
    label: 'modal.observations',
    placeholder: 'Escribe aquí',
    maxLength: 200,
    value: null as string | null
  };

  // Constrains
  maxFileSizeBytes = environment.files.maxFileSizeBytes; // 10MB
  maxFileSizeMB = this.maxFileSizeBytes / 1024 / 1024;
  allowedTypeFiles = [
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  MessageSource = MessageSource;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['order']?.currentValue !== changes['order']?.previousValue) {
      this.initGeneralDocuments();
      this.cdr.detectChanges();
    }
  }

  // General Documents
  onDropdownGeneralDocumentTypeChange(document: Document, value: string): void {
    this.generalDocuments = this.generalDocuments.map((doc) => (doc.temporalId === document.temporalId ? { ...doc, type: value } : doc));
  }

  async onGeneralFileInput(event: FileList | any): Promise<void> {
    event.preventDefault();
    const files: File[] = event.length ? Array.from(event) : event.target.files;
    const file = files[0];

    if (!this.isAllowedFile(file)) {
      return;
    }

    const content: string = await this.getFileContent(file);

    this.generalDocuments = this.generalDocuments.map((doc) =>
      doc.temporalId === this.generalDocument?.temporalId ? { ...doc, file, content } : doc
    );
    this.generalDocument = null;
    this.cdr.detectChanges();
  }

  onButtonAddGeneralFileClick(document: Document): void {
    this.generalDocument = { ...document };
    this.generalFileInput.nativeElement.click();
  }

  onButtonDeleteGeneralDocumentClick(document: Document): void {
    this.generalDocuments = this.generalDocuments.map((doc) =>
      doc.temporalId === document.temporalId ? { ...doc, file: null, content: null } : doc
    );
  }

  showFileError(doc: Document): boolean {
    return doc.file ? !this.isAllowedFile(doc.file) : false;
  }

  // Observations
  onTextAreaObservationsEnter(): void {
    this.handleSendModifyOrder();
  }

  // Action Buttons
  onButtonSendClick(): void {
    this.handleSendModifyOrder();
  }

  onButtonDeleteClick(): void {
    this.observations.value = '';
  }

  disableButtonSend(): boolean {
    return !this.observations.value || this.generalDocuments.some((doc) => doc.file && (!this.isAllowedFile(doc.file) || !doc.type));
  }

  // Shared
  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  onCloseModal(): void {
    this.closeModal.emit();
  }

  // Initialization
  private initGeneralDocuments(): void {
    const generalDocument: Document = {
      temporalId: generateId(),
      type: null,
      file: null,
      content: null
    };
    this.generalDocuments = [generalDocument];
  }

  // Utils
  private isAllowedFile(file: File): boolean {
    return file?.size <= this.maxFileSizeBytes && this.allowedTypeFiles.includes(file?.type);
  }

  private handleSendModifyOrder(): void {
    const allDocuments = [...this.generalDocuments].filter((doc) => doc.file);
    const modifyData: ModifyOrderData = {
      documentsWithoutIds: allDocuments.map((doc) => mapDocumentToDocumentDetail(doc)),
      comments: this.observations.value ?? ''
    };
    this.sendModifyOrder.emit(modifyData);
    this.closeModal.emit();
  }

  private async getFileContent(file: File): Promise<string> {
    const content = await getFileContent(file);
    return content.split(',')[1]; // Just the content after de ',' character
  }
}

const generateId = (): string => {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 1000000);
  return `${timestamp}-${random}`;
};

const mapDocumentToDocumentDetail = (doc: Document): DocumentDetail => ({
  documentId: '',
  name: doc.file!.name,
  type: doc.type! === 'supplied' ? DOCUMENT.TYPE.SUPLIDO : doc.type!,
  mimeType: doc.file!.type,
  content: doc.content!,
  date: new Date(),
  origin: DOCUMENT.ORIGIN.PROVEEDOR
});

type Document = {
  temporalId: string | null;
  type: string | null;
  file: File | null;
  content: string | null;
};

export type ModifyOrderData = {
  documentsWithoutIds: DocumentDetail[];
  comments: string;
};
