import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { trackBy } from '@sl/utils';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'sl-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabMenuComponent implements AfterViewChecked {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('content') content!: ElementRef;

  @Input() tabs: TabMenu[] = [];
  @Input() selectedNameColor = '#28344A';
  @Input() nonSelectedNameColor = '#006FF5';
  @Input() notificationColor = '#B50500';
  @Input() selectedBorder = 'var(--color-primary6)';

  @Output() readonly tabChange = new EventEmitter<number>();

  selectedTab: string | undefined;
  canScrollRight!: boolean;
  canScrollLeft!: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.selectedTab = this.tabs[0]?.label;
  }

  ngAfterViewChecked(): void {
    this.updateArrows();
  }

  onScroll(): void {
    this.updateArrows();
  }

  onTabClick(tab: TabMenu, index: number): void {
    this.selectedTab = tab.label;
    this.tabChange.emit(index);
  }

  onRightArrowClick(): void {
    this.scrollRight();
  }

  onLeftArrowClick(): void {
    this.scrollLeft();
  }

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  private updateArrows(): void {
    const { scrollLeft, clientWidth, scrollWidth } = this.scrollContainer.nativeElement;
    this.canScrollLeft = scrollLeft > 0;
    this.canScrollRight = scrollLeft + clientWidth + 1 < scrollWidth; // El +1 es por el margen de error
    this.cdr.detectChanges();
  }

  private scrollLeft(): void {
    this.scrollContainer.nativeElement.scrollLeft -= 100;
  }

  private scrollRight(): void {
    this.scrollContainer.nativeElement.scrollLeft += 100;
  }
}

export type TabMenu = MenuItem & { num: number };
