/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  GetNotificationsFilter,
  GetOrderStatisticsFilter,
  GetOrderVolumetriesFilter,
  NotificationStore,
  PlaceStore,
  SpinnerService,
  StatisticsStore,
  VolumetryStore
} from '@sl/services';
import { SharedModule } from '@sl/shared';
import { filter, Observable, take } from 'rxjs';

import { OrderStatistic, OrderVolumetry, Place } from '@sl/features';
import { trackBy } from '@sl/utils';
import { GetIssuesParams } from 'src/app/core/features/issue/infrastructure/repositories/issue.repository.interface';
import { GetOrdersFilter } from 'src/app/core/features/order/infrastructure/repositories/order.repository.interface';
import { IssueStore } from 'src/app/core/services/stores/issue.store';
import { OrderStore } from 'src/app/core/services/stores/order.store';
import { OrderTrackingComponent } from 'src/app/modules/orders/components';
import { BannerComponent, NotificationCardComponent, StatisticsComponent } from '../../components';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-global-position',
  templateUrl: './global-position.page.html',
  styleUrls: ['./global-position.page.scss'],
  standalone: true,
  imports: [SharedModule, BannerComponent, StatisticsComponent, NotificationCardComponent, OrderTrackingComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalPositionPageComponent implements OnInit {
  cardsToShow = 6;
  totalPages = 0;
  carousell: any;
  isScrolling = false;
  state = 0;

  profile = {
    name: '¡Hola, Interfunerarias!'
  };

  orders$ = this.orderStore.selectOrders();
  volumetries$ = this.volumetryStore.selectOrderVolumetries();
  statistics$ = this.statisticsStore.selectOrderStatistics();

  orderStatistics: OrderStatistic[] = [];
  loadingOrderStatistics$!: Observable<boolean>;

  orderVolumetries: OrderVolumetry[] = [];
  loadingOrderVolumetries$!: Observable<boolean>;

  orderNotifications: any[] = [];
  loadingOrderNotifications$!: Observable<boolean>;

  loadingOrders$!: Observable<boolean>;
  loadingIssues$!: Observable<boolean>;

  description = 'notification';
  showErrorModal = false;
  selectedYear = 'allYears';
  selectedActivity = 'allActivities';
  pendingOrderVolumetryId = 'v00001';
  pendingOrderVolumetryIdOutOfDate = 'v00002';

  orders = [
    {
      text: 'Liquidados',
      num: 64,
      color: '#47D7AC'
    },
    {
      text: 'Pendientes',
      num: 26,
      color: '#0082FF'
    },
    {
      text: 'A facturar',
      num: 10,
      color: '#BD4310'
    }
  ];

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly spinnerService: SpinnerService,
    private readonly placeStore: PlaceStore,
    private readonly notificationStore: NotificationStore,
    private readonly statisticsStore: StatisticsStore,
    private readonly volumetryStore: VolumetryStore,
    private readonly orderStore: OrderStore,
    private readonly issueStore: IssueStore
  ) {}

  ngOnInit(): void {
    this.placeStore
      .selectPlaces()
      .pipe(
        untilDestroyed(this),
        filter(({ places, loading, error }) => Boolean(places) && !loading && !error),
        take(1) // Prevent duplicate requests since place-selector redirects to this page when a place is selected
      )
      .subscribe(({ places }) => {
        this.getStatistics();
        this.getVolumetries();
        this.getNotifications(); // Get notifications for sidebar-menu notifications
        this.getOrders(); // Get orders for sidebar-menu notifications
        this.getIssues(); // Get issues for sidebar-menu notifications
        this.initProfileName(places!);
      });

    this.initOrderStadistics();
    this.initOrderVolumetries();
  }

  onWheel(event: WheelEvent): void {
    event.preventDefault();

    if (event.deltaX > 0 || event.deltaY > 0) {
      this.setState(this.state + 1);
    } else {
      this.setState(this.state - 1);
    }

    this.isScrolling = true;
  }

  onScroll(event?: any): void {
    if (
      this.carousell.scrollLeft % this.carousell.offsetWidth === 0 ||
      (this.carousell.scrollLeft + 1) % this.carousell.offsetWidth === 0 || //Se establece +-1 por los redondeos que realiza javascript
      (this.carousell.scrollLeft - 1) % this.carousell.offsetWidth === 0
    ) {
      this.isScrolling = false;
      this.state = Math.floor((this.carousell.scrollLeft + 1) / this.carousell.offsetWidth);
    }
  }

  setState(state: number): void {
    this.carousell?.scrollTo({
      left: this.carousell.offsetWidth * state,
      behavior: 'smooth'
    });
  }

  onStatisticsFilterChange(statisticsFilter?: GetOrderStatisticsFilter): void {
    if (statisticsFilter) {
      this.selectedYear = statisticsFilter.year!;
      this.selectedActivity = statisticsFilter.activity!;
    }
    this.getStatistics(statisticsFilter);
  }

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  // Api

  private getStatistics(statisticsFilter?: GetOrderStatisticsFilter): void {
    this.loadingOrderStatistics$ = this.spinnerService.getLoadingObservable('statistics');
    this.statisticsStore.actionGetOrderStatistics({ statisticsFilter });
  }

  private getVolumetries(volumetriesFilter?: GetOrderVolumetriesFilter): void {
    this.loadingOrderVolumetries$ = this.spinnerService.getLoadingObservable('volumetries');
    this.volumetryStore.actionGetOrderVolumetries({ volumetriesFilter });
  }

  private getNotifications(notificationFilter?: GetNotificationsFilter): void {
    this.loadingOrderNotifications$ = this.spinnerService.getLoadingObservable('notifications');
    this.notificationStore.actionGetNotifications({ notificationFilter });
  }

  private getOrders(params?: GetOrdersFilter): void {
    this.loadingOrders$ = this.spinnerService.getLoadingObservable('orders');
    this.orderStore.actionGetOrders({ ordersFilter: params });
  }

  private getIssues(issueFilter?: GetIssuesParams): void {
    this.loadingIssues$ = this.spinnerService.getLoadingObservable('issues');
    this.issueStore.actionGetIssues({ issueFilter }, true);
  }

  // Initialization

  private initOrderStadistics(): void {
    this.statistics$
      .pipe(
        untilDestroyed(this),
        filter(({ orderStatistics, loading, error }) => {
          if (error) {
            this.showErrorModal = true;
            this.cdr.detectChanges();
          }
          return Boolean(orderStatistics) && !loading && !error;
        })
      )
      .subscribe(({ orderStatistics }) => {
        this.orderStatistics = [...orderStatistics!];
        this.cdr.detectChanges();
      });
  }

  private initOrderVolumetries(): void {
    this.volumetries$
      .pipe(
        untilDestroyed(this),
        filter(({ orderVolumetries, loading, error }) => {
          if (error) {
            this.showErrorModal = true;
            this.cdr.detectChanges();
          }
          return Boolean(orderVolumetries) && !loading && !error;
        })
      )
      .subscribe(({ orderVolumetries }) => {
        this.orderVolumetries = [...orderVolumetries!];
        this.totalPages = Math.ceil(this.orderVolumetries.length / this.cardsToShow);
        if (this.orderVolumetries && this.orderVolumetries.length > 0) {
          const pendingOrderVolumetry = this.orderVolumetries.find((volumetry) => volumetry.volumetryId === this.pendingOrderVolumetryId);
          const pendingOrderVolumetryOutOfDate = this.orderVolumetries.find(
            (volumetry) => volumetry.volumetryId === this.pendingOrderVolumetryIdOutOfDate
          );
          if (pendingOrderVolumetry && pendingOrderVolumetry.count === 0) {
            this.description = 'no-orders';
          } else if (pendingOrderVolumetryOutOfDate && pendingOrderVolumetryOutOfDate.count === 0) {
            this.description = 'up-to-date';
          }
        }
        this.cdr.detectChanges();
      });
  }

  private initProfileName(places: Place[]): void {
    const allPlacesLabel = this.placeStore.getProviderPlaces()?.provider.providerName.replace('Sede', '');
    const placeLabel = places[0]?.description;
    const providerName = placeLabel || allPlacesLabel || '';
    this.profile = { name: `¡Hola, ${providerName}!` };
    this.cdr.detectChanges();
  }
}
