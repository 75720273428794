/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISSUE } from '@sl/features';
import { NavigationService, NotificationStore, PlaceStore, ScreenSizeService, VolumetryStore } from '@sl/services';
import { ROUTES, SharedModule } from '@sl/shared';
import { getInitials } from '@sl/utils';
import { filter } from 'rxjs';
import { IssueStore } from 'src/app/core/services/stores/issue.store';
import { OrderStore } from 'src/app/core/services/stores/order.store';
import { PlaceSelectorComponent } from '../place-selector/place-selector.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  standalone: true,
  imports: [SharedModule, PlaceSelectorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarMenuComponent implements OnInit {
  @Input() showSidebar = true;

  @Output() readonly routeChange = new EventEmitter<string>();
  @Output() readonly showSidebarChange = new EventEmitter<boolean>();

  orders$ = this.orderStore.selectOrders();
  sidebarIssues$ = this.issueStore.selectSidebarIssues();
  providerPlaces$ = this.placeStore.selectProviderPlaces();
  places$ = this.placeStore.selectPlaces();
  volumetries$ = this.volumetryStore.selectOrderVolumetries();
  notifications$ = this.notificationStore.selectNotifications();

  route = this.navigation.getActualRoute().split('/')[1];

  profileTab = {
    route: ROUTES.PROFILE.PATH,
    picture: '',
    label: ''
  };

  tabOptions: TabOption[] = [
    {
      route: ROUTES.GLOBAL_POSITION.PATH,
      icon: 'pi icon-home',
      volumetryId: null,
      counter: null,
      notifications: null
    },
    {
      route: ROUTES.COMMUNICATIONS.PATH,
      icon: 'pi icon-alert1',
      volumetryId: 'v00006',
      counter: 0,
      notifications: null
    },
    {
      route: ROUTES.ORDERS.PATH,
      icon: 'pi icon-encargo',
      volumetryId: 'v00001',
      counter: 0,
      notifications: null
    },
    {
      route: ROUTES.DOCUMENTATION.PATH,
      icon: 'pi icon-adjunto',
      volumetryId: null,
      counter: null,
      notifications: null
    },
    {
      route: ROUTES.COMMUNIQUES.PATH,
      icon: 'pi icon-mail',
      volumetryId: null,
      counter: null,
      notifications: 0
    },
    {
      route: ROUTES.LIQUIDATIONS.PATH,
      icon: 'pi icon-liquidaciones',
      volumetryId: null,
      counter: null,
      notifications: null
    }
  ];

  isSmallerThanLaptop$ = this.screenSizeService.isSmallerThanLaptop();
  previousIsSmallerThanLaptop = this.isSmallerThanLaptop;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly navigation: NavigationService,
    private readonly screenSizeService: ScreenSizeService,
    private readonly orderStore: OrderStore,
    private readonly volumetryStore: VolumetryStore,
    private readonly issueStore: IssueStore,
    private readonly notificationStore: NotificationStore,
    private readonly placeStore: PlaceStore
  ) {}

  get sidebarSize(): string {
    return this.showSidebar ? SIDEBAR_SIZE.M : SIDEBAR_SIZE.S;
  }

  get isSmallerThanLaptop(): boolean {
    return this.screenSizeService.isSmallerThanLaptopInstant();
  }

  ngOnInit(): void {
    this.initProfileTab();
    this.initActiveTab();
    this.initTabs();
    this.initTabCommunications();
    this.initTabCommuniques();
    this.initTabOrders();
    //this.getCountersData();
    this.isSmallerThanLaptop$.subscribe((isSmaller) => {
      if (isSmaller !== this.previousIsSmallerThanLaptop) {
        this.previousIsSmallerThanLaptop = isSmaller;
        this.closeSidebar();
      }
    });
  }

  onToggleSidebar(): void {
    this.toggleSidebar();
  }

  onCloseSidebar(): void {
    this.closeSidebar();
  }

  onSelectedTab(route: string): void {
    if (route === ROUTES.ORDERS.PATH) {
      this.orderStore.updateOrders({ pageSize: 10, pageIndex: 1 });
    }
    this.route = route;
    this.routeChange.emit(route);
    if (this.isSmallerThanLaptop) {
      this.closeSidebar();
    }
  }

  onPlaceChange(): void {
    this.closeSidebar();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  setShowSidebar(show: boolean): void {
    this.showSidebar = show;
    this.showSidebarChange.emit(this.showSidebar);
  }

  private toggleSidebar(): void {
    this.setShowSidebar(!this.showSidebar);
  }

  private closeSidebar(): void {
    this.setShowSidebar(false);
  }

  // Initialization

  private initProfileTab(): void {
    this.placeStore
      .selectPlaces()
      .pipe(
        untilDestroyed(this),
        filter(({ places, loading, error }) => Boolean(places) && !loading && !error)
      )
      .subscribe(({ places }) => {
        const allPlacesLabel = this.placeStore.getProviderPlaces()?.provider.providerName.replace('Sede', '');
        const placeLabel = places![0]?.description;
        const providerName = placeLabel || allPlacesLabel || '';
        this.profileTab = { ...this.profileTab, label: `¡Hola, ${providerName}!`, picture: getInitials(providerName) };
        this.cdr.detectChanges();
      });
  }

  private initActiveTab(): void {
    this.navigation.onRouteChange().subscribe((route) => {
      this.route = route.split('/')[1];
      if (this.route.includes('?')) {
        this.route = this.route.split('?')[0];
      }
      this.cdr.detectChanges();
    });
  }

  private initTabs(): void {
    this.volumetries$
      .pipe(
        untilDestroyed(this),
        filter(({ orderVolumetries, loading, error }) => Boolean(orderVolumetries) && !loading && !error)
      )
      .subscribe(({ orderVolumetries }) => {
        this.tabOptions = this.fillTabsWithVolumetries(this.tabOptions, orderVolumetries);
        this.cdr.detectChanges();
      });
  }

  private initTabCommuniques(): void {
    this.notifications$
      .pipe(
        untilDestroyed(this),
        filter(({ notifications, loading, error }) => Boolean(notifications) && !loading && !error)
      )
      .subscribe(({ notifications }) => {
        const notReadedNotifications = notifications?.filter((n) => !n.readDate).length || 0;
        this.tabOptions = this.tabOptions.map((tab) =>
          tab.route === ROUTES.COMMUNIQUES.PATH ? { ...tab, notifications: notReadedNotifications } : tab
        );
        this.cdr.detectChanges();
      });
  }

  private initTabOrders(): void {
    this.orders$
      .pipe(
        untilDestroyed(this),
        filter((orders) => Boolean(orders))
      )
      .subscribe({
        next: ({ total }) => {
          this.tabOptions = this.tabOptions.map((tab) => (tab.route === ROUTES.ORDERS.PATH ? { ...tab, counter: total } : tab));
          this.cdr.detectChanges();
        }
      });
  }

  private initTabCommunications(): void {
    this.sidebarIssues$
      .pipe(
        untilDestroyed(this),
        filter((issues) => Boolean(issues))
      )
      .subscribe({
        next: ({ issues }) => {
          const openIssues = issues?.filter(({ status }) => status === ISSUE.STATUS.OPEN).length;
          if (openIssues) {
            this.tabOptions = this.tabOptions.map((tab) =>
              tab.route === ROUTES.COMMUNICATIONS.PATH ? { ...tab, counter: openIssues } : tab
            );
          }
          this.cdr.detectChanges();
        }
      });
  }

  // Utils

  private fillTabsWithVolumetries(tabOptions: TabOption[], volumetries: VolumetryEntity[] | null): TabOption[] {
    return tabOptions.map((tab) => {
      const matchingVolumetry = volumetries?.find(({ volumetryId }) => volumetryId === tab.volumetryId);
      return matchingVolumetry &&
        tab.route !== ROUTES.ORDERS.PATH &&
        tab.route !== ROUTES.COMMUNIQUES.PATH &&
        tab.route !== ROUTES.COMMUNICATIONS.PATH
        ? { ...tab, counter: matchingVolumetry.count }
        : tab;
    });
  }
}

export const SIDEBAR_SIZE = {
  S: '86px',
  M: '248px'
};

interface TabOption {
  route: string;
  url?: string;
  icon?: string;
  path?: string;
  volumetryId: string | null;
  notifications: number | null;
  counter: number | null;
}

interface VolumetryEntity {
  volumetryId: string;
  count: number;
}
