/**
 * Remove diacritics and trim whitespaces of a string
 *
 * @param {string} text string to be formatted
 * @returns {string} new string with removed diacritics
 */
export const removeDiacritics = (text: string): string =>
  text
    ?.trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

/**
 * Capitalize the first letter of a string and make the rest lowercase
 *
 * @param {string} value string to be capitalize
 * @param {'all'} args 'all' will capitalize each word of the string
 * @returns {string} new string with the first letter capitalized
 */
export const capitalize = (value: string, args?: 'all'): string => {
  let response = value;

  if (value) {
    response = args === 'all' ? value.split(' ').map(toTitleCase).join(' ') : toTitleCase(value);
  }

  return response;
};

export const toTitleCase = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

/**
 * Add thousand separators to the provided number.
 *
 * @param {string} text - The number as a string without separators.
 * @returns {string} The number with thousand separators.
 * @example
 * // Returns "240.000.144"
 * addThousandSeparators("240000144");
 */
export const addThousandSeparators = (text: string): string => text.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

/**
 * Returns the initials of a name.
 *
 * @param {string} name The name from which to extract the initials.
 * @param {number} [numInitials] The number of initials to retrieve. Default value is 2.
 * @returns {string} The initials of the name.
 */
export const getInitials = (name: string, numInitials = 2): string =>
  name
    .split(' ')
    .filter((word) => word.length > 0)
    .slice(0, numInitials)
    .map((word) => word[0].toUpperCase())
    .join('');
