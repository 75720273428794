import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { DeleteParams, GetParams, IApiService, PostParams, PutParams, QueryParams, QueryParamsNonNullable } from '.';

/**
 * Service for making http request using HttpClient from angular
 */
@Injectable({ providedIn: 'root' })
export class ApiService implements IApiService {
  constructor(private http: HttpClient) {}

  /** Using shareReplay() to avoid multiple HTTP calls */

  get<T>({ url, queryParams }: GetParams<T>): Observable<T> {
    const options: Options = { params: this.cleanQueryParams(queryParams) };
    return this.http.get<T>(url, options).pipe(shareReplay());
  }

  put<T extends object>({ url, body, queryParams }: PutParams<T>): Observable<void> {
    const options: Options = { params: this.cleanQueryParams(queryParams) };
    return this.http.put<void>(url, body, options).pipe(shareReplay());
  }

  post<T>({ url, body, queryParams }: PostParams<T>): Observable<T> {
    const options: Options = { params: this.cleanQueryParams(queryParams) };
    return this.http.post<T>(url, body, options).pipe(shareReplay());
  }

  delete<T>({ url, queryParams }: DeleteParams<T>): Observable<void> {
    const options: Options = { params: this.cleanQueryParams(queryParams) };
    return this.http.delete<void>(url, options).pipe(shareReplay());
  }

  private cleanQueryParams(queryParams: QueryParams | undefined): QueryParamsNonNullable {
    const cleanedParams: QueryParamsNonNullable = {};

    for (const key in queryParams) {
      if (queryParams[key] !== null && queryParams[key] !== undefined) {
        cleanedParams[key] = queryParams[key]!;
      }
    }

    return cleanedParams;
  }
}

export type Options = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
};
