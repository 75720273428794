/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderBillClaim } from '../../domain/orderBillClaim.model';
import { OrderBillClaimEntity } from '../entities/orderBillClaim.entity';
import { IOrderBillClaimMapper } from './orderBillClaim.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderBillClaimMapper implements IOrderBillClaimMapper {
  constructor() {}

  mapFromApi(apiData: OrderBillClaimEntity): OrderBillClaim {
    return new OrderBillClaim({
      idNumber: apiData.idNumber
    });
  }

  mapToApi(orderClaim: OrderBillClaim): OrderBillClaimEntity {
    return new OrderBillClaimEntity({
      idNumber: orderClaim.idNumber
    });
  }
}
