import { OrderSummaryClientId } from './enums/orderSummaryStatus.enum';
import { IOrderSummary } from './orderSummary.model.interface';
import { OrderSummaryBill } from './orderSummaryBill.model';
import { OrderSummaryOrder } from './orderSummaryOrder.model';
import { OrderSummaryProceeding } from './orderSummaryProceeding.model';
import { OrderSummaryStatus } from './orderSummaryStatus.model';

export class OrderSummary implements IOrderSummary {
  readonly unreadedNotes: number;
  readonly activity: string;
  readonly additionalInfo: string;
  readonly affectedName: string;
  readonly bill: OrderSummaryBill;
  readonly clientId: OrderSummaryClientId;
  readonly placeId: string;
  readonly insuredId: string;
  readonly mainDate: Date | null;
  readonly order: OrderSummaryOrder;
  readonly policeId: string;
  readonly proceeding: OrderSummaryProceeding;
  readonly status: OrderSummaryStatus;
  readonly historyTracking: OrderSummaryStatus[];
  readonly settlementId: string;

  constructor(data: IOrderSummary) {
    this.unreadedNotes = data.unreadedNotes;
    this.activity = data.activity;
    this.policeId = data.policeId;
    this.bill = new OrderSummaryBill(data.bill);
    this.order = new OrderSummaryOrder(data.order);
    this.mainDate = data.mainDate ? new Date(data.mainDate) : null; // Create a new Object to make sure it is immutable;
    this.status = new OrderSummaryStatus(data.status); // Create a new Object to make sure it is immutable;
    this.proceeding = new OrderSummaryProceeding(data.proceeding);
    this.clientId = data.clientId;
    this.placeId = data.placeId;
    this.insuredId = data.insuredId;
    this.affectedName = data.affectedName;
    this.additionalInfo = data.additionalInfo;
    this.historyTracking = data.historyTracking.map((h) => new OrderSummaryStatus(h));
    this.settlementId = data.settlementId;
  }
}
