<p-dialog
  class="dialog"
  [visible]="visible"
  [breakpoints]="breakpoints"
  [draggable]="draggable"
  [header]="header"
  [maximizable]="maximizable"
  [modal]="modal"
  [position]="position"
  [resizable]="resizable"
  [style]="style"
  [showHeader]="showHeader || !!header"
  (visibleChange)="onVisibleChange($event)"
  (onShow)="onDialogShow($event)"
  (onHide)="onDialogHide($event)"
>
  <ng-template *ngIf="showHeader && !header" pTemplate="header">
    <div><ng-content select="[header]"></ng-content></div>
  </ng-template>

  <div class="dialog__content">
    <div class="dialog__content--sticky">
      <ng-container *ngIf="title || subtitle" [ngTemplateOutlet]="StickyHeader"></ng-container>
      <ng-content select="[sticky]"></ng-content>
    </div>
    <ng-content></ng-content>
  </div>

  <ng-template *ngIf="showFooter" pTemplate="footer">
    <ng-content select="[footer]"></ng-content>
    <!-- * No footer actions by the moment -->
    <!-- <ng-container *ngIf="confirm || dismiss" [ngTemplateOutlet]="FooterActions"></ng-container> -->
  </ng-template>
</p-dialog>

<ng-template #StickyHeader>
  <div class="sticky-header" [ngClass]="{ 'sticky-header--no-showHeader': !showHeader }">
    <h2 class="sticky-header__title">{{ title }}</h2>
    <p class="sticky-header__subtitle">{{ subtitle }}</p>
  </div>
</ng-template>

<!-- * No footer actions by the moment -->
<!-- <ng-template #FooterActions>
  <div class="footer-actions">
    <sl-button type="secondary">{{ dismiss }}</sl-button>
    <sl-button type="primary">{{ confirm }}</sl-button>
  </div>
</ng-template> -->
