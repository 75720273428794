import { IOrderStatistic } from './orderStatistic.model.interface';

export class OrderStatistic implements IOrderStatistic {
  readonly id: string;
  readonly value: number;
  readonly date: string;
  readonly activity: string;

  constructor(data: IOrderStatistic) {
    this.id = data.id;
    this.value = data.value;
    this.date = data.date;
    this.activity = data.activity;
  }
}
