<div class="detail-modal__container">
  <div class="detail-modal__container__content">
    <div class="detail-modal__container__content__up">
      <i class="pi icon-cerrar" (click)="onCloseModal()" (keypress)="onCloseModal()"></i>
      <span class="detail-modal__container__content__up__title">{{ title }}</span>
      <p *ngIf="subtitle">{{ subtitle }}</p>

      <div class="document-content__container">
        <!-- Dropdwon file type -->
        <ng-container [ngTemplateOutlet]="DropdwonFileType"></ng-container>

        <!-- Info card -->
        <ng-container [ngTemplateOutlet]="InfoCard"></ng-container>
      </div>

      <!-- Drag and Drop -->
      <ng-container [ngTemplateOutlet]="DragAndDrop"></ng-container>
    </div>

    <div class="detail-modal__container__content__add">
      <!-- Action buttons -->
      <ng-container [ngTemplateOutlet]="ActionButtons"></ng-container>
    </div>
  </div>
</div>

<ng-template #DropdwonFileType>
  <div class="document-content__container__filter" [ngStyle]="{ width: 'fit-content' }">
    <sl-dropdown-filter
      [showArrow]="dropdownGeneralDocumentTypes.showArrow"
      [filterName]="dropdownGeneralDocumentTypes.label | transloco"
      [placeholder]="dropdownGeneralDocumentTypes.placeholder | transloco"
      [dropdownOptions]="dropdownGeneralDocumentTypes.options"
      [(value)]="dropdownGeneralDocumentTypes.value"
      (valueChange)="onDropdownGeneralDocumentTypeChange($event!)"
    ></sl-dropdown-filter>
  </div>
</ng-template>

<ng-template #InfoCard>
  <div class="document-content__container__info">
    <i class="pi icon-info1"></i>
    <div class="document-content__container__info__text">
      <div>
        <span>-{{ 'modal.infoPrimary' | transloco : { maxFileSizeMB: maxFileSizeMB } }}</span>
      </div>
      <span>-{{ 'modal.infoSecondary' | transloco }}</span>
      <!-- <span class="detail-modal__container__content__up__info__error"> -{{ 'modal.fileError' | transloco }} </span> -->
    </div>
  </div>
</ng-template>

<ng-template #DragAndDrop>
  <div class="document-content__container__droparea" slDragAndDrop (fileDropped)="onFileDropped($event)">
    <ng-container *ngIf="showFileError">
      <span class="document-content__container__droparea__error">{{ 'edit-modal.documentError' | transloco }}</span>
    </ng-container>
    <div class="document-content__container__droparea__upload">
      <i class="pi icon-upload"></i>
      <span
        >{{ 'droparea.text' | transloco }} {{ 'droparea.other' | transloco }}
        <span
          (click)="onButtonAddGeneralDocumentClick()"
          (keypress)="onButtonAddGeneralDocumentClick()"
          class="document-content__container__droparea__file"
        >
          {{ 'droparea.fileText' | transloco }}
        </span>
      </span>
    </div>

    <div *ngIf="generalDocuments?.length" class="document-content__container__droparea__list-files">
      <ng-container *ngFor="let doc of generalDocuments; let i = index; trackBy: trackByFn">
        <div class="document-content__container__droparea__list-files__file">
          <i class="pi icon-check"></i>
          <span>{{ doc.file!.name }}</span>
          <i
            class="pi icon-eliminar icon-eliminar__border"
            (click)="onButtonDeleteGeneralDocumentClick(doc)"
            (keypress)="onButtonDeleteGeneralDocumentClick(doc)"
          ></i>
        </div>
      </ng-container>
    </div>
  </div>
  <input
    #generalFileInput
    type="file"
    style="display: none"
    multiple="multiple"
    (click)="generalFileInput.value = ''"
    (input)="onGeneralFileInput($event)"
  />
</ng-template>

<ng-template #ActionButtons>
  <article class="button-container">
    <div class="button-container__erase">
      <sl-button [borderRound]="true" [type]="'secondary'" [size]="'l'" (click)="onButtonDeleteClick()">
        {{ 'Borrar' }}
      </sl-button>
    </div>
    <div class="button-container__side">
      <sl-button [type]="'primary'" [size]="'l'" [borderRound]="true" [disabled]="disableButtonSend()" (click)="onButtonSendClick()">
        {{ 'Enviar' }}
      </sl-button>
    </div>
  </article>
</ng-template>
