/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderBillOrderStatus } from '../../domain/orderBillOrderStatus.model';
import { OrderBillOrderStatusEntity } from '../entities/orderBillOrderStatus.entity';
import { IOrderBillOrderStatusMapper } from './orderBillOrderStatus.mapper.interface';
import { OrderBillOrderStatusReasonMapper } from './orderBillOrderStatusReason.mapper';

@Injectable({ providedIn: 'root' })
export class OrderBillOrderStatusMapper implements IOrderBillOrderStatusMapper {
  constructor(private readonly orderBillOrderStatusReasonMapper: OrderBillOrderStatusReasonMapper) {}

  mapFromApi(apiData: OrderBillOrderStatusEntity): OrderBillOrderStatus {
    return new OrderBillOrderStatus({
      code: apiData.code,
      reasons: apiData.reasons.map((r) => this.orderBillOrderStatusReasonMapper.mapFromApi(r))
    });
  }

  mapToApi(orderStatus: OrderBillOrderStatus): OrderBillOrderStatusEntity {
    return new OrderBillOrderStatusEntity({
      code: orderStatus.code,
      reasons: orderStatus.reasons.map((r) => this.orderBillOrderStatusReasonMapper.mapToApi(r))
    });
  }
}
