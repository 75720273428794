<div #Trigger class="trigger">
  <label class="trigger__label" for="search">Buscar por</label>
  <div
    class="trigger__button"
    (click)="isMobile ? showDialog(true) : togglePopover($event)"
    (keydown.enter)="isMobile ? showDialog(true) : togglePopover($event)"
    [ngClass]="{ 'trigger--focus': popover.visible }"
  >
    <input
      class="trigger__input"
      [value]="datesDisplay"
      [placeholder]="placeholder"
      [ngClass]="{ 'trigger__input--value': datesDisplay }"
    />
    <i class="pi icon-calendar trigger__icon"></i>
  </div>
</div>

<ng-container [ngSwitch]="isMobile$ | async">
  <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="Popover"></ng-container>
  <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="Modal"></ng-container>
</ng-container>

<ng-template #Popover>
  <p-overlayPanel #Panel (onShow)="popover.visible = true" (onHide)="popover.visible = false">
    <ng-template pTemplate="content">
      <ng-container [ngTemplateOutlet]="Header"></ng-container>
      <ng-container [ngTemplateOutlet]="Content"></ng-container>
      <ng-container [ngTemplateOutlet]="Footer"></ng-container>
    </ng-template>
  </p-overlayPanel>
</ng-template>

<ng-template #Modal>
  <p-dialog #Dialog [(visible)]="dialog.visible" [modal]="dialog.modal" [draggable]="dialog.draggable" [resizable]="dialog.resizable">
    <ng-template pTemplate="content">
      <ng-container [ngTemplateOutlet]="Header"></ng-container>
      <ng-container [ngTemplateOutlet]="Content"></ng-container>
      <ng-container [ngTemplateOutlet]="Footer"></ng-container>
    </ng-template>
  </p-dialog>
</ng-template>

<!-- * SECTIONS -->
<ng-template #Header>
  <!-- TODO: Uncomment when ready. <sl-tab-menu [items]="menuItems" [(activeItem)]="activeMenuItem"> </sl-tab-menu> -->
  <menu class="header">
    <ng-container *ngFor="let item of menuItems; let i = index; trackBy: trackByFn">
      <span
        class="header__option"
        [ngClass]="{ 'header__option--selected': activeMenuItem === item }"
        (click)="activeMenuItem = item"
        (keyup)="$event.key === 'Enter' && (activeMenuItem = item)"
        tabindex="0"
      >
        {{ item.label }}
      </span>
    </ng-container>
  </menu>
</ng-template>

<ng-template #Content>
  <div class="content">
    <ng-container [ngSwitch]="activeMenuItem">
      <ng-container *ngSwitchCase="menuItem['calendar']" [ngTemplateOutlet]="Calendar"></ng-container>
      <ng-container *ngSwitchCase="menuItem['randomRange']" [ngTemplateOutlet]="RandomRange"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #Footer>
  <div class="footer">
    <sl-button class="sl-button" [type]="'secondary'" [size]="'s'" (clicked)="cancelSelection()"> Cancelar </sl-button>
    <div class="footer__buttons-right">
      <sl-button class="sl-button" [type]="'secondary'" [size]="'s'" [disabled]="isRemoveDisabled" (clicked)="removeSelection()">
        {{ 'dateFilter.remove' | transloco }}
      </sl-button>
      <sl-button class="sl-button" [type]="'primary'" [size]="'s'" [disabled]="isApplyDisabled" (clicked)="applySelection()">
        {{ 'dateFilter.select' | transloco }}
      </sl-button>
    </div>
  </div>
</ng-template>

<!-- * COMPONENTS -->
<ng-template #Calendar>
  <p-calendar
    #Calendar
    [inline]="calendar.inline"
    [selectionMode]="calendar.selectionMode"
    [readonlyInput]="calendar.readonlyInput"
    [numberOfMonths]="calendar.numberOfMonths"
    [(ngModel)]="dates"
    inputStyleClass="hidden-input"
    (ngModelChange)="onDatesChange()"
  >
  </p-calendar>
</ng-template>

<ng-template #RandomRange>
  <sl-random-range [(dates)]="dates" (datesChange)="onDatesChange()"> </sl-random-range>
</ng-template>
