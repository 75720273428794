import { OrderStore } from './../../../../core/services/stores/order.store';
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NavigationService } from '@sl/services';
import { SharedModule } from '@sl/shared';
import dayjs from 'dayjs';
import { filter, Observable } from 'rxjs';
import { OrderSummaryClientId } from 'src/app/core/features/order/domain/enums/orderSummaryStatus.enum';
import { OrderSummary } from 'src/app/core/features/order/domain/orderSummary.model';
import { GetOrdersFilter, GetOrdersResponse } from 'src/app/core/features/order/infrastructure/repositories/order.repository.interface';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { ButtonFilterOrdersComponent } from '../../components/button-filter-orders/button-filter-orders.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sl-orders',
  templateUrl: './orders.page.html',
  styleUrls: ['./orders.page.scss'],
  standalone: true,
  imports: [SharedModule, ButtonFilterOrdersComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersPageComponent implements OnInit {
  orders$ = this.orderStore.selectOrders();

  table: OrderSummaryTableItem[] = [];

  isLoading$!: Observable<any>;
  isDownloading = false;
  showErrorModal = false;

  totalTablePages!: number;
  pageIndex!: number;
  pageSize!: number;

  // Filters
  dateFilter = {
    startDate: null as Date | null,
    endDate: null as Date | null
  };

  buttonFilter: Partial<GetOrdersFilter> = {
    status: this.navigationService.getQueryParam('status')?.split(','),
    type: this.navigationService.getQueryParam('type')
  };

  // Api Params
  orderParams: GetOrdersFilter = {
    pageSize: this.orderStore.getPageSize() ?? 10,
    pageIndex: this.orderStore.getPageIndex() ?? 1,
    claimId: null,
    orderId: null,
    status: null,
    startDate: null,
    endDate: null,
    billId: null,
    type: null,
    clientId: null
  };

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly orderStore: OrderStore,
    private readonly downloadService: DownloadService,
    private readonly spinnerService: SpinnerService,
    private readonly navigationService: NavigationService,
    private readonly translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.getTableData();
    this.initTable();
  }

  // Filters
  onDatePickerDates(dates: Date[]): void {
    this.dateFilter.startDate = dates[0];
    this.dateFilter.endDate = dates[1];
    this.resetPageIndex();
    this.getTableData();
  }

  onDatePickerRemove(): void {
    this.dateFilter.startDate = null;
    this.dateFilter.endDate = null;
    this.resetPageIndex();
    this.getTableData();
  }

  onSubmitFilters(filters: Partial<GetOrdersFilter>): void {
    this.buttonFilter = { ...filters };
    this.resetPageIndex();
    this.getTableData();
  }

  onDiscardFilters(filters: Partial<GetOrdersFilter>): void {
    this.buttonFilter = { ...filters };
    this.resetPageIndex();
    this.getTableData();
  }

  // Download
  onDownloadTableClick(): void {
    this.downloadAllOrders();
  }

  // Table
  onRowClick(data: any): void {
    this.goToDetail(data['orderNumber']);
  }

  onChangePage(pages: Pages): void {
    this.orderParams.pageSize = pages.pageSize;
    this.orderParams.pageIndex = pages.pageIndex;
    this.getTableData();
  }

  /* Private */
  private getTableData(): void {
    const startDate = this.dateFilter.startDate ? mapDateToApi(this.dateFilter.startDate) : null;
    const endDate = this.dateFilter.endDate ? mapDateToApi(this.dateFilter.endDate) : null;
    this.orderParams = { ...this.orderParams, ...this.buttonFilter, startDate, endDate };
    this.getOrders(this.orderParams);
  }

  // Api
  private getOrders(params?: GetOrdersFilter): void {
    this.isLoading$ = this.spinnerService.getLoadingObservable('orders');
    this.orderStore.actionGetOrders({ ordersFilter: params });
  }

  private async getOrdersPromise(params?: GetOrdersFilter): Promise<GetOrdersResponse> {
    return this.orderStore.actionGetOrdersPromise({ ordersFilter: params });
  }

  // Initialization
  private initTable(): void {
    this.orders$
      .pipe(
        filter(({ list, loading, error }) => {
          if (error) {
            this.showErrorModal = true;
          }
          return Boolean(list) && !loading && !error;
        })
      )
      .subscribe(({ list, total, pageSize, pageIndex }) => {
        this.table = list?.map((order) => this.mapOrderToTableItem(order)) ?? [];
        this.totalTablePages = Math.ceil(total! / pageSize!);
        this.pageIndex = pageIndex!;
        this.pageSize = pageSize!;
      });
  }

  // Filters
  private resetPageIndex(): void {
    this.orderParams.pageIndex = 1;
  }

  // Download table feature
  private async downloadAllOrders(): Promise<void> {
    this.isDownloading = true;
    try {
      const { pageSize, pageIndex, ...filters } = this.orderParams;
      const { list } = await this.getOrdersPromise(filters);
      const ordersTable = list?.map((order) => this.mapDataToDownloadTableItem(order));
      this.downloadTable(ordersTable);
    } catch (e) {
      console.error(e);
      this.showErrorModal = true;
    } finally {
      this.isDownloading = false;
      this.cdr.detectChanges();
    }
  }

  private downloadTable(table: any): void {
    this.downloadService.exportAsExcel(table, 'listado_de_encargos', 'orders');
  }

  // Go to detail feature
  private goToDetail(orderId: string): void {
    this.navigationService.navigate({ route: `orders/${orderId}` });
  }

  // Mappers
  private mapOrderToTableItem(order: OrderSummary): OrderSummaryTableItem {
    return {
      value: order,
      enterprise: order.clientId,
      orderNumber: order.order.id,
      policyNumber: order.policeId,
      proceedingNumber: order.proceeding.id,
      deceasedName: order.affectedName,
      activity: order.activity,
      deceasedDate: order.mainDate ? mapApiToDate(order.mainDate) : '',
      orderDate: order.order.date ? mapApiToDate(order.order.date) : '',
      state: order.status.status,
      orderType: order.order.type
    };
  }

  private mapDataToDownloadTableItem(item: OrderSummary): DownloadTableItem {
    const { value, enterprise, orderType, ...downloadTableItem } = this.mapOrderToTableItem(item);
    const orderTypeTranslations = orderType
      ?.map((t) => this.translocoService.translate('orderType.' + t).replace('orderType.', ''))
      .filter((translation) => translation)
      .join(', ');
    return {
      enterprise: CLIENT_ID_MAP[enterprise as OrderSummaryClientId],
      orderType: orderTypeTranslations,
      ...downloadTableItem
    };
  }
}

interface Pages {
  pageSize: number;
  pageIndex: number;
}
const mapApiToDate = (orderDate: any): string => dayjs(orderDate).utc().format('DD/MM/YYYY');
const mapDateToApi = (orderDate: any): string => dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss[Z]');

type OrderSummaryTableItem = {
  value: OrderSummary;
  enterprise: string;
  orderNumber: string;
  policyNumber: string;
  proceedingNumber: string;
  deceasedName: string;
  activity: string;
  deceasedDate: string;
  orderDate: string;
  state: string;
  orderType: string[];
};

export type DownloadTableItem = Omit<OrderSummaryTableItem, 'value' | 'orderType'> & { orderType: string };

const CLIENT_ID_MAP: Record<OrderSummaryClientId, string> = {
  ['IG']: 'Iris Global',
  ['SL']: 'Santalucía'
};
