<sl-places-dropdown
  [titleColor]="titleColor"
  [activeColor]="activeColor"
  [optionAll]="optionAll"
  [options]="placeOptions"
  [value]="placesOption"
  (valueChange)="onPlaceOptionChange($event)"
  (closeSession)="onCloseSession()"
></sl-places-dropdown>

<sl-info-modal
  *ngIf="modalConfirmAllPlaces.show"
  [title]="modalConfirmAllPlaces.title"
  [subtitle]="modalConfirmAllPlaces.subtitle"
  [description]="modalConfirmAllPlaces.description"
  (close)="onModalConfirmAllPlacesClose()"
  (dismiss)="onModalConfirmAllPlacesDismiss()"
  (accept)="onModalConfirmAllPlacesAccept()"
></sl-info-modal>

<sl-info-modal
  *ngIf="modalConfirmPlace.show"
  [title]="modalConfirmPlace.title"
  [subtitle]="modalConfirmPlace.subtitle"
  [description]="modalConfirmPlace.description"
  (close)="onModalConfirmPlaceClose()"
  (dismiss)="onModalConfirmPlaceDismiss()"
  (accept)="onModalConfirmPlaceAccept()"
></sl-info-modal>

<!-- 
<sl-info-modal
  *ngIf="modalRemovePlace.show"
  [title]="modalRemovePlace.title"
  [subtitle]="modalRemovePlace.subtitle"
  [description]="modalRemovePlace.description"
  (close)="onModalRemovePlaceClose()"
  (dismiss)="onModalRemovePlaceDismiss()"
  (accept)="onModalRemovePlaceAccept()"
></sl-info-modal> 
-->
