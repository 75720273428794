import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '@sl/shared';

@Component({
  selector: 'sl-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationCardComponent {
  @Input() volumetryId = '';
  @Input() icon = '';
  @Input() notifications = 0;

  type = -1;

  links: Record<string, any> = {
    v00001: {
      // Encargos pendientes de gestionar
      type: 1,
      url: 'orders'
    },
    v00002: {
      // Encargos fuera de plazo
      type: 2,
      url: 'orders'
    },
    v00003: {
      // Encargos para facturar fuera de plazo
      type: 3,
      url: 'orders'
    },
    v00004: {
      // Nuevos encargos
      type: 4,
      url: 'orders'
    },
    v00005: {
      // Notas no leídas
      type: 5,
      url: 'orders'
    },
    v00006: {
      // Nuevas segundas comunicaciones
      type: 6,
      url: 'orders'
    }
  };

  constructor(private readonly router: Router) {}

  ngOnInit() {
    this.type = this.getType(this.volumetryId);
  }

  public getType(volumetryId: string): number {
    return this.links[volumetryId]?.type;
  }

  public navigateToLink(volumetryId: string): void {
    let queryParams = {};

    if (volumetryId == 'v00001') {
      queryParams = {
        status: 'PENDING,BILLISSUE,MODIFIED'
      };
    } else if (volumetryId == 'v00002') {
      queryParams = {
        type: 'OUTOFTIME',
        status: 'PENDING,BILLISSUE,MODIFIED'
      };
    } else if (volumetryId == 'v00003') {
      queryParams = {
        type: 'OUTOFTIME',
        status: 'BILLISSUE'
      };
    } else if (volumetryId == 'v00004') {
      queryParams = {
        type: 'NEW'
      };
    } else if (volumetryId == 'v00005') {
      queryParams = {
        type: 'NEWNOTE'
      };
    } else if (volumetryId == 'v00006') {
      queryParams = {
        type: 'ISSUE'
      };
    }
    const url = this.links[volumetryId]?.url;

    this.router.navigate([url], { queryParams });
  }
}
