<div class="itemTable-container">
  <span>{{ title }}</span>
  <div class="itemTable-container__content" [style.gap]="columnSpace">
    <ng-container *ngFor="let num of [].constructor(columnNumber); let i = index; trackBy: trackByFn">
      <div class="itemTable-container__content__item" [style.width]="100 / columnNumber + '%'" [style.gap]="rowSpace">
        <ng-container *ngFor="let item of names.slice(rows * i, rows * i + rows); let j = index; trackBy: trackByFn">
          <div class="itemTable-container__content__cell">
            <span>{{ 'dataTable.' + item | transloco }}:</span>
            <span>{{ descriptions[j + rows * i] }}</span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
