import { ProviderTypeIdentification } from '../../domain/enums/provider.enums';
import { IProviderIdentificationEntity } from './providerIdentification.entity.interface';

export class ProviderIdentificationEntity implements IProviderIdentificationEntity {
  readonly typeIdentification: ProviderTypeIdentification;
  readonly value: string;

  constructor(data: IProviderIdentificationEntity) {
    this.typeIdentification = data.typeIdentification;
    this.value = data.value;
  }
}
