/* eslint-disable @typescript-eslint/naming-convention */
/**
 * The purpose of this is avoiding string literals routes
 */

// TODO: Add titles to i18n, then the page-title-strategy.service will translate this with the translate service
export const ROUTES = {
  EMPTY: {
    PATH: '',
    TITLE: ''
  },
  WILDCARD: {
    PATH: '**',
    TITLE: ''
  },
  LOGIN: {
    PATH: 'login',
    TITLE: 'Inicio de sesión' // 'page.login.title'
  },
  GLOBAL_POSITION: {
    PATH: 'global-position',
    TITLE: 'Posición Global' // 'page.global-position.title'
  },
  LIQUIDATIONS: {
    PATH: 'liquidations',
    TITLE: 'Liquidaciones' // 'page.liquidations.title'
  },
  ORDERS: {
    PATH: 'orders',
    TITLE: 'Encargos' // 'page.orders.title'
  },
  COMMUNICATIONS: {
    PATH: 'communications',
    TITLE: 'Segundas Comunicaciones' // 'page.communications.title'
  },
  COMMUNIQUES: {
    PATH: 'communiques',
    TITLE: 'Comunicados' // 'page.global-position.title'
  },
  DOCUMENTATION: {
    PATH: 'documentation',
    TITLE: 'Documentación' // 'page.documentation.title'
  },
  PROFILE: {
    PATH: 'profile',
    TITLE: 'Perfil' // 'page.profile.title'
  },
  SANDBOX: {
    PATH: 'sandbox',
    TITLE: 'Sandbox' // 'page.sandbox.title'
  }
} as const;
