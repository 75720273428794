import { INotificationEntity } from './notification.entity.interface';

export class NotificationEntity implements INotificationEntity {
  readonly notificationId: string;
  readonly title: string;
  readonly content: string;
  readonly createdDate: string | null;
  readonly expirationDate: string;
  readonly readDate: string | null;
  readonly documentId: string;
  readonly documentName: string;

  constructor(data: INotificationEntity) {
    this.notificationId = data.notificationId;
    this.title = data.title;
    this.content = data.content;
    this.createdDate = data.createdDate ? data.createdDate : null;
    this.expirationDate = data.expirationDate;
    this.readDate = data.readDate ? data.readDate : null;
    this.documentId = data.documentId;
    this.documentName = data.documentName;
  }

  // Puedes agregar métodos adicionales aquí si es necesario
}
