<div class="field">
  <label class="field__label" for="{{ name }}-id">{{ label }}</label>
  <span class="p-input-icon-left">
    <i class="field__icon" [ngClass]="icon"></i>
    <input
      id="{{ name }}-id"
      class="field__input"
      [type]="type"
      [name]="name"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [(ngModel)]="value"
      (ngModelChange)="onModelChange($event)"
    />
  </span>
  <small id="{{ name }}-help">{{ helperText }}</small>
</div>
