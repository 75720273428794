/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule } from '@sl/shared';

@Component({
  selector: 'sl-sandbox-template',
  templateUrl: './sandbox-template.component.html',
  styleUrls: ['./sandbox-template.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SandboxTemplateComponent {
  constructor() {}
}
