import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DialogConfig } from '@sl/components';
import { SharedModule } from '@sl/shared';

export type IbanData = {
  [key: string]: any;
  country: string;
  entity: string;
  branch: string;
  account: string;
};

@Component({
  selector: 'sl-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditModalComponent implements OnChanges {
  @Input() data: any;
  @Input() title = '';
  @Input() sectionTitle = '';
  @Input() subtitle = '';

  @Input() multiAddress = true;
  @Input() document = false;

  @Input() visible = false;

  @Output() readonly visibleChange = new EventEmitter<boolean>();
  @Output() readonly saveData = new EventEmitter();

  @ViewChild('fileInput') fileInputRef!: ElementRef;

  auxArray: any;
  editData: any = {};
  showDocument = false;
  descriptionsAreas: string[] = [];
  selectedFile!: File | null;
  allowedDocument = true;
  ibanData: IbanData = {
    country: '',
    entity: '',
    branch: '',
    account: ''
  };

  tableDataEntries: any[] = [];

  dialog: DialogConfig = {
    modal: true,
    showHeader: true,
    showFooter: true
  };

  areaEditedData: any[] = [];
  editedData: any;
  editedDataKeys: any[] = [];
  allowedTypeFiles = [
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  allowedIban = true;

  constructor() {}

  addDataEntryFromArray(element: any[]) {
    const entries: any = [];

    element.forEach((item: any) => {
      const objectKeys = Object.keys(item);
      const dataEntry = [item[objectKeys[0]], item[objectKeys[1]]];

      entries.push(dataEntry);
    });

    return entries;
  }

  addDataEntryFromObject(object: object) {
    return Object.entries(object);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.data) {
      return;
    }

    this.setDataByTitle();
  }

  setDataByTitle() {
    if (this.sectionTitle === 'area') {
      this.setAreaData();
    } else {
      this.setData();
    }
  }

  /*setGeneralData() {
    this.generalEditedData = this.deepCopy(this.data);
    this.generalEditedDataKeys = Object.keys(this.generalEditedData);
  }*/

  setAreaData() {
    this.areaEditedData = this.deepCopy(this.data.contacts);
    this.data.contacts.forEach((contact: any) => {
      this.addDescriptionArea(contact.description);
    });
  }

  addDescriptionArea(descriptionArea: string) {
    if (!this.descriptionsAreas.includes(descriptionArea)) {
      this.descriptionsAreas.push(descriptionArea);
    }
  }

  setData() {
    this.editedData = this.deepCopy(this.data);
    this.editedDataKeys = Object.keys(this.editedData);
    if (this.editedDataKeys.includes('iban')) {
      this.setIbanValues();
    }
  }

  deepCopy(itemToCopy: any) {
    return JSON.parse(JSON.stringify(itemToCopy));
  }

  setIbanValues() {
    this.data.iban = this.data.iban.replace(/\s/g, ''); //Elimina los espacios
    this.ibanData.country = this.data.iban.slice(0, 4);
    this.ibanData.entity = this.data.iban.slice(4, 8);
    this.ibanData.branch = this.data.iban.slice(8, 12);
    this.ibanData.account = this.data.iban.slice(12);
    this.allowedIban = true;
  }

  setAllowedIban(actualKey: string, value: string) {
    this.allowedIban = true;
    this.ibanData[actualKey as keyof IbanData] = value;
    Object.keys(this.ibanData).forEach((key) => {
      if (key === 'account') {
        if (!this.isLengthEqualTo(this.ibanData[key as keyof IbanData], 12)) {
          this.allowedIban = false;
        }
      } else {
        if (!this.isLengthEqualTo(this.ibanData[key as keyof IbanData], 4)) {
          this.allowedIban = false;
        }
      }
    });
  }

  isLengthEqualTo(text: string, lengthNumber: number): boolean {
    return text.length === lengthNumber;
  }

  createObjectFromEntitiesArray() {
    const newObject: any = {};
    this.tableDataEntries.forEach((entry: string[]) => {
      newObject[entry[0]] = entry[1];
    });

    return newObject;
  }

  onVisibleChange(visible: boolean): void {
    this.visible = visible;
    if (this.document) {
      this.cleanDocument();
    }
    this.visibleChange.emit(visible);
  }

  onSaveData(): void {
    if (this.sectionTitle === 'area') {
      const editedSection = {
        title: this.sectionTitle,
        data: this.areaEditedData
      };

      this.saveData.emit(editedSection);
    } else {
      this.editedData.iban = '';
      Object.keys(this.ibanData).forEach((key: string) => {
        this.editedData.iban += this.ibanData[key as keyof IbanData] + ' ';
      });
      this.editedData.iban.trim(); //Elimina el espacio del final
      const editedSection = {
        title: this.sectionTitle,
        data: this.editedData
      };

      this.saveData.emit(editedSection);
    }

    this.closeModal();
  }

  uploadDocument(): void {
    if (this.fileInputRef) {
      const fileInput = this.fileInputRef.nativeElement as HTMLInputElement;
      fileInput.click();
    }
  }

  deleteDocument(): void {
    this.selectedFile = null;
  }

  cleanDocument() {
    this.selectedFile = null;
    this.allowedDocument = true;
  }

  handleDocument(event: any) {
    const selectedFile = event.target.files[0];
    if (this.allowedTypeFiles.includes(selectedFile.type)) {
      this.selectedFile = selectedFile;
      this.allowedDocument = true;
    } else {
      this.selectedFile = null;
      this.allowedDocument = false;
    }
  }

  objectKeys(object: object) {
    return Object.keys(object);
  }

  private closeModal(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    if (this.document) {
      this.cleanDocument();
    }
  }
}
