<sl-dialog
  [modal]="dialog.modal!"
  [showHeader]="dialog.showHeader!"
  [showFooter]="dialog.showFooter!"
  [title]="dialog.title!"
  [subtitle]="dialog.subtitle!"
  [(visible)]="visible!"
  (visibleChange)="onVisibleChange($event)"
>
  <div content class="content">
    <ng-container [ngTemplateOutlet]="AddForm"></ng-container>
  </div>
  <div footer class="footer">
    <ng-container [ngTemplateOutlet]="FooterActions"></ng-container>
  </div>
</sl-dialog>

<!--Add form-->
<ng-template #AddForm>
  <sl-input
    *ngFor="let input of getKeys()"
    [label]="'add-modal.' + input | transloco"
    [placeholder]="input === 'label' ? 'Introducir nueva área' : ''"
    [value]="data[input]"
    (valueChange)="data[input] = $event; isAllNeccessaryData()"
  ></sl-input>
</ng-template>

<ng-template #FooterActions>
  <div class="modal__content">
    <sl-button
      (clicked)="onSaveData()"
      [disabled]="!neccessaryData"
      [label]="'edit-modal.save' | transloco"
      [borderRound]="true"
      [type]="'confirm'"
    ></sl-button>
  </div>
</ng-template>
