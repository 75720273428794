/* eslint-disable @typescript-eslint/naming-convention */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, Observable, of, switchMap } from 'rxjs';
import { IMocksService, MockResponseParams } from '.';

/** Adds functionality for mocking responses (Basically used in providers) */
@Injectable({ providedIn: 'root' })
export class MocksService implements IMocksService {
  private DEFAULT_DELAY = 1000;

  constructor() {}

  mockResponse<T>(body?: T): Observable<T> {
    let response$;
    if (!body) {
      response$ = of(void 0) as Observable<T>;
    } else {
      response$ = of(body);
    }

    return response$.pipe(delay(this.DEFAULT_DELAY));
  }

  // * Not used by now
  mockResponseHttp<T>({ body, statusCode = 200 }: MockResponseParams<T>): Observable<HttpResponse<T>> {
    // null is necessary
    return of(null).pipe(
      delay(this.DEFAULT_DELAY),
      switchMap(() =>
        of(
          new HttpResponse<T>({
            status: statusCode,
            body
          })
        )
      )
    );
  }
}
