import { IOrderNoteNoteMessageDocEntity } from './orderNoteNoteMessageDoc.entity.interface';

export class OrderNoteNoteMessageDocEntity implements IOrderNoteNoteMessageDocEntity {
  readonly docId: string | null;
  readonly docName: string;
  readonly docType: string;
  readonly creationDate: string;
  readonly metadata: string;

  constructor(data: IOrderNoteNoteMessageDocEntity) {
    this.docId = data.docId;
    this.docName = data.docName;
    this.docType = data.docType;
    this.creationDate = data.creationDate;
    this.metadata = data.metadata;
  }
}
