<div
  class="banner-container"
  [style.background]="'linear-gradient(315deg,' + secondColor + ' 0%, ' + firstColor + ' 100%)'"
  *transloco="let t"
>
  <div class="banner-container__text">
    <span class="banner-container__text__title">{{ title }}</span>
    <div class="banner-container__text__info">
      <i
        [ngClass]="{
          'icon-notification-simple': description === 'notification',
          'icon-check': description === 'up-to-date',
          'icon-alert1': description === 'no-orders'
        }"
      >
      </i>
      <span>{{ t('banner.description-' + description) }}</span>
    </div>
  </div>
  <!-- <div class="banner-container__image">
    <img src="../../../../assets/icons/interfunerarias.png" alt="Interfunerarias image" />
  </div> -->
</div>
