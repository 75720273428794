<div class="info-modal__container">
  <div class="info-modal__container__content">
    <i class="pi icon-cerrar" (click)="onClose()" (keypress)="onClose()"></i>
    <i class="pi icon-sede"></i>
    <span>{{ title }}</span>
    <span>{{ subtitle }}</span>
    <p>{{ description }}</p>
    <div class="info-modal__container__content__buttons">
      <sl-button class="button" [borderRound]="true" [type]="'primary'" (click)="onAccept()" [size]="'l'"> Confirmar </sl-button>
      <sl-button class="button" [borderRound]="true" [type]="'secondary'" (click)="onDismiss()" [size]="'l'"> Cancelar </sl-button>
    </div>
  </div>
</div>
