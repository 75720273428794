/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { SettlementAmount } from '../../domain/settlementAmount.model';
import { SettlementAmountEntity } from '../entities/settlementAmountEntity.model';
import { ISettlementAmountMapper } from './settlementAmount.mapper.interface';

@Injectable({ providedIn: 'root' })
export class SettlementAmountMapper implements ISettlementAmountMapper {
  mapFromApi(apiData: SettlementAmountEntity): SettlementAmount {
    return new SettlementAmount({
      amount: apiData.amount,
      netAmount: apiData.netAmount,
      tax: Number(apiData.tax)
    });
  }

  mapToApi(settlementAmount: SettlementAmount): SettlementAmountEntity {
    return new SettlementAmountEntity({
      amount: settlementAmount.amount,
      netAmount: settlementAmount.netAmount,
      tax: settlementAmount.tax ? settlementAmount.tax.toString() : null
    });
  }
}
