<div
  *transloco="let t"
  (click)="toggleDropdown()"
  (keyup)="handleKeyUp($event)"
  class="filter__container"
  [style.background-color]="backgroundColor"
  [ngClass]="{ filter__container__border: showArrow && showDropdown, disabled: disabled }"
  tabindex="0"
>
  <input
    (input)="onValueChange()"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [readonly]="showArrow"
    [style.cursor]="showArrow && 'default'"
    [style.background-color]="disabled ? 'var(--color-grey16)' : 'var(--color-white)'"
  />
  <i
    *ngIf="showArrow"
    class="pi icon"
    [ngClass]="{ 'icon-arrow-down': !showDropdown, 'icon-arrow-up': showDropdown }"
    [style.color]="disabled ? 'var(--color-grey17)' : 'var(--color-primary6)'"
  ></i>
  <i *ngIf="searchIcon" class="pi icon-buscar icon"></i>
  <span>{{ filterName }}</span>

  <div *ngIf="showArrow && showDropdown" class="filter__container__dropdown">
    <div
      *ngFor="let option of dropdownOptions; let i = index"
      (click)="valueChange.emit(option)"
      (keypress)="valueChange.emit(option)"
      class="filter__container__dropdown__option"
    >
      <span class="option">{{ translatedOptions[i] }}</span>
    </div>
  </div>
</div>
