<article class="total">
  <header class="total__header">
    <h3 class="total__title">{{ title }}</h3>
  </header>
  <section>
    <form>
      <ul class="list-total">
        <ng-container *ngFor="let item of dataGrouped; trackBy: trackByFn">
          <li>
            <button class="list-total__item" type="button" (click)="onItemClick(item)">
              <span>{{ prefix }} </span>
              <span>{{ item.name }}: </span>
              <span>{{ item.amount | round : 2 }}{{ currency }}</span>
            </button>
          </li>
        </ng-container>
      </ul>
    </form>
    <div class="amount-total">
      <h4 class="amount-total__title">{{ subtitle }}</h4>
      <p class="amount-total__content">
        <strong>{{ total | round : 2 }}{{ currency }}</strong>
      </p>
    </div>
  </section>
</article>
