<article class="explanation">
  <section class="explanation__summary">
    <sl-sandbox-card>In this sandbox, you can safely test, experiment, and learn without putting the application at risk.</sl-sandbox-card>
  </section>
</article>

<!-- <sl-sandbox-template>
  <h3 title>Dialog</h3>
  <p description>This is an example</p>
  <div content>
    <sl-dialog-example></sl-dialog-example>
  </div>
</sl-sandbox-template> -->

<!-- <sl-sandbox-template>
  <h3 title>Modal modify bill</h3>
  <p description>This is an example</p>
  <div content>
    <sl-modal-modify-bill-poc></sl-modal-modify-bill-poc>
  </div>
</sl-sandbox-template> -->

<!-- Example -->
<sl-sandbox-template>
  <h3 title><!-- Here your title --></h3>
  <p description><!-- Here your description --></p>
  <div content>
    <!-- Here your component -->
  </div>
</sl-sandbox-template>
