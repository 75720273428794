/* eslint-disable no-unused-vars */
import { NgModule } from '@angular/core';
import { ExtraOptions, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { ROUTES } from '@sl/constants';
import { isNotProductionGuard } from '@sl/guards';
import { CustomRouteReuseStrategy } from '@sl/services';
import { SharedModule } from '@sl/shared';

const routes: Routes = [
  {
    path: ROUTES.EMPTY.PATH,
    pathMatch: 'full',
    redirectTo: ROUTES.GLOBAL_POSITION.TITLE
  },
  {
    path: ROUTES.GLOBAL_POSITION.PATH,
    loadChildren: () => import('@sl/modules').then((m) => m.GlobalPositionRoutingModule),
    title: ROUTES.GLOBAL_POSITION.TITLE
  },
  {
    path: ROUTES.LIQUIDATIONS.PATH,
    loadChildren: () => import('@sl/modules').then((m) => m.LiquidationsRoutingModule),
    title: ROUTES.LIQUIDATIONS.TITLE
  },
  {
    path: ROUTES.ORDERS.PATH,
    loadChildren: () => import('@sl/modules').then((m) => m.OrdersRoutingModule),
    title: ROUTES.ORDERS.TITLE
  },
  {
    path: ROUTES.COMMUNICATIONS.PATH,
    loadChildren: () => import('@sl/modules').then((m) => m.CommunicationsRoutingModule),
    title: ROUTES.COMMUNICATIONS.TITLE
  },
  {
    path: ROUTES.COMMUNIQUES.PATH,
    loadChildren: () => import('@sl/modules').then((m) => m.CommuniquesRoutingModule),
    title: ROUTES.COMMUNIQUES.TITLE
  },
  {
    path: ROUTES.DOCUMENTATION.PATH,
    loadChildren: () => import('@sl/modules').then((m) => m.DocumentationRoutingModule),
    title: ROUTES.DOCUMENTATION.TITLE
  },
  {
    path: ROUTES.PROFILE.PATH,
    loadChildren: () => import('@sl/modules').then((m) => m.ProfileRoutingModule),
    title: ROUTES.PROFILE.TITLE
  },
  {
    path: ROUTES.SANDBOX.PATH,
    loadChildren: () => import('@sl/modules').then((m) => m.SandboxRoutingModule),
    title: ROUTES.SANDBOX.TITLE,
    canActivate: [isNotProductionGuard]
  },
  { path: '**', redirectTo: ROUTES.GLOBAL_POSITION.PATH }
];

const config: ExtraOptions = {
  // useHash: true // Sets HashLocationStrategy (PathLocationStrategy is the default)
  // bindToComponentInputs: true // Angular 16
  onSameUrlNavigation: 'reload'
};

/**
 * Módulo de gestión de las rutas de la aplicación
 *
 * @author Arquitectura Empresarial - Santalucía
 * @exports
 * @class AppRoutingModule
 * @typedef {AppRoutingModule}
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, config), SharedModule],
  exports: [RouterModule],
  providers: [{ provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }]
})
export class AppRoutingModule {}
