/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@sl/core';

import { ApiService } from '@sl/services';
import { map, Observable } from 'rxjs';
import { ProviderEntity } from '../entities/provider.entity';
import { ProviderDataEntity } from '../entities/providerData.entity';
import { ProviderMapper } from '../mappers/provider.mapper';
import { ProviderDataMapper } from '../mappers/providerData.mapper';
import {
  GetProviderDataParams,
  GetProviderDataResponse,
  GetProviderParams,
  GetProviderResponse,
  IProviderRepository,
  PutProviderParams,
  PutProviderResponse
} from './provider.repository.interface';
import { ProviderMocks } from './provider.repository.mocks';

@Injectable({ providedIn: 'root' })
export class ProviderRepository implements IProviderRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly providerMocks: ProviderMocks,
    private readonly providerMapper: ProviderMapper,
    private readonly providerDataMapper: ProviderDataMapper
  ) {}

  /**
   * Using mappers because interfaces do not ensure the type of the returned object.
   * If isMocked redirects to Mocks Provider (injection is not used to have each request mocked separately)
   */

  getProvider({ providerId, placeId }: GetProviderParams): Observable<GetProviderResponse> {
    let response;
    if (APP_CONFIG.repository.provider.getProvider.isMocked) {
      response = this.providerMocks.getProvider({ providerId, placeId });
    } else {
      response = this.apiService
        .get<ProviderEntity>({ url: `/api/v1/provider/${providerId}/place/${placeId}` })
        .pipe(map((res) => this.providerMapper.mapFromApi(res)));
    }
    return response;
  }

  getProviderData({ providerId }: GetProviderDataParams): Observable<GetProviderDataResponse> {
    let response;
    if (APP_CONFIG.repository.provider.getProviderData.isMocked) {
      response = this.providerMocks.getProviderData({ providerId });
    } else {
      response = this.apiService
        .get<ProviderDataEntity>({ url: `/api/v1/providers/${providerId}` })
        .pipe(map((res) => this.providerDataMapper.mapFromApi(res)));
    }
    return response;
  }

  putProvider({ providerId, placeId, provider }: PutProviderParams): Observable<PutProviderResponse> {
    let response;
    if (APP_CONFIG.repository.provider.putProvider.isMocked) {
      response = this.providerMocks.putProvider({ providerId, placeId, provider });
    } else {
      response = this.apiService.put<ProviderEntity>({
        url: `/api/v1/provider/${providerId}/place/${placeId}`,
        body: this.providerMapper.mapToApi(provider)
      });
    }
    return response;
  }
}
