import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sl-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoModalComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() description = '';

  @Output() readonly close = new EventEmitter();
  @Output() readonly dismiss = new EventEmitter();
  @Output() readonly accept = new EventEmitter();

  constructor() {}

  onClose(): void {
    this.close.emit();
  }

  onDismiss(): void {
    this.dismiss.emit();
  }

  onAccept(): void {
    this.accept.emit();
  }
}
