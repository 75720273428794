/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@sl/core';
import { ApiService } from '@sl/services';
import { map, Observable } from 'rxjs';
import { OrderNoteEntity } from '../entities/orderNote.entity';
import { OrderNoteMapper } from '../mappers/orderNote.mapper';
import {
  GetOrderNotesParams,
  GetOrderNotesResponse,
  IOrderNoteRepository,
  PostOrderNotesParams,
  PostOrderNotesResponse,
  PutOrderNoteParams,
  PutOrderNoteResponse
} from './orderNote.repository.interface';
import { OrderNoteMocks } from './orderNote.repository.mocks';

@Injectable({ providedIn: 'root' })
export class OrderNoteRepository implements IOrderNoteRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly orderNoteMocks: OrderNoteMocks,
    private readonly orderNoteMapper: OrderNoteMapper
  ) {}

  /**
   * Using mappers because interfaces do not ensure the type of the returned object.
   * If isMocked redirects to Provider Mocks Provider (injection is not used to have each request mocked separately)
   */

  getOrderNotes({ providerId, placeId, orderId }: GetOrderNotesParams): Observable<GetOrderNotesResponse> {
    let response;
    if (APP_CONFIG.repository.orderNote.getOrderNotes.isMocked) {
      response = this.orderNoteMocks.getOrderNotes({ providerId, placeId, orderId });
    }
    response = this.apiService
      .get<OrderNoteEntity[]>({ url: `/api/v1/provider/${providerId}/place/${placeId}/order/${orderId}/notes` })
      .pipe(map((res) => res.map((data) => this.orderNoteMapper.mapFromApi(data))));
    return response;
  }

  postOrderNotes({ providerId, placeId, orderId, orderNote }: PostOrderNotesParams): Observable<PostOrderNotesResponse> {
    let response;
    if (APP_CONFIG.repository.orderNote.postOrderNotes.isMocked) {
      response = this.orderNoteMocks.postOrderNotes({ providerId, placeId, orderId, orderNote });
    }
    response = this.apiService.post<void>({
      url: `/api/v1/provider/${providerId}/place/${placeId}/order/${orderId}/note`,
      body: this.orderNoteMapper.mapToApi(orderNote)
    });
    return response;
  }

  putOrderNote({ providerId, placeId, orderId, noteId, orderNote }: PutOrderNoteParams): Observable<PutOrderNoteResponse> {
    let response;
    if (APP_CONFIG.repository.orderNote.putOrderNote.isMocked) {
      response = this.orderNoteMocks.putOrderNote({ providerId, placeId, orderId, noteId, orderNote });
    }
    response = this.apiService.put<OrderNoteEntity>({
      url: `/api/v1/provider/${providerId}/place/${placeId}/order/${orderId}/note/${noteId}`,
      body: this.orderNoteMapper.mapToApi(orderNote)
    });
    return response;
  }
}
