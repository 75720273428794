import { SettlementClientId } from './enums/settlement.enums';
import { ISettlement } from './settlement.model.interface';
import { SettlementAmount } from './settlementAmount.model';

export class Settlement implements ISettlement {
  readonly settlementId: string;
  readonly startDate: Date;
  readonly endDate: Date;
  readonly amount: SettlementAmount;
  readonly documentId: string;
  readonly documentName: string;
  readonly clientId: SettlementClientId;
  readonly orders: string[];
  readonly batchId: string;

  constructor(data: ISettlement) {
    this.settlementId = data.settlementId;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.amount = new SettlementAmount(data.amount);
    this.documentId = data.documentId;
    this.documentName = data.documentName;
    this.clientId = data.clientId;
    this.orders = data.orders;
    this.batchId = data.batchId;
  }
}
