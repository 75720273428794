/* eslint-disable no-unused-vars */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trackBy } from '@sl/utils';
import {
  OrderSummaryClientId,
  OrderSummaryStatusStatus,
  OrderSummaryType
} from 'src/app/core/features/order/domain/enums/orderSummaryStatus.enum';
import { GetOrdersFilter } from 'src/app/core/features/order/infrastructure/repositories/order.repository.interface';
import { SharedModule } from '../../../../shared';
import { DropdownOption } from '../../../../shared/components/common/dropdown/dropdown.component';

@Component({
  selector: 'sl-button-filter-orders',
  templateUrl: './button-filter-orders.component.html',
  styleUrls: ['./button-filter-orders.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonFilterOrdersComponent implements OnInit {
  @Input() filters: GetOrdersFilter = { ...defaultFilters };

  @Output() readonly filtersChange = new EventEmitter<GetOrdersFilter>();
  @Output() readonly submitFilters = new EventEmitter<GetOrdersFilter>();
  @Output() readonly discardFilters = new EventEmitter<Partial<GetOrdersFilter>>();

  visible = false;
  initialFilters!: Partial<GetOrdersFilter>;

  /* Section 1 */
  section1 = {
    legend: 'Estados'
  };

  checklistStatus = {
    options: [
      { label: 'Pendiente', value: OrderSummaryStatusStatus.Pending },
      { label: 'Modificado', value: OrderSummaryStatusStatus.Modified },
      { label: 'Pendiente revisar', value: OrderSummaryStatusStatus.Revise },
      { label: 'Incidencia Factura', value: OrderSummaryStatusStatus.BillIssue }
    ],
    value: null as string[] | null
  };

  /* Section 2 */
  section2 = {
    legend: 'Filtrar por nº'
  };

  inputProceedingId = {
    label: 'Buscar',
    placeholder: 'Nº Expediente',
    value: null as string | null
  };

  inputClaimId = {
    label: 'Buscar por',
    placeholder: 'Nº Expediente',
    value: null as string | null
  };

  inputOrderId = {
    label: 'Buscar por',
    placeholder: 'Nº Encargo',
    value: null as string | null
  };

  /* Section 3 */
  section3 = {
    legend: 'Tipo de encargo'
  };

  dropdownType = {
    label: 'Filtrar',
    placeholder: 'Tipo de encargo',
    options: [
      { label: 'Nuevo', value: OrderSummaryType.New },
      { label: 'Nueva nota', value: OrderSummaryType.NewNote },
      { label: 'Fuera de plazo', value: OrderSummaryType.OutofTime },
      { label: 'Incidencia', value: OrderSummaryType.Issue }
    ] as DropdownOption<string>[],
    value: null as string | null
  };

  /* Section 4 */
  section4 = {
    legend: 'Empresas'
  };

  dropdownClientId = {
    label: 'Filtrar',
    placeholder: 'Empresa',
    options: [
      { label: 'Santalucía', value: OrderSummaryClientId.Santalucia },
      { label: 'Iris Global', value: OrderSummaryClientId.Iris }
    ] as DropdownOption<string>[],
    value: null as string | null
  };

  constructor() {}

  ngOnInit(): void {
    this.initialFilters = { ...this.filters };
    this.resetFilters();
  }

  /* Action Buttons */

  handleSubmitFilters(): void {
    this.submitFilters.emit(this.filters);
    this.closePanel();
  }

  handleRemoveFilters(): void {
    this.removeFilters();
    this.discardFilters.emit(this.filters);
    this.closePanel();
  }

  /* Section 1 */

  onChecklistStatus(value: string): void {
    const isSelectedValue = this.checklistStatus.value?.includes(value);
    const selectedValues = this.checklistStatus.value ?? [];
    this.checklistStatus.value = isSelectedValue ? selectedValues.filter((v) => v !== value) : [...selectedValues, value];
    this.filters.status = this.checklistStatus.value;
    this.onChange();
  }

  /* Section 2 */

  onProceedingId(): void {
    this.filters.proceedingId = this.inputProceedingId.value || null;
    this.onChange();
  }

  onClaimId(): void {
    this.filters.claimId = this.inputClaimId.value || null;
    this.onChange();
  }

  onOrderId(): void {
    this.filters.orderId = this.inputOrderId.value || null;
    this.onChange();
  }

  /* Section 3 */

  onDropdownType(): void {
    this.filters.type = this.dropdownType.value;
    this.onChange();
  }

  onDropdownClientId(): void {
    this.filters.clientId = this.dropdownClientId.value;
    this.onChange();
  }

  /* Utils */

  trackByFn(index: number, item: any): any {
    return trackBy(index, item);
  }

  /* Private */

  private onChange(): void {
    this.filtersChange.emit(this.filters);
  }

  private closePanel(): void {
    this.visible = false;
  }

  private resetFilters(): void {
    const { status, proceedingId, claimId, orderId, type, clientId } = this.initialFilters;
    this.checklistStatus.value =
      this.checklistStatus.options.filter((option) => status?.includes(option.value)).map(({ value }) => value) ?? null;
    this.inputProceedingId.value = proceedingId ?? null;
    this.inputClaimId.value = claimId ?? null;
    this.inputOrderId.value = orderId ?? null;
    this.dropdownType.value = this.dropdownType.options.find((option) => option.value === type)?.value ?? null;
    this.dropdownClientId.value = this.dropdownClientId.options.find((option) => option.value === clientId)?.value ?? null;
    this.filters = { ...this.initialFilters };
    this.onChange();
  }

  private removeFilters(): void {
    this.checklistStatus.value = null;
    this.inputProceedingId.value = null;
    this.inputClaimId.value = null;
    this.inputOrderId.value = null;
    this.dropdownType.value = null;
    this.dropdownClientId.value = null;
    this.filters = { ...defaultFilters };
    this.onChange();
  }
}

const defaultFilters: GetOrdersFilter = {
  proceedingId: null,
  orderId: null,
  status: null,
  startDate: null,
  endDate: null,
  notification: null,
  clientId: null,
  claimId: null
  // type: null // We don't want to overwrite this property when removing filters
};
