/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { OrderBilling } from '../../domain/orderBilling.model';
import { OrderBillingEntity } from '../entities/orderBilling.entity';
import { IOrderBillingMapper } from './orderBilling.mapper.interface';

@Injectable({ providedIn: 'root' })
export class OrderBillingMapper implements IOrderBillingMapper {
  mapFromApi(apiData: OrderBillingEntity): OrderBilling {
    return new OrderBilling({
      amount: apiData.amount,
      netAmount: apiData.netAmount,
      tax: apiData.tax
    });
  }

  mapToApi(orderBilling: OrderBilling): OrderBillingEntity {
    return new OrderBillingEntity({
      amount: orderBilling.amount,
      netAmount: orderBilling.netAmount,
      tax: orderBilling.tax
    });
  }
}
