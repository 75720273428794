import { IOrderNoteNoteEntity } from './orderNoteNote.entity.interface';
import { OrderNoteNoteMessageEntity } from './orderNoteNoteMessage.entity';

export class OrderNoteNoteEntity implements IOrderNoteNoteEntity {
  readonly noteId: string | null;
  readonly message: OrderNoteNoteMessageEntity;

  constructor(data: IOrderNoteNoteEntity) {
    this.noteId = data.noteId;
    this.message = new OrderNoteNoteMessageEntity(data.message);
  }
}
