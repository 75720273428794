import { IIssueMessageDocEntity } from './IssueMessageDoc.entity.interface';

export class IssueMessageDocEntity implements IIssueMessageDocEntity {
  readonly docId: string | null;
  readonly docName: string;
  readonly docType: string;
  readonly creationDate: string;
  readonly metadata: string;

  constructor(data?: IIssueMessageDocEntity) {
    this.docId = data?.docId || '';
    this.docName = data?.docName || '';
    this.docType = data?.docType || '';
    this.creationDate = data?.creationDate || '';
    this.metadata = data?.metadata || '';
  }
}
