/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';

import { Order } from '../../domain/order.model';
import { OrderBilling } from '../../domain/orderBilling.model';
import { OrderConcept } from '../../domain/orderConcept.model';
import { OrderEntity } from '../entities/order.entity';
import { IOrderMapper } from './order.mapper.interface';
import { OrderBillingMapper } from './orderBilling.mapper';
import { OrderConceptMapper } from './orderConcept.mapper';
import { OrderSummaryMapper } from './orderSummary.mapper';

@Injectable({ providedIn: 'root' })
export class OrderMapper implements IOrderMapper {
  constructor(
    private orderSummaryMapper: OrderSummaryMapper,
    private orderBillingMapper: OrderBillingMapper,
    private orderConceptMapper: OrderConceptMapper
  ) {}

  mapFromApi(apiData: OrderEntity): Order {
    return new Order({
      orderSummary: this.orderSummaryMapper.mapFromApi(apiData.orderSummary),
      billing: apiData.billing.map((orderBilling: any) => this.orderBillingMapper.mapFromApi(orderBilling)),
      concepts: apiData.concepts.map((orderConcept: any) => this.orderConceptMapper.mapFromApi(orderConcept))
    });
  }

  mapToApi(order: Order): OrderEntity {
    return new OrderEntity({
      orderSummary: this.orderSummaryMapper.mapToApi(order.orderSummary),
      billing: order.billing.map((orderBilling: OrderBilling) => this.orderBillingMapper.mapToApi(orderBilling)),
      concepts: order.concepts.map((orderConcept: OrderConcept) => this.orderConceptMapper.mapToApi(orderConcept))
    });
  }
}
