<!-- <div class="container">
  <ng-container *ngFor="let col of getColumns(); let index; trackBy: trackByFn">
    <div class="container__column" [ngStyle]="{ width: 100 / columns + '%' }">
      <ng-container *ngFor="let row of getRanges(col); trackBy: trackByFn">
        <div class="option" [ngStyle]="{ height: 100 / itemsPerCol + '%' }">
          <input
            class="option__input"
            type="checkbox"
            id="range-{{ index }}"
            name="range"
            [value]="getLabel(row, col)"
            (change)="handleSelectedCheck(row, col)"
            [checked]="checkOption === getLabel(row, col)"
          />
          <span class="option__label">
            {{ getLabel(row, col) }}
          </span>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div> -->

<!-- TODO -->
<div class="container">
  <ng-container *ngFor="let col of [].constructor(columns); let i = index">
    <div class="container__column" [ngStyle]="{ width: 100 / columns + '%' }">
      <ng-container *ngFor="let item of options.slice(i * itemsPerCol, i * itemsPerCol + itemsPerCol); let index">
        <div class="option" [ngStyle]="{ height: 100 / itemsPerCol + '%' }">
          <input
            class="option__input"
            type="checkbox"
            id="range-{{ index }}"
            name="range"
            [value]="item.value"
            (change)="handleSelectedCheck(item.label, item.value[0], item.value[1] ? item.value[1] : null)"
            [checked]="checkOption === item.label"
          />
          <span class="option__label">
            {{ item.label }}
          </span>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
