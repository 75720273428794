<article class="tab-container tab-container__border-bottom tab-container__gap">
  <div *ngIf="personalData" class="tab-container__order__table">
    <sl-simple-table
      [title]="'Datos del encargo'"
      [tableData]="personalData"
      [rows]="2"
      [rowSpace]="'8px'"
      [columnSpace]="'10px'"
    ></sl-simple-table>
    <div class="tab-container__order__table__result">
      <span>{{ 'detail.amount' | transloco }}</span>
      <span>{{ (totalAmount | CurrencyCustom) ?? 0 }} €</span>
    </div>
  </div>
</article>

<article class="tab-container">
  <p-progressSpinner
    *ngIf="(spinnerService.getLoadingObservable('documents') | async) || (spinnerService.getLoadingObservable('bills') | async)"
    [style]="{ width: '100px', height: '100px' }"
    styleClass="custom-spinner"
    strokeWidth="2"
    animationDuration="1s"
  ></p-progressSpinner>

  <sl-table-cards
    *ngIf="order"
    [title]="'Resumen del encargo'"
    [order]="order"
    [cellColor]="'var(--color-primary14)'"
    [cellBorderColor]="'var(--color-primary15)'"
  ></sl-table-cards>

  <sl-alert
    *ngIf="alertDisableBilling.show"
    (close)="alertDisableBilling.show = false"
    [type]="alertDisableBilling.type"
    [closable]="alertDisableBilling.closable"
  >
    {{ alertDisableBilling.label | transloco }}
  </sl-alert>

  <div class="button-container">
    <ng-container *ngIf="order?.concepts">
      <sl-button
        class="expand-button"
        [type]="'secondary'"
        [borderRound]="true"
        [size]="'l'"
        [disabled]="isDisableBilling"
        (clicked)="onModifyOrderClick()"
      >
        {{ 'button.modify' | transloco | titlecase }}
      </sl-button>
    </ng-container>

    <ng-container *ngIf="order?.concepts">
      <sl-button
        class="expand-button"
        [type]="'primary'"
        [borderRound]="true"
        [size]="'l'"
        [disabled]="isDisableBilling"
        (clicked)="onSendBillClick()"
      >
        {{ 'button.sendBill' | transloco | titlecase }}
      </sl-button>
    </ng-container>
  </div>
</article>

<sl-modal-modify-order
  *ngIf="showModal === 'modifyOrder'"
  [title]="'modal.modify' | transloco"
  [subtitle]="'modal.modifySubtitle' | transloco"
  [order]="order"
  (closeModal)="onCloseModal()"
  (sendModifyOrder)="onSendModifyOrder($event)"
></sl-modal-modify-order>

<sl-modal-send-bill
  *ngIf="showModal === 'bill'"
  [title]="'modal.billTitle' | transloco"
  [subtitle]="'modal.billSubtitle' | transloco"
  [order]="order"
  (sendBill)="onModalSendBill($event)"
  (closeModal)="onCloseModal()"
></sl-modal-send-bill>

<sl-modal-modify-bill
  *ngIf="showModal === 'modifyBill'"
  [title]="'modal.modifyBill' | transloco"
  [subtitle]="'modal.modifyBillSubtitle' | transloco"
  [order]="order"
  (sendBill)="onSendModifyBill($event)"
  (closeModal)="onCloseModal()"
></sl-modal-modify-bill>

<sl-error-modal
  *ngIf="showErrorModal"
  [errorMessage]="'errorModal.message' | transloco"
  (closeModal)="showErrorModal = false"
></sl-error-modal>
<sl-success-modal
  *ngIf="showSuccessModal"
  [successMessage]="'successModal.' + successMessage | transloco"
  (closeModal)="showSuccessModal = false"
></sl-success-modal>
