import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SharedModule } from '@sl/shared';

@Component({
  selector: 'sl-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableListComponent implements OnChanges {
  @Input() tableData!: any[];
  @Input() title = 'contactAccess-data';

  arrayDataEntries: any[] = [];
  tableDataEntries: any[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tableData']) {
      this.updateTableData(changes['tableData'].currentValue);
      console.log(changes['tableData'].currentValue);
    }
  }

  updateTableData(newTableContent: any): void {
    this.tableData = [...newTableContent];
    this.tableDataEntries = [];
    this.initTable();
    this.cdr.detectChanges();
  }

  initTable(): void {
    this.tableData.forEach((element) => {
      if (Array.isArray(element[1])) {
        this.tableDataEntries.push(...this.addDataEntryFromArray(element[1]));
      } else if (typeof element[1] === 'object' && element[1]) {
        this.tableDataEntries.push(...this.addDataEntryFromObject(element[1]));
      } else {
        this.tableDataEntries.push(element);
      }
    });
  }

  addDataEntryFromArray(element: any[]): [string, any][] {
    const entries: any = [];

    element.forEach((item: any) => {
      const objectKeys = Object.keys(item);
      const dataEntry = [item[objectKeys[0]], item[objectKeys[1]]];

      entries.push(dataEntry);
    });

    return entries;
  }

  addDataEntryFromObject(object: object): [string, any][] {
    return Object.entries(object);
  }

  showData(name: string): boolean {
    let response = true;
    if (notShowFileds.includes(name) || !name) {
      response = false;
    }
    return response;
  }

  isArray(item: any): boolean {
    if (Array.isArray(item)) {
      item.forEach((field) => this.arrayDataEntries.push(Object.entries(field)));
    }
    return Array.isArray(item);
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}

const notShowFileds = ['contacts', 'generalDataModified', 'contactsDataModified', 'billingDataModified'];
